import { ITooltipParams } from '@ag-grid-community/core';
import React from 'react';
import styled from 'styled-components';

export const CommentTooltip = (props: ITooltipParams) => {
  return (
    <TooltipWrapper>
      <TooltipText>{props.value}</TooltipText>
    </TooltipWrapper>
  );
};

export const TooltipWrapper = styled.div`
  max-width: 500px;
  height: 100%;
  background-color: #666;
  border-radius: 4px;
  padding: 6px 3px;
`;

export const TooltipText = styled.p`
  color: #fff;
  text-align: center;
  height: 100%;
  display: block;
  word-break: break-all;
`;
