import useSWR, { Fetcher, SWRConfiguration } from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';

import { AlertNotificationCount } from './model';

export const useAlertNotificationCount = (
  config?: SWRConfiguration<any, Error, Fetcher<any, `alerts/notification/count`>>,
) => {
  const { data, mutate, error } = useSWR(`alerts/notification/count`, requestFromApi, config);

  return {
    alertCount: data as AlertNotificationCount | undefined,
    refresh: mutate,
    error,
  };
};
