import * as msal from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common';

import { apiTokenConfig, msalConfig } from '../../config/authConfig';

/**
 * Get an access token for the backend. First try silently then fallback to a popup.
 * Throws an error if no token can be retrieved.
 *
 * @returns the access token as string
 */
export async function getAccessToken() {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(apiTokenConfig);
    return tokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Azure singals that user interaction is required (e.g. password is prompted again),
      // so we trigger a redirect to aquire the token.
      await msalInstance.acquireTokenRedirect(apiTokenConfig);
    }
  }
}

export const msalInstance = new msal.PublicClientApplication(msalConfig);
