import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React from 'react';

import { ColumnSetting } from './useColumnSettings';

export function showFloatingFilters(gridApi: GridApi, visible: boolean) {
  const columnDefs = gridApi.getColumnDefs();

  if (columnDefs) {
    gridApi.setColumnDefs(
      columnDefs.map((col) => ({
        ...col,
        floatingFilter: visible,
      })),
    );
  }

  gridApi.refreshHeader();
}

export function getColumnSettingsFromGrid<ColId extends string>(
  columnApi: ColumnApi,
): Array<ColumnSetting<ColId>> {
  return columnApi.getColumnState().map(({ colId, hide }) => ({
    colId: colId as ColId, // it has to be a ColId since we filtered out undefined
    visible: !hide,
  }));
}

export function applyColumnSettings<ColId extends string>(
  columnApi: ColumnApi,
  columnDefinitions: Array<ColumnSetting<ColId>>,
) {
  columnApi.applyColumnState({
    state: columnDefinitions.map((col) => ({
      colId: col.colId,
      hide: !col.visible,
      sort: col.sort,
    })),
    applyOrder: true,
  });
}

export function resetGrid(gridRef: React.MutableRefObject<AgGridReact | null>) {
  const gridApi = gridRef.current?.api;

  if (!gridApi) {
    return;
  }

  gridApi.setRowData([{}]);
}

export function ensureEmptyRowAtBottom(gridRef: React.MutableRefObject<AgGridReact | null>) {
  const gridApi = gridRef.current?.api;
  if (!gridApi) return;

  const rowCount = gridApi.getModel().getRowCount();

  const lastRow = gridApi.getModel().getRow(rowCount - 1);
  const lastRowHasData = Object.values(lastRow?.data || {}).filter((value) => value).length != 0;

  if (lastRowHasData || lastRow === undefined) {
    gridApi.applyTransaction({ addIndex: rowCount, add: [{}] });
  }
}

export function addRowsFromClipboard(
  gridRef: React.MutableRefObject<AgGridReact | null>,
  data: string[][],
) {
  const gridApi = gridRef.current?.api;
  const columnApi = gridRef.current?.columnApi;
  if (!gridApi || !columnApi) return null;

  const focusedCell = gridApi.getFocusedCell();
  if (!focusedCell) return null;

  const newRows = data.map((data) => {
    const newRow: any = {};
    let currColumn = focusedCell.column;

    for (const columnData of data) {
      const fieldName = currColumn.getColDef().field;
      if (!fieldName) continue;

      newRow[fieldName] = columnData;

      const nextColumn = columnApi.getDisplayedColAfter(currColumn);
      if (!nextColumn) return; // no more in row -> cancel
      currColumn = nextColumn;
    }

    return newRow;
  });

  gridApi.applyTransaction({ addIndex: focusedCell.rowIndex, add: newRows });
  return null;
}
