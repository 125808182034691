import { useRef } from 'react';

let counter = 0;

/**
 * Hook that generates IDs for use as id property in DOM.
 * Use it if you need to reference an element by id (e.g. labels for inputs).
 */
export default function useDomId() {
  return useRef(`sft-${++counter}`).current;
}
