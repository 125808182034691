import { Stack, TextField } from '@mui/material';
import { DatePickerProps } from '@mui/x-date-pickers';
import { isBefore } from 'date-fns';
import React, { useEffect } from 'react';

import { DatePicker } from '../../../components/DatePicker';
import { datePickerErrorReasonToLocalizedText } from '../../../core/datePickerUtils';
import { t } from '../../../core/i18n/i18n';

type DatePickerPanelParams = {
  fromDate: Date | null;
  onFromDateChange: (value: Date | null) => void;
  toDate: Date | null;
  onToDateChange: (value: Date | null) => void;
  onDatePickerError: (value: boolean) => void;
  size: 'small' | 'medium';
  padded: boolean;
  fromPickerProps?: Partial<DatePickerProps<Date, Date>>;
  toPickerProps?: Partial<DatePickerProps<Date, Date>>;
};
export const DatePickerPanel = ({
  fromDate,
  onFromDateChange,
  toDate,
  onToDateChange,
  onDatePickerError,
  size,
  padded,
  fromPickerProps,
  toPickerProps,
}: DatePickerPanelParams) => {
  const [fromPickerErrorReason, setFromPickerErrorReason] = React.useState<string | null>(null);
  const [toPickerErrorReason, setToPickerErrorReason] = React.useState<string | null>(null);

  let fromErrorText = datePickerErrorReasonToLocalizedText(fromPickerErrorReason);
  let toErrorText = datePickerErrorReasonToLocalizedText(toPickerErrorReason);

  if (fromDate === null) {
    fromErrorText = t('error.date.emptyDate', {});
  }

  if (toDate === null) {
    toErrorText = t('error.date.emptyDate', {});
  }

  if (fromDate !== null && toDate !== null && isBefore(toDate, fromDate)) {
    toErrorText = t('validation_of_demand.date_picker.error', {});
  }

  useEffect(() => {
    onDatePickerError(fromErrorText != null || toErrorText != null);
  }, [fromErrorText, toErrorText, onDatePickerError]);

  return (
    <Stack direction="row" padding={padded ? '24px 0' : '0'} gap={2}>
      <DatePicker
        label={t('validation_of_demand.date_picker.start_date', {})}
        value={fromDate}
        onChange={(newValue) => {
          onFromDateChange(newValue);
        }}
        onError={(reason, _) => setFromPickerErrorReason(reason)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={fromErrorText !== null}
            helperText={fromErrorText}
            size={size}
          />
        )}
        {...fromPickerProps}
      />
      <DatePicker
        label={t('validation_of_demand.date_picker.end_date', {})}
        value={toDate}
        onChange={(newValue) => {
          onToDateChange(newValue);
        }}
        onError={(reason, _) => setToPickerErrorReason(reason)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={toErrorText !== null}
            helperText={toErrorText}
            size={size}
          />
        )}
        {...toPickerProps}
      />
    </Stack>
  );
};
