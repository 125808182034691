import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { IMRSubstitution } from '../../../domain/internalMaterialReplacement/model';
import { deleteIMRSubstitution } from '../../../domain/internalMaterialReplacement/saveSingleIMRSubstitution';

type DeleteSubstitutionDialogProps = {
  open: boolean;
  seletedEntry: IMRSubstitution;
  onClose: () => void;
};

export default function DeleteSubstitutionDialog({
  open,
  seletedEntry,
  onClose,
}: DeleteSubstitutionDialogProps) {
  const save = async () => {
    const postResult = await deleteIMRSubstitution(seletedEntry, false);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('generic.validation.save.success', {}),
    );
    enqueueSnackbar(userMessage.message, { variant: userMessage.variant });
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          {t('internal_material_replacement.text.deleteSubstitution', {})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('button.no', {})}</Button>
        <Button onClick={save} autoFocus>
          {t('button.yes', {})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
