import { isMonday, previousMonday } from 'date-fns';

import { t } from './i18n/i18n';

export function datePickerErrorReasonToLocalizedText(reason: string | null): string | null {
  switch (reason) {
    case 'invalidDate':
      return t('error.date.invalidFormat', {});
    case 'minDate':
      return t('error.date.beforeMin', {});
    case 'maxDate':
      return t('error.date.afterMax', {});
    case 'emptyDate':
      return t('error.date.emptyDate', {});
    default:
      return reason;
  }
}

export function mondayOfTheWeek(date: Date | number): Date {
  const dateToUse = new Date(date);
  return isMonday(dateToUse) ? dateToUse : previousMonday(dateToUse);
}
