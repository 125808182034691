import React from 'react';

import { useSelectableOptions } from '../../core/hooks/useSelectableOptions';
import MultiselectFromClipboard from '../inputs/MultiselectFromClipboard';
import { MultiselectFromClipboardLabelProps } from '../inputs/MultiselectFromClipboardModal';
import { MultiAutocompletePreLoaded } from '../inputs/autocomplete/MultiAutocompletePreLoaded';
import {
  AutocompleteLabelProps,
  AutocompleteMultiValueBaseProps,
} from '../inputs/baseComponents/MultiAutocompleteBase';
import {
  ResolveSelectableValueResult,
  SelectableValue,
} from '../inputs/baseComponents/selectableValues';

import { GlobalSelectionFieldContainer } from './GlobalSelectionFieldContainer';

export type PreLoadedAutocompleteWithMultiselectProps = {
  selectableOptionsPath: string;
  resolveFunction: (
    values: Array<string>,
    options: SelectableValue[],
  ) => Promise<Array<ResolveSelectableValueResult>>;
} & AutocompleteMultiValueBaseProps &
  AutocompleteLabelProps &
  MultiselectFromClipboardLabelProps;

export default function PreLoadedAutocompleteWithMultiselect(
  props: PreLoadedAutocompleteWithMultiselectProps,
) {
  // Load options once for autocomplete and multiselect
  const optionsLoadingResult = useSelectableOptions(props.selectableOptionsPath);

  return (
    <GlobalSelectionFieldContainer>
      <MultiAutocompletePreLoaded
        value={props.value}
        onValueChange={props.onValueChange}
        autocompleteLabel={props.autocompleteLabel}
        optionsLoadingResult={optionsLoadingResult}
        muiProps={props.muiProps}
      />
      <MultiselectFromClipboard
        value={props.value}
        onValueChange={props.onValueChange}
        autocompleteLabel={props.autocompleteLabel}
        optionsLoadingResult={optionsLoadingResult}
        entityName={props.entityName}
        entityNamePlural={props.entityNamePlural}
        selectableValuesByKeys={(values) =>
          props.resolveFunction(values, optionsLoadingResult.options)
        }
        muiProps={props.muiProps}
      />
    </GlobalSelectionFieldContainer>
  );
}
