import { Card, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { textLightGrey } from '../styles/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HintCard = styled(Card)`
  padding: 50px;
  margin-top: 70px;
`;

const Hint = styled(Typography)`
  color: ${textLightGrey};
`;

export default function DataHint({ text }: { text: string }) {
  return (
    <Container>
      <HintCard>
        <Hint variant={'h6'}>{text}</Hint>
      </HintCard>
    </Container>
  );
}
