import React, { useCallback } from 'react';

import { generateUrlWithSearchTerm, requestFromApi } from '../../core/requests/httpClient';
import MultiselectFromClipboard from '../inputs/MultiselectFromClipboard';
import { MultiselectFromClipboardLabelProps } from '../inputs/MultiselectFromClipboardModal';
import { MultiAutocompleteOnType } from '../inputs/autocomplete/MultiAutocompleteOnType';
import {
  AutocompleteLabelProps,
  AutocompleteMultiValueBaseProps,
} from '../inputs/baseComponents/MultiAutocompleteBase';
import { ResolveSelectableValueResult } from '../inputs/baseComponents/selectableValues';

import { GlobalSelectionFieldContainer } from './GlobalSelectionFieldContainer';

type OnTypeAutocompleteWithMultiselectProps = {
  urlBegin: string;
  resolveFunction: (values: Array<string>) => Promise<Array<ResolveSelectableValueResult>>;
  language?: string;
} & AutocompleteMultiValueBaseProps &
  AutocompleteLabelProps &
  MultiselectFromClipboardLabelProps;

export default function OnTypeAutocompleteWithMultiselect(
  props: OnTypeAutocompleteWithMultiselectProps,
) {
  const { urlBegin, language } = props;

  const getOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      const url = generateUrlWithSearchTerm(urlBegin, searchTerm, language);
      return requestFromApi(url, {
        signal,
      });
    },
    [urlBegin, language],
  );

  return (
    <GlobalSelectionFieldContainer>
      <MultiAutocompleteOnType
        value={props.value}
        onValueChange={props.onValueChange}
        autocompleteLabel={props.autocompleteLabel}
        getOptions={getOptions}
        muiProps={props.muiProps}
      />
      <MultiselectFromClipboard
        value={props.value}
        onValueChange={props.onValueChange}
        autocompleteLabel={props.autocompleteLabel}
        getOptions={getOptions}
        entityName={props.entityName}
        entityNamePlural={props.entityNamePlural}
        selectableValuesByKeys={props.resolveFunction}
        muiProps={props.muiProps}
      />
    </GlobalSelectionFieldContainer>
  );
}
