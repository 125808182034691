import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { isSameDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ErrorHandlingDatePicker } from '../../../../../components/DatePicker';
import { errorHelperTextProps } from '../../../../../components/FieldError';
import { SingleAutocompleteOnType } from '../../../../../components/inputs/autocomplete/SingleAutocompleteOnType';
import { SelectableValue } from '../../../../../components/inputs/baseComponents/selectableValues';
import { LoadingSpinnerModal } from '../../../../../components/loadingSpinner/LoadingSpinnerDialog';
import useDomId from '../../../../../core/hooks/useDomId';
import { t } from '../../../../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../../../../core/requests/httpClient';
import { validateMaterialNumber } from '../../../../../core/validation/filterValidation';
import { CfcrActionResponse } from '../../../../../domain/customerMaterialPortfolio/model';
import fetchForecastActionData from '../../../../../domain/customerMaterialPortfolio/useForecastActionData';
import { StatusSpecificContentProps } from '../StatusSpecificContent';
import { DemandPlanAdoption, demandPlanAdoptionOptions } from '../cmpModalTypes';

const MAX_DATE = new Date(9999, 12, 31);

export default function SubstitutionStatusSpecificContent({
  data,
  onDataChange,
  showValidation,
  setValid: setIsValid,
}: StatusSpecificContentProps) {
  const { successorMaterial, demandPlanAdoption, repDate } = data;
  const originalRepDate = useRef<Date | null>(repDate);
  const [repDateError, setRepDateError] = useState<DateValidationError | null>(null);
  const [forecastActionData, setForecastActionData] = useState<CfcrActionResponse | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isForecastActionInitialized, setIsForecastActionInitialized] = useState<boolean>(false);

  const customerNumberError: string | null = useMemo(() => {
    if (!successorMaterial) {
      return t('generic.validation.missing_fields', {});
    }

    const validationErrors = validateMaterialNumber(successorMaterial);
    if (validationErrors) {
      return validationErrors.join(', ');
    }

    return null;
  }, [successorMaterial]);

  const getMaterialNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-materials', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const fetchedData = await fetchForecastActionData(data);
    setForecastActionData(fetchedData);

    const defaultDemandPlanAdoption: DemandPlanAdoption | undefined = fetchedData?.cfcrActions.find(
      (action) => action.selected === true,
    )?.cfcrAction;

    if (defaultDemandPlanAdoption) {
      onDataChange({
        ...data,
        demandPlanAdoption: data.demandPlanAdoption || defaultDemandPlanAdoption,
      });
    }

    setIsLoading(false);
  }, [data, onDataChange]);

  useEffect(() => {
    if (isForecastActionInitialized) {
      return;
    }
    setIsForecastActionInitialized(true);
    fetchData();
  }, [fetchData, isForecastActionInitialized]);

  const refreshForecastActionData = (successorMaterial: string) => {
    setIsLoading(true);
    fetchForecastActionData({ ...data, successorMaterial })
      .then((forecastData) => {
        if (!forecastData) {
          return;
        }
        setForecastActionData(forecastData);

        const defaultDemandPlanAdoption: DemandPlanAdoption | undefined =
          forecastData?.cfcrActions.find((action) => action.selected === true)?.cfcrAction;

        if (defaultDemandPlanAdoption) {
          onDataChange({
            ...data,
            successorMaterial,
            demandPlanAdoption: defaultDemandPlanAdoption,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const repDateIsValid =
      (originalRepDate.current && repDate && isSameDay(originalRepDate.current, repDate)) ||
      !(repDateError || !repDate);
    const allFieldsValid =
      customerNumberError == null && repDateIsValid && demandPlanAdoption != null;
    setIsValid(allFieldsValid);
  }, [customerNumberError, setIsValid, demandPlanAdoption, originalRepDate, repDate, repDateError]);

  const handleDemandPlanAdoptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as DemandPlanAdoption;
    const newDemandPlanAdoption = demandPlanAdoptionOptions.includes(newValue) ? newValue : null;

    onDataChange({ ...data, demandPlanAdoption: newDemandPlanAdoption });
  };

  const handleSuccessorMaterialNrChange = (value: SelectableValue | null) => {
    onDataChange({ ...data, successorMaterial: value?.id || null });
  };

  const radioId = useDomId();
  return (
    <>
      <HeadlineSecondPart component="h3" variant="subtitle2">
        {t('customer_material_portfolio.modal.subheader.substitution', {})}
      </HeadlineSecondPart>

      <Grid container direction={'row'} spacing={2}>
        <Grid item xs={12} md={4}>
          <SingleAutocompleteOnType
            value={data.successorMaterial || ''}
            onValueChange={(_e, value) => {
              handleSuccessorMaterialNrChange(value);
              refreshForecastActionData(value?.id || '');
            }}
            autocompleteLabel={t('customer.material_portfolio.modal.substitution.successor', {})}
            getOptions={getMaterialNumberOptions}
            getOptionLabel={(o) => o.text}
            errorHelperTextProps={errorHelperTextProps(
              showValidation && Boolean(customerNumberError) ? [customerNumberError || ''] : [],
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ErrorHandlingDatePicker
            maxDate={MAX_DATE}
            minDate={new Date()}
            value={data.repDate}
            onChange={(date) => onDataChange({ ...data, repDate: date })}
            label={t('customer_material_portfolio.modal.substitution.label', {})}
            errorMessages={
              showValidation && !repDate ? [t('generic.validation.missing_fields', {})] : []
            }
            fullWidth
            onError={setRepDateError}
            validate={
              !originalRepDate.current || !repDate || !isSameDay(originalRepDate.current, repDate)
            }
          />
        </Grid>
      </Grid>

      <Grid container direction={'row'} spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={12} md={12}>
          <FormControl error={showValidation && !demandPlanAdoption}>
            <FormLabel id={radioId}>
              {t('customer.material_portfolio.modal.substitution.transfere_forecast', {})}
            </FormLabel>
            <RadioGroup value={data.demandPlanAdoption} row aria-labelledby={radioId}>
              <FormControlLabel
                value={'DELETE'}
                disabled={
                  !forecastActionData ||
                  forecastActionData?.cfcrActions.filter((action) => action.cfcrAction === 'DELETE')
                    .length === 0
                }
                checked={data.demandPlanAdoption == 'DELETE'}
                control={<Radio onChange={handleDemandPlanAdoptionChange} />}
                label={t(
                  'customer.material_portfolio.modal.substitution.transfere_forecast.delete',
                  {},
                )}
              />
              <FormControlLabel
                value={'COPY'}
                disabled={
                  !forecastActionData ||
                  forecastActionData?.cfcrActions.filter((action) => action.cfcrAction === 'COPY')
                    .length === 0
                }
                checked={data.demandPlanAdoption == 'COPY'}
                control={<Radio onChange={handleDemandPlanAdoptionChange} />}
                label={t(
                  'customer.material_portfolio.modal.substitution.transfere_forecast.copy',
                  {},
                )}
              />
              <FormControlLabel
                value={'ADD'}
                disabled={
                  !forecastActionData ||
                  forecastActionData?.cfcrActions.filter((action) => action.cfcrAction === 'ADD')
                    .length === 0
                }
                checked={data.demandPlanAdoption == 'ADD'}
                control={<Radio onChange={handleDemandPlanAdoptionChange} />}
                label={t(
                  'customer.material_portfolio.modal.substitution.transfere_forecast.add',
                  {},
                )}
              />
              {showValidation && !demandPlanAdoption && (
                <Grid item xs={12} md={12}>
                  <FormHelperText>{t('generic.validation.missing_fields', {})}</FormHelperText>
                </Grid>
              )}
            </RadioGroup>
          </FormControl>
          <Grid item xs={12} md={12}>
            <FormHelperText>
              {t('customer.material_portfolio.modal.substitution.transfere_forecast.hint', {})}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <LoadingSpinnerModal open={isLoading} />
    </>
  );
}

const HeadlineSecondPart = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
` as typeof Typography;
