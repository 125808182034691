import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Download, GridViewOutlined, Menu } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { ActionButton } from '../../../components/ActionButton';
import { checkRoles, demandValidationChangeAllowedRoles } from '../../../core/auth/roles';
import { DemandValidationFilter } from '../../../domain/demandValidation/demandValidationFilters';
import { KpiDateRanges } from '../../../domain/demandValidation/model';
import { PlanningView } from '../../../domain/demandValidation/planningView';
import { CustomerEntry } from '../../../domain/globalSelection/model';
import { useUserData } from '../../../domain/user/useUserData';
import { BatchDeletionModal } from '../batchDeletionModal/BatchDeletionModal';
import { ExportModal } from '../exportModal/ExportModal';
import { GridUploadModal } from '../gridUploadModal/GridUploadModal';
import { ListUploadModal } from '../listUploadModal/ListUploadModal';

const Buttons = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

enum DemandValidationModal {
  GridModal,
  ListModal,
  BatchDeleteModal,
  ExportModal,
}

export type ActionButtonsDVProps = {
  planningView: PlanningView;
  currentCustomer: CustomerEntry;
  customers: CustomerEntry[];
  dateRange: KpiDateRanges;
  reloadData: () => void;
  demandValidationFilters: DemandValidationFilter | undefined;
};

export default function ActionButtonsDemandValidation({
  planningView,
  currentCustomer,
  customers,
  dateRange,
  reloadData,
  demandValidationFilters,
}: ActionButtonsDVProps) {
  const [openModal, setOpenModal] = useState<DemandValidationModal | null>(null);

  const appInsights = useAppInsightsContext();
  const { roles } = useUserData();

  const authorizedToChange = checkRoles(roles, demandValidationChangeAllowedRoles);
  const disableUpload = useMemo(() => planningView !== PlanningView.REQUESTED, [planningView]);

  const onSaveInModal = (eventName: string) => () => {
    reloadData();
    appInsights.trackEvent({
      name: eventName,
    });
  };

  return (
    <>
      <Buttons>
        <ActionButton
          color={'secondary'}
          onClick={() => setOpenModal(DemandValidationModal.ExportModal)}
        >
          <Download />
        </ActionButton>

        <ActionButton
          onClick={() => setOpenModal(DemandValidationModal.ListModal)}
          disabled={!authorizedToChange || disableUpload}
        >
          <Menu />
        </ActionButton>

        <ActionButton
          onClick={() => setOpenModal(DemandValidationModal.GridModal)}
          disabled={!authorizedToChange || disableUpload}
        >
          <GridViewOutlined />
        </ActionButton>

        <ActionButton
          color={'secondary'}
          onClick={() => setOpenModal(DemandValidationModal.BatchDeleteModal)}
        >
          <DeleteIcon />
        </ActionButton>
      </Buttons>

      <ExportModal
        open={openModal === DemandValidationModal.ExportModal}
        onClose={() => setOpenModal(null)}
        customers={customers}
        dateRange={dateRange}
        demandValidationFilters={demandValidationFilters}
      />
      <GridUploadModal
        open={openModal === DemandValidationModal.GridModal}
        onSave={onSaveInModal('[Validated Sales Planning] Upload Grid')}
        onClose={() => setOpenModal(null)}
        customer={currentCustomer}
      />
      <ListUploadModal
        open={openModal === DemandValidationModal.ListModal}
        onSave={onSaveInModal('[Validated Sales Planning] Upload List')}
        onClose={() => setOpenModal(null)}
        customer={currentCustomer}
      />
      <BatchDeletionModal
        customer={currentCustomer}
        onSave={onSaveInModal('[Validated Sales Planning] Upload List')}
        onClose={() => setOpenModal(null)}
        open={openModal === DemandValidationModal.BatchDeleteModal}
      />
    </>
  );
}
