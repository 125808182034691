import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { parseDateIfPossible } from '../../../core/parseValues';

export const DateOrOriginalCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  const value = params.value;
  if (!value) {
    return null;
  }

  return parseDateIfPossible(value);
};
