import { AgEvent, IServerSideDatasource } from '@ag-grid-community/core';

import { DATA_FETCHED_EVENT, FETCH_ERROR_EVENT } from '../../core/datasources';
import { requestFromApi } from '../../core/requests/httpClient';
import { GlobalSelectionCriteriaFilters } from '../globalSelection/model';

import { DemandValidationStringFilter } from './demandValidationFilters';
import { DemandMaterialCustomerRequest } from './model';

export function createDemandMaterialCustomerDatasource({
  selectionFilters,
}: {
  selectionFilters?: GlobalSelectionCriteriaFilters & DemandValidationStringFilter;
}): IServerSideDatasource {
  return {
    getRows(params) {
      const { startRow, endRow, sortModel } = params.request;

      requestFromApi(`demand-validation/material-customer-list`, {
        method: 'POST',
        body: JSON.stringify({
          startRow,
          endRow,
          sortModel,
          selectionFilters,
        } as DemandMaterialCustomerRequest),
      })
        .then(({ rows, rowCount }) => {
          params.success({ rowData: rows, rowCount });

          // dispatch custom event when data is loaded, so we can react to it
          params.api.dispatchEvent({ type: DATA_FETCHED_EVENT });
        })
        .catch((e) => {
          params.fail();
          params.api.dispatchEvent({ type: FETCH_ERROR_EVENT, error: e } as AgEvent);
        });
    },
  };
}
