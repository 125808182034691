import { useCallback, useMemo } from 'react';
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router';

import { GlobalSelectionCriteriaFields } from '../../domain/globalSelection/model';

import {
  getGlobalSelectionFallback,
  removeGlobalSelectionFallback,
  setGlobalSelectionFallback,
} from './globalSelectionFallback';

export default function useGlobalSelectionCriteria(): {
  globalSelection: GlobalSelectionCriteriaFields | undefined;
  navigateWithGlobalSelection: (
    to: To,
    newSelection: GlobalSelectionCriteriaFields | undefined,
    options?: NavigateOptions,
  ) => void;
  updateGlobalSelectionOnPage: (newSelection: GlobalSelectionCriteriaFields | undefined) => void;
} {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateWithGlobalSelection = useCallback(
    (
      to: To,
      newSelection: GlobalSelectionCriteriaFields | undefined,
      options?: NavigateOptions,
    ) => {
      // When all selection fields are empty, there is no selection so it should be undefined
      const selectionToSet =
        newSelection &&
        Object.values(newSelection).some((selectionField) => selectionField.length > 0)
          ? newSelection
          : undefined;

      // Set fallback in session storage because navigation and global selection should be the same
      selectionToSet ? setGlobalSelectionFallback(selectionToSet) : removeGlobalSelectionFallback();

      // Navigate to new location with state
      navigate(to, { ...options, state: { ...options?.state, globalSelection: selectionToSet } });
    },
    [navigate],
  );

  const updateGlobalSelectionOnPage = useCallback(
    (newSelection: GlobalSelectionCriteriaFields | undefined) => {
      // The main source of global selection is the navigation,
      // so updating means pushing the same page with different navigation state.
      // The function also updates the fallback.
      navigateWithGlobalSelection(location.pathname, newSelection, { replace: true });
    },
    [location, navigateWithGlobalSelection],
  );

  const globalSelection = useMemo(() => {
    // Use the navigation state as source of truth
    const selectionFromNav =
      (location.state?.globalSelection as GlobalSelectionCriteriaFields) || undefined;
    if (selectionFromNav) return selectionFromNav;

    // Use values from session storage as fallback or after reload
    const selectionFallback = getGlobalSelectionFallback();
    if (selectionFallback) return selectionFallback;

    return undefined;
  }, [location]);

  return {
    globalSelection: globalSelection,
    navigateWithGlobalSelection: navigateWithGlobalSelection,
    updateGlobalSelectionOnPage: updateGlobalSelectionOnPage,
  };
}
