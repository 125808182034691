import { GetLocaleTextParams } from '@ag-grid-community/core/dist/cjs/es5/interfaces/iCallbackParams';

import { makeTranslate, TranslationFunction } from './i18nLib';
import { type DefaultLanguage } from './languages/de';

/**
 * All languages that are available in the application.
 * Add new languages here.
 */
const availableLanguages = {
  de: {
    label: 'DE',
    load: async () => import('./languages/de'),
    dateFnsLocale: async () => import('date-fns/locale/de'),
  },
  en: {
    label: 'EN',
    load: async () => import('./languages/en'),
    dateFnsLocale: async () => import('date-fns/locale/en-US'),
  },
  es: {
    label: 'ES',
    load: async () => import('./languages/es'),
    dateFnsLocale: async () => import('date-fns/locale/es'),
  },
  fr: {
    label: 'FR',
    load: async () => import('./languages/fr'),
    dateFnsLocale: async () => import('date-fns/locale/fr'),
  },
  it: {
    label: 'IT',
    load: async () => import('./languages/it'),
    dateFnsLocale: async () => import('date-fns/locale/it'),
  },
  ptBr: {
    label: 'PT (BR)',
    load: async () => import('./languages/pt-br'),
    dateFnsLocale: async () => import('date-fns/locale/pt-BR'),
  },
  zhCn: {
    label: 'CN',
    load: async () => import('./languages/zh-cn'),
    dateFnsLocale: async () => import('date-fns/locale/zh-CN'),
  },
  debug: {
    // "language" which displays raw i18n keys for creating and debugging translations
    label: 'I18N',
    load: async () => {
      const referenceLanguage = await import('./languages/de');
      const keyToKeyEntries = Object.keys(referenceLanguage.messages).map((key) => [key, key]);

      return {
        messages: Object.fromEntries(keyToKeyEntries),
      };
    },
    dateFnsLocale: async () => import('date-fns/locale/de'),
    hidden: !(process.env.I18N_SHOW_DEBUG_LANGUAGE?.toLowerCase() === 'true'),
  },
} as const;

export function getAvailableLanguages() {
  return Object.fromEntries(
    Object.entries(availableLanguages).filter(([_key, value]) =>
      'hidden' in value ? !value.hidden : true,
    ),
  ) as Partial<typeof availableLanguages>;
}

export type AvailableLanguage = keyof typeof availableLanguages;

const LOCAL_STORAGE_LANGUAGE = 'language';

/**
 * Stores the language preferred by the user.
 */
export function setPreferredLanguage(lang: AvailableLanguage) {
  localStorage.setItem(LOCAL_STORAGE_LANGUAGE, lang);
}

/**
 * Retreives the language set by the users, falls back to browser settings.
 */
export function getPreferredLanguage(): AvailableLanguage {
  const storedLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  if (storedLanguage && storedLanguage in availableLanguages) {
    return storedLanguage as AvailableLanguage;
  } else {
    return getDefaultLanguage();
  }
}

export function getLocaleText(params: GetLocaleTextParams) {
  return t(`grid.${params.key}` as any, {}, params.defaultValue);
}

/**
 * Tries to get a valid language from browser APIs.
 * Falls back to 'en'.
 * @returns
 */
function getDefaultLanguage(): AvailableLanguage {
  const lang = [
    ...(navigator.languages || []),
    (navigator as any).userLanguage,
    navigator.language,
    (navigator as any).browserLanguage,
  ].find((lang) => lang && lang in availableLanguages);

  return lang || 'en';
}

let translate: TranslationFunction<any, any> | null = null;

export async function initializeTranslation() {
  const preferredLanguage = getPreferredLanguage();

  // load preferred language and create translate function
  const selectedLanguage = availableLanguages[preferredLanguage];
  const langModule = await selectedLanguage.load();
  translate = makeTranslate(langModule.messages);

  return (await selectedLanguage.dateFnsLocale()).default;
}

// Implementation note: Params is not optional, because that makes the typescript
// compiler not complain about missing props defined for a key

/**
 * The typed translate function. Should only be called after {@link initializeTranslation}.
 *
 * @param key The key of the translation entry
 * @param params The params to feed to the template
 * @returns The translated string or the key if uninitialized
 */
export const t: TranslationFunction<keyof DefaultLanguage, DefaultLanguage> = (
  key,
  params,
  fallback,
) => {
  if (!translate) {
    console.error(`Trying to get '${key}' from uninitialized translations.`);
    return key;
  }

  return translate(key, params, fallback);
};
