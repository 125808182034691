import { createGlobalStyle } from 'styled-components';

import { defaultRowHeight } from '../agGrid/gridDefaults';

import { schaefflerColor } from './colors';

const AgGridStyles = createGlobalStyle`
  .ag-theme-material {
    --ag-material-accent-color: ${schaefflerColor};
    --ag-material-primary-color: ${schaefflerColor};
  }

  // correct the editor popup style
  .ag-theme-material .ag-cell-inline-editing {
    height: ${defaultRowHeight}px;
    padding-top: 16px;
  }

  .ag-tabs {
    min-width: 350px;
  }

  // correct the editor popup style
  .ag-theme-material input[class^=ag-]:not([type]), .ag-theme-material input[class^=ag-][type=text], .ag-theme-material input[class^=ag-][type=number], .ag-theme-material input[class^=ag-][type=tel], .ag-theme-material input[class^=ag-][type=date], .ag-theme-material input[class^=ag-][type=datetime-local], .ag-theme-material textarea[class^=ag-]{
    padding-bottom: 0;
  }
`;

export default AgGridStyles;
