import { PlanningView } from '../../../domain/demandValidation/planningView';
import { ChartUnitMode, ForecastChartData } from '../../../domain/forecastChart/model';

export const previewData: ForecastChartData = {
  currency: 'EUR',
  chartUnitMode: ChartUnitMode.CURRENCY,
  planningView: PlanningView.REQUESTED,
  chartEntries: [
    {
      yearMonth: '2022-01-01',
      orders: 0,
      deliveries: 6000,
      demandPlan: 0,
      opportunities: 0,
      opAdjustment: 0,
      rollingSalesForecast: null,
    },
    {
      yearMonth: '2022-02-01',
      orders: 0,
      deliveries: 4000,
      demandPlan: 0,
      opportunities: 0,
      opAdjustment: 0,
      rollingSalesForecast: null,
    },
    {
      yearMonth: '2022-03-01',
      orders: 0,
      deliveries: 5000,
      demandPlan: 0,
      opportunities: 0,
      opAdjustment: 0,
      rollingSalesForecast: null,
    },
    {
      yearMonth: '2022-04-01',
      orders: 200,
      deliveries: 8000,
      demandPlan: 0,
      opportunities: 0,
      opAdjustment: 0,
      rollingSalesForecast: null,
    },
    {
      yearMonth: '2022-05-01',
      orders: 400,
      deliveries: 0,
      demandPlan: 4000,
      opportunities: 500,
      opAdjustment: 300,
      rollingSalesForecast: 4000,
    },
    {
      yearMonth: '2022-06-01',
      orders: 200,
      deliveries: 0,
      demandPlan: 4500,
      opportunities: 400,
      opAdjustment: 200,
      rollingSalesForecast: 4500,
    },
    {
      yearMonth: '2022-07-01',
      orders: 0,
      deliveries: 0,
      demandPlan: 6000,
      opportunities: 600,
      opAdjustment: 300,
      rollingSalesForecast: 6000,
    },
    {
      yearMonth: '2022-08-01',
      orders: 0,
      deliveries: 0,
      demandPlan: 3000,
      opportunities: 100,
      opAdjustment: 100,
      rollingSalesForecast: 3000,
    },
    {
      yearMonth: '2022-09-01',
      orders: 0,
      deliveries: 0,
      demandPlan: 2500,
      opportunities: 100,
      opAdjustment: 100,
      rollingSalesForecast: 3000,
    },
  ],
};
