import * as React from 'react';
import { Link } from 'react-router-dom';

export const PrivacyPolicyIT = () => {
  return (
    <>
      <h2>Informativa sulla privacy</h2>
      <p>
        Siamo lieti dell’interesse dimostrato per il Gruppo Schaeffler (Schaeffler AG e società
        affiliate) e per i nostri prodotti. Proteggere la sfera privata dell&apos;utente quando
        utilizza i nostri servizi online è per noi di massima importanza. In caso di trattamento dei
        dati personali, ci atteniamo alle leggi vigenti in materia di protezione dei dati.
      </p>

      <h3>I. Informazioni generali sul trattamento dei dati </h3>

      <h4>1. Ambito e finalità del trattamento di dati personali </h4>
      <p>
        In linea di principio raccogliamo e utilizziamo i dati personali dell&apos;utente solo nella
        misura in cui è necessario per fornire un sito web funzionale, nonché per i contenuti e i
        servizi offerti nel nostro sito web. Il trattamento dei dati personali dell&apos;utente
        avviene sulla base del consenso da questi fornito oppure nella misura prevista dalle norme
        di legge che consentono il trattamento dei dati persino senza consenso.
      </p>

      <h4>2. Base giuridica del trattamento dei dati </h4>
      <p>
        Il trattamento dei dati personali dell&apos;utente è effettuato sulla base del Regolamento
        generale sulla protezione dei dati (GDPR, General Data Protection Regulation) dell’Unione
        Europea e secondo le leggi vigenti nel rispettivo Paese in materia di protezione dei dati,
        ad es. la legge federale tedesca in materia di protezione dei dati personali (BDSG,
        Bundesdatenschutzgesetz).
      </p>
      <p>
        Qualora l&apos;utente abbia già espresso il suo consenso al trattamento dei dati personali
        per determinate finalità, l’art. 6 par. 1 lett. a GDPR serve da base giuridica per il
        trattamento dei dati personali. Il consenso accordato può essere revocato dall&apos;utente
        in qualsiasi momento. Tenere presente che la revoca del consenso ha efficacia giuridica solo
        per il trattamento futuro. I trattamenti effettuati prima della revoca non rientrano nella
        revoca.
      </p>
      <p>
        Il trattamento di dati personali nell’ambito dell’esecuzione di contratti di cui
        l&apos;utente è parte contraente o dell’esecuzione di misure precontrattuali adottate su sua
        richiesta, viene effettuato sulla base dell’art. 6 par. 1 lett. b GDPR. Le finalità del
        trattamento dei dati si basano sui rispettivi documenti contrattuali e sull’oggetto del
        contratto.
      </p>
      <p>
        Qualora un trattamento di dati personali sia necessario per adempiere un obbligo legale a
        cui è soggetta la nostra impresa, la base giuridica è l’art. 6 par. 1 lett. c GDPR.
      </p>
      <p>
        Se il trattamento è necessario per perseguire un legittimo interesse di Schaeffler o di un
        terzo (ad es. per esercitare diritti legali e difesa in caso di controversie legali; per
        garantire la sicurezza informatica; per prevenire reati; per attività di gestione aziendale
        e per sviluppare ulteriormente servizi e prodotti) e se gli interessi, i diritti e le
        libertà fondamentali dell’utente quale interessato non prevalgono rispetto al primo
        interesse suddetto, l’art. 6 par. 1 lett. f GDPR è la base giuridica per il trattamento.
      </p>

      <h4>3. Cancellazione e periodo di conservazione dei dati </h4>
      <p>
        Trattiamo e conserviamo i dati personali dell&apos;utente fino a quando necessario per la
        rispettiva finalità di trattamento. Inoltre tale conservazione può essere effettuata per
        adempiere un obbligo legale previsto da leggi, normative o altre disposizioni
        dell&apos;Unione Europea o dello Stato membro cui è soggetto il titolare del trattamento. Se
        i dati non sono più necessari o se è scaduto un periodo di conservazione dei dati prescritto
        dalle leggi suddette, i dati di tracciatura saranno cancellati regolarmente dopo 90 giorni.
        Inoltre le previsioni dei clienti vengono conservate in Demand360. In caso di previsioni dei
        clienti appena create o modificate, queste sono scritte a SAP, insieme
        all&apos;abbreviazione del nome, e cancellate dopo 36 mesi. I dati delle impostazioni utente
        possono essere cancellati su richiesta.
      </p>

      <h4>4. Accesso ai dati personali all’interno di Schaeffler Group e da parte di terzi </h4>
      <p>
        All&apos;interno di Schaeffler Group è consentito l&apos;accesso ai dati dell&apos;utente
        alle persone giuridiche che lo richiedono secondo il cosiddetto principio del
        &quot;privilegio minimo&quot; (assegnazione dei diritti utente nella misura minima
        necessaria) e il principio della &quot;necessità di sapere&quot; (conoscere i dati solo se
        necessario). Possiamo comunicare i dati a terzi fuori dal Gruppo Schaeffler solo se
        necessario, se una norma di legge lo impone, se l&apos;utente ha espresso il consenso o se
        gli incaricati da noi del trattamento si sono impegnati contrattualmente al rispetto delle
        norme del GDPR e della legge in materia di protezione dei dati vigente nel rispettivo Paese.
      </p>
      <p>
        In base a tali condizioni, tra i destinatari dei dati personali ci possono essere: Microsoft
        Azure, SAP API Management e SAP
      </p>

      <h4>5. Trasferimento di dati personali verso paesi terzi o organizzazioni internazionali</h4>
      <p>
        Il trasferimento di dati in paesi esterni all’UE/SEE (i cosiddetti paesi terzi) ha luogo
        solo se necessario o prescritto per legge, se l’utente ha espresso il consenso oppure se ciò
        fa parte del trattamento dei dati svolto dal relativo responsabile. Qualora vengano
        utilizzati fornitori di servizi in paesi terzi, essi sono tenuti a conformarsi, oltre alle
        disposizioni scritte, al livello di protezione dei dati vigente in Europa accettando le
        clausole contrattuali standard UE.
      </p>

      <h4>6. Sicurezza informatica e link a siti web di terzi </h4>
      <p>
        Il Gruppo Schaeffler applica misure di sicurezza tecniche ed organizzative per proteggere i
        dati dell&apos;utente in caso di distruzione casuale o intenzionale, manipolazione, perdita
        o accesso da parte di persone non autorizzate. Queste misure di sicurezza seguono un
        aggiornamento continuo in linea con le nuove possibilità tecniche. Le nostre pagine web
        possono contenere link ad altri siti web di terzi. Le nostre informazioni sulla protezione
        dei dati non valgono per tali siti web esterni.
      </p>

      <h4>7. Obbligo di fornire i dati personali </h4>
      <p>
        Su questa pagina web mettiamo a disposizione servizi che l&apos;utente può utilizzare
        facoltativamente. L&apos;utente non ha l&apos;obbligo di fornirci i suoi dati, ma senza di
        essi per l&apos;utente potrebbe non essere possibile utilizzare i nostri servizi ed offerte.
      </p>

      <h4>8. Profilatura e processo decisionale automatizzato </h4>
      <p>
        Non utilizziamo processi decisionali completamente automatizzati ai sensi dell’art. 22 GDPR.
        In linea di massima Schaeffler non utilizza profilatura. Qualora la impiegassimo in singoli
        casi, informiamo l’utente con apposita comunicazione, sempre che sia prescritto dalla legge,
        e richiediamo eventualmente il consenso preventivo dell&apos;utente.
      </p>

      <h4>9. Fonti dei dati personali dell&apos;utente </h4>
      <p>Utilizziamo i dati che riceviamo dall&apos;utente.</p>

      <h3>
        II. Operazioni di trattamento dei dati nella pubblicazione del sito web e nella creazione di
        file di registro
      </h3>
      <p>
        Quando l&apos;utente visita il nostro sito web, i nostri server web registrano e raccolgono
        normalmente il nome del suo provider di servizi Internet, il suo indirizzo IP,
        l&apos;indirizzo web dal quale ci sta visitando, le pagine che visita nel nostro sito web,
        nonché la data e la durata della visita. Questi dati sono memorizzati nei file di registro
        dei nostri sistemi e servono all’analisi di problemi ed errori. L’utilizzo dell’indirizzo IP
        si limita tuttavia all’ambito tecnicamente necessario, viene accorciato e pertanto impiegato
        solo anonimizzato, in modo da rendere impossibile associare un indirizzo IP a un utente. I
        dati non vengono uniti con i dati personali.
      </p>
      <p>
        La base giuridica per la conservazione temporanea dei dati è l’art. 6 par. 1 lett. f GDPR.{' '}
      </p>
      <p>
        La conservazione provvisoria dell&apos;indirizzo IP accorciato da parte dei nostri sistemi è
        tecnicamente necessaria affinché il sito web sia visualizzato sul dispositivo terminale
        dell&apos;utente. La conservazione in file di registro serve a garantire la funzionalità del
        sito web. In questo contesto non viene effettuata analisi dei dati per finalità di
        marketing.
      </p>
      <p>
        La base giuridica per tale trattamento rientra nel nostro legittimo interesse al trattamento
        dei dati ai sensi dell’art. 6 par. 1 lett. f GDPR.
      </p>
      <p>
        La raccolta dei dati per rendere accessibile il sito web e la conservazione dei dati in file
        di registro sono assolutamente necessari per il funzionamento del sito web. Di conseguenza
        l&apos;utente non ha alcuna possibilità di opporsi a tale trattamento dei dati.
      </p>

      <h3>III. Trattamento dei dati in relazione a servizi offerti sul sito web </h3>
      <p>
        Sul nostro sito web vengono offerti diversi servizi, per il cui utilizzo vengono richiesti i
        dati personali dell&apos;utente. In questo contesto, per l&apos;utente è sempre facoltativo
        fornirci i suoi dati personali. In questo ambito, noi trattiamo i dati personali
        dell&apos;utente esclusivamente in forma anonima per rendere possibili le seguenti
        valutazioni:
      </p>
      <ul>
        <li>
          Valutazioni della frequenza delle richieste, tempi di risposta e frequenza di errori del
          sito web, per scoprire quali sono le pagine visitate più di frequente, a quale ora del
          giorno vengono visitate e da dove provengono gli utenti.
        </li>
        <li>
          Valutazioni delle visualizzazioni delle pagine e delle prestazioni di caricamento del sito
          web{' '}
        </li>
        <li>Valutazioni del numero di utenti e sessioni </li>
        <li>Valutazione di eventi e parametri definiti dall&apos;utente</li>
      </ul>
      <p>
        Inoltre trattiamo i dati personali dell&apos;utente con riferimento all&apos;impostazione
        utente. Per migliorare l&apos;esperienza dell&apos;utente, vengono salvate impostazioni
        specifiche dell&apos;applicazione come le impostazioni tabella preferite per la
        visualizzazione dei dati.
      </p>
      <p>
        Inoltre, per agevolare il processo di pianificazione delle vendite e delle operazioni
        (S&OP), i record di dati che l&apos;utente ha modificato o creato vengono memorizzati in
        SAP.
      </p>

      <h3>IV. Uso dei cookie </h3>
      <p>
        Impieghiamo cookie per consentire all&apos;utente di utilizzare il sito web nel modo
        migliore possibile. I cookie sono file di testo che vengono registrati nel browser Internet
        o salvati dal browser Internet nel dispositivo terminale dell&apos;utente. Quando
        l&apos;utente accede al nostro sito, può essere depositato un cookie nel sistema operativo
        dell&apos;utente. Tale cookie contiene una sequenza caratteristica di caratteri che permette
        di identificare in modo univoco il browser al successivo richiamo del sito.
      </p>
      <p>
        Seguono informazioni generali sull’utilizzo di cookie nel nostro sito web e sul conseguente
        trattamento dei dati personali. Ulteriori informazioni sui cookie effettivamente utilizzati
        sono disponibili nella Informativa sui cookie Schaeffler pubblicata su questo sito web.
        Facciamo uso di cookie per rendere il nostro sito web di più facile utilizzo. Alcuni
        elementi del nostro sito web rendono necessario identificare il browser richiedente anche
        dopo un cambio di pagina.
      </p>
      <p>
        Nel nostro sito web facciamo inoltre uso di cookie che permettono di analizzare il
        comportamento dell’utente durante la navigazione.
      </p>
      <p>
        Quando visita il nostro sito web l’utente viene informato dell&apos;utilizzo di cookie e
        viene indirizzato alla presente informativa sulla privacy.
      </p>
      <p>
        I cookie necessari a livello tecnico hanno la finalità di semplificare all&apos;utente
        l&apos;utilizzo del nostro sito web. Alcune funzioni del nostro sito web non possono essere
        offerte senza l&apos;impiego di cookie. Per tali funzioni è necessario che il browser venga
        riconosciuto anche dopo un cambio di pagina. I dati utente raccolti tramite i cookie
        necessari per motivi tecnici non sono utilizzati per profilare l’utente. Ulteriori
        informazioni sono disponibili nell&apos;Informativa sui cookie di Schaeffler. I cookie
        analitici sono utilizzati al fine di migliorare la qualità del nostro sito web e dei suoi
        contenuti. I cookie analitici ci dicono come viene utilizzato il sito web e ci permettono di
        ottimizzare costantemente la nostra offerta. Ulteriori informazioni sono disponibili
        nell&apos;Informativa sui <Link to={'/cookiePolicy'}>cookie di Schaeffler.</Link>.
      </p>
      <p>
        La base giuridica per il trattamento dei dati personali con l&apos;utilizzo di cookie è
        l’art. 6 par. 1 lett. f GDPR.
      </p>
      <p>
        I cookie sono salvati nel dispositivo terminale dell&apos;utente e da questo trasmessi al
        nostro sito. Per tale motivo l&apos;utente ha la possibilità di controllare totalmente
        l&apos;uso dei cookie. Modificando le impostazioni sul proprio browser Internet, è possibile
        disabilitare o limitare la trasmissione dei cookie. I cookie già salvati possono essere
        cancellati in qualsiasi momento. L’operazione può anche essere effettuata automaticamente.
        Disattivando i cookie per il nostro sito web, potrebbe non essere più possibile utilizzare
        tutte le funzioni del sito web.
      </p>

      <h3>V. I diritti dell’utente in qualità di soggetto interessato </h3>
      <p>
        Se vengono trattati i dati personali dell’utente, l’utente è soggetto interessato ai sensi
        del GDPR e vanta i seguenti diritti:
      </p>

      <h4>1. Diritto di accesso (art. 15 GDPR) </h4>
      <p>
        L&apos;interessato ha il diritto di ottenere da noi la conferma che sia o meno in corso un
        trattamento di dati personali che lo riguardano. In caso sussista un tale trattamento,
        l’interessato può ottenere l’accesso alle informazioni previste per legge (vedere art. 15
        par. 1 GDPR) e ha diritto ad essere informato su garanzie adeguate ai sensi dell&apos;art.
        46 GDPR in relazione al trasferimento dei dati, qualora i dati personali che lo riguardano
        siano trasferiti in un paese terzo o in un’organizzazione internazionale. Vigono le
        limitazioni di cui agli artt. 34 e 35 della legge federale tedesca in materia di protezione
        dei dati personali (BDSG, Bundesdatenschutzgesetz).
      </p>

      <h4>2. Diritto di rettifica (art. 16 GDPR) </h4>
      <p>
        L’interessato ha il diritto di ottenere da noi la rettifica e/o l’integrazione, qualora i
        dati personali trattati siano inesatti o incompleti. Noi dobbiamo effettuare immediatamente
        la rettifica.
      </p>

      <h4>3. Right to restriction of processing (Art. 18 GDPR)</h4>
      <p>
        In base ai requisiti di legge (vedi art. 18 par. 1 GDPR) l’interessato ha il diritto di
        richiedere a noi la limitazione del trattamento dei dati personali che lo riguardano. In
        merito alle conseguenze della limitazione consultare l’art. 18 par. 2 e 3 GDPR.
      </p>

      <h4>4. Diritto alla cancellazione (art. 17 GDPR) </h4>
      <p>
        L’interessato ha il diritto di ottenere da noi la rettifica e/o l’integrazione, qualora i
        dati personali trattati siano inesatti o incompleti. Noi dobbiamo effettuare immediatamente
        la rettifica.
      </p>

      <h4>5. Diritto ad essere informati </h4>
      <p>
        Qualora l’interessato abbia esercitato nei nostri confronti il diritto di rettifica,
        cancellazione o limitazione del trattamento, noi siamo tenuti a comunicare tale rettifica o
        cancellazione dei dati oppure limitazione del trattamento a tutti i destinatari a cui sono
        stati divulgati i dati personali dell&apos;interessato, a meno che ciò risultasse
        impossibile o implicasse uno sforzo sproporzionato. L’interessato ha il diritto nei nostri
        confronti di essere informato in merito a tali destinatari.
      </p>

      <h4>6. Diritto alla portabilità dei dati (art. 20 GDPR) </h4>
      <p>
        L’interessato ha il diritto di ricevere in formato strutturato, di uso comune e leggibile da
        dispositivo automatico, i dati personali che lo riguardano a noi forniti. Per i dettagli si
        rimanda all’art. 20 GDPR.{' '}
      </p>

      <h4>7. Diritto di opposizione (art. 21 GDPR) </h4>
      <p>
        L’interessato ha il diritto di opporsi in qualsiasi momento, per motivi connessi alla sua
        situazione particolare, al trattamento dei dati personali che lo riguardano ai sensi
        dell&apos;art. 6, par. 1, lett. e) o f) GDPR. Ulteriori dettagli sono riportati
        nell&apos;art. 21 GDPR.{' '}
      </p>
      <p>
        L’interessato ha inoltre diritto di presentare reclamo a un’autorità di controllo della
        protezione dei dati ai sensi dell’art. 77 GDPR e dell’art. 19 BDSG.{' '}
      </p>

      <h3>VI. Nome e indirizzo del titolare del trattamento</h3>
      <p>
        Schaeffler Technologies AG & Co. KG <br />
        Industriestraße 1-3 <br />
        91074 Herzogenaurach <br />
        Germania <br />
        Numero di telefono: + 49 9132 82-0 <br />
        Numero di fax: + 49 9132 82 - 49 50 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>

      <h3>VII. Informazioni di recapito del responsabile della protezione dei dati </h3>
      <p>
        Schaeffler AG <br />
        Datenschutzbeauftragter <br />
        Industriestraße1-3 <br />
        91074 Herzogenaurach <br />
        Germania <br />
        Numero di telefono: +49 9132 82-1476 <br />
        Numero di fax: +49 9132 82-5901 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>
      <small>Status: Version 1.0, 16.03.2022</small>
    </>
  );
};
