import { getAccessToken } from '../auth/authentication';
import { getPreferredLanguage } from '../i18n/i18n';

export const HEADER_CONTENTTYPE = 'Content-Type';
export const HEADER_ACCEPT_LANGUAGE = 'Accept-Language';
export const CONTENTTYPE_JSON = 'application/json';
export const CONTENTTYPE_TEXT = 'text/plain';

export type Options = {
  suppressContentTypeHeader?: boolean;
};

/**
 * The URL of the backend API. Has to end with a slash!
 *
 * Can be set via environment variable `BACKEND_URL`.
 * Defaults to `/api/` on the same origin.
 */
export const backendUrl = process.env.BACKEND_URL || '/api/';

/**
 * Perform an authenticated request to the backend.
 *
 * @param url The url to request
 * @param requestInit Options to pass to the fetch call
 * @param options Options to control the header generation
 * @returns the fetch {@link Response}
 */
export async function authenticatedFetch(
  url: string,
  requestInit?: RequestInit,
  options?: Options,
) {
  const accessToken = await getAccessToken();
  let preferredLanguage = getPreferredLanguage().toString();

  if (preferredLanguage.length == 4) {
    preferredLanguage = preferredLanguage.substring(0, 2) + '-' + preferredLanguage.substring(2, 4);
  }

  const fullUrl = backendUrl + url;

  if (!accessToken) {
    throw Error('No Access token found.');
  }

  const fullInit = {
    ...requestInit,
    headers: {
      ...(requestInit?.body && !options?.suppressContentTypeHeader
        ? { [HEADER_CONTENTTYPE]: CONTENTTYPE_JSON }
        : {}),
      ...requestInit?.headers,
      Authorization: `Bearer ${accessToken}`,
      [HEADER_ACCEPT_LANGUAGE]: preferredLanguage,
    },
  };

  return await fetch(fullUrl, fullInit);
}

export class HttpError extends Error {
  statusCode: number;
  details: unknown;

  constructor(statusCode: number, details: unknown) {
    super(`HttpError: ${statusCode}`);
    this.statusCode = statusCode;
    this.details = details;
  }
}

export class NetworkError extends Error {}

export class RequestAbortedError extends Error {}

/**
 * Sends a request to the API of the application specific backend.
 * Supports JSON and plain text.
 *
 * @throws {HttpError} if the response status is not ok.
 * @throws {NetworkError} if the request could not be sent.
 *
 * @returns the parsed body of the response if any, null otherwise
 */
export async function requestFromApi(url: string, fetchOptions?: RequestInit, options?: Options) {
  const res = await authenticatedFetch(url, fetchOptions, options).catch((e) => {
    if (e instanceof DOMException && e.name == 'AbortError') {
      throw new RequestAbortedError(e.message);
    }
    throw new NetworkError(e instanceof Error ? e.message : undefined);
  });

  if (!res.ok) {
    let errorDetails = null;

    try {
      errorDetails = await res.json();
    } catch (e) {
      console.warn('Could not parse error body', e);
    }

    throw new HttpError(res.status, errorDetails);
  }

  const contentType = getBaseContentType(res);

  if (!contentType) {
    return null; // no body
  } else if (contentType == CONTENTTYPE_JSON) {
    return await res.json();
  } else if (contentType == CONTENTTYPE_TEXT) {
    return await res.text();
  } else {
    throw new Error(`Unsupported content-type: ${contentType}`);
  }
}

function getBaseContentType(res: Response) {
  const base = res.headers.get(HEADER_CONTENTTYPE)?.toLocaleLowerCase().split(';');
  return base?.[0];
}

/**
 * Fetcher to use with SWR. Simple wrapper around {@link requestFromApi}.
 */
export async function swrFetcher([url, fetchOptions, options]: [
  url: string,
  fetchOptions?: RequestInit,
  options?: Options,
]) {
  return await requestFromApi(url, fetchOptions, options);
}

/**
 * Generates an url with the search params for 'search' and 'language' and
 * uses the encodeURI method for replacing special characters with UTF-8 replacements
 * @param url
 * @param searchTerm
 * @param language
 */
export function generateUrlWithSearchTerm(
  url: string,
  searchTerm: string,
  language?: string,
): string {
  return language
    ? url + '?' + new URLSearchParams({ search: searchTerm, language: language })
    : url + '?' + new URLSearchParams({ search: searchTerm });
}
