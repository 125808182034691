import { formatISO } from 'date-fns';
import useSWR from 'swr';

import { PlanningView } from '../demandValidation/planningView';
import { GlobalSelectionCriteriaFilters } from '../globalSelection/model';
import useCurrency from '../info/useCurrency';

import { ChartUnitMode, ForecastChartData } from './model';

export type ChartSettings = {
  startDate: Date;
  endDate: Date;
  planningView: PlanningView;
  chartUnitMode: ChartUnitMode;
};

export default function useForecastChartData(
  globalSelectionFilters: GlobalSelectionCriteriaFilters | undefined,
  columnFilters: Record<string, any>,
  chartSettings: ChartSettings,
) {
  const { currentCurrency } = useCurrency();

  const request = {
    startDate: formatISO(chartSettings.startDate, { representation: 'date' }),
    endDate: formatISO(chartSettings.endDate, { representation: 'date' }),
    currency: currentCurrency,
    chartUnitMode: chartSettings.chartUnitMode,
    planningView: chartSettings.planningView,
    selectionFilters: globalSelectionFilters,
    columnFilters: [...(columnFilters ? [columnFilters] : [])],
  };

  // Do not send a request if the applied selection filters are null, this means nothing has been loaded yet
  return useSWR<ForecastChartData>(
    globalSelectionFilters
      ? [
          'forecast-chart',
          {
            method: 'POST',
            body: JSON.stringify(request),
          },
        ]
      : null,
  );
}
