import { ICellRendererParams } from '@ag-grid-community/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu as MuiMenu, MenuItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import styled from 'styled-components';

import { getErrorMessage, useRateLimitedErrorNotification } from '../../../core/errors';
import useGlobalSelectionCriteria from '../../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { t } from '../../../core/i18n/i18n';
import { activateAlert, completeAlert, deactivateAlert } from '../../../domain/alerts/alertActions';
import { AlertCategory } from '../../../domain/alerts/model';
import { GlobalSelectionCriteriaFields } from '../../../domain/globalSelection/model';

type AdditionalProps = {
  onPerformAction?: () => void;
};

function AlertRowMenuButton(props: ICellRendererParams & AdditionalProps) {
  const id = props.data.id;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const showErrorNotification = useRateLimitedErrorNotification();
  const { enqueueSnackbar } = useSnackbar();
  const { navigateWithGlobalSelection } = useGlobalSelectionCriteria();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const complete = async () => {
    if (!id) return;

    try {
      await completeAlert(id);
      enqueueSnackbar(t('alert.action_menu.alert_completed', {}), { variant: 'success' });
      props.onPerformAction && props.onPerformAction();
      handleClose();
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const d360FunctionMapping = (openFunction: string) => {
    switch (openFunction) {
      case 'VOD':
        return { target: '/validationOfDemand', name: t('validation_of_demand.title', {}) };
      case 'CMP':
        return {
          target: '/customerMaterialPortfolio',
          name: t('customer_material_portfolio.title', {}),
        };
      default:
        console.log('Unknown open function: ' + openFunction);
        return { target: '/', name: openFunction };
    }
  };

  const deactivate = async () => {
    if (!id) return;

    try {
      await deactivateAlert(id);
      enqueueSnackbar(t('alert.action_menu.alert_deactivated', {}), { variant: 'success' });
      props.onPerformAction && props.onPerformAction();
      handleClose();
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const activate = async () => {
    if (!id) return;

    try {
      await activateAlert(id);
      enqueueSnackbar(t('alert.action_menu.alert_activated', {}), { variant: 'success' });
      props.onPerformAction && props.onPerformAction();
      handleClose();
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const menuId = `context-menu-${id}`;
  const btnId = `trigger-menu-${id}`;

  return (
    <>
      <MoreVert
        onClick={handleOpen}
        aria-controls={open ? menuId : undefined}
        aria-haspopup={true}
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVertIcon />
      </MoreVert>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': btnId,
        }}
      >
        <Typography sx={{ ml: 2 }} variant="overline">
          {t('alert.action_menu.goto_function', {
            function: d360FunctionMapping(props.data.openFunction).name,
          })}
        </Typography>

        <MenuItem
          onClick={() => {
            const newGlobalSelection = {
              materialNumber: [
                { id: props.data.materialNumber, text: props.data.materialDescription },
              ],
              customerNumber: [{ id: props.data.customerNumber, text: props.data.customerName }],
            } as GlobalSelectionCriteriaFields;

            navigateWithGlobalSelection(
              d360FunctionMapping(props.data.openFunction).target,
              newGlobalSelection,
            );
          }}
        >
          <ListItemText inset sx={{ pr: 6 }}>
            {t('alert.action_menu.base_combination', {})}
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            const newGlobalSelection = {
              alertType: [
                {
                  id: props.data.type,
                  text: t(`alert.category.${props.data.type as AlertCategory}`, {}),
                },
              ],
              customerNumber: [{ id: props.data.customerNumber, text: props.data.customerName }],
            } as GlobalSelectionCriteriaFields;

            navigateWithGlobalSelection(
              d360FunctionMapping(props.data.openFunction).target,
              newGlobalSelection,
            );
          }}
        >
          <ListItemText inset sx={{ pr: 6 }}>
            {t('alert.action_menu.customer_category', {})}
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            const newGlobalSelection = {
              customerNumber: [{ id: props.data.customerNumber, text: props.data.customerName }],
            } as GlobalSelectionCriteriaFields;

            navigateWithGlobalSelection(
              d360FunctionMapping(props.data.openFunction).target,
              newGlobalSelection,
            );
          }}
        >
          <ListItemText inset sx={{ pr: 6 }}>
            {t('alert.action_menu.customer', {})}
          </ListItemText>
        </MenuItem>

        <Divider />
        {props.data.open && (
          <MenuItem onClick={complete}>{t('alert.action_menu.complete', {})}</MenuItem>
        )}
        {props.data.deactivated && (
          <MenuItem onClick={activate}>{t('alert.action_menu.activate', {})}</MenuItem>
        )}
        {!props.data.deactivated && (
          <MenuItem onClick={deactivate}>{t('alert.action_menu.deactivate', {})}</MenuItem>
        )}
      </Menu>
    </>
  );
}

export default AlertRowMenuButton;

const Menu = styled(MuiMenu)`
  > * {
    min-width: 160px;
    padding-right: 24px;
  }
`;

const MoreVert = styled(IconButton)`
  color: #00893d;
`;
