import { ResponseWithResultMessage } from '../../core/errorhandling';
import { GlobalSelectionCriteriaFilters } from '../globalSelection/model';

export const replacementTypeValues = [
  'RELOCATION',
  'PARTIAL_RELOCATION',
  'PACKAGING_CHANGE',
  'PRODUCT_DEVELOPMENT',
  'DISCONTINUED',
  'CUSTOMER_DROPOUT',
] as const;

export type ReplacementType = (typeof replacementTypeValues)[number];

export type IMRRequest = {
  selectionFilters: GlobalSelectionCriteriaFilters;
  columnFilters: Record<string, any>;
  startRow: number;
  endRow: number;
  sortModel: Array<object>;
};

export type IMRSubstitution = {
  replacementType: ReplacementType | null;
  region: string;
  salesArea: string | null;
  salesOrg: string | null;
  customerNumber: string | null;
  predecessorMaterial: string | null;
  successorMaterial: string | null;
  replacementDate: Date | null;
  cutoverDate: Date | null;
  startOfProduction: Date | null;
  note: string | null;
};

export type IMRSubstitutionRequest = {
  replacementType: string;
  region: string;
  salesArea: string | null;
  salesOrg: string | null;
  customerNumber: string | null;
  predecessorMaterial: string | null;
  successorMaterial: string | null;
  replacementDate: string | null;
  cutoverDate: string | null;
  startOfProduction: string | null;
  note: string | null;
};

export type IMRSubstitutionResponse = {
  replacementType: ReplacementType | null;
  region: string;
  salesArea: string | null;
  salesOrg: string | null;
  customerNumber: string | null;
  predecessorMaterial: string | null;
} & ResponseWithResultMessage;
