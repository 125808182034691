import { formatISO } from 'date-fns';
import useSWR from 'swr';

import { KpiBucket, KpiDateRanges } from './model';
import { KpiDataRequestDateRange } from './useKpiData';

export type BucketRequest = {
  range1: KpiDataRequestDateRange;
  range2?: KpiDataRequestDateRange;
};

export default function useKpiBuckets(kpiDateRanges: KpiDateRanges) {
  const url = `demand-validation/buckets`;
  const requestParams: BucketRequest = {
    range1: {
      from: formatISO(kpiDateRanges.range1.from, { representation: 'date' }),
      to: formatISO(kpiDateRanges.range1.to, { representation: 'date' }),
      period: kpiDateRanges.range1.period,
    },
    range2: kpiDateRanges.range2
      ? {
          from: formatISO(kpiDateRanges.range2.from, { representation: 'date' }),
          to: formatISO(kpiDateRanges.range2.to, { representation: 'date' }),
          period: kpiDateRanges.range2.period,
        }
      : undefined,
  };

  const result = useSWR<KpiBucket[]>(
    requestParams
      ? [
          url,
          {
            method: 'POST',
            body: JSON.stringify(requestParams),
          },
        ]
      : undefined,
  );

  return result;
}
