import React, { useEffect, useState } from 'react';

import useSelectableOptionsOnType from '../../../core/hooks/useSelectableOptionsOnType';
import {
  MultiAutocompleteBase,
  AutocompleteLabelProps,
  AutocompleteMultiValueBaseProps,
} from '../baseComponents/MultiAutocompleteBase';
import {
  SelectableValue,
  toOptionsWithSearchCallIfNecessary,
} from '../baseComponents/selectableValues';

type MultiAutocompleteOnTypeProps = {
  getOptions: (searchTerm: string, signal?: AbortSignal) => Promise<SelectableValue[]>;
} & AutocompleteMultiValueBaseProps &
  AutocompleteLabelProps;

export const MultiAutocompleteOnType = (props: MultiAutocompleteOnTypeProps) => {
  const [processedValue, setProcessedValue] = useState<SelectableValue[]>([]);

  const { inputValue, onInputValueChange, options, loading, loadingError } =
    useSelectableOptionsOnType(props.getOptions);
  const { value, getOptions } = props;

  useEffect(() => {
    toOptionsWithSearchCallIfNecessary(value, getOptions).then(
      (selectedValues: SelectableValue[]) => {
        setProcessedValue(selectedValues);
      },
    );
  }, [value, getOptions]);

  return (
    <MultiAutocompleteBase
      autocompleteLabel={props.autocompleteLabel}
      value={processedValue}
      onValueChange={props.onValueChange}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      options={options}
      openDelayedAfterTyping={2}
      getOptionLabel={props.getOptionLabel}
      getOptionLabelInTag={props.getOptionLabelInTag}
      loading={loading}
      loadingError={loadingError}
      muiProps={{ forcePopupIcon: false, filterSelectedOptions: true, ...props.muiProps }}
    />
  );
};
