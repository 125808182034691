import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { ErrorHandlingSelect } from '../../../components/ErrorHandlingSelect';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { ModalHeadlineSeparator } from '../../../components/modal/ModalHeadline';
import {
  PostResult,
  ResponseWithResultMessage,
  errorsFromSAPtoMessage,
  singlePostResultToUserMessage,
} from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import {
  DemandCharacteristic,
  demandCharacteristics,
} from '../../../domain/materialCustomer/model';
import { CMPChangeModalFlavor } from '../table/statusActions';

import StatusSpecificContent from './cmpModalStatusSpecificElements/StatusSpecificContent';
import { CMPData } from './cmpModalStatusSpecificElements/cmpModalTypes';

type CMPMaterialChangeModalProps<T extends ResponseWithResultMessage> = {
  title: string;
  changeModalFlavor: CMPChangeModalFlavor;
  open: boolean;
  onClose: () => void;
  closeAndRefresh: () => void;
  cmpData: CMPData | null;
  onSave: (cmpData: CMPData, dryrun: boolean, confirmation?: boolean) => Promise<PostResult<T>>;
};

/**
 * This modal is a wrapper component for all edit actions and status changes of one material within the CMP function.
 * It renders the customer and material information and allows the user to change some specific data.
 * Editable fields depend on the action and the portfolioStatus in StatusSpecificContent.
 */
export default function CMPMaterialChangeModal<T extends ResponseWithResultMessage>({
  title,
  open,
  onClose,
  closeAndRefresh,
  cmpData,
  onSave,
}: CMPMaterialChangeModalProps<T>) {
  const snackbar = useSnackbar();

  const [showValidation, setShowValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  const [demandCharacteristic, setDemandCharacteristic] = useState<DemandCharacteristic | null>(
    cmpData ? cmpData.demandCharacteristic : null,
  );

  const [changedCMPData, setChangedCMPData] = useState<CMPData | null>(cmpData);
  const [statusSpecificDataIsValid, setStatusSpecificDataIsValid] = useState(true);
  const [confirmationMessage, setConfirmationMessage] = useState<string | undefined>(undefined);

  // set status specific data
  useEffect(() => {
    setChangedCMPData(cmpData);
    setDemandCharacteristic(() => cmpData?.demandCharacteristic || null);
  }, [cmpData]);

  // reset data when closing
  const close = (refresh?: boolean) => {
    setDemandCharacteristic(null);
    setShowValidation(false);
    if (refresh) {
      closeAndRefresh();
    } else {
      onClose();
    }
  };

  const onDataChange = (changedCMPData: CMPData) => {
    setChangedCMPData(changedCMPData);
  };

  const onSaveModal = (confirmed: boolean) => async () => {
    setLoading(true);
    const customerNumber = cmpData?.customerNumber;
    const materialNumber = cmpData?.materialNumber;
    const hasStatusSpecificData =
      cmpData?.portfolioStatus === 'PI' ||
      cmpData?.portfolioStatus === 'PO' ||
      cmpData?.portfolioStatus === 'SE';

    if (
      !demandCharacteristic ||
      (hasStatusSpecificData && !statusSpecificDataIsValid) ||
      !changedCMPData ||
      !customerNumber ||
      !materialNumber
    ) {
      setShowValidation(true);
      snackbar.enqueueSnackbar(t('generic.validation.check_inputs', {}), { variant: 'error' });
      setLoading(false);
      return;
    }

    const postResult = await onSave(
      {
        ...changedCMPData,
        demandCharacteristic,
      },
      false,
      confirmed,
    );

    // If the requested successor is not already in the portfolio of this customer,
    // the user has to confirm adding it. After confirmation, the request can run again with the confirmed param set.
    if (
      cmpData.portfolioStatus == 'SE' &&
      postResult.overallStatus == 'WARNING' &&
      postResult.overallErrorMsg ==
        t('customer.material_portfolio.modal.substitution.warning.add_material', {})
    ) {
      setConfirmationMessage(postResult.overallErrorMsg);
      setLoading(false);
      return;
    }

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('generic.validation.save.success', {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });
    setLoading(false);
    if (userMessage.variant == 'success' || userMessage.variant == 'warning') close(true);
  };

  return (
    <Modal onClose={() => close()} fullWidth open={open} maxWidth={'md'}>
      <LoadingSpinnerModal open={loading} />
      <Dialog open={Boolean(confirmationMessage)} onClose={() => setConfirmationMessage(undefined)}>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationMessage(undefined)}>{t('button.no', {})}</Button>
          <Button
            onClick={() => {
              setConfirmationMessage(undefined);
              onSaveModal(true)();
            }}
            autoFocus
          >
            {t('button.yes', {})}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal.Headline onClose={close} text={title}>
        <Button size="medium" variant="contained" color="primary" onClick={onSaveModal(false)}>
          {t('button.save', {})}
        </Button>
      </Modal.Headline>
      <ModalHeadlineSeparator />
      <Modal.Body>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              label={t('customer_material_portfolio.modal.customer_number', {})}
              disabled={true}
              value={cmpData?.customerNumber || ''}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label={t('customer_material_portfolio.modal.material', {})}
              disabled={true}
              value={cmpData?.materialDescription || ''}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth size={'small'}>
              <ErrorHandlingSelect<DemandCharacteristic>
                label={t('customer_material_portfolio.modal.demand_characteristic', {})}
                value={demandCharacteristic}
                onChange={(newValue) => {
                  setDemandCharacteristic(newValue);
                }}
                options={demandCharacteristics.map((dc) => ({
                  key: dc,
                  displayValue: t(`field.demandCharacteristic.value.${dc}`, {}),
                }))}
                errorMessages={
                  showValidation && !demandCharacteristic
                    ? [t('generic.validation.missing_fields', {})]
                    : []
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        {changedCMPData && (
          <StatusSpecificContent
            data={changedCMPData}
            onDataChange={onDataChange}
            showValidation={showValidation}
            setValid={setStatusSpecificDataIsValid}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
