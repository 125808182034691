import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { t } from '../../../core/i18n/i18n';
import { DemandCharacteristic } from '../../../domain/materialCustomer/model';

export const DemandCharacteristicCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  return (
    <span>
      {t(
        `demand_characterictics.${params.value as DemandCharacteristic}`,
        {},
        t(`error.valueUnknown`, {}),
      )}
    </span>
  );
};
