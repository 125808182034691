import { Settings } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ActionButton } from '../../../components/ActionButton';
import { t } from '../../../core/i18n/i18n';
import { PlanningView } from '../../../domain/demandValidation/planningView';

export type DemandValidationSettingsProps = {
  planningView: PlanningView;
  onPlanningViewChange: (newValue: PlanningView) => void;
};

export function DemandValidationSettings({
  planningView,
  onPlanningViewChange,
}: DemandValidationSettingsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <ActionButton
        onClick={({ currentTarget }) => {
          setAnchorEl(currentTarget);
        }}
        variant={'text'}
        color={'secondary'}
      >
        <Settings />
      </ActionButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Stack padding={'24px'} direction="column">
            <FormGroup>
              <FormControl>
                <RadioGroup
                  value={planningView}
                  onChange={(e) => {
                    onPlanningViewChange(e.target.value as PlanningView);
                  }}
                >
                  <StyledMenuItem>
                    <FormControlLabel
                      value={PlanningView.REQUESTED}
                      label={t('planing_type.title.REQUESTED', {})}
                      control={<Radio />}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <FormControlLabel
                      value={PlanningView.CONFIRMED}
                      label={t('planing_type.title.CONFIRMED', {})}
                      control={<Radio />}
                    />
                  </StyledMenuItem>
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
}

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;
