import { t } from '../i18n/i18n';

import {
  condenseValidationResults,
  validateExactLength,
  validateForNumbers,
  validateMaxLength,
} from './validationHelper';

export function validateSalesOrg(value: string): Array<string> | null {
  const validLength = validateMaxLength(value, 4);
  const validTypes = validateForNumbers(value);

  return condenseValidationResults([validLength, validTypes]);
}

export function validateGkamNumber(value: string): Array<string> | null {
  const validLength = validateMaxLength(value, 6);
  const validTypes = validateForNumbers(value);

  return condenseValidationResults([validLength, validTypes]);
}

export function validateCustomerNumber(value: string): Array<string> | null {
  const validLength = validateMaxLength(value, 10);
  const validTypes = validateForNumbers(value);

  return condenseValidationResults([validLength, validTypes]);
}

export function validateMaterialNumber(value: string): Array<string> | null {
  const valueForChecking = value.replace(/-/g, '');
  const validExactLength = validateExactLength(valueForChecking, 15);
  const validTypes = validateForNumbers(valueForChecking);

  return condenseValidationResults([validExactLength, validTypes]);
}

export function validateProductionPlants(value: string): Array<string> | null {
  const validLength = validateMaxLength(value, 4);
  const validTypes = validateForNumbers(value);

  return condenseValidationResults([validLength, validTypes]);
}

export function validateSectors(value: string): Array<string> | null {
  const validLength = validateMaxLength(value, 4);
  const firstChar = value[0] == 'V' ? null : t('error.sectorWrongBegin', {});
  const validTypes = validateForNumbers(value.slice(1));

  return condenseValidationResults([validLength, firstChar, validTypes]);
}

export function validateProductionSegment(value: string): Array<string> | null {
  const validLength = validateMaxLength(value, 6);
  const validTypes = validateForNumbers(value);

  return condenseValidationResults([validLength, validTypes]);
}
