import { AgEvent, IServerSideDatasource } from '@ag-grid-community/core';

import { formatFilterModelForBackend } from '../../agGrid/FilterModel';
import { DATA_FETCHED_EVENT, FETCH_ERROR_EVENT } from '../../core/datasources';
import { requestFromApi } from '../../core/requests/httpClient';
import { GlobalSelectionCriteriaFilters } from '../globalSelection/model';

import { IMRRequest } from './model';

export function createInternalMaterialReplacementDatasource({
  selectedRegion,
}: {
  selectedRegion: string;
}): IServerSideDatasource {
  return {
    getRows(params) {
      const { startRow, endRow, sortModel, filterModel } = params.request;
      const columnFilters = formatFilterModelForBackend(filterModel);

      const selectionFilter: GlobalSelectionCriteriaFilters = { region: [selectedRegion] };
      const request: IMRRequest = {
        selectionFilters: selectionFilter,
        columnFilters: [columnFilters],
        sortModel: sortModel,
        startRow: startRow || 0,
        endRow: endRow || 50,
      };

      requestFromApi(`internal-material-replacement`, {
        method: 'POST',
        body: JSON.stringify(request),
      })
        .then(({ rows, rowCount }) => {
          params.success({ rowData: rows, rowCount: rowCount });

          // dispatch custom event when data is loaded, so we can react to it
          params.api.dispatchEvent({ type: DATA_FETCHED_EVENT });
        })
        .catch((e) => {
          params.fail();
          params.api.dispatchEvent({ type: FETCH_ERROR_EVENT, error: e } as AgEvent);
        });
    },
  };
}
