import { ColDef, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  getDefaultColDef,
  serverSideTableDefaultProps,
  sideBar,
} from '../../../agGrid/gridDefaults';
import { GridApis } from '../../../agGrid/gridTypes';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { DATA_FETCHED_EVENT, getIdForRow } from '../../../core/datasources';
import { useSelectableOptions } from '../../../core/hooks/useSelectableOptions';
import { getPreferredLanguage, t } from '../../../core/i18n/i18n';
import { createAlertDatasource } from '../../../domain/alerts/datasource';
import { Alert, AlertCategory, AlertStatus } from '../../../domain/alerts/model';
import useCurrency from '../../../domain/info/useCurrency';

import AlertRowMenuButton from './AlertRowMenuButton';
import {
  getAlertTableColumnDefinitions,
  getAlertTypeFilterParams,
} from './alertTableColumnDefinitions';

type AlertTableProps = {
  alertStatus: AlertStatus;
  onUpdateAlert?: () => void;
  onGridReady?: (event: GridReadyEvent) => void;
};

function AlertTable(props: AlertTableProps) {
  const { onUpdateAlert } = props;
  const [grid, setGrid] = useState<GridApis | undefined>();
  const [rowCount, setRowCount] = useState<number | null>(null);
  const { currentCurrency } = useCurrency();

  const alertTypeOptions = useSelectableOptions(
    `global-selection/alert-types?language=${getPreferredLanguage()}`,
  );

  useEffect(() => {
    if (!grid) return;

    const listener = () => {
      const storeState = grid.api.getServerSideGroupLevelState()[0];
      setRowCount(storeState.rowCount);
    };
    setRowCount(0);
    grid.api.addEventListener(DATA_FETCHED_EVENT, listener);
  }, [grid]);

  useEffect(() => {
    if (rowCount === 0) {
      grid?.api.showNoRowsOverlay();
    } else {
      grid?.api.hideOverlay();
    }
  }, [rowCount, grid]);

  const datasource = useMemo(
    () => createAlertDatasource({ status: props.alertStatus, currency: currentCurrency }),
    [props.alertStatus, currentCurrency],
  );

  const columnDefs = useMemo(() => {
    // use translations from frontend instead of SAP and fallback to SAP translation
    const alertTypesWithTranslations = alertTypeOptions.options.map((v) => ({
      id: v.id,
      text: t(`alert.category.${v.id as AlertCategory}`, {}, v.text),
    }));
    const alertTypeFilter = getAlertTypeFilterParams(alertTypesWithTranslations);
    const columnDefinitions = getAlertTableColumnDefinitions(alertTypeFilter);

    return [
      ...(columnDefinitions.map((col) => ({
        ...getDefaultColDef(col.filter, col.filterParams),
        key: col.colId,
        field: col.property,
        headerName: t(col.colId, {}),
        sortable: col.sortable,
        filter: col.filter,
        flex: col.flex,
        type: col.type,
        cellRenderer: col.cellRenderer,
        minWidth: col.minWidth,
        maxWidth: col.maxWidth,
        tooltipComponent: col.tooltipComponent,
        tooltipField: col.tooltipField,
      })) || []),
      {
        field: 'id',
        headerName: '',
        lockVisible: true,
        pinned: 'right',
        lockPinned: true,
        cellRenderer: 'alertMenuRenderer',
        cellRendererParams: {
          onPerformAction: () => {
            onUpdateAlert && onUpdateAlert();
          },
        },
        suppressMenu: true,
        maxWidth: 64,
        suppressSizeToFit: true,
      },
    ] as ColDef[];
  }, [onUpdateAlert, alertTypeOptions]);

  return (
    <>
      <AlertStatusWrapper>
        <StyledGridSection>
          <AgGridReact
            {...serverSideTableDefaultProps}
            onGridReady={(event) => {
              props.onGridReady && props.onGridReady(event);
              setGrid(event);
            }}
            rowClassRules={{
              hasStatus: () => true,
              isActive: (params) => (params.data as Alert | undefined)?.open === true,
              isCritical: (params) =>
                (params.data as Alert | undefined)?.open === true &&
                (params.data as Alert | undefined)?.priority === true,
              isDeactivated: (params) => (params.data as Alert | undefined)?.deactivated === true,
              isClosed: (params) => (params.data as Alert | undefined)?.open === false,
            }}
            rowHeight={51}
            serverSideDatasource={datasource}
            getRowId={getIdForRow}
            components={{
              ...serverSideTableDefaultProps.components,
              alertMenuRenderer: AlertRowMenuButton,
            }}
            sideBar={sideBar}
            columnDefs={columnDefs}
          ></AgGridReact>
        </StyledGridSection>
      </AlertStatusWrapper>
    </>
  );
}

const AlertStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 400px;
  flex-grow: 1;

  .ag-center-cols-container {
    .hasStatus {
      &::before {
        display: block;
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
      }
    }

    .isActive {
      &::before {
        background: #f5a623;
      }
    }

    .isCritical {
      &::before {
        background: #e47280;
      }
    }

    .isDeactivated {
      &::before {
        background: #646464;
      }
    }

    .isClosed {
      &::before {
        background: #00893d;
      }
    }
  }
`;

export default AlertTable;
