import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import * as React from 'react';
import { useState } from 'react';

import { datePickerErrorReasonToLocalizedText } from '../core/datePickerUtils';
import { datePickerMasks, preferredDateFormat } from '../core/i18n/l10n';

import { errorHelperTextProps } from './FieldError';

export const DatePicker = ({
  inputFormat = preferredDateFormat,
  mask = datePickerMasks.preferredDateFormat,
  ...rest
}: DatePickerProps<Date, Date>) => {
  return <MuiDatePicker inputFormat={inputFormat} mask={mask} {...rest} />;
};

export type ErrorHandlingDatePickerProps = Omit<DatePickerProps<Date, Date>, 'renderInput'> & {
  errorMessages?: Array<string>;
  helperText?: string;
  validate?: boolean;
} & Pick<TextFieldProps, 'fullWidth'>;

/**
 * date picker with simple error handling and internal handling of internally risen errors
 */
export const ErrorHandlingDatePicker = ({
  errorMessages = [],
  helperText,
  fullWidth = false,
  validate = true,
  onChange,
  onAccept,
  ...rest
}: ErrorHandlingDatePickerProps) => {
  const [errorReason, setErrorReason] = useState<string | null>(null);

  const errorText = validate === true ? datePickerErrorReasonToLocalizedText(errorReason) : null;

  const handleError = React.useCallback(
    (reason: DateValidationError, value: Date | null) => {
      setErrorReason(reason);
      if (rest.onError) {
        rest.onError(reason, value);
      }
    },
    [rest, setErrorReason],
  );

  const renderInput = React.useCallback(
    (params: TextFieldProps) => {
      return (
        <TextField
          {...params}
          {...errorHelperTextProps(
            [...(validate ? errorMessages : []), ...(errorText ? [errorText] : [])],
            helperText,
          )}
          FormHelperTextProps={{ component: 'div' }}
          size="small"
          fullWidth={fullWidth}
        />
      );
    },
    [errorText, helperText, errorMessages, fullWidth, validate],
  );

  return (
    <DatePicker
      onChange={onChange}
      {...rest}
      onError={handleError}
      renderInput={renderInput}
      onAccept={onAccept}
    />
  );
};
