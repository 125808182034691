import styled from 'styled-components';

export const GlobalSelectionFieldContainer = styled.div`
  display: flex;
  margin: 8px 0;
  width: 25%;

  &:not(:nth-child(4n)) {
    padding-right: 20px;
  }

  @media (max-width: 680px) {
    width: 100%;
    padding-right: 0;
  }
`;
