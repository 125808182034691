import * as React from 'react';

export const ImprintEN = () => {
  return (
    <>
      <h2>Imprint</h2>
      <h3>Responsible for Internet Website Contents</h3>
      <p>
        Schaeffler AG
        <br />
        Industriestraße 1-3
        <br />
        91074 Herzogenaurach
        <br />
        Germany
      </p>
      <p>
        Phone: +49 9132 82-0
        <br />
        Fax: +49 9132 82-49 50
      </p>
      <p>
        <a
          className="icon icon-mail"
          data-widget="GATrakken"
          data-ar-event-category="Engagement"
          data-ar-event-action="Content/Mailto"
          data-ar-event-label="info@schaeffler.com mailto:info@schaeffler.com "
          href="mailto:info@schaeffler.com "
          target="_blank"
          title="info@schaeffler.com "
          rel="noreferrer"
        >
          info@schaeffler.com
        </a>
      </p>
      <p>
        <strong>Registered Seat:</strong> Herzogenaurach
        <br />
        <strong>Commercial Register:</strong>
        Amtsgericht Fürth HRA 10129
        <br />
        <strong>Valued-added tax identification no.:</strong>
        DE 291 636 029
        <br />
      </p>
      <p>
        <strong>General Partner:</strong> Schaeffler AG
        <br />
        <strong>Registered Seat:</strong> Herzogenaurach
        <br />
        <strong>Commercial Register:</strong> Amtsgericht Fürth, HRB 14738
        <br />
      </p>
      <p>
        <strong>Chairman of the Supervisory Board:</strong> Georg F. W. Schaeffler
      </p>
      <p>
        <strong>Board of Managing Directors:</strong> Klaus Rosenfeld (CEO), Claus Bauer, Andreas
        Schick, Corinna Schittenhelm, Jens Schüler, Dr. Stefan Spindler, Uwe Wagner, Matthias Zink
      </p>
      <p>
        <strong>
          Responsible for content in accordance with § 55 paragraph 2 RStV (German Federal Agreement
          on Broadcasting and Telemedia):
        </strong>{' '}
        Denis Wiegel
        <br />
        Schaeffler Technologies AG & Co. KG
      </p>
      <p className="g_lastchild">
        <strong>Liability Notice:</strong> Despite carefully verifying the contents, we do not
        assume any liability for the contents of links to external websites. The owners of these
        external sites are solely responsible for the contents of these sites.
      </p>
    </>
  );
};
