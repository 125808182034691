import { PostResult } from '../../core/errorhandling';
import { getErrorMessage } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';

import { IMRSubstitution, IMRSubstitutionResponse } from './model';
import { dataToIMRSubstitutionRequest } from './requestHelper';

export const deleteIMRSubstitution = async (substitution: IMRSubstitution, dryrun: boolean) =>
  writeSingleIMRSubstitution(substitution, dryrun, true);

export const saveSingleIMRSubstitution = async (substitution: IMRSubstitution, dryrun: boolean) =>
  writeSingleIMRSubstitution(substitution, dryrun, false);

async function writeSingleIMRSubstitution(
  substitution: IMRSubstitution,
  dryrun: boolean,
  deleteData = false,
): Promise<PostResult<IMRSubstitutionResponse>> {
  try {
    const request = dataToIMRSubstitutionRequest(substitution);

    const action = deleteData ? 'DELETE' : 'POST';

    const url = encodeURI(`internal-material-replacement/single-substitution?dryRun=${dryrun}`);
    const result = (await requestFromApi(url, {
      method: action,
      body: JSON.stringify(request),
    })) as IMRSubstitutionResponse;

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: [result],
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}
