import { Button, Typography, Popover, Stack, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { DatePickerProps } from '@mui/x-date-pickers';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { t } from '../../../core/i18n/i18n';
import { datePickerMasks, preferredDateFormatWithoutDay } from '../../../core/i18n/l10n';
import { firstViewableDate, lastViewableDate } from '../../../domain/demandValidation/limits';

import { DatePickerPanel } from './DatePickerPanel';

type Props = {
  startDate: Date;
  endDate: Date;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSave: (startDate: Date, endDate: Date) => void;
};

export function ForecastChartDatepickerPopover(props: Props) {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(props.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(props.endDate);

  const [datePickerError, setDatePickerError] = React.useState(false);

  useEffect(() => {
    setSelectedStartDate(props.startDate);
    setSelectedEndDate(props.endDate);
  }, [props.startDate, props.endDate]);

  const datePickerProps = {
    minDate: startOfMonth(firstViewableDate()),
    maxDate: endOfMonth(lastViewableDate()),
    inputFormat: preferredDateFormatWithoutDay,
    mask: datePickerMasks.preferredDateFormatWithoutDay,
    views: ['year', 'month'],
  } as Partial<DatePickerProps<Date, Date>>;

  return (
    <>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Stack padding={'24px'} direction="column">
            <Typography padding={0} variant="h6">
              {t('validation_of_demand.date_picker.title', {})}
            </Typography>
            <DatePickerPanel
              fromDate={selectedStartDate}
              onFromDateChange={(newValue) => setSelectedStartDate(newValue)}
              toDate={selectedEndDate}
              onToDateChange={(newValue) => setSelectedEndDate(newValue)}
              onDatePickerError={setDatePickerError}
              size={'medium'}
              padded={true}
              fromPickerProps={datePickerProps}
              toPickerProps={datePickerProps}
            />
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                size="large"
                disabled={datePickerError}
                onClick={() => {
                  if (selectedStartDate && selectedEndDate) {
                    props.onSave(selectedStartDate, selectedEndDate);
                    props.onClose();
                  }
                }}
              >
                {t('button.save', {})}
              </Button>
            </Box>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
}
