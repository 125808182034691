import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { formatDate } from '../../../core/i18n/l10n';

export const IntlDateCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  if (!params.value) {
    return '';
  }
  return formatDate(new Date(params.value));
};
