import { Edit } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import { ActionButton } from '../../../components/ActionButton';
import { ErrorHandlingDatePicker } from '../../../components/DatePicker';
import { errorHelperTextProps } from '../../../components/FieldError';
import { SingleAutocompleteOnType } from '../../../components/inputs/autocomplete/SingleAutocompleteOnType';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { ModalHeadlineSeparator } from '../../../components/modal/ModalHeadline';
import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../../core/requests/httpClient';
import { CfcrActionResponse } from '../../../domain/customerMaterialPortfolio/model';
import {
  acceptSubstitution,
  saveInactive,
  savePhaseOut,
  saveVeto,
} from '../../../domain/customerMaterialPortfolio/saveCMPChange';
import { saveSubstitution } from '../../../domain/customerMaterialPortfolio/saveSubstitution';
import fetchForecastActionData from '../../../domain/customerMaterialPortfolio/useForecastActionData';

import {
  CMPData,
  DemandPlanAdoption,
  demandPlanAdoptionOptions,
} from './cmpModalStatusSpecificElements/cmpModalTypes';

type SubstitutionMaterialModalProps = {
  open: boolean;
  close: () => void;
  closeAndRefresh: () => void;
  cmpData: CMPData | null;
};

enum SubstitutionModalType {
  FIRST_STEP_ACCEPT = 'FIRST_STEP_ACCEPT ',
  SECOND_STEP_EDIT = 'SECOND_STEP_EDIT',
}

export function CMPSubstitutionProposalModal({
  close,
  closeAndRefresh,
  open,
  cmpData,
}: SubstitutionMaterialModalProps) {
  const snackbar = useSnackbar();

  const [substitutionModalType, setSubstitutionModalType] = useState<SubstitutionModalType>(
    SubstitutionModalType.FIRST_STEP_ACCEPT,
  );
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [repDateError, setRepDateError] = useState<DateValidationError | null>(null);
  const [showPhaseOutValidation, setShowPhaseOutValidation] = useState<boolean>(false);
  const [cmpDataChanged, setCMPDataChanged] = useState<boolean>(false);
  const [substitutionCMPData, setSubstitutionCMPData] = useState<CMPData | null>();
  const [confirmationMessage, setConfirmationMessage] = useState<string | undefined>(undefined);
  const [forecastActionData, setForecastActionData] = useState<CfcrActionResponse | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetModal = () => {
    setSubstitutionModalType(SubstitutionModalType.FIRST_STEP_ACCEPT);
    setCMPDataChanged(false);
    setShowValidation(false);
    setShowPhaseOutValidation(false);
    setSubstitutionCMPData(null);
  };

  useEffect(() => {
    setSubstitutionCMPData(cmpData);
    setIsLoading(true);
    fetchForecastActionData(cmpData)
      .then((data) => setForecastActionData(data))
      .finally(() => setIsLoading(false));
  }, [cmpData]);

  useEffect(() => {
    if (!forecastActionData) {
      return;
    }

    const defaultDemandPlanAdoption: DemandPlanAdoption | undefined =
      forecastActionData?.cfcrActions.find((action) => action.selected === true)?.cfcrAction;

    if (defaultDemandPlanAdoption) {
      setSubstitutionCMPData((data) => {
        if (data) {
          return {
            ...data,
            demandPlanAdoption: defaultDemandPlanAdoption,
          };
        } else {
          return null;
        }
      });
    }
  }, [forecastActionData]);

  const handleCancel = () => {
    close();
    resetModal();
  };

  const handleAccept = async () => {
    if (!substitutionCMPData) return;

    const postResult = await acceptSubstitution(substitutionCMPData, false);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t(`customer_material_portfolio.phase_in_out_single_modal.save.success`, {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });

    if (userMessage.variant !== 'error') {
      closeAndRefresh();
      resetModal();
    }
  };

  const handleVeto = async () => {
    if (!cmpData) return;

    const postResult = await saveVeto(cmpData, false);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t(`customer_material_portfolio.phase_in_out_single_modal.save.success`, {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });
    if (userMessage.variant !== 'error') {
      closeAndRefresh();
      resetModal();
    }
  };

  const onEdit = () => {
    setSubstitutionModalType(SubstitutionModalType.SECOND_STEP_EDIT);
  };

  const getMaterialNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-materials', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const handleDemandPlanAdoptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as DemandPlanAdoption;
    const newDemandPlanAdoption = demandPlanAdoptionOptions.includes(newValue) ? newValue : null;
    if (!newDemandPlanAdoption || !substitutionCMPData) {
      return;
    }

    setSubstitutionCMPData({ ...substitutionCMPData, demandPlanAdoption: newDemandPlanAdoption });
  };

  const inputsAreValid = () => {
    return (
      substitutionCMPData?.successorMaterial != null &&
      substitutionCMPData?.successorMaterial.length > 0 &&
      substitutionCMPData.repDate &&
      !repDateError &&
      substitutionCMPData?.demandPlanAdoption != null
    );
  };

  const handleEdit = async (operation: 'EDIT' | 'PHASEOUT' | 'INACTIVE', confirmed: boolean) => {
    if (!substitutionCMPData || !cmpData) {
      return;
    }

    setShowValidation(false);
    setShowPhaseOutValidation(false);

    let postResult;

    switch (operation) {
      case 'EDIT':
        setShowValidation(true);
        if (!inputsAreValid()) {
          return;
        }
        postResult = await saveSubstitution({ ...substitutionCMPData }, false, confirmed);

        // If the requested successor is not already in the portfolio of this customer,
        // the user has to confirm adding it. After confirmation, the request can run again with the confirmed param set.
        if (
          postResult.overallStatus == 'WARNING' &&
          postResult.overallErrorMsg ==
            t('customer.material_portfolio.modal.substitution.warning.add_material', {})
        ) {
          setConfirmationMessage(postResult.overallErrorMsg);
          return;
        }

        break;
      case 'PHASEOUT':
        setShowPhaseOutValidation(true);
        if (!substitutionCMPData.repDate || repDateError) {
          return;
        }
        postResult = await savePhaseOut(
          { ...cmpData, repDate: null, autoSwitchDate: substitutionCMPData.repDate },
          false,
        );
        break;
      case 'INACTIVE':
        // Autoswitchdate is set in BE. We set it here for consistency.
        postResult = await saveInactive(
          { ...cmpData, repDate: null, autoSwitchDate: new Date(Date.now()) },
          false,
        );
        break;
    }

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t(`customer_material_portfolio.phase_in_out_single_modal.save.success`, {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });

    if (userMessage.variant !== 'error') {
      closeAndRefresh();
      resetModal();
    }
  };

  return (
    <>
      <Modal onClose={handleCancel} fullWidth open={open} maxWidth={'md'}>
        <LoadingSpinnerModal open={false} />
        <Modal.Headline
          onClose={handleCancel}
          text={t('customer_material_portfolio.substitution_modal.headline', {})}
        ></Modal.Headline>
        <ModalHeadlineSeparator />
        <Modal.Body>
          <Grid container spacing={3}>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={{ xs: 1, md: 4 }}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  label={t('customer_material_portfolio.modal.customer_number', {})}
                  disabled={true}
                  value={substitutionCMPData?.customerNumber || ''}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={{ xs: 1, md: 4 }}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  label={t('customer_material_portfolio.modal.predecessor_material_number', {})}
                  disabled={true}
                  value={substitutionCMPData?.materialNumber || ''}
                  size="small"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SingleAutocompleteOnType
                  muiProps={{
                    disabled: substitutionModalType == SubstitutionModalType.FIRST_STEP_ACCEPT,
                  }}
                  value={substitutionCMPData?.successorMaterial || ''}
                  onValueChange={(e, value) => {
                    setCMPDataChanged(true);
                    if (!substitutionCMPData) {
                      return;
                    }
                    const newCMPData = {
                      ...substitutionCMPData,
                      successorMaterial: value?.id || '',
                    };
                    setSubstitutionCMPData(newCMPData);
                    setIsLoading(true);
                    fetchForecastActionData(newCMPData)
                      .then((data) => setForecastActionData(data))
                      .finally(() => setIsLoading(false));
                  }}
                  errorHelperTextProps={errorHelperTextProps(
                    showValidation && !substitutionCMPData?.successorMaterial
                      ? [t('generic.validation.missing_fields', {})]
                      : [],
                  )}
                  autocompleteLabel={t(
                    'customer_material_portfolio.modal.sucessor_material_number',
                    {},
                  )}
                  getOptions={getMaterialNumberOptions}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={{ xs: 1, md: 4 }}
            >
              <Grid item xs={12} md={4}>
                <ErrorHandlingDatePicker
                  maxDate={new Date(9999, 12, 31)}
                  minDate={new Date(Date.now())}
                  value={substitutionCMPData?.repDate || null}
                  disabled={true}
                  onChange={(value) => {
                    setCMPDataChanged(true);
                    if (!substitutionCMPData) {
                      return;
                    }
                    setSubstitutionCMPData({ ...substitutionCMPData, repDate: value });
                  }}
                  label={t('customer_material_portfolio.modal.substitution_time', {})}
                  errorMessages={
                    (showValidation || showPhaseOutValidation) && !substitutionCMPData?.repDate
                      ? [t('generic.validation.missing_fields', {})]
                      : []
                  }
                  validate={
                    substitutionModalType == SubstitutionModalType.SECOND_STEP_EDIT &&
                    (substitutionCMPData?.repDate != null ||
                      showValidation ||
                      showPhaseOutValidation)
                  }
                  onError={setRepDateError}
                ></ErrorHandlingDatePicker>
              </Grid>
            </Grid>
          </Grid>

          <Typography
            sx={{
              mt: 2,
              color: (theme) =>
                substitutionModalType == SubstitutionModalType.FIRST_STEP_ACCEPT
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
            }}
          >
            {t('customer.material_portfolio.modal.substitution.transfere_forecast', {})}
          </Typography>
          <RadioGroup value={substitutionCMPData?.demandPlanAdoption || ''} row>
            <FormControlLabel
              disabled={
                (!cmpDataChanged &&
                  SubstitutionModalType.SECOND_STEP_EDIT == substitutionModalType) ||
                forecastActionData?.cfcrActions.filter((action) => action.cfcrAction === 'DELETE')
                  .length === 0
              }
              value={'DELETE'}
              checked={substitutionCMPData?.demandPlanAdoption == 'DELETE'}
              control={<Radio onChange={handleDemandPlanAdoptionChange} />}
              label={t(
                'customer.material_portfolio.modal.substitution.transfere_forecast.delete',
                {},
              )}
            />
            <FormControlLabel
              disabled={
                (!cmpDataChanged &&
                  SubstitutionModalType.SECOND_STEP_EDIT == substitutionModalType) ||
                forecastActionData?.cfcrActions.filter((action) => action.cfcrAction === 'COPY')
                  .length === 0
              }
              value={'COPY'}
              checked={substitutionCMPData?.demandPlanAdoption == 'COPY'}
              control={<Radio onChange={handleDemandPlanAdoptionChange} />}
              label={t(
                'customer.material_portfolio.modal.substitution.transfere_forecast.copy',
                {},
              )}
            />
            <FormControlLabel
              disabled={
                (!cmpDataChanged &&
                  SubstitutionModalType.SECOND_STEP_EDIT == substitutionModalType) ||
                forecastActionData?.cfcrActions.filter((action) => action.cfcrAction === 'ADD')
                  .length === 0
              }
              value={'ADD'}
              checked={substitutionCMPData?.demandPlanAdoption == 'ADD'}
              control={<Radio onChange={handleDemandPlanAdoptionChange} />}
              label={t('customer.material_portfolio.modal.substitution.transfere_forecast.add', {})}
            />
          </RadioGroup>
          {showValidation && !substitutionCMPData?.demandPlanAdoption && (
            <Grid item xs={12} md={12}>
              <FormHelperText error={true}>
                {t('generic.validation.missing_fields', {})}
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <FormHelperText>
              {t('customer.material_portfolio.modal.substitution.transfere_forecast.hint', {})}
            </FormHelperText>
          </Grid>
          {substitutionModalType == SubstitutionModalType.FIRST_STEP_ACCEPT && (
            <Grid item xs={12} md={12}>
              <FormHelperText>
                {t(
                  'customer.material_portfolio.modal.substitution.transfere_forecast_veto.hint',
                  {},
                )}
              </FormHelperText>
            </Grid>
          )}

          <Divider sx={{ mt: 2, mb: 2 }} />

          {substitutionModalType == SubstitutionModalType.FIRST_STEP_ACCEPT && (
            <>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <ActionButton color={'secondary'} name={t('button.edit', {})} onClick={onEdit}>
                      <Edit />
                    </ActionButton>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button variant="contained" color="white" size="large" onClick={handleVeto}>
                      {t('customer_material_portfolio.modal.veto', {})}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" size="large" onClick={handleAccept}>
                      {t('customer_material_portfolio.modal.accept', {})}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {substitutionModalType == SubstitutionModalType.SECOND_STEP_EDIT && (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!cmpDataChanged}
                  onClick={() => {
                    handleEdit('EDIT', false);
                  }}
                >
                  {t('button.change', {})}
                </Button>
              </Grid>
            </Grid>
          )}
        </Modal.Body>
        <LoadingSpinnerModal open={isLoading} />
      </Modal>
      <Dialog open={Boolean(confirmationMessage)} onClose={() => setConfirmationMessage(undefined)}>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationMessage(undefined)}>{t('button.no', {})}</Button>
          <Button
            onClick={() => {
              setConfirmationMessage(undefined);
              handleEdit('EDIT', true);
            }}
            autoFocus
          >
            {t('button.yes', {})}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
