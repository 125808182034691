import { Divider } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export type HeaderActionBarProps = {
  actionButtons?: React.ReactNode;
  children: React.ReactNode;
  rightChildren?: React.ReactNode;
};

export default function HeaderActionBar({
  actionButtons,
  children,
  rightChildren,
}: HeaderActionBarProps) {
  return (
    <Wrapper>
      <Layout>
        <Part>
          {actionButtons}
          {actionButtons && children && <ActionDivider />}
          {children}
        </Part>
        <Part>{rightChildren}</Part>
      </Layout>
      <Divider />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`;

const Part = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const ActionDivider = styled.div`
  height: 40px;
  border-right: 1px solid #646464;
`;
