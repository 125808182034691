import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { t } from '../../../core/i18n/i18n';
import { parseToStringLiteralTypeIfPossible } from '../../../core/parseValues';
import {
  ReplacementType,
  replacementTypeValues,
} from '../../../domain/internalMaterialReplacement/model';

export const ReplacementTypeOrOriginalCellRenderer = (
  params: ICellRendererParams,
): React.ReactNode => {
  const value = params.value;

  if (value == undefined) {
    return null;
  }

  const localizationKeyCreation = (val: ReplacementType) => t(`replacement_type.${val}`, {});
  const parsed = parseToStringLiteralTypeIfPossible<ReplacementType>(
    value,
    replacementTypeValues,
    localizationKeyCreation,
  );

  if (parsed == undefined) {
    return <span>{value}</span>;
  }

  return <span>{t(`replacement_type.${parsed}`, {})}</span>;
};
