import { useEffect } from 'react';

import { t } from '../i18n/i18n';

const useUnsavedChangesWarning = (
  unsavedChanges: boolean,
  message: string = t('error.unsaved_changes', {}),
) => {
  useEffect(() => {
    const func = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return message;
    };

    if (unsavedChanges) window.addEventListener('beforeunload', func);

    return () => {
      window.removeEventListener('beforeunload', func);
    };
  }, [unsavedChanges, message]);

  const confirmContinueAndLooseUnsavedChanges = () => {
    if (unsavedChanges) return confirm(message);
    else return true;
  };

  return { confirmContinueAndLooseUnsavedChanges };
};

export default useUnsavedChangesWarning;
