import React from 'react';
import styled from 'styled-components';

import { t } from '../../../core/i18n/i18n';

export const CustomTooltip = (props: any) => {
  if (props.data.portfolioStatus == 'IA') {
    return (
      <StyledTooltip>
        <span className="tooltipText">{t('material_customer.column.tooltipTextIA', {})}</span>
      </StyledTooltip>
    );
  }

  if (props.data.portfolioStatus == 'SE' || props.data.portfolioStatus == 'SI') {
    return (
      <StyledTooltip>
        <span className="tooltipText">{t('material_customer.column.tooltipTextRE', {})}</span>
      </StyledTooltip>
    );
  }

  return '';
};

const StyledTooltip = styled.div`
  position: relative;
  display: inline-block;

  :hover {
    visibility: visible;
  }

  .tooltipText {
    width: 120px;
    background-color: black;
    opacity: 0.6;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -60px;
  }
`;
