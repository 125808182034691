import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfMonth,
} from 'date-fns';

import { DateRange, DateRangePeriod } from '../../core/dateRange';

import { KpiDateRanges } from './model';

const LOCAL_STORAGE_TIMERANGE_KEY = 'demandValidation.timeRange';

type LocalStorageTimeRange = {
  relativeStartDate: number;
  relativeEndDate: number;
  periodType: DateRangePeriod;
  relativeOptionalEndDate: number | undefined;
};

export function readLocalStorageTimeRange(): KpiDateRanges | undefined {
  const relativeTimeRangeString = localStorage.getItem(LOCAL_STORAGE_TIMERANGE_KEY);
  if (relativeTimeRangeString) {
    const relativeTimeRange = JSON.parse(relativeTimeRangeString);
    const now = new Date(Date.now());

    switch (relativeTimeRange.periodType) {
      case 'MONTHLY':
        return {
          range1: {
            from: addMonths(now, relativeTimeRange.relativeStartDate),
            to: addMonths(now, relativeTimeRange.relativeEndDate),
            period: relativeTimeRange.periodType,
          },
          range2: undefined,
        };
      case 'WEEKLY':
        return {
          range1: {
            from: addDays(now, relativeTimeRange.relativeStartDate),
            to: relativeTimeRange.relativeOptionalEndDate
              ? endOfMonth(addDays(now, relativeTimeRange.relativeEndDate))
              : addDays(now, relativeTimeRange.relativeEndDate),
            period: relativeTimeRange.periodType,
          },
          range2: relativeTimeRange.relativeOptionalEndDate
            ? {
                from: addMonths(addDays(now, relativeTimeRange.relativeEndDate), 1),
                to: addMonths(now, relativeTimeRange.relativeOptionalEndDate),
                period: 'MONTHLY',
              }
            : undefined,
        };
    }
  }

  return undefined;
}

export function saveLocalStorageTimeRange(
  dateRange1: Partial<DateRange>,
  dateRange2: Partial<DateRange> | undefined,
): void {
  if (!dateRange1.from || !dateRange1.to) {
    return;
  }

  const now = new Date(Date.now());
  let relativeTimeRange: LocalStorageTimeRange | unknown;

  switch (dateRange1.period) {
    case 'WEEKLY':
      relativeTimeRange = {
        periodType: 'WEEKLY',
        relativeStartDate: differenceInCalendarDays(dateRange1.from, now),
        relativeEndDate: differenceInCalendarDays(dateRange1.to, now),
        relativeOptionalEndDate: dateRange2?.to
          ? differenceInCalendarMonths(dateRange2.to, now)
          : undefined,
      };
      break;
    case 'MONTHLY':
      relativeTimeRange = {
        periodType: 'MONTHLY',
        relativeStartDate: differenceInCalendarMonths(dateRange1.from, now),
        relativeEndDate: differenceInCalendarMonths(dateRange1.to, now),
        relativeOptionalEndDate: undefined,
      };
      break;
    default:
      relativeTimeRange = {};
      break;
  }
  localStorage.setItem(LOCAL_STORAGE_TIMERANGE_KEY, JSON.stringify(relativeTimeRange));
}
