import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Typography } from '@mui/material';
import React, { useRef } from 'react';

import { GridApis } from '../../agGrid/gridTypes';
import { showFloatingFilters } from '../../agGrid/gridUtils';
import { t } from '../../core/i18n/i18n';
import { formatNumber } from '../../core/i18n/l10n';
import { ToolbarContainer } from '../../pages/home/table/HomeTableToolbar';

type TableToolbarProps = {
  rowCount: number | undefined;
  grid: GridApis | undefined;
  children?: React.ReactNode;
};

export default function TableToolbar({ rowCount, grid, children }: TableToolbarProps) {
  const showFloatingFiltersRef = useRef(false);

  const toggleFloatingFilter = () => {
    showFloatingFiltersRef.current = !showFloatingFiltersRef.current;

    if (grid) {
      showFloatingFilters(grid.api, showFloatingFiltersRef.current);
    }
  };

  return (
    <ToolbarContainer $position={'flex-start'}>
      <Button color="secondary" startIcon={<FilterListIcon />} onClick={toggleFloatingFilter}>
        {t('material_customer.toolbar.toggle_filters', {})}
      </Button>
      <Box>
        {rowCount != null && (
          <>
            <Typography
              component="span"
              mr="14px"
              ml="8px"
              color="secondary"
              aria-hidden
              variant="body2"
            >
              |
            </Typography>
            <Typography component="span" color="secondary" variant="body2">
              {t('table.toolbar.material_count', { count: formatNumber(rowCount) })}
            </Typography>
          </>
        )}
      </Box>
      {children}
    </ToolbarContainer>
  );
}
