import useSWR from 'swr';

import { useSwrErrorNotification } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';

import { CriteriaFields } from './model';

export function useCriteriaData() {
  const result = useSWR<CriteriaFields>('material-customer/criteria-fields', requestFromApi);
  useSwrErrorNotification(result.error);
  return result;
}
