import { Typography } from '@mui/material';
import * as React from 'react';
import { Props } from 'recharts/types/component/Label';
import { CartesianViewBox } from 'recharts/types/util/types';
import styled from 'styled-components';

import { LoadingSpinner } from '../../../components/loadingSpinner/LoadingSpinner';

/**
 * Recharts only accepts svg elements as labels, so we render a svg foreign object over the whole area and put
 *  our components inside it.
 * @param props
 * @constructor
 */
export function ChartOverlay(props: Props & { loadingSpinner?: boolean }) {
  const { viewBox, value, loadingSpinner } = props;
  const { x, y, width, height } = viewBox as CartesianViewBox;

  return (
    <g>
      <foreignObject x={x} y={y} width={width} height={height}>
        <OverlayContainer>
          <Overlay>
            {loadingSpinner ? (
              <LoadingSpinnerContainer>
                <LoadingSpinner />
              </LoadingSpinnerContainer>
            ) : (
              <Typography>{value}</Typography>
            )}
          </Overlay>
        </OverlayContainer>
      </foreignObject>
    </g>
  );
}

const OverlayContainer = styled.div`
  padding: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #ffffffb3;
`;

const Overlay = styled.div`
  color: #646464;
  background: #ffffff;
  padding: 26px;
  border-radius: 3px;
`;

const LoadingSpinnerContainer = styled.div`
  min-height: 60px;
  min-width: 60px;
`;
