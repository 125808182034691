import { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { clientSideTableDefaultProps, getDefaultColDef, sideBar } from '../../agGrid/gridDefaults';
import useColumnSettings from '../../agGrid/useColumnSettings';
import { ActionButton } from '../../components/ActionButton';
import { ContentWrapper } from '../../components/ContentWrapper';
import HeaderActionBar from '../../components/HeaderActionBar';
import { StyledGridSection } from '../../components/StyledGridSection';
import { StyledSection } from '../../components/StyledSection';
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner';
import { Page } from '../../components/page/Page';
import { t } from '../../core/i18n/i18n';
import { AlertRule } from '../../domain/alertrules/model';
import useAlertRuleData from '../../domain/alertrules/useAlertRuleData';

import AlertRuleEditModal, { AlertRuleModalTitle } from './AlertRuleEditModal';
import AlertRuleTableRowMenuButton from './AlertRuleTableRowMenuButton';
import { alertRuleColumnDefinitions } from './alertRuleColumnDefinitions';
import AlertMultiDeleteModal from './modals/AlertMultiDeleteModal';
import AlertRuleMultiModal from './modals/AlertRuleMultiModal';
import DeleteRuleDialog from './modals/DeleteRuleDialog';

export enum AlertModal {
  NEW_SINGLE = 'NEW_SINGLE',
  EDIT_SINGLE = 'EDIT_SINGLE',
  NEW_MULTI = 'NEW_MULTI',
  DELETE_SINGLE = 'DELETE_SINGLE',
  DELETE_MULTI = 'DELETE_MULTI',
}

const AlertRuleManagement = () => {
  const alertRuleData = useAlertRuleData();
  const [openModal, setOpenModal] = useState<AlertModal | null>(null);
  const [selectedAlertRuleForEdit, setSelectedAlertRuleForEdit] = useState<AlertRule>({
    id: '00000000-0000-0000-0000-000000000000',
    startDate: new Date(Date.now()),
    endDate: new Date('9999-12-31'),
  });
  const [alertRuleModalTitle, setAlertRuleModalTitle] = useState<AlertRuleModalTitle>('create');

  const title = `${t('tabbar.functions.label', {})} | ${t(
    'tabbarMenu.alert-rule-editor.label',
    {},
  )}`;

  const columnSettings = useColumnSettings('alert-rules', alertRuleColumnDefinitions);

  const columnDefs = useMemo(() => {
    return [
      ...(columnSettings.columns?.map((col) => ({
        ...getDefaultColDef(col.filter, col.filterParams),
        key: col.colId,
        colId: col.colId,
        field: col.colId,
        headerName: t(col.title, {}),

        filter: col.filter,
        cellRenderer: col.cellRenderer,
        hide: !col.visible,
        sortable: col.sortable,
        sort: col.sort,
        lockVisible: col.alwaysVisible,
        lockPinned: true,
      })) || []),
      {
        field: 'menu',
        headerName: '',
        cellRenderer: 'workflowMenuRenderer',
        lockVisible: true,
        pinned: 'right',
        lockPinned: true,
        cellRendererParams: {
          onPerformAction: () => {
            alertRuleData.refresh();
          },
          onDeleteAction: (data: AlertRule) => {
            setSelectedAlertRuleForEdit({ ...data });
            setOpenModal(AlertModal.DELETE_SINGLE);
          },
          onEditAction: (data: AlertRule) => {
            // data comes form ag grid, we create a new object instance to prevent errors when working on the internal ag grid data structure
            setSelectedAlertRuleForEdit({ ...data });
            setAlertRuleModalTitle('edit');
            setOpenModal(AlertModal.EDIT_SINGLE);
          },
        },

        suppressMenu: true,
        maxWidth: 64,
        suppressSizeToFit: true,
      },
    ] as ColDef[];
  }, [columnSettings, alertRuleData]);

  return (
    <Page title={title}>
      <ContentWrapper style={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}>
        <AlertRuleEditModal
          open={openModal == AlertModal.EDIT_SINGLE || openModal == AlertModal.NEW_SINGLE}
          onClose={() => {
            alertRuleData.refresh();
            setOpenModal(null);
          }}
          alertRule={selectedAlertRuleForEdit}
          onAlertRuleChange={setSelectedAlertRuleForEdit}
          title={alertRuleModalTitle}
        />

        <AlertRuleMultiModal
          open={openModal == AlertModal.NEW_MULTI}
          onClose={() => setOpenModal(null)}
          onAdded={() => {
            alertRuleData.refresh();
            setOpenModal(null);
          }}
        />

        <AlertMultiDeleteModal
          open={openModal == AlertModal.DELETE_MULTI}
          onClose={() => setOpenModal(null)}
          onAdded={() => {
            alertRuleData.refresh();
            setOpenModal(null);
          }}
        />

        <DeleteRuleDialog
          open={openModal == AlertModal.DELETE_SINGLE}
          onClose={() => {
            alertRuleData.refresh();
            setOpenModal(null);
          }}
          alertRule={selectedAlertRuleForEdit}
        />

        <Typography variant="h6" component={'h2'}>
          {t('alert_rules.title', {})}
        </Typography>
        <HeaderActionBar
          actionButtons={
            <>
              <ActionButton
                color="primary"
                onClick={() => {
                  setSelectedAlertRuleForEdit({
                    deactivated: false,
                    id: '00000000-0000-0000-0000-000000000000',
                    startDate: new Date(Date.now()),
                    endDate: new Date('9999-12-31'),
                    generation: 'R',
                  });
                  setAlertRuleModalTitle('create');
                  setOpenModal(AlertModal.NEW_SINGLE);
                }}
              >
                <AddIcon />
              </ActionButton>
              <ActionButton color="secondary" onClick={() => setOpenModal(AlertModal.NEW_MULTI)}>
                <MenuIcon />
              </ActionButton>
              <ActionButton color="error" onClick={() => setOpenModal(AlertModal.DELETE_MULTI)}>
                <DeleteIcon />
              </ActionButton>
            </>
          }
        >
          {undefined}
        </HeaderActionBar>

        <StyledSection style={{ display: 'flex', flexGrow: '1' }}>
          {alertRuleData.result && columnSettings.columns ? (
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '400px', flexGrow: 1 }}>
              <StyledGridSection>
                <AgGridReact
                  {...columnSettings.addAgGridEvents({
                    ...clientSideTableDefaultProps,
                    rowData: alertRuleData?.result?.content,
                    components: {
                      ...clientSideTableDefaultProps.components,
                      workflowMenuRenderer: AlertRuleTableRowMenuButton,
                    },
                    sideBar,
                  })}
                  columnDefs={columnDefs}
                ></AgGridReact>
              </StyledGridSection>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </StyledSection>
      </ContentWrapper>
    </Page>
  );
};

export default AlertRuleManagement;
