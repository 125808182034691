import { formatISO } from 'date-fns';

import { t } from '../../core/i18n/i18n';
import { parseDate } from '../../core/i18n/l10n';
import { CMPData } from '../../pages/customerMaterialPortfolio/modal/cmpModalStatusSpecificElements/cmpModalTypes';
import { demandCharacteristics } from '../materialCustomer/model';

import { CMPWriteRequest } from './model';

export function dataToCMPWriteRequest(cmpData: CMPData): CMPWriteRequest {
  if (!cmpData.portfolioStatus) {
    throw new Error(t('error.unknown', {}));
  }

  const autoSwitchDate =
    typeof cmpData.autoSwitchDate == 'string'
      ? parseDate(cmpData.autoSwitchDate)
      : cmpData.autoSwitchDate;

  const repDate = typeof cmpData.repDate == 'string' ? parseDate(cmpData.repDate) : cmpData.repDate;

  // Somtimes SAP sends us empty strings, we want to send null to the backend if that happens
  const demandChar =
    cmpData.demandCharacteristic && demandCharacteristics.includes(cmpData.demandCharacteristic)
      ? cmpData.demandCharacteristic
      : null;

  return {
    customerNumber: cmpData.customerNumber,
    materialNumber: cmpData.materialNumber,
    portfolioStatus: cmpData.portfolioStatus,
    demandCharacteristic: demandChar,
    autoSwitchDate: autoSwitchDate
      ? formatISO(autoSwitchDate, {
          representation: 'date',
        })
      : null,
    repDate: repDate
      ? formatISO(repDate, {
          representation: 'date',
        })
      : null,
    successorMaterial: cmpData.successorMaterial,
    demandPlanAdoption: cmpData.demandPlanAdoption,
  };
}
