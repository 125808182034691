import { addYears, endOfYear, formatISO, startOfYear } from 'date-fns';
import useSWR from 'swr';

import { authenticatedFetch, requestFromApi } from '../../core/requests/httpClient';
import { PlanningView } from '../demandValidation/planningView';

import { ChartUnitMode } from './model';

export type ChartSettings = {
  startDate: Date;
  endDate: Date;
  planningView: PlanningView;
  chartUnitMode: ChartUnitMode;
};

type ChartSettingsStored = {
  startDate: string;
  endDate: string;
  planningView: string;
  chartUnitMode: string;
};

export const defaultChartSettings = {
  startDate: startOfYear(Date.now()), // agreed upon default start date
  endDate: endOfYear(addYears(Date.now(), 1)), // agreed upon default end date
  planningView: PlanningView.REQUESTED,
  chartUnitMode: ChartUnitMode.CURRENCY,
};

function parseChartSettings(raw: ChartSettingsStored): ChartSettings {
  return {
    startDate: new Date(Date.parse(raw.startDate)),
    endDate: new Date(Date.parse(raw.endDate)),
    planningView: raw.planningView,
    chartUnitMode: raw.chartUnitMode,
  } as ChartSettings;
}

export default function useChartSettings() {
  const result = useSWR<ChartSettingsStored>('user-settings/chart', requestFromApi);
  const updateChartSettings = async (chartSettings: ChartSettings) => {
    const request = {
      startDate: formatISO(chartSettings.startDate, { representation: 'date' }),
      endDate: formatISO(chartSettings.endDate, { representation: 'date' }),
      chartUnitMode: chartSettings.chartUnitMode,
      planningView: chartSettings.planningView,
    };

    await authenticatedFetch('user-settings/chart', {
      method: 'POST',
      body: JSON.stringify(request),
    });
    await result.mutate(request);
  };

  return {
    chartSettings: result.data ? parseChartSettings(result.data) : defaultChartSettings,
    updateChartSettings: updateChartSettings,
    isValidating: result.isValidating,
  };
}
