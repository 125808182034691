import { Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { ModalClose } from './ModalClose';

const leftRightPadding = '24px';

const DialogTitle = styled.div<{ $showCloseIcon: boolean }>`
  display: flex;
  align-items: center;
  padding: 24px ${leftRightPadding} 8px;
  ${(props) => (props.$showCloseIcon ? 'padding-right: 64px;' : '')}
`;

const ModalHeadlineSeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6em ${leftRightPadding} 1.5em;
`;

const TextEllipsisHeadline = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
` as typeof Typography;

interface ModalHeaderProps {
  text: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const ModalHeadline = ({ text, onClose, children = null }: ModalHeaderProps) => {
  const showCloseIcon = Boolean(onClose);
  return (
    <DialogTitle $showCloseIcon={showCloseIcon}>
      <TextEllipsisHeadline component="h2" variant="h6">
        {text}
      </TextEllipsisHeadline>

      <ActionItems>{children}</ActionItems>
      {showCloseIcon && <ModalClose onClick={onClose} />}
    </DialogTitle>
  );
};

export const ModalHeadlineSeparator = () => {
  return (
    <ModalHeadlineSeparatorContainer>
      <ModalHeadlineSeparatorHr />
    </ModalHeadlineSeparatorContainer>
  );
};

const ModalHeadlineSeparatorHr = styled.hr`
  margin: 0px;
  border: none;
  height: 0.1em;
  background-color: #ddd;
  width: 100%;
`;

const ActionItems = styled.div`
  flex: 0 1 auto;
  align-items: flex-end;
  justify-content: flex-end;
  display: inline-flex;
  gap: 16px;
  margin-left: auto;
`;
