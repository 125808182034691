import { CellClassParams, CellClickedEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import styled from 'styled-components';

import { getDefaultColDef, serverSideTableDefaultProps } from '../../../agGrid/gridDefaults';
import { GridApis } from '../../../agGrid/gridTypes';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { t } from '../../../core/i18n/i18n';
import { MaterialListEntry } from '../../../domain/demandValidation/model';
import { disableColor, schaefflerColor } from '../../../styles/colors';
import { ToolbarContainer } from '../../home/table/HomeTableToolbar';

import ToolbarCounter from './ToolbarCounter';
import columnDefinitions from './columnDefinitions';

type MaterialListProps = {
  materialListVisible: boolean;
  rowCount: number | undefined;
  selectedMaterialListEntry: MaterialListEntry | undefined;
  setSelectedMaterialListEntry: (customer: MaterialListEntry) => void;
  setGrid: Dispatch<SetStateAction<GridApis | undefined>>;
  confirmContinueAndLooseUnsavedChanges: () => boolean;
  toggleFloatingFilter: () => void;
};

export default function MaterialList(props: MaterialListProps) {
  const columnDefs = useMemo(() => {
    return columnDefinitions.map((def) => {
      return {
        ...getDefaultColDef(undefined, undefined),
        ...def,
        field: def.colId,
        headerName: t(`material_customer.column.${def.colId}`, {}),
        headerTooltip: t(`material_customer.column.${def.colId}`, {}),
        suppressMenu: true,
        cellStyle: (cellParams: CellClassParams) => {
          if (cellParams.data.materialClassification === 'OP') {
            return { backgroundColor: `${disableColor}` };
          }
        },
      };
    });
  }, []);

  return (
    <MaterialListWrapper $isOpen={props.materialListVisible}>
      <ToolbarContainer $position={'flex-start'}>
        <ToolbarCounter rowCount={props.rowCount} />
      </ToolbarContainer>
      <StyledGridSection>
        <AgGridReact
          {...serverSideTableDefaultProps}
          onGridReady={props.setGrid}
          onCellClicked={(e: CellClickedEvent) => {
            if (!e.data) return;
            if (
              e.data.customerMaterialNumber &&
              e.data.customerMaterialNumberCount > 1 &&
              e.column.getColId() === 'customerMaterialNumber'
            ) {
              // we cannot prevent clicking on a column, so we handle it here. The customerMaterialNumber column
              // opens a pop up to show all material numbers.
              // Maybe, in the future a check is required to prevent the click if more than one number exists only.
              return;
            }
            if (
              e.data.materialNumber === props.selectedMaterialListEntry?.materialNumber ||
              !props.confirmContinueAndLooseUnsavedChanges()
            ) {
              return;
            }
            e.node.setSelected(true, true);
            props.setSelectedMaterialListEntry(e.data);
          }}
          getRowId={(row: any) => {
            return row.data.materialNumber as string;
          }}
          defaultColDef={{
            sortable: true,
            suppressMovable: true,
            lockVisible: true,
            menuTabs: [],
          }}
          columnDefs={columnDefs}
        ></AgGridReact>
      </StyledGridSection>
    </MaterialListWrapper>
  );
}

const MaterialListWrapper = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0 0 ${({ $isOpen }) => ($isOpen ? '730px' : '0%')};
  overflow: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  border-right: ${({ $isOpen }) => ($isOpen ? '3px solid #e2e2e2' : '')};
  margin-right: 10px;

  transition: all 0.25s ease-in-out;
  will-change: width;

  .ag-theme-material .ag-row-selected {
    --ag-selected-row-background-color: #ffffff00;
    color: ${schaefflerColor};
  }
`;
