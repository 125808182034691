import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu as MuiMenu } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

const Menu = styled(MuiMenu)`
  > * {
    min-width: 160px;
  }
`;

const MoreVert = styled(IconButton)`
  color: #00893d;
`;

export default function RowMenu({
  open,
  setOpen,
  children,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const menuId = `context-menu`;
  const btnId = `trigger-menu`;

  return (
    <>
      <MoreVert
        onClick={handleOpen}
        aria-controls={open ? menuId : undefined}
        aria-haspopup={true}
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVertIcon />
      </MoreVert>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': btnId,
        }}
      >
        {children}
      </Menu>
    </>
  );
}
