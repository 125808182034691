import { TrafficLightTooltip } from '../../../components/agGrid/TrafficLightTooltip';
import { DemandCharacteristicCellRenderer } from '../../../components/agGrid/cellRenderers/DemandCharacteristicCellRenderer';
import { PortfolioStatusCellRenderer } from '../../../components/agGrid/cellRenderers/PortfolioStatusCellRenderer';
import TrafficLightCellRenderer from '../../../components/agGrid/cellRenderers/TrafficLightCellRenderer';
import {
  trafficLightValueFormatter,
  trafficLightValues,
} from '../../../components/agGrid/trafficLightSharedFunctions';
import { t } from '../../../core/i18n/i18n';
import {
  DemandCharacteristic,
  demandCharacteristics,
  materialClassifications,
} from '../../../domain/materialCustomer/model';
import {
  PortfolioStatus,
  portfolioStatusValues,
} from '../modal/cmpModalStatusSpecificElements/cmpModalTypes';

const columnDefinitions = [
  {
    colId: 'tlMessageType',
    visible: true,
    alwaysVisible: false,
    cellRenderer: TrafficLightCellRenderer,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: trafficLightValues,
      valueFormatter: trafficLightValueFormatter,
    },
    tooltipComponent: TrafficLightTooltip,
    tooltipField: 'tlMessage',
  },
  {
    colId: 'portfolioStatus',
    visible: true,
    alwaysVisible: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: portfolioStatusValues,
      valueFormatter: (params: any): string => {
        return t(`material_customer.portfolio_status.${params.value as PortfolioStatus}`, {}, '');
      },
    },
    cellRenderer: PortfolioStatusCellRenderer,
  },
  {
    colId: 'materialDescription',
    visible: true,
    alwaysVisible: true,
  },
  {
    colId: 'customerMaterialNumber',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'customerMaterialNumberCellRenderer',
  },
  {
    colId: 'packagingSize',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'numberRenderer',
    filter: 'agNumberColumnFilter',
  },
  {
    colId: 'materialClassification',
    visible: true,
    alwaysVisible: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: materialClassifications,
    },
  },
  {
    colId: 'stochasticType',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'productionPlant',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'productionSegment',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'productionLine',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'productLine',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'productLineText',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'demandCharacteristic',
    visible: true,
    alwaysVisible: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: demandCharacteristics,
      valueFormatter: (params: any): string => {
        return t(`demand_characterictics.${params.value as DemandCharacteristic}`, {}, '');
      },
    },
    cellRenderer: DemandCharacteristicCellRenderer,
  },
  {
    colId: 'pfStatusAutoSwitch',
    visible: true,
    alwaysVisible: true,
    cellRenderer: 'dateRenderer',
    filter: 'agDateColumnFilter',
  },
  {
    colId: 'repDate',
    visible: true,
    alwaysVisible: true,
    cellRenderer: 'dateRenderer',
    filter: 'agDateColumnFilter',
  },

  // Recommendation customer

  {
    colId: 'successorMaterial',
    visible: true,
    alwaysVisible: true,
  },
  {
    colId: 'successorMaterialDescription',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorCustomerMaterialNumber',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'customerMaterialNumberCellRenderer',
    cellRendererParams: {
      materialNumberField: 'successorMaterial',
      customerMaterialNumberField: 'successorCustomerMaterialNumber',
      matCountNumberField: 'successorCustomerMaterialNumberCount',
    },
  },
  {
    colId: 'successorMaterialPackagingSize',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'numberRenderer',
    filter: 'agNumberColumnFilter',
  },
  {
    colId: 'successorMaterialClassification',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorStochasticType',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorProductionPlant',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorProductionSegment',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorProductionLine',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorProductLine',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorProductLineText',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorSchaefflerMaterial',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorSchaefflerMaterialDescription',
    visible: true,
    alwaysVisible: false,
  },
  {
    colId: 'successorSchaefflerMaterialPackagingSize',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'numberRenderer',
    filter: 'agNumberColumnFilter',
  },
  {
    colId: 'deliveryQuantity18Months',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'numberRenderer',
    filter: 'agNumberColumnFilter',
  },
  {
    colId: 'orderQuantity',
    visible: true,
    alwaysVisible: false,
    cellRenderer: 'numberRenderer',
    filter: 'agNumberColumnFilter',
  },
] as const;

export type CMPColId = (typeof columnDefinitions)[number]['colId'];

export default columnDefinitions;
