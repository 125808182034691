import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useDomId from '../../../core/hooks/useDomId';
import {
  AvailableLanguage,
  getAvailableLanguages,
  getPreferredLanguage,
  setPreferredLanguage,
  t,
} from '../../../core/i18n/i18n';
import {
  availableDateFormats,
  AvailableDateFormats,
  AvailableDecimalSeparators,
  availableDecimalSeparators,
  preferredDateFormat,
  preferredDecimalSeparator,
  setPreferredDateFormat,
  setPreferredDecimalSeparator,
} from '../../../core/i18n/l10n';
import useCurrency from '../../../domain/info/useCurrency';
import useVersion from '../../../domain/info/useVersion';
import { useUserData } from '../../../domain/user/useUserData';

import {
  BackButton,
  DrawerHeader,
  SlideOut,
  UserInfo,
  UserSettings,
  Version,
  VersionNumber,
} from './layout';

export interface DrawerProps {
  open?: boolean;
  onBackButtonClick?: (e: React.MouseEvent) => void;
}

export const Drawer = ({ open, onBackButtonClick }: DrawerProps) => {
  const snackbar = useSnackbar();
  const userData = useUserData();
  const versionData = useVersion();
  const { currentCurrency, availableCurrencies, setCurrentCurrency } = useCurrency();

  // Unsaved setting states
  const [lang, setLang] = React.useState<AvailableLanguage>(getPreferredLanguage());
  const [currency, setCurrency] = React.useState<string>(currentCurrency);
  const [decimalSeparator, setDecimalSeparator] =
    useState<AvailableDecimalSeparators>(preferredDecimalSeparator);
  const [dateFormat, setDateFormat] = useState<AvailableDateFormats>(preferredDateFormat);

  const languageLabelID = useDomId();
  const dateformatLabelID = useDomId();
  const decimalSeparatorLabelID = useDomId();
  const currencyLabelID = useDomId();

  const handleChangeLanguage = (e: SelectChangeEvent<AvailableLanguage>) =>
    setLang(e.target.value as AvailableLanguage);
  const handleChangeCurrency = (e: SelectChangeEvent) => setCurrency(e.target.value as string);
  const handleChangeDateFormat = (e: SelectChangeEvent<AvailableDateFormats>) =>
    setDateFormat(e.target.value as AvailableDateFormats);
  const handleChangeDecimalSeparator = (e: SelectChangeEvent<AvailableDecimalSeparators>) =>
    setDecimalSeparator(e.target.value as AvailableDecimalSeparators);

  useEffect(() => {
    setCurrency(currentCurrency);
  }, [currentCurrency]);

  return (
    <SlideOut open={open}>
      <DrawerHeader>
        <BackButton data-testid="slideout-backbtn" onClick={onBackButtonClick}>
          <ArrowBack />
        </BackButton>
        <Typography variant="h6" component="h2">
          {t('drawer.settings', {})}
        </Typography>
      </DrawerHeader>

      <Divider />

      <UserInfo>
        {userData?.givenName && userData?.familyName && (
          <Typography variant="subtitle2" id="current-user">
            {`${userData.givenName} ${userData.familyName}`}
          </Typography>
        )}
        {userData?.department && (
          <Typography variant="subtitle2" id="current-user-department">
            {userData.department}
          </Typography>
        )}
      </UserInfo>

      <Divider />

      <UserSettings>
        <StyledBox>
          <FormControl fullWidth>
            <InputLabel id={languageLabelID}>{t('drawer.user-language-label', {})}</InputLabel>
            <Select
              labelId={languageLabelID}
              size="small"
              value={lang}
              label={t('drawer.user-language-label', {})}
              onChange={handleChangeLanguage}
            >
              {Object.entries(getAvailableLanguages()).map(([prop, lang]) => (
                <MenuItem key={`lang-${prop}`} value={prop}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledBox>

        <StyledDivider />

        {/* DATE FORMAT */}
        <StyledBox>
          <FormControl fullWidth>
            <InputLabel id={dateformatLabelID}>{t('drawer.user-dateformat-label', {})}</InputLabel>
            <Select
              labelId={dateformatLabelID}
              size="small"
              value={dateFormat}
              label={t('drawer.user-dateformat-label', {})}
              onChange={handleChangeDateFormat}
            >
              {availableDateFormats.map((format) => (
                <MenuItem key={`dateformat-${format}`} value={format}>
                  {format.toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledBox>

        {/* DECIMAL SEPARATOR */}
        <StyledBox>
          <FormControl fullWidth>
            <InputLabel id={decimalSeparatorLabelID}>
              {t('drawer.user-decimal-separator-label', {})}
            </InputLabel>
            <Select
              labelId={decimalSeparatorLabelID}
              size="small"
              value={decimalSeparator}
              label={t('drawer.user-decimal-separator-label', {})}
              onChange={handleChangeDecimalSeparator}
            >
              {availableDecimalSeparators.map((separator) => (
                <MenuItem key={`decimal-separator-${separator}`} value={separator}>
                  {t(`drawer.user-decimal-${separator as AvailableDecimalSeparators}`, {})}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledBox>

        <StyledDivider />

        {/* CURRENCY */}
        <StyledBox>
          <FormControl fullWidth>
            <InputLabel id={currencyLabelID}>{t('drawer.user-currency-label', {})}</InputLabel>
            <Select
              labelId={currencyLabelID}
              size="small"
              value={currency}
              label={t('drawer.user-currency-label', {})}
              onChange={handleChangeCurrency}
              data-testid="currency-select"
            >
              {availableCurrencies.map((curr) => (
                <MenuItem key={`currency-${curr}`} value={curr}>
                  {curr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledBox>

        <StyledBox>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              try {
                setPreferredLanguage(lang);
                setPreferredDateFormat(dateFormat);
                setPreferredDecimalSeparator(decimalSeparator);
                setCurrentCurrency(currency);
                window.location.reload();
              } catch (e: unknown) {
                snackbar.enqueueSnackbar(t('error.save.failed', {}), {
                  variant: 'error',
                });
              }
            }}
          >
            {t('drawer.apply', {})}
          </Button>
        </StyledBox>
        <StyledDivider />
      </UserSettings>

      <StyledBox>
        <Version>
          <Typography variant="subtitle2">{t('drawer.version', {})}</Typography>
          <VersionNumber>{versionData?.version}</VersionNumber>
        </Version>
      </StyledBox>
    </SlideOut>
  );
};

const StyledDivider = styled(Divider)`
  margin: 0 16px;
`;

const StyledBox = styled(Box)`
  padding: 22px 20px;
`;
