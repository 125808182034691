import {
  ReplacementType,
  replacementTypeValues,
} from '../domain/internalMaterialReplacement/model';
import { DemandCharacteristic, demandCharacteristics } from '../domain/materialCustomer/model';

import { t } from './i18n/i18n';
import { formatDate, parseDate } from './i18n/l10n';

export function parseDateIfPossible(dateString: string): string {
  const parsed = parseDate(dateString);
  return parsed ? formatDate(parsed) : dateString;
}

export function parseDemandCharacteristicIfPossible(value: string): string {
  const localizationKeyCreation = (val: DemandCharacteristic) =>
    t(`demand_characterictics.${val}`, {});
  const parsed = parseToStringLiteralTypeIfPossible<DemandCharacteristic>(
    value,
    demandCharacteristics,
    localizationKeyCreation,
  );
  return parsed ?? value;
}

export function parseReplacementTypeIfPossible(value: string): ReplacementType | string {
  const localizationKeyCreation = (val: ReplacementType) => t(`replacement_type.${val}`, {});
  const parsed = parseToStringLiteralTypeIfPossible<ReplacementType>(
    value,
    replacementTypeValues,
    localizationKeyCreation,
  );
  return parsed ?? value;
}

export function parseToStringLiteralTypeIfPossible<T extends string>(
  valueToParse: string,
  stringLiteralArray: readonly T[],
  localizationFunc?: (stringLit: T) => string,
): T | undefined {
  // Parse value case insensitive
  const capitalValueToParse = valueToParse.toUpperCase();
  const match = stringLiteralArray.find(
    (stringLit) => stringLit.toUpperCase() == capitalValueToParse,
  );
  if (match) {
    return match as T;
  }

  // Check for localized strings (case insensitive)
  if (localizationFunc) {
    for (const stringLit of stringLiteralArray) {
      const localizedNameUppercase = localizationFunc(stringLit).toUpperCase();
      if (localizedNameUppercase == capitalValueToParse) {
        return stringLit as T;
      }
    }
  }

  return undefined;
}

export function combineParseFunctionsForFields(
  functionMap?: Map<string | number | symbol, (value: string) => string>,
): ((fieldName: string, value: string) => string) | undefined {
  if (!functionMap) return undefined;

  return (fieldName: string, value: string) => {
    if (functionMap.has(fieldName)) {
      const parseFunc = functionMap.get(fieldName);
      return parseFunc ? parseFunc(value) : value;
    }

    return value;
  };
}
