import { ICellRendererParams } from '@ag-grid-community/core';
import { MenuItem } from '@mui/material';
import React, { useState } from 'react';

import RowMenu from '../../../components/agGrid/RowMenu';
import { t } from '../../../core/i18n/i18n';
import { IMRSubstitution } from '../../../domain/internalMaterialReplacement/model';
import { IMRModal } from '../InternalMaterialReplacementPage';

type AdditionalProps = {
  handleModalChange: (modal: IMRModal, selectedEntry: IMRSubstitution) => void;
};

export default function IMRRowMenuButton(props: ICellRendererParams & AdditionalProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (modal: IMRModal) => () => {
    // It is very important to use props.node.data not props.data
    // because the data in the latter is not up to date and may return values from old renderings
    const substitution: IMRSubstitution = {
      region: props.node.data.region,
      replacementType: props.node.data.replacementType,
      salesArea: props.node.data.salesArea,
      salesOrg: props.node.data.salesOrg,
      customerNumber: props.node.data.customerNumber,
      predecessorMaterial: props.node.data.predecessorMaterial,
      successorMaterial: props.node.data.successorMaterial,
      replacementDate: props.node.data.replacementDate,
      cutoverDate: props.node.data.cutoverDate,
      startOfProduction: props.node.data.startOfProduction,
      note: props.node.data.note,
    };

    props.handleModalChange(modal, substitution);
    setOpen(false);
  };

  return (
    <>
      <RowMenu open={open} setOpen={setOpen}>
        <MenuItem key={IMRModal.EDIT_SINGLE_SI} onClick={handleClick(IMRModal.EDIT_SINGLE_SI)}>
          {t('button.edit', {})}
        </MenuItem>
        <MenuItem key={IMRModal.DELETE_SINGLE_SI} onClick={handleClick(IMRModal.DELETE_SINGLE_SI)}>
          {t('button.delete', {})}
        </MenuItem>
      </RowMenu>
    </>
  );
}
