import { formatISO } from 'date-fns';

import { t } from '../../core/i18n/i18n';
import { parseDate } from '../../core/i18n/l10n';

import { IMRSubstitution, IMRSubstitutionRequest } from './model';

export function dataToIMRSubstitutionRequest(data: IMRSubstitution): IMRSubstitutionRequest {
  if (!data.region || !data.replacementType) {
    throw new Error(t('generic.validation.check_inputs', {}));
  }

  const replacementDate =
    typeof data.replacementDate == 'string'
      ? parseDate(data.replacementDate)
      : data.replacementDate;

  const cutoverDate =
    typeof data.cutoverDate == 'string' ? parseDate(data.cutoverDate) : data.cutoverDate;

  const startOfProduction =
    typeof data.startOfProduction == 'string'
      ? parseDate(data.startOfProduction)
      : data.startOfProduction;

  return {
    replacementType: data.replacementType,
    region: data.region,
    salesArea: data.salesArea,
    salesOrg: data.salesOrg,
    customerNumber: data.customerNumber,
    predecessorMaterial: data.predecessorMaterial,
    successorMaterial: data.successorMaterial,
    replacementDate: replacementDate
      ? formatISO(replacementDate, {
          representation: 'date',
        })
      : null,
    cutoverDate: cutoverDate
      ? formatISO(cutoverDate, {
          representation: 'date',
        })
      : null,
    startOfProduction: startOfProduction
      ? formatISO(startOfProduction, {
          representation: 'date',
        })
      : null,
    note: data.note,
  };
}
