import React from 'react';

import {
  ErrorMessage,
  TableUploadModal,
} from '../../../components/tableUploadModal/TableUploadModal';
import { PostResult, errorsFromSAPtoMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { parseDateIfPossible, parseReplacementTypeIfPossible } from '../../../core/parseValues';
import {
  IMRSubstitution,
  IMRSubstitutionResponse,
} from '../../../domain/internalMaterialReplacement/model';
import { saveMultiIMRSubstitution } from '../../../domain/internalMaterialReplacement/saveMultiIMRSubstitution';

import { multiSubstitutionModalColumns } from './multiSubstitutionModalColumns';
import {
  checkForbiddenFieldsForNewSubstitution,
  checkMissingFields,
} from './replacementTypeLogicHelpers';

type MultiSubstitutionModalProps = {
  open: boolean;
  onClose: () => void;
  onAdded?: () => void;
};

export default function MultiSubstitutionModal(props: MultiSubstitutionModalProps) {
  return (
    <TableUploadModal
      title={t('internal_material_replacement.title.newSubstitutions', {})}
      open={props.open}
      onClose={props.onClose}
      onAdded={props.onAdded}
      applyFunction={saveMultiIMRSubstitution}
      parseErrorsFromResult={parseErrorsFromResult}
      columnDefinitions={multiSubstitutionModalColumns}
      checkDataForErrors={checkData}
      specialParseFunctionsForFields={specialParseFunctionsForFields}
      wideModal={true}
    />
  );
}

function parseErrorsFromResult(
  res: PostResult<IMRSubstitutionResponse>,
): ErrorMessage<IMRSubstitution>[] {
  const errors: ErrorMessage<IMRSubstitution>[] = [];
  res.response.forEach((r) => {
    if (r.result.messageType == 'ERROR') {
      errors.push({
        dataIdentifier: {
          replacementType: r.replacementType,
          region: r.region,
          salesArea: r.salesArea,
          salesOrg: r.salesOrg,
          customerNumber: r.customerNumber,
          predecessorMaterial: r.predecessorMaterial,
        },
        errorMessage: errorsFromSAPtoMessage(r.result),
      });
    }
  });
  return errors;
}

function checkData(data: IMRSubstitution[]): ErrorMessage<IMRSubstitution>[] {
  const errors: ErrorMessage<IMRSubstitution>[] = [];
  data.forEach((substitutionToAdd) => {
    const missingFields = checkMissingFields(substitutionToAdd);
    if (missingFields) {
      const missingFieldErrors: ErrorMessage<IMRSubstitution>[] = missingFields.map((field) => {
        return {
          dataIdentifier: substitutionToAdd,
          specificField: field,
          errorMessage: t('generic.validation.missing_fields', {}),
        };
      });
      errors.push(...missingFieldErrors);
    }

    const wronglyFilledFields = checkForbiddenFieldsForNewSubstitution(substitutionToAdd);
    if (wronglyFilledFields) {
      const forbiddenFieldErrors: ErrorMessage<IMRSubstitution>[] = wronglyFilledFields.map(
        (field) => {
          return {
            dataIdentifier: substitutionToAdd,
            specificField: field,
            errorMessage: t('internal_material_replacement.error.fielNotAllowed', {}),
          };
        },
      );
      errors.push(...forbiddenFieldErrors);
    }
  });

  return errors;
}

const specialParseFunctionsForFields: Map<keyof IMRSubstitution, (value: string) => string> =
  new Map([
    ['replacementType', parseReplacementTypeIfPossible],
    ['replacementDate', parseDateIfPossible],
    ['cutoverDate', parseDateIfPossible],
    ['startOfProduction', parseDateIfPossible],
  ]);
