import React, { useState } from 'react';

import { StyledSection } from '../../components/StyledSection';
import GlobalSelectionCriteria from '../../components/globalSelectionCriteria/GlobalSelectionCriteria';
import { Page } from '../../components/page/Page';
import { t } from '../../core/i18n/i18n';

import { ForecastChartSection } from './forecastChart/ForecastChartSection';
import MaterialCustomerTable from './table/MaterialCustomerTable';

const Home = () => {
  const [columnFilters, setColumnFilters] = useState<Record<string, any>>({});

  return (
    <Page title={t('tabbar.start-page.label', {})}>
      <GlobalSelectionCriteria />
      <ForecastChartSection columnFilters={columnFilters} />
      <StyledSection last={true} grow={true}>
        <MaterialCustomerTable columnFilters={columnFilters} setColumnFilters={setColumnFilters} />
      </StyledSection>
    </Page>
  );
};

export default Home;
