import { TrafficLightTooltip } from '../../../components/agGrid/TrafficLightTooltip';
import { ReplacementTypeCellRenderer } from '../../../components/agGrid/cellRenderers/ReplacementTypeCellRenderer';
import TrafficLightCellRenderer from '../../../components/agGrid/cellRenderers/TrafficLightCellRenderer';
import {
  trafficLightValues,
  trafficLightValueFormatter,
} from '../../../components/agGrid/trafficLightSharedFunctions';
import { t } from '../../../core/i18n/i18n';
import {
  ReplacementType,
  replacementTypeValues,
} from '../../../domain/internalMaterialReplacement/model';

export default function getIMRColumnDefinitons() {
  return [
    {
      property: 'region',
      colId: 'material_customer.column.region',
      cellRenderer: undefined,
      filter: undefined,
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'salesArea',
      colId: 'material_customer.column.salesArea',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'salesOrg',
      colId: 'material_customer.column.salesOrg',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'customerNumber',
      colId: 'material_customer.column.customerNumber',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'predecessorMaterial',
      colId: 'internal_material_replacement.column.predecessorMaterial',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'successorMaterial',
      colId: 'internal_material_replacement.column.successorMaterial',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'replacementDate',
      colId: 'internal_material_replacement.column.replacementDate',
      cellRenderer: 'dateRenderer',
      filter: 'agDateColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'cutoverDate',
      colId: 'internal_material_replacement.column.cutoverDate',
      cellRenderer: 'dateRenderer',
      filter: 'agDateColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'startOfProduction',
      colId: 'internal_material_replacement.column.startOfProduction',
      cellRenderer: 'dateRenderer',
      filter: 'agDateColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'replacementType',
      colId: 'internal_material_replacement.column.replacementType',
      cellRenderer: ReplacementTypeCellRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: replacementTypeValues,
        valueFormatter: (params: any): string => {
          return t(`replacement_type.${params.value as ReplacementType}`, {});
        },
      },
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'lastChangeDate',
      colId: 'internal_material_replacement.column.lastChangeDate',
      cellRenderer: 'dateRenderer',
      filter: 'agDateColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'lastChangeUser',
      colId: 'internal_material_replacement.column.lastChangeUser',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'note',
      colId: 'internal_material_replacement.column.note',
      cellRenderer: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'tlMessageType',
      colId: 'internal_material_replacement.column.statusMasterData',
      cellRenderer: TrafficLightCellRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: trafficLightValues,
        valueFormatter: trafficLightValueFormatter,
      },
      tooltipComponent: TrafficLightTooltip,
      tooltipField: 'tlMessage',
    },
    {
      property: 'countBCTotal',
      colId: 'internal_material_replacement.column.countBCTotal',
      cellRenderer: 'numberRenderer',
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'countBCAutomaticAccepted',
      colId: 'internal_material_replacement.column.countBCAutomaticAccepted',
      cellRenderer: 'numberRenderer',
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'countBCManualAccepted',
      colId: 'internal_material_replacement.column.countBCManualAccepted',
      cellRenderer: 'numberRenderer',
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'countBCManualRejected',
      colId: 'internal_material_replacement.column.countBCManualRejected',
      cellRenderer: 'numberRenderer',
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'countBCVeto',
      colId: 'internal_material_replacement.column.countBCVeto',
      cellRenderer: 'numberRenderer',
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'countBCOpen',
      colId: 'internal_material_replacement.column.countBCOpen',
      cellRenderer: 'numberRenderer',
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
  ] as const;
}
