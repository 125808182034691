import {
  createTheme,
  formLabelClasses,
  outlinedInputClasses,
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material';

import {
  borderColor,
  borderColorDark,
  secondaryColor,
  textDarkGrey,
  textLightGrey,
  errorColor,
  schaefflerColor,
  warningColor,
  textLight,
} from './colors';
import shadows from './shadows';

const theme = createTheme({
  shadows,
  palette: {
    primary: {
      main: schaefflerColor,
    },
    secondary: {
      main: secondaryColor,
    },
    warning: {
      main: warningColor,
    },
    error: {
      main: errorColor,
    },
    background: {
      default: textLight,
    },
    white: {
      main: textLight,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: textLight,
          color: textLightGrey,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: borderColorDark,
            borderWidth: 2,
          },
          [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: errorColor,
            borderWidth: 2,
          },

          [`&:hover:not(.${outlinedInputClasses.error}):not(.${outlinedInputClasses.focused}) .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: borderColor,
            },
        },
        notchedOutline: {
          borderColor: borderColor,
          borderWidth: 1,
        },
        input: {
          color: textDarkGrey,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: textLightGrey,
          [`&.${formLabelClasses.focused}`]: {
            color: secondaryColor,
          },
          [`&.${formLabelClasses.error}`]: {
            color: errorColor,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          [`&.${formLabelClasses.error}`]: {
            color: errorColor,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: textLightGrey,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        invisible: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiButton: {
      variants: [
        {
          props: { color: 'white' },
          style: {
            backgroundColor: textLight,
          },
        },
      ],
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    // add white as an option to button colors
    white: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    white: PaletteColor;
  }

  interface PaletteOptions {
    white: Partial<PaletteColorOptions>;
  }
}

export default theme;
