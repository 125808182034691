import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import Modal from '../modal/Modal';

import { LoadingSpinner } from './LoadingSpinner';

export function LoadingSpinnerModal({ open, text }: { open: boolean; text?: string }) {
  return (
    <Modal open={open}>
      <ModalContainer>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
        {text && <Typography mt="16px">{text}</Typography>}
      </ModalContainer>
    </Modal>
  );
}

const ModalContainer = styled.div`
  padding: 32px;
  text-align: center;
`;

const LoadingSpinnerContainer = styled.div`
  position: relative;
  min-height: 60px;
  min-width: 60px;
  width: 100%;
`;
