import { Button, FormControl, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import { ErrorHandlingSelect } from '../../../components/ErrorHandlingSelect';
import { errorHelperTextProps } from '../../../components/FieldError';
import { SingleAutocompleteOnType } from '../../../components/inputs/autocomplete/SingleAutocompleteOnType';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { ModalHeadlineSeparator } from '../../../components/modal/ModalHeadline';
import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../../core/requests/httpClient';
import { validateMaterialNumber } from '../../../core/validation/filterValidation';
import { saveCMPChange } from '../../../domain/customerMaterialPortfolio/saveCMPChange';
import {
  DemandCharacteristic,
  demandCharacteristics,
} from '../../../domain/materialCustomer/model';

import PhaseInStatusSpecificContent from './cmpModalStatusSpecificElements/statusSpecificContent/PhaseInStatusSpecificContent';

type AddSingleCustomerMaterialModalProps = {
  open: boolean;
  onCancel?: () => void;
  onAdded?: () => void;
  customerNumber: string | null;
};

export function AddSingleCustomerMaterialModal({
  onCancel,
  onAdded,
  customerNumber,
  open,
}: AddSingleCustomerMaterialModalProps) {
  const snackbar = useSnackbar();

  const getMaterialNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-materials', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const [demandCharacteristic, setDemandCharacteristic] = useState<DemandCharacteristic | null>(
    null,
  );
  const [demandCharacteristicErrorMessages, setDemandCharacteristicErrorMessages] = useState<
    Array<string>
  >([]);
  const [phaseInDate, setPhaseInDate] = useState<Date | null>(null);
  const [phaseInDateIsValid, setPhaseInDateIsValid] = useState(true);
  const [showValidation, setShowValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  const [materialNr, setMaterialNr] = useState('');
  const [materialNrErrorMessages, setMaterialNrErrorMessages] = useState<Array<string>>([]);

  const resetState = () => {
    setPhaseInDate(null);
    setPhaseInDateIsValid(true);
    setMaterialNr('');
    setMaterialNrErrorMessages([]);
    setDemandCharacteristic(null);
    setDemandCharacteristicErrorMessages([]);
    setShowValidation(false);
  };

  const doValidate = (): boolean => {
    const newMaterialNrErrorMessages: Array<string> = [];
    if (!materialNr) {
      newMaterialNrErrorMessages.push(t('generic.validation.missing_fields', {}));
    } else {
      const materialNrErrors = validateMaterialNumber(materialNr);
      if (materialNrErrors != null) {
        materialNrErrors.forEach((errorMessage) => newMaterialNrErrorMessages.push(errorMessage));
      }
    }
    setMaterialNrErrorMessages(newMaterialNrErrorMessages);

    const newDemandCharacteristicErrorMessages: Array<string> = [];
    if (!demandCharacteristic) {
      newDemandCharacteristicErrorMessages.push(t('generic.validation.missing_fields', {}));
    }
    setDemandCharacteristicErrorMessages(newDemandCharacteristicErrorMessages);

    return (
      newMaterialNrErrorMessages.length == 0 && newDemandCharacteristicErrorMessages.length == 0
    );
  };

  const handleAdd = async () => {
    setShowValidation(true);
    setLoading(true);
    const upperRowValid = doValidate();
    if (!(customerNumber && demandCharacteristic && phaseInDateIsValid && materialNr)) {
      snackbar.enqueueSnackbar(t('generic.validation.missing_fields', {}), {
        variant: 'error',
      });
      setLoading(false);
      return;
    }
    if (!upperRowValid || !phaseInDateIsValid) {
      // Error messages are shown next to the fields
      setLoading(false);
      return;
    }

    const postResult = await saveCMPChange(
      {
        customerNumber: customerNumber,
        materialNumber: materialNr,
        materialDescription: null,
        demandCharacteristic: demandCharacteristic,
        autoSwitchDate: phaseInDate,
        repDate: null,
        portfolioStatus: 'PI',
        successorMaterial: null,
        demandPlanAdoption: null,
      },
      false,
      'single-phase-in',
    );

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t(`customer_material_portfolio.phase_in_out_single_modal.save.success`, {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });
    setLoading(false);
    if (userMessage.variant == 'success' || userMessage.variant == 'warning') {
      resetState();
      onAdded ? onAdded() : null;
    }
  };

  const handleCancel = () => {
    resetState();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal onClose={handleCancel} fullWidth open={open} maxWidth={'md'}>
      <LoadingSpinnerModal open={loading} />
      <Modal.Headline
        onClose={handleCancel}
        text={t('customer_material_portfolio.phase_in_modal.headline', {})}
      >
        <Button size="medium" variant="contained" color="primary" onClick={handleAdd}>
          {t('customer_material_portfolio.modal.add', {})}
        </Button>
      </Modal.Headline>
      <ModalHeadlineSeparator />
      <Modal.Body>
        <Box sx={{ mb: 2 }}>
          <Typography>{t('customer_material_portfolio.modal.text', {})}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              label={t('customer_material_portfolio.modal.customer_number', {})}
              disabled={true}
              value={customerNumber}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleAutocompleteOnType
              value={materialNr || ''}
              onValueChange={(e, value) => {
                setMaterialNr(value?.id || '');
                setMaterialNrErrorMessages([]);
              }}
              autocompleteLabel={t('customer_material_portfolio.modal.material', {})}
              getOptions={getMaterialNumberOptions}
              getOptionLabel={(o) => o.text}
              errorHelperTextProps={errorHelperTextProps(materialNrErrorMessages)}
              muiProps={{
                renderOption: (props: React.HTMLAttributes<unknown>, option: SelectableValue) => (
                  <Box component="li" key={option.id} {...props}>
                    {option.id} - {option.text}
                  </Box>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth size={'small'}>
              <ErrorHandlingSelect<DemandCharacteristic>
                label={t('customer_material_portfolio.modal.demand_characteristic', {})}
                value={demandCharacteristic}
                errorMessages={demandCharacteristicErrorMessages}
                onChange={(newValue) => {
                  setDemandCharacteristic(newValue);
                  setDemandCharacteristicErrorMessages([]);
                }}
                options={demandCharacteristics.map((dc) => ({
                  key: dc,
                  displayValue: t(`field.demandCharacteristic.value.${dc}`, {}),
                }))}
              />
            </FormControl>
          </Grid>
        </Grid>

        <PhaseInStatusSpecificContent
          data={{
            customerNumber: '',
            materialNumber: '',
            materialDescription: '',
            demandCharacteristic: null,
            autoSwitchDate: phaseInDate,
            repDate: null,
            portfolioStatus: null,
            successorMaterial: null,
            demandPlanAdoption: null,
          }}
          onDataChange={(statusSpecificData) => setPhaseInDate(statusSpecificData.autoSwitchDate)}
          showValidation={showValidation}
          setValid={setPhaseInDateIsValid}
        />
      </Modal.Body>
    </Modal>
  );
}
