import { useCallback, useEffect, useRef } from 'react';

import { DATA_FETCHED_EVENT } from '../core/datasources';

import { GridApis } from './gridTypes';

/**
 * Subscribes to our custom data fetched event and autosizes all columns the
 * first time data is fetched.
 */
function useAutosizeColumns(grid: GridApis | undefined) {
  const gridAutoSized = useRef(false);

  const autosize = useCallback(() => {
    grid?.columnApi?.autoSizeAllColumns();
  }, [grid]);

  useEffect(() => {
    if (!grid) return;

    const listener = () => {
      if (!gridAutoSized.current) {
        autosize();
        gridAutoSized.current = true;
      }
    };

    grid.api?.addEventListener(DATA_FETCHED_EVENT, listener);

    return () => {
      grid.api?.removeEventListener(DATA_FETCHED_EVENT, listener);
    };
  }, [grid, autosize]);

  return {
    resizeColumns: autosize,
  };
}

export default useAutosizeColumns;
