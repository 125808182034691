import useSWR from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';

import { VersionData } from './model';

export function useVersionData() {
  const versionData = useSWR<VersionData | undefined>('info/version', requestFromApi);

  return versionData;
}

export function useAvailableCurrenciesData() {
  const availableCurrenciesData = useSWR<string[]>('info/currencies', requestFromApi);

  return availableCurrenciesData;
}
