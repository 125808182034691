import { FilterOptionsState } from '@mui/material';

import {
  ResolveSelectableValueResult,
  SelectableValue,
} from '../../components/inputs/baseComponents/selectableValues';
import { t } from '../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../core/requests/httpClient';
import {
  validateCustomerNumber,
  validateGkamNumber,
  validateMaterialNumber,
  validateProductionPlants,
  validateSalesOrg,
  validateSectors,
} from '../../core/validation/filterValidation';
import { fillZeroOnValueFunc } from '../../core/validation/validationHelper';
import { fetchMaterialData } from '../materialCustomer/materialsData';

export async function resolveOptions(
  values: Array<string>,
  options: SelectableValue[],
  formatFunc?: (value: string) => string,
  validateFunc?: (value: string) => Array<string> | null,
  errorTextFunc?: (value: string) => string,
): Promise<Array<ResolveSelectableValueResult>> {
  return values.map((value) => {
    const validationErrors = validateFunc ? validateFunc(value) : null;

    if (validationErrors) {
      return { id: value, error: validationErrors };
    }

    const formattedValue = formatFunc ? formatFunc(value) : value;
    const optionExists = options.find((item) => item.id === formattedValue);

    if (!optionExists) {
      const errText = errorTextFunc
        ? errorTextFunc(formattedValue)
        : t('error.not_valid', {}) + formattedValue;
      return { id: value, error: [errText] };
    }

    return { id: value, selectableValue: optionExists };
  });
}

export async function resolveGkamNumber(
  values: Array<string>,
  options: SelectableValue[],
): Promise<Array<ResolveSelectableValueResult>> {
  return resolveOptions(
    values,
    options,
    (value) => fillZeroOnValueFunc(6, value),
    (value) => validateGkamNumber(value),
    (formattedValue) => t('error.notValidGkamNumber', { formattedValue }),
  );
}

export async function resolveSalesOrg(
  values: Array<string>,
  options: SelectableValue[],
): Promise<Array<ResolveSelectableValueResult>> {
  return resolveOptions(
    values,
    options,
    (value) => fillZeroOnValueFunc(4, value),
    (value) => validateSalesOrg(value),
    (formattedValue) => t('error.notValidSalesOrg', { formattedValue }),
  );
}

export async function resolveProductionPlants(
  values: Array<string>,
  options: SelectableValue[],
): Promise<Array<ResolveSelectableValueResult>> {
  return resolveOptions(values, options, undefined, (value) => validateProductionPlants(value));
}

export async function resolveSectors(
  values: Array<string>,
  options: SelectableValue[],
): Promise<Array<ResolveSelectableValueResult>> {
  return resolveOptions(values, options, undefined, (value) => validateSectors(value));
}

export async function resolveCustomerNumbers(
  values: Array<string>,
): Promise<Array<ResolveSelectableValueResult>> {
  const resolveResults = values.map((value) => {
    const validationErrors = validateCustomerNumber(value);

    if (validationErrors) {
      return { id: value, error: validationErrors } as ResolveSelectableValueResult;
    }

    return { id: value } as ResolveSelectableValueResult;
  });

  const formattedCustomerNumbers = resolveResults
    .filter((entry) => !entry.error)
    .map((entry) => fillZeroOnValueFunc(10, entry.id));

  for (const chunk of splitToChunks(formattedCustomerNumbers, 100)) {
    const fetchedCustomers = await requestFromApi(`global-selection/search-customers-by-name`, {
      method: 'POST',
      body: JSON.stringify(chunk),
    });

    fetchedCustomers.forEach((customer: any) => {
      const entry = resolveResults.filter((e) => customer.id === fillZeroOnValueFunc(10, e.id));

      if (entry) {
        entry.forEach((e) => (e.selectableValue = { id: customer.id, text: customer.text }));
      }
    });
  }

  resolveResults.forEach((entry) => {
    if (entry.error == null && entry.selectableValue == null) {
      entry.error = [t('error.notValidCustomerNumber', { formattedValue: entry.id })];
    }
  });

  return resolveResults;
}

export async function resolveMaterialNumbers(
  values: Array<string>,
): Promise<Array<ResolveSelectableValueResult>> {
  const resolveResults = values.map((value) => {
    const validationErrors = validateMaterialNumber(value);

    if (validationErrors) {
      return { id: value, error: validationErrors };
    }

    return { id: value } as ResolveSelectableValueResult;
  });

  const formattedMaterialNumbers = resolveResults
    .filter((entry) => !entry.error)
    .map((entry) => entry.id.replace(/-/g, ''));

  for (const chunk of splitToChunks(formattedMaterialNumbers, 100)) {
    const fetchedMaterials = await fetchMaterialData(chunk);

    fetchedMaterials.rows.forEach((material: any) => {
      const formattedMaterialNumber = material.materialNumber.replace(/-/g, '');
      const entry = resolveResults.filter(
        (entry) => formattedMaterialNumber === entry.id.replace(/-/g, ''),
      );

      if (entry) {
        entry.forEach(
          (e) =>
            (e.selectableValue = {
              id: material.materialNumber,
              text: material.materialDescription,
            }),
        );
      }
    });
  }

  resolveResults.forEach((entry) => {
    if (entry.error == null && entry.selectableValue == null) {
      entry.error = [t('error.notValidMaterialNumber', { formattedValue: entry.id })];
    }
  });

  return resolveResults;
}

/**
 * Very simple implementation of resolving of multiple values.
 * Requests each value in a separate request!
 * @param values
 * @param urlBegin
 * @param language
 */
export async function resolveOptionsOnType(
  values: Array<string>,
  urlBegin: string,
  validateFunc?: (value: string) => Array<string> | null,
  language?: string,
): Promise<Array<ResolveSelectableValueResult>> {
  const validatedValues = values.map((value) => {
    const validationErrors = validateFunc ? validateFunc(value) : null;
    if (validationErrors) {
      return { id: value, error: validationErrors };
    }

    return { id: value } as ResolveSelectableValueResult;
  });

  const resolvedValues = await Promise.all(
    validatedValues.map(async (value) => {
      if (value.error) {
        return value;
      }

      const idToSearch = value.id;
      const url = generateUrlWithSearchTerm(urlBegin, idToSearch, language);
      const fetchedResults = await requestFromApi(url);

      return fetchedResults.length > 0
        ? { ...value, selectableValue: fetchedResults[0] as SelectableValue }
        : value;
    }),
  );

  resolvedValues.forEach((entry) => {
    if (entry.error == null && entry.selectableValue == null) {
      entry.error = [`Resolving value ${entry.id} failed.`];
    }
  });

  return resolvedValues;
}

export const filterOptionsIgnoreMinus = (
  options: SelectableValue[],
  state: FilterOptionsState<SelectableValue>,
) => {
  return options.filter((option: SelectableValue) => {
    // ignore minuses in material numbers when filtering options by text
    if (
      option.id
        .replaceAll('-', '')
        .toLowerCase()
        .includes(state.inputValue.replaceAll('-', '').toLowerCase())
    ) {
      return true;
    }

    if (option.text.toLocaleLowerCase().includes(state.inputValue.toLowerCase())) {
      return true;
    }

    return false;
  });
};

function splitToChunks(arr: Array<any>, len: number) {
  const chunks = [];

  for (let i = 0; i < arr.length; i += len) {
    chunks.push(arr.slice(i, i + len));
  }

  return chunks;
}
