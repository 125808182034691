import { Column } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React from 'react';

export function parseFromClipboard(
  gridRef: React.MutableRefObject<AgGridReact | null>,
  data: string[][],
  parseSpecialFields?: (fieldName: string, cellDataToAdd: string) => string,
) {
  const gridApi = gridRef.current?.api;
  const columnApi = gridRef.current?.columnApi;
  if (!gridApi || !columnApi) return null;

  const focusedCell = gridApi.getFocusedCell();
  if (!focusedCell) return null;

  const startColumn = focusedCell.column;

  const existingRowData: any[] = [];
  gridApi.forEachNode((node) => {
    existingRowData.push(node.data);
  });

  const addRows: any[] = [];
  const updateRows: any[] = [];

  let currentEditingRowIndex: number = focusedCell.rowIndex;

  data.forEach((rowData) => {
    let currentEditingRowData: any = {};
    let isRowAlreadyInGrid = false;
    let currentEditingColumn: Column | null = startColumn;

    const existingData = existingRowData[currentEditingRowIndex];
    if (existingData) {
      currentEditingRowData = existingData;
      isRowAlreadyInGrid = true;
    }

    rowData.forEach((cellDataToAdd) => {
      if (!currentEditingColumn) {
        return;
      }

      const fieldName = currentEditingColumn.getColDef().field ?? '';

      const dataToAdd =
        parseSpecialFields == undefined
          ? cellDataToAdd
          : parseSpecialFields(fieldName, cellDataToAdd);

      currentEditingRowData[fieldName] = dataToAdd.trim();

      currentEditingColumn = columnApi.getDisplayedColAfter(currentEditingColumn);
    });

    currentEditingRowIndex = currentEditingRowIndex + 1;

    if (isRowAlreadyInGrid) {
      updateRows.push(currentEditingRowData);
    } else {
      addRows.push(currentEditingRowData);
    }
  });

  gridApi.applyTransaction({
    addIndex: existingRowData.length,
    add: addRows,
    update: updateRows,
  });
  return null;
}
