import CloseIcon from '@mui/icons-material/Close';
import { ExtendButtonBase, IconButton, IconButtonTypeMap } from '@mui/material';
import * as React from 'react';
import styled, { DefaultTheme, StyledComponentProps } from 'styled-components';

import { t } from '../../core/i18n/i18n';

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 18px;
  color: ${(props) => props.theme.palette.grey[500]};
`;

export function ModalClose(
  props: StyledComponentProps<ExtendButtonBase<IconButtonTypeMap>, DefaultTheme, object, never>,
) {
  return (
    <CloseButton aria-label={t('button.close', {})} {...props}>
      <CloseIcon />
    </CloseButton>
  );
}
