import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { t } from '../../../core/i18n/i18n';
import { ReplacementType } from '../../../domain/internalMaterialReplacement/model';

export const ReplacementTypeCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  const replacementType = params.value as ReplacementType;
  return <span>{t(`replacement_type.${replacementType}`, {}, t(`error.valueUnknown`, {}))}</span>;
};
