import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

import { t } from './i18n/i18n';
import { HttpError, NetworkError } from './requests/httpClient';

type ProblemDetail = {
  title: string;
  detail: string;
  code: string | null;
  values: Record<string, any> | null;
};

function isProblemDetail(detail: any): detail is ProblemDetail {
  if (!detail || typeof detail !== 'object') return false;

  return (
    typeof detail.title === 'string' &&
    typeof detail.detail === 'string' &&
    (detail.code === null || typeof detail.code === 'string') &&
    (detail.values === null || typeof detail.values === 'object')
  );
}

export type CustomErrorMessages = Record<string, (details: ProblemDetail) => string>;

export function getErrorMessage(e: unknown, customMessages?: CustomErrorMessages) {
  if (e instanceof HttpError) {
    return getHttpErrorMessage(e, customMessages);
  } else if (e instanceof NetworkError) {
    return t('error.network_error', {});
  } else if (e instanceof Error) {
    return e.message;
  }

  return t('error.unknown', {});
}

function getHttpErrorMessage(e: HttpError, customMessages?: CustomErrorMessages) {
  const details = e.details;

  if (isProblemDetail(details)) {
    const customMessageFn = customMessages && details.code && customMessages[details.code];
    if (customMessageFn) {
      return customMessageFn(details);
    }

    const message = [details.title, details.detail].filter(Boolean).join(': ');

    if (message) {
      return message;
    }

    if (details.code) {
      return t('error.error_with_code', { code: details.code });
    }
  }

  return t('error.unknown', {});
}

export function useSwrErrorNotification(error?: unknown) {
  const showErrorMessage = useRateLimitedErrorNotification();
  const errorMessage = error ? getErrorMessage(error) : undefined;

  useEffect(() => {
    if (errorMessage) {
      showErrorMessage(errorMessage);
    }
  }, [errorMessage, showErrorMessage]);
}

const rateLimitTimeouts: Record<string, NodeJS.Timeout> = {};

export function useRateLimitedErrorNotification() {
  const snackbar = useSnackbar();

  return useCallback(
    (errorMessage: string) => {
      if (rateLimitTimeouts[errorMessage]) {
        clearTimeout(rateLimitTimeouts[errorMessage]);
      } else {
        snackbar.enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }

      rateLimitTimeouts[errorMessage] = setTimeout(() => {
        delete rateLimitTimeouts[errorMessage];
      }, 200);
    },
    [snackbar],
  );
}
