import { useEffect, useRef, useState } from 'react';

import { getErrorMessage, useRateLimitedErrorNotification } from '../../core/errors';
import { RequestAbortedError, requestFromApi } from '../../core/requests/httpClient';
import { MaterialCustomerCustomerRequest } from '../materialCustomer/model';

import { GlobalSelectionCriteriaFields, globalSelectionCriteriaToFilter } from './model';

export default function useGlobalSelectionResultCount(
  globalSelection: GlobalSelectionCriteriaFields | undefined,
): {
  resultCount: number | undefined;
  loading: boolean;
} {
  const [resultCount, setResultCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const abortControllerRef = useRef<AbortController | undefined>(undefined);
  const showErrorMessage = useRateLimitedErrorNotification();

  // Update the result count
  useEffect(() => {
    if (!globalSelection) {
      setResultCount(undefined);
      return;
    }

    setLoading(true);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const globalSelectionFilter = globalSelectionCriteriaToFilter(globalSelection);
    // Make a very simple request to just get the count
    const request = {
      startRow: 0,
      endRow: 1,
      sortModel: [],
      selectionFilters: globalSelectionFilter,
      columnFilters: [],
    } as MaterialCustomerCustomerRequest;
    requestFromApi(`global-selection/count`, {
      method: 'POST',
      body: JSON.stringify(request),
      signal: abortController.signal,
    })
      .then((rowCount) => {
        setResultCount(rowCount);
      })
      .catch((e) => {
        if (e instanceof RequestAbortedError) {
          // never mind, the request was aborted by us
        } else {
          showErrorMessage(getErrorMessage(e));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [globalSelection, setResultCount, setLoading, showErrorMessage]);

  return {
    resultCount: resultCount,
    loading: loading,
  };
}
