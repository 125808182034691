import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import useDomId from '../core/hooks/useDomId';
import { t } from '../core/i18n/i18n';
import { CustomerEntry } from '../domain/globalSelection/model';
import { dimmedGrey } from '../styles/colors';

export type CustomerDropdownProps = {
  customers: CustomerEntry[];
  selectedCustomer: CustomerEntry;
  onChangeSelectedCustomer: (newValue: CustomerEntry) => void;
};

export default function CustomerDropdown({
  customers,
  selectedCustomer,
  onChangeSelectedCustomer,
}: CustomerDropdownProps) {
  const currentCustomerNumberId = useDomId();

  return (
    <>
      <VKORGDisplay>
        <Typography variant={'subtitle1'} color={dimmedGrey}>
          {t('validation_of_demand.vkorg', {})}
        </Typography>
        <Typography>{selectedCustomer.salesOrg || ''}</Typography>
      </VKORGDisplay>

      <FormControl fullWidth size={'small'}>
        <InputLabel id={currentCustomerNumberId}>
          {t('validation_of_demand.customer', {})}
        </InputLabel>
        <CustomerNumberSelect
          labelId={currentCustomerNumberId}
          label={t('validation_of_demand.customer', {})}
          value={selectedCustomer.customerNumber}
          onChange={(e) => {
            const newCustomer = customers.find((c) => c.customerNumber === e.target.value);
            if (newCustomer) {
              onChangeSelectedCustomer(newCustomer);
            }
          }}
        >
          {customers.map((cust) => (
            <MenuItem key={cust.customerNumber} value={cust.customerNumber}>
              {cust.customerNumber} - {cust.customerName || ''}
            </MenuItem>
          ))}
        </CustomerNumberSelect>
      </FormControl>
    </>
  );
}

export const VKORGDisplay = styled.span`
  display: inline-flex;
  flex-direction: column;

  > * {
    white-space: nowrap;
    line-height: unset;
  }
`;

export const CustomerNumberSelect = styled(Select)`
  width: 100%;
`;
