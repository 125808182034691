import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { t } from '../../../core/i18n/i18n';
import { PlanningView } from '../../../domain/demandValidation/planningView';
import { ChartUnitMode } from '../../../domain/forecastChart/model';
import useCurrency from '../../../domain/info/useCurrency';

type Props = {
  planningView: PlanningView;
  chartUnitMode: ChartUnitMode;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: (planningView: PlanningView, chartUnitMode: ChartUnitMode) => void;
};

export function ForecastChartSettingsPopover(props: Props) {
  const { currentCurrency } = useCurrency();

  const [planningView, setPlanningView] = useState<PlanningView>(props.planningView);
  const [chartUnitMode, setChartUnitMode] = useState<ChartUnitMode>(props.chartUnitMode);

  useEffect(() => {
    setPlanningView(props.planningView);
    setChartUnitMode(props.chartUnitMode);
  }, [props.planningView, props.chartUnitMode]);

  const handleChartUnitModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    if (Object.keys(ChartUnitMode).includes(newValue)) {
      const value = ChartUnitMode[newValue as keyof typeof ChartUnitMode];
      setChartUnitMode(value);
    }
  };

  const handlePlanningViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    if (Object.keys(PlanningView).includes(newValue)) {
      const value = PlanningView[newValue as keyof typeof PlanningView];
      setPlanningView(value);
    }
  };

  return (
    <>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={() => props.onClose(planningView, chartUnitMode)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <Stack padding={'24px'} direction="column">
            <FormGroup>
              <FormControl>
                <RadioGroup value={chartUnitMode} onChange={handleChartUnitModeChange}>
                  <StyledMenuItem>
                    <FormControlLabel
                      value={ChartUnitMode.CURRENCY}
                      label={currentCurrency}
                      aria-label={currentCurrency}
                      control={<Radio />}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <FormControlLabel
                      value={ChartUnitMode.QUANTITY}
                      label={t('home.chart.pieces_option', {})}
                      aria-label={t('home.chart.pieces_option', {})}
                      control={<Radio />}
                    />
                  </StyledMenuItem>
                </RadioGroup>
              </FormControl>
            </FormGroup>
            <Divider />
            <FormGroup>
              <FormControl>
                <RadioGroup value={planningView} onChange={handlePlanningViewChange}>
                  <StyledMenuItem>
                    <FormControlLabel
                      value={PlanningView.REQUESTED}
                      label={t('planing_type.title.REQUESTED', {})}
                      aria-label={t('planing_type.title.REQUESTED', {})}
                      control={<Radio />}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem>
                    <FormControlLabel
                      value={PlanningView.CONFIRMED}
                      label={t('planing_type.title.CONFIRMED', {})}
                      aria-label={t('planing_type.title.CONFIRMED', {})}
                      control={<Radio />}
                    />
                  </StyledMenuItem>
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
}

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;
