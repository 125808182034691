import { Autocomplete, AutocompleteProps, FormControl, TextField } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';

import { OptionsLoadingResult } from '../../../core/hooks/useSelectableOptions';
import { t } from '../../../core/i18n/i18n';
import { ErrorProps } from '../../FieldError';

import { AutocompleteLabelProps } from './MultiAutocompleteBase';
import { SelectableValue } from './selectableValues';

// Don`t use AutocompleteSingleValueBaseProps here because value should be just
// SelectableValue here, the parent components should handle the processing
type SingleAutocompleteProps = {
  value: SelectableValue | undefined | null;
  onValueChange: (event: unknown, value: SelectableValue | null) => void;
  inputValue: string;
  onInputValueChange: (event: SyntheticEvent, inputVal: string) => void;
  openDelayedAfterTyping?: number;
  muiProps?: Partial<AutocompleteProps<SelectableValue, false, false, false>>;
  errorHelperTextProps?: ErrorProps;
} & AutocompleteLabelProps &
  OptionsLoadingResult;

export type SingleAutocompleteValueBaseProps = {
  value: SelectableValue | string | undefined | null;
  onValueChange: (event: unknown, values: SelectableValue | null) => void;
  errorHelperTextProps?: ErrorProps;
  muiProps?: Partial<AutocompleteProps<SelectableValue, false, false, false>>;
};

export const SingleAutocompleteBase = (props: SingleAutocompleteProps) => {
  const [open, setOpen] = useState(false);

  return (
    <FormControl fullWidth size={'small'}>
      <Autocomplete
        size={'small'}
        value={props.value}
        onChange={props.onValueChange}
        inputValue={props.inputValue}
        onInputChange={props.onInputValueChange}
        open={
          props.openDelayedAfterTyping == undefined ||
          props.inputValue.length >= props.openDelayedAfterTyping
            ? open
            : false
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={
          props.getOptionLabel
            ? props.getOptionLabel
            : (opt: SelectableValue) => `${opt.id} - ${opt.text}`
        }
        options={props.options}
        loading={props.loading || !!props.loadingError}
        loadingText={
          props.loadingError ? props.loadingError : t('globalSelection.dropdown.loading', {})
        }
        renderInput={(params) => (
          <TextField
            {...params}
            size={'small'}
            label={props.autocompleteLabel}
            {...props.errorHelperTextProps}
            FormHelperTextProps={{ component: 'div' }}
          />
        )}
        renderOption={(renderProps, option) => {
          return (
            <li {...renderProps} key={option.id}>
              {`${option.id} - ${option.text}`}
            </li>
          );
        }}
        filterOptions={(options, state) =>
          options.filter(
            (opt) =>
              opt.id.toLowerCase().includes(state.inputValue.trim().toLowerCase()) ||
              opt.text.toLowerCase().includes(state.inputValue.trim().toLowerCase()),
          )
        }
        {...props.muiProps}
      />
    </FormControl>
  );
};
