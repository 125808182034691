import * as React from 'react';
import styled from 'styled-components';

interface ModalBodyProps {
  children: React.ReactNode;
}

const Body = styled.div`
  padding: 8px 24px 24px;
`;

export const ModalBody = ({ children }: ModalBodyProps) => {
  return <Body>{children}</Body>;
};
