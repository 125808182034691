import { Check, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

import { ActionButton } from '../../../components/ActionButton';
import { t } from '../../../core/i18n/i18n';

export type ForecastEditingActionButtonProps = {
  isDisabledForecastEditing: boolean;
  onDeleteUnsavedForecast: () => void;
  onSaveForecast: (dryrun: boolean) => () => void;
};

export default function ForecastEditingActionButtons({
  isDisabledForecastEditing,
  onDeleteUnsavedForecast,
  onSaveForecast,
}: ForecastEditingActionButtonProps) {
  return (
    <>
      <ActionButton
        color={'secondary'}
        onClick={onDeleteUnsavedForecast}
        disabled={isDisabledForecastEditing}
      >
        <Close />
      </ActionButton>
      <ActionButton
        color={'secondary'}
        onClick={onSaveForecast(true)}
        disabled={isDisabledForecastEditing}
      >
        <Check />
      </ActionButton>
      <Button
        size={'large'}
        variant={'contained'}
        onClick={onSaveForecast(false)}
        disabled={isDisabledForecastEditing}
      >
        {t('button.save', {})}
      </Button>
    </>
  );
}
