import { AddCircleOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';

import { t } from '../../../core/i18n/i18n';
import { requestFromApi } from '../../../core/requests/httpClient';
import { FormActions } from '../../../pages/demandValidation/gridUploadModal/ModalFormLayouts';
import { LoadingSpinner } from '../../loadingSpinner/LoadingSpinner';
import Modal from '../../modal/Modal';

export const CustomerMaterialNumberCellRenderer = (params: any) => {
  const [selectedCustomberNumber, setSelectedCustomerNumber] = useState('');
  const [selectedMaterialNumber, setSelectedMaterialNumber] = useState('');
  const [materialCustomerNumbersModalOpen, setMaterialCustomerNumbersModalOpen] = useState(false);

  const handleOpenMaterialCustomerNumbersModal = () => setMaterialCustomerNumbersModalOpen(true);
  const handleCloseMaterialCustomerNumbersModal = () => setMaterialCustomerNumbersModalOpen(false);

  const { data } = useSWR<string[]>(
    selectedCustomberNumber && selectedMaterialNumber
      ? `material-customer/customer-material-numbers?customerNumber=${selectedCustomberNumber}&materialNumber=${selectedMaterialNumber}`
      : undefined,
    requestFromApi,
  );

  if (params.data[params.customerMaterialNumberField || 'customerMaterialNumber'] !== '') {
    if (params.data[params.matCountNumberField || 'customerMaterialNumberCount'] <= 1) {
      return params.data[params.customerMaterialNumberField || 'customerMaterialNumber'];
    }

    return (
      <>
        <CellWrapper
          onClick={() => {
            setSelectedCustomerNumber(params.data.customerNumber);
            setSelectedMaterialNumber(params.data[params.materialNumberField || 'materialNumber']);
            handleOpenMaterialCustomerNumbersModal();
          }}
        >
          {params.data[params.customerMaterialNumberField || 'customerMaterialNumber']}
          <AddCircleOutlined color={'secondary'} fontSize={'small'} />
        </CellWrapper>
        <Modal
          open={materialCustomerNumbersModalOpen}
          onClose={handleCloseMaterialCustomerNumbersModal}
          maxWidth={'xs'}
          fullWidth={true}
        >
          <Modal.Headline
            onClose={handleCloseMaterialCustomerNumbersModal}
            text={t('material_customer.column.customerMaterialNumber_plural', {})}
          />
          <Modal.SubHeadline />
          <Modal.Body>
            <MaterialNumbersWrapper>
              {!data ? (
                <LoadingSpinner />
              ) : (
                data.map((customerMaterialNumber) => {
                  return (
                    <CustomerMaterialNumberList key={customerMaterialNumber}>
                      <Typography variant={'subtitle2'}>{customerMaterialNumber}</Typography>
                    </CustomerMaterialNumberList>
                  );
                })
              )}
            </MaterialNumbersWrapper>

            <FormActions justify={'space-between'}>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => navigator.clipboard.writeText((data as string[]).join('\n'))}
              >
                {t('copy_to_clipboard', {})}
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleCloseMaterialCustomerNumbersModal}
              >
                {t('button.close', {})}
              </Button>
            </FormActions>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  return '';
};

const MaterialNumbersWrapper = styled.div`
  min-height: 60px;
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 16px;
`;

const CustomerMaterialNumberList = styled.div`
  padding: 3px 0;
`;
