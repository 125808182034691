import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';

export type CustomerEntry = {
  salesOrg?: string;
  customerNumber: string;
  customerName?: string;
};

export const GLOBAL_SELECTION_CRITERIA_FIELD_NAMES = [
  'region',
  'salesArea',
  'sectorManagement',
  'salesOrg',
  'gkamNumber',
  'customerNumber',
  'materialNumber',
  'materialClassification',
  'sector',
  'productionSegment',
  'alertType',
  'productionPlant',
] as const;

export type GlobalSelectionCriteriaType = (typeof GLOBAL_SELECTION_CRITERIA_FIELD_NAMES)[number];

export type GlobalSelectionCriteriaFields = Record<
  GlobalSelectionCriteriaType,
  Array<SelectableValue>
>;

export const emptyGlobalSelectionCriteriaFields: GlobalSelectionCriteriaFields = {
  region: [],
  salesArea: [],
  sectorManagement: [],
  salesOrg: [],
  gkamNumber: [],
  customerNumber: [],
  materialNumber: [],
  materialClassification: [],
  sector: [],
  productionSegment: [],
  alertType: [],
  productionPlant: [],
};

export function isGlobalSelectionCriteriaEmpty(
  criteria: GlobalSelectionCriteriaFields | undefined,
) {
  return !criteria || Object.values(criteria).every((v) => v.length === 0);
}

export function isGlobalSelectionCriteria(criteria: string) {
  return GLOBAL_SELECTION_CRITERIA_FIELD_NAMES.includes(criteria as GlobalSelectionCriteriaType);
}

export type GlobalSelectionCriteriaFilters = Partial<
  Record<GlobalSelectionCriteriaType, Array<string>>
>;

export function globalSelectionCriteriaToFilter(
  criteria: GlobalSelectionCriteriaFields | undefined,
): GlobalSelectionCriteriaFilters | undefined {
  if (!criteria) return undefined;

  let filter: GlobalSelectionCriteriaFilters = {};
  Object.entries(criteria).forEach(([key, filters]: [string, SelectableValue[]]) => {
    if (filters.length > 0) {
      filter = { ...filter, [key]: filters.map((v) => v.id) };
    }
  });
  return filter;
}
