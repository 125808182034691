import { SilentRequest } from '@azure/msal-browser';

const clientId: string | undefined = process.env.AZURE_ACTIVEDIRECTORY_OBOFLOW_CLIENT_ID;

if (!clientId) {
  throw new Error(
    'Auth config: AZURE_ACTIVEDIRECTORY_OBOFLOW_CLIENT_ID env variable needs to be set',
  );
}

const scope = process.env.AZURE_ACTIVEDIRECTORY_OBOFLOW_SCOPE;

if (!scope) {
  throw new Error('Auth config: AZURE_ACTIVEDIRECTORY_OBOFLOW_SCOPE env variable needs to be set');
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/67416604-6509-4014-9859-45e709f53d3f',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Config used to retreive the acces token for backend API access
export const apiTokenConfig: SilentRequest = {
  scopes: [scope],
};
