import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';
import { getErrorMessage, useRateLimitedErrorNotification } from '../errors';
import { t } from '../i18n/i18n';
import { RequestAbortedError } from '../requests/httpClient';

import { useDebounce } from './useDebounce';
import { OptionsLoadingResult } from './useSelectableOptions';

export type AutocompleteOptionsResult = {
  inputValue: string;
  onInputValueChange: (event: SyntheticEvent | null, inputValue: string) => void;
} & OptionsLoadingResult;

export default function useSelectableOptionsOnType(
  getOptions: (searchTerm: string, signal?: AbortSignal) => Promise<SelectableValue[]>,
): AutocompleteOptionsResult {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<SelectableValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);

  const showErrorMessage = useRateLimitedErrorNotification();
  const abortControllerRef = useRef<AbortController | undefined>(undefined);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  function onInputValueChange(event: SyntheticEvent | null, inputValue: string) {
    setLoading(true);
    setInputValue(inputValue);

    if (event?.type == 'change') {
      setSearchTerm(inputValue.trim());
      abortControllerRef.current?.abort();
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm == undefined || debouncedSearchTerm.length <= 2) {
      return;
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    getOptions(debouncedSearchTerm, abortController.signal)
      .then((opts) => {
        setOptions(opts);
      })
      .catch((e) => {
        if (e instanceof RequestAbortedError) {
          // never mind, the request was aborted by us
        } else {
          showErrorMessage(getErrorMessage(e));
          setLoadingError(t('globalSelection.dropdown.loading_error', {}));
        }
      })
      .finally(() => {
        setLoading(false);
        setSearchTerm('');
      });
  }, [debouncedSearchTerm, getOptions, showErrorMessage]);

  return {
    inputValue: inputValue,
    onInputValueChange: onInputValueChange,
    options: options,
    loading: loading,
    loadingError: loadingError,
  };
}
