import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { addMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';

import Modal from '../../../components/modal/Modal';
import { DateRange } from '../../../core/dateRange';
import useDomId from '../../../core/hooks/useDomId';
import { t } from '../../../core/i18n/i18n';
import { firstEditableDate, lastEditableDate } from '../../../domain/demandValidation/limits';
import { KpiDateRanges } from '../../../domain/demandValidation/model';
import { MaterialType } from '../../../domain/demandValidation/saveValidatedDemand';
import { CustomerEntry } from '../../../domain/globalSelection/model';
import { DemandValidationDatePicker, fillGapBetweenRanges } from '../DemandValidationDatePicker';

import { FormActions, FormLayout } from './ModalFormLayouts';

type Props = {
  dateRange: KpiDateRanges;
  onDateRangeChange: (dateRange: KpiDateRanges) => void;
  selectedMaterialType: MaterialType;
  onSelectedMaterialTypeChanged: (materialType: MaterialType) => void;
  onCreate: () => void;
  customer: CustomerEntry;
  onClose: () => void;
  open: boolean;
};

export function GridUploadConfigurationModal(props: Props) {
  const materialTypeInputId = useDomId();

  const [dateRange1, setDateRange1] = useState<Partial<DateRange>>(props.dateRange.range1);
  const [dateRange2, setDateRange2] = useState<Partial<DateRange> | undefined>(
    props.dateRange.range2,
  );
  const [dateRangeError, setDateRangeError] = React.useState(false);

  useEffect(() => {
    setDateRange1(props.dateRange.range1);
    setDateRange2(
      props.dateRange.range2 || {
        from: addMonths(props.dateRange.range1.to, 1),
        period: 'MONTHLY',
      },
    );
  }, [props.dateRange]);

  return (
    <>
      <Modal open={props.open} onClose={props.onClose} maxWidth="md" fullWidth={true}>
        <Modal.Headline
          onClose={props.onClose}
          text={`${t('validation_of_demand.upload_modal.grid.title', {})} - ${
            props.customer.customerName
          }`}
        />
        <Modal.SubHeadline />

        <Modal.Body>
          <FormLayout direction={'column'}>
            <DemandValidationDatePicker
              dateRange1={dateRange1}
              onDateRange1Change={(value) => setDateRange1(value)}
              dateRange2={dateRange2}
              onDateRange2Change={(value) => setDateRange2(value)}
              onDateRangeErrorChange={setDateRangeError}
              minDate={firstEditableDate(dateRange1.period || 'MONTHLY')}
              maxDate={lastEditableDate()}
            />

            <div>
              <FormControl fullWidth size={'small'}>
                <FormLabel id={materialTypeInputId}>
                  {t('validation_of_demand.upload_modal.material_type.title', {})}
                </FormLabel>
                <RadioGroup
                  value={props.selectedMaterialType}
                  onChange={(e) => {
                    props.onSelectedMaterialTypeChanged(e.target.value as MaterialType);
                  }}
                  aria-labelledby={materialTypeInputId}
                  row={true}
                >
                  <FormControlLabel
                    value="schaeffler"
                    control={<Radio />}
                    label={t('validation_of_demand.upload_modal.material', {})}
                  />
                  <FormControlLabel
                    value="customer"
                    control={<Radio />}
                    label={t('validation_of_demand.upload_modal.customer_material', {})}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <FormActions justify={'flex-end'}>
              <Button
                variant={'contained'}
                color={'primary'}
                disabled={dateRangeError}
                onClick={() => {
                  const filledRange = fillGapBetweenRanges(dateRange1, dateRange2);
                  if (filledRange) {
                    props.onDateRangeChange(filledRange);
                    props.onCreate();
                  }
                }}
              >
                {t('button.create', {})}
              </Button>
            </FormActions>
          </FormLayout>
        </Modal.Body>
      </Modal>
    </>
  );
}
