import * as React from 'react';
import { Link } from 'react-router-dom';

export const PrivacyPolicyDE = () => {
  return (
    <>
      <h2>Datenschutzerklärung</h2>
      <p>
        Wir freuen uns über Ihr Interesse an der Schaeffler Gruppe (Schaeffler AG und verbundene
        Unternehmen) und an unseren Produkten. Bei der Nutzung unseres Onlineangebots ist der Schutz
        Ihrer Privatsphäre von großer Bedeutung. Wenn personenbezogene Daten verarbeitet werden,
        beachten wir die jeweils geltenden datenschutzrechtlichen Bestimmungen.
      </p>

      <h3>I. Allgemeines zur Datenverarbeitung</h3>

      <h4>1. Umfang und Zweck der Verarbeitung personenbezogener Daten</h4>
      <p>
        Wir erheben und verwenden Ihre personenbezogenen Daten grundsätzlich nur, soweit dies zur
        Bereitstellung einer funktionsfähigen Webseite sowie unserer auf der Webseite angebotenen
        Inhalte und Services erforderlich ist. Die Erhebung und Verwendung Ihrer personenbezogenen
        Daten erfolgt regelmäßig nur nach Ihrer Einwilligung. Eine Ausnahme gilt in solchen Fällen,
        in denen die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
      </p>

      <h4>2. Rechtsgrundlage für die Datenverarbeitung</h4>
      <p>
        Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage der EU-
        Datenschutzgrundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG).
      </p>
      <p>
        Soweit Sie uns eine Einwilligung zur Verarbeitung von personenbezogenen Daten für bestimmte
        Zwecke erteilt haben, dient Art. 6 Abs. 1 lit. a DSGVO als Rechtsgrundlage für die
        Verarbeitung personenbezogener Daten. Eine erteilte Einwilligung kann von Ihnen jederzeit
        widerrufen werden. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt.
        Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
      </p>
      <p>
        Die Verarbeitung personenbezogener Daten im Rahmen der Erfüllung von Verträgen, dessen
        Vertragspartei Sie sind, oder zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre
        Anfrage erfolgen, erfolgt auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Die Zwecke der
        Datenverarbeitung richten sich nach den jeweiligen Vertragsunterlagen und dem
        Vertragsgegenstand.
      </p>
      <p>
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
        Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c
        DSGVO als Rechtsgrundlage.
      </p>
      <p>
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses von Schaeffler oder eines
        Dritten erforderlich (z.B. zur Geltendmachung rechtlicher Ansprüche und Verteidigung bei
        rechtlichen Streitigkeiten; zur Gewährleistung der IT-Sicherheit; zur Verhinderung von
        Straftaten; für Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von Dienstleistungen
        und Produkten) und überwiegen die Interessen, Grundrechte und Grundfreiheiten von Ihnen als
        Betroffener das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
        Rechtsgrundlage für die Verarbeitung.
      </p>

      <h4>3. Datenlöschung und Speicherdauer</h4>
      <p>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten solange diese zur Erfüllung des
        jeweiligen Zwecks erforderlich sind. Eine Speicherung kann darüber hinaus dann erfolgen,
        wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen
        Verordnungen, Gesetzen oder sonstigen Vorschriften, denen wir als Verantwortlicher
        unterliegen, vorgesehen wurde. Sind die Daten nicht mehr erforderlich oder ist eine durch
        die genannten Normen vorgeschriebene Speicherfrist abgelaufen, werden Ihre Tracking-Daten
        regelmäßig nach 90 Tagen gelöscht. Des Weiteren werden Kundenforecasts in Demand360
        gepflegt. Bei neu erstellten oder geänderten Kundenforecasts werden diese zusammen mit dem
        Namenskürzel nach SAP weggeschrieben und nach spätestens 36 Monaten wieder gelöscht. Die
        Daten zur Nutzereinstellung können auf Anfrage gelöscht werden.
      </p>

      <h4>
        4. Zugriff auf personenbezogene Daten innerhalb des Schaeffler Konzerns und durch Dritte
      </h4>
      <p>
        Innerhalb der Schaeffler Gruppe erhalten diejenigen Stellen Zugriff auf Ihre Daten, die
        diese im Rahmen „Least Privilege“ (Zuweisung von Nutzerrechten im geringstmöglichen Umfang)
        und des „Need-to-Know“ Prinzips (Kenntnis von Daten nur wenn nötig) benötigen. An Dritte
        außerhalb der Schaeffler Gruppe dürfen wir Daten nur weitergeben, wenn dies erforderlich
        ist, eine gesetzliche Bestimmung dies gebietet, Sie eingewilligt haben oder von uns
        beauftragte Auftragsverarbeiter sich zur Einhaltung der Vorgaben der DSGVO und des BDSG
        vertraglich verpflichtet haben.
      </p>
      <p>
        Unter diesen Voraussetzungen können Empfänger personenbezogener Daten sein: Microsoft Azure,
        SAP API Management und SAP
      </p>

      <h4>
        5. Übermittlung personenbezogener Daten in ein Drittland oder an eine internationale
        Organisation
      </h4>
      <p>
        Eine Datenübermittlung in Länder außerhalb der EU/EWR (sogenannte Drittländer) findet nur
        statt, soweit dies erforderlich oder gesetzlich vorgeschrieben ist, Sie uns eine
        Einwilligung erteilt haben oder im Rahmen einer Auftragsverarbeitung. Werden Dienstleister
        in Drittländern eingesetzt, sind diese zusätzlich zu schriftlichen Weisungen durch die
        Vereinbarung der EU-Standardvertragsklauseln zur Einhaltung des Datenschutzniveaus in Europa
        verpflichtet.
      </p>

      <h4>6. IT-Sicherheit und Links auf Webseiten Dritter</h4>
      <p>
        Die Schaeffler Gruppe setzt technische und organisatorische Sicherheitsmaßnahmen ein, um
        Ihre durch uns verwalteten Daten gegen zufällige oder vorsätzliche Zerstörung, Manipulation,
        Verlust oder gegen den Zugriff unberechtigter Personen zu schützen. Diese
        Sicherungsmaßnahmen werden entsprechend der jeweiligen neuen technischen Möglichkeiten
        ständig weiterentwickelt.
      </p>
      <p>
        Unsere Webseiten können Links zu Webseiten anderer Anbieter enthalten. Unsere Hinweise zum
        Datenschutz gelten nicht für diese Webseiten.
      </p>

      <h4>7. Pflicht zur Bereitstellung von personenbezogenen Daten</h4>
      <p>
        Sofern wir Ihnen auf dieser Webseite Angebote und Services zur Verfügung stellen, die Sie
        freiwillig in Anspruch nehmen können, besteht keine Pflicht zur Bereitstellung Ihrer Daten
        an uns, ohne Ihre personenbezogenen Daten können Sie aber ggfs. unsere Angebote und Services
        nicht nutzen.
      </p>

      <h4>8. „Profiling“ und automatisierte Entscheidungsfindung</h4>
      <p>
        Wir nutzen keine vollautomatisierte Entscheidungsfindung gemäß Art. 22 DSGVO. Schaeffler
        nutzt grundsätzlich kein „Profiling“. Sollten wir dies in Einzelfällen einsetzen, werden wir
        Sie hierüber gesondert informieren, sofern dies gesetzlich vorgegeben ist, und ggfs. Ihre
        vorherige Einwilligung einholen.
      </p>

      <h4>9. Quellen Ihrer personenbezogenen Daten</h4>
      <p>Wir nutzen Daten, die wir von Ihnen selbst erhalten.</p>

      <h3>
        II. Datenverarbeitungsvorgänge bei der Bereitstellung der Webseite und bei der Erstellung
        von Logfiles
      </h3>
      <p>
        Wenn Sie unsere Webseite besuchen, erfassen und erheben unsere Webserver standardmäßig den
        Namen Ihres Internet Service Providers, Ihre IP-Adresse, die Webseite von der aus Sie uns
        besuchen, die Webseiten, die Sie bei uns besuchen sowie das Datum und die Dauer des
        Besuches. Diese Daten werden in den Logfiles unserer Systeme gespeichert. Die Nutzung der
        IP-Adresse beschränkt sich jedoch auf den technisch notwendigen Umfang und wird gekürzt und
        hierdurch nur anonymisiert verwendet, so dass eine Zuordnung der IP-Adresse zu einem Nutzer
        nicht ermöglicht wird. Die Daten werden nicht mit personenbezogenen Daten verbunden.
      </p>
      <p>
        Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 lit. f DSGVO.
      </p>
      <p>
        Die vorübergehende Speicherung der gekürzten IP-Adresse durch unsere Systeme ist technisch
        notwendig, um eine Auslieferung der Webseite an Ihr Endgerät zu ermöglichen. Die Speicherung
        in Logfiles erfolgt, um die Funktionsfähigkeit der Webseite sicherzustellen. Eine Auswertung
        der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt. In diesen Zwecken
        liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f
        DSGVO.
      </p>
      <p>
        Die Erfassung der Daten zur Bereitstellung der Webseite und die Speicherung der Daten in
        Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es besteht folglich
        für Sie als Nutzer keine Widerspruchsmöglichkeit.
      </p>

      <h3>III. Datenverarbeitung bei auf der Webseite angebotenen Services</h3>
      <p>
        Auf unserer Webseite werden verschiedene Services angeboten, für deren Nutzung
        personenbezogene Daten von Ihnen abgefragt werden. Die Übermittlung von personenbezogenen
        Daten durch Sie an uns ist dabei stets freiwillig. In diesem Zusammenhang verarbeiten wir
        Ihre personenbezogenen Daten ausschließlich auf anonymer Basis, um beispielsweise folgende
        Auswertungen zu ermöglichen:
      </p>
      <ul>
        <li>
          Auswertungen zu Anforderungsraten, Antwortzeiten und Fehlerraten der Website um zu
          erfahren, welche Seiten zu welchen Tageszeiten am häufigsten verwendet werden und wo die
          User sich befinden.
        </li>
        <li>Auswertungen zu Seitenansichten und Ladeleistung der Website</li>
        <li>Auswertungen zu Anzahl von Benutzern und Sitzungen</li>
        <li>Auswertungen von benutzerdefinierten Ereignissen und Metriken</li>
      </ul>
      <p>
        Außerdem verarbeiten wir Ihre personenbezogenen Daten im Zusammenhang mit der
        Nutzereinstellung. Zur Verbesserung der Benutzer-Erfahrung werden dabei
        anwendungsspezifische Einstellungen wie zum Beispiel die favorisierte Tabellen-Einstellung
        zur Anzeige von Daten gespeichert.
      </p>
      <p>
        Des Weiteren werden zur Unterstützung des S&OP Prozesses von Ihnen geänderte oder erstellte
        Datensätze in SAP gespeichert.
      </p>

      <h3>IV. Verwendung von Cookies</h3>
      <p>
        Damit Sie die Webseite bestmöglich nutzen können, setzen wir Cookies ein. Bei Cookies
        handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
        Endgerät des Nutzers gespeichert werden. Ruft ein Nutzer unsere Webseite auf, so kann ein
        Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
        charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers bei
        erneuten Aufrufen der Webseite ermöglicht.
      </p>
      <p>
        Nachfolgend informieren wir Sie allgemein über den Einsatz von Cookies auf unserer Webseite
        und die damit verbundene Verarbeitung personenbezogener Daten. Weitere Informationen zu
        konkret verwendeten Cookies entnehmen Sie bitte der auf dieser Webseite veröffentlichten
        <Link to={'/cookiePolicy'}>Schaeffler Cookie- Richtlinie</Link>.
      </p>
      <p>
        Wir setzen Cookies ein, um unsere Webseite nutzerfreundlicher zu gestalten. Einige Elemente
        unserer Webseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel
        identifiziert werden kann.
      </p>
      <p>
        Darüber hinaus verwenden wir auf unserer Webseite Cookies, die eine Analyse des
        Surfverhaltens des Nutzers ermöglichen.
      </p>
      <p>
        Beim Aufruf unserer Webseite wird der Nutzer über die Verwendung von Cookies informiert und
        auf diese Datenschutzerklärung hingewiesen.
      </p>
      <p>
        Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung unserer Webseite für
        den Nutzer zu vereinfachen. Einige Funktionen unserer Webseite können ohne den Einsatz von
        Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach
        einem Seitenwechsel wiedererkannt wird. Die durch technisch notwendige Cookies erhobenen
        Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet. Weitere Informationen
        entnehmen Sie bitte der Schaeffler Cookie-Richtlinie. Die Verwendung von Analyse-Cookies
        erfolgt zu dem Zweck, die Qualität unserer Webseite und ihre Inhalte zu verbessern. Durch
        die Analyse-Cookies erfahren wir, wie die Webseite genutzt wird und können so unser Angebot
        stetig optimieren. Weitere Informationen entnehmen Sie bitte der Schaeffler
        Cookie-Richtlinie.
      </p>
      <p>
        Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von
        Cookies ist Art. 6 Abs.1 lit. f DSGVO.
      </p>
      <p>
        Cookies werden auf dem Endgerät des Nutzers gespeichert und von diesem an unsere Webseite
        übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von
        Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die
        Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können
        jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere
        Webseite deaktiviert, können möglicherweise nicht mehr alle Funktionen der Webseite
        vollumfänglich genutzt werden.
      </p>

      <h3>V. Ihre Rechte als betroffene Person</h3>
      <p>
        Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und
        es stehen Ihnen die folgenden Rechte gegenüber uns als Verantwortlichen zu:
      </p>

      <h4>1. Auskunftsrecht (Art. 15 DSGVO)</h4>
      <p>
        Sie können von uns eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie
        betreffen, von uns verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von
        uns über die gesetzlich vorgesehenen Informationen (siehe Art. 15 Abs. 1 DSGVO) Auskunft
        verlangen sowie über geeignete Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
        Übermittlung unterrichtet zu werden, wenn die Sie betreffenden personenbezogenen Daten in
        ein Drittland oder an eine internationale Organisation übermittelt werden. Es bestehen die
        Einschränkungen nach §§ 34 und 35 BDSG.
      </p>

      <h4>2. Recht auf Berichtigung (Art. 16 DSGVO)</h4>
      <p>
        Sie haben gegenüber uns ein Recht auf Berichtigung und/oder Vervollständigung, sofern die
        verarbeiteten personenbezogenen Daten unrichtig oder unvollständig sind. Wir haben die
        Berichtigung unverzüglich vorzunehmen.
      </p>

      <h4>3. Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</h4>
      <p>
        Unter den gesetzlichen Voraussetzungen (siehe Art. 18 Abs. 1 DSGVO) können Sie die
        Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen. Über
        Folgen der Einschränkung siehe bitte Art. 18 Abs. 2 und 3 DSGVO.
      </p>

      <h4>4. Recht auf Löschung (Art. 17 DSGVO)</h4>
      <p>
        Sie können von uns verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich
        gelöscht werden, und wir sind verpflichtet, diese Daten unverzüglich zu löschen, wenn einer
        der Gründe gemäß Art. 17 Abs. 1 DSGVO zutrifft. Ein Recht auf Löschung besteht nicht in den
        Fällen des Art. 17 Abs. 3 DSGVO. Weiter bestehen die Einschränkungen nach §§ 34 und 35 BDSG.
      </p>

      <h4>5. Recht auf Unterrichtung</h4>
      <p>
        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber
        uns geltend gemacht, sind wir verpflichtet, allen Empfängern, denen Ihre personenbezogenen
        Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der
        Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem
        unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber uns das Recht zu, über diese
        Empfänger unterrichtet zu werden.
      </p>

      <h4>6. Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</h4>
      <p>
        Sie haben das Recht, die Sie betreffenden personenbezogen Daten, die Sie uns bereitgestellt
        haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Wir
        verweisen für Einzelheiten auf Art. 20 DSGVO.
      </p>

      <h4>7. Widerspruchsrecht (Art. 21 DSGVO)</h4>
      <p>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
        gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
        Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen. Weitere Einzelheiten ergeben
        sich aus Art. 21 DSGVO.
      </p>
      <p>
        Darüber hinaus besitzen Sie ein Beschwerderecht bei den Datenschutzaufsichtsbehörden nach
        Art. 77 DSGVO i.V.m. § 19 BDSG.
      </p>

      <h3>VI. Name und Anschrift des Verantwortlichen</h3>
      <p>
        Schaeffler Technologies AG & Co. KG Industriestraße 1-3 <br />
        91074 Herzogenaurach <br />
        Deutschland <br />
        Telefon: +49 9132 82-0 <br />
        Telefax: +49 9132 82-49 50 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>

      <h3>VII. Anschrift des Datenschutzbeauftragten</h3>
      <p>
        Schaeffler AG Datenschutzbeauftragter Industriestraße 1-3 91074 Herzogenaurach Deutschland
        <br />
        Telefon: +49 9132 82-1476 <br />
        Fax: +49 9132 82-5901 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>
      <small>Stand: Version 1.0, 16.03.2022</small>
    </>
  );
};
