import { ICellRendererParams } from '@ag-grid-community/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu as MuiMenu, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import styled from 'styled-components';

import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../core/errorhandling';
import { t } from '../../core/i18n/i18n';
import { saveMultiAlertRules } from '../../domain/alertrules/alertRuleActions';
import { AlertRule } from '../../domain/alertrules/model';

type AdditionalProps = {
  onPerformAction?: () => void;
  onEditAction?: (alertRule: AlertRule) => void;
  onDeleteAction: (alertRule: AlertRule) => void;
};

function AlertRuleTableRowMenuButton(props: ICellRendererParams & AdditionalProps) {
  const id = props.data.id;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activate = async () => {
    if (!id) return;

    const workflow = [{ ...props.data, deactivated: false }] as AlertRule[];
    const postResult = await saveMultiAlertRules(workflow);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('alert_rules.action_menu_activated', {}),
    );

    enqueueSnackbar(userMessage.message, { variant: userMessage.variant });

    if (userMessage.variant !== 'error') {
      props.onPerformAction && props.onPerformAction();
      handleClose();
    }
  };

  const deactivate = async () => {
    if (!id) return;

    const workflow = [{ ...props.data, deactivated: true }] as AlertRule[];
    const postResult = await saveMultiAlertRules(workflow);
    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('alert_rules.action_menu_deactivated', {}),
    );

    enqueueSnackbar(userMessage.message, { variant: userMessage.variant });

    if (userMessage.variant !== 'error') {
      props.onPerformAction && props.onPerformAction();
      handleClose();
    }
  };

  const edit = () => {
    props.onEditAction && props.onEditAction(props.data);
  };

  const menuId = `context-menu-${id}`;
  const btnId = `trigger-menu-${id}`;

  return (
    <>
      <MoreVert
        onClick={handleOpen}
        aria-controls={open ? menuId : undefined}
        aria-haspopup={true}
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVertIcon />
      </MoreVert>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': btnId,
        }}
      >
        <MenuItem onClick={edit}>{t('button.edit', {})}</MenuItem>
        {props.data.deactivated && (
          <MenuItem onClick={activate}>{t('alert_rules.action_menu.activate', {})}</MenuItem>
        )}
        {!props.data.deactivated && (
          <MenuItem onClick={deactivate}>{t('alert_rules.action_menu.deactivate', {})}</MenuItem>
        )}
        <MenuItem onClick={() => props.onDeleteAction(props.data)}>
          {t('alert_rules.action_menu.delete', {})}
        </MenuItem>
      </Menu>
    </>
  );
}

export default AlertRuleTableRowMenuButton;

const Menu = styled(MuiMenu)`
  > * {
    min-width: 160px;
  }
`;

const MoreVert = styled(IconButton)`
  color: #00893d;
`;
