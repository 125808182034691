import { requestFromApi } from '../../core/requests/httpClient';

import { DeleteKpiDataRequest, DeleteKpiDataResponse } from './model';

export async function deleteValidatedDemandBatch(
  data: DeleteKpiDataRequest,
  dryRun: boolean,
): Promise<DeleteKpiDataResponse> {
  // In order for a multipart to work, the browser needs to automatically deduce the
  // content type and set the correct header with a boundary, hence we need to turn the autoHeader off.
  const resData = (await requestFromApi(`demand-validation?dryRun=${dryRun}`, {
    method: 'DELETE',
    body: JSON.stringify(data),
  })) as DeleteKpiDataResponse;

  return resData;
}
