import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  IRowNode,
  ITooltipParams,
  RowNode,
} from '@ag-grid-community/core';

import { errorColorLight } from '../../styles/colors';

import { GridTooltip } from './GridTooltip';

const cellStyleInvalid = {
  backgroundColor: errorColorLight,
};

export function buildValidationProps(
  valFn: (value: string, rowData: IRowNode, columnId?: string) => string | null | undefined,
  validateEmptyValues = false,
  defaultColor: string | null = null,
): Partial<ColDef> {
  const validate = (params: ITooltipParams | CellClassParams) => {
    if (!params.node) return undefined;
    if (!params.value && !validateEmptyValues) return undefined;
    const colId = getColumIdFromColumnDef(params.colDef);

    return valFn(params.value || '', params.node, colId);
  };
  const defaultStyle = defaultColor ? { backgroundColor: defaultColor } : undefined;
  return {
    tooltipComponent: GridTooltip,
    tooltipValueGetter: validate,
    cellStyle: (params: CellClassParams) => (validate(params) ? cellStyleInvalid : defaultStyle),
  };
}

export function rowIsEmpty(row: RowNode) {
  return Object.values(row.data).every((value) => !value);
}

function getColumIdFromColumnDef(
  colDef: ColDef<any> | ColGroupDef<any> | null | undefined,
): string | undefined {
  const colIdFromColDef =
    colDef && Object.hasOwn(colDef, 'field') ? (colDef as ColDef<any>).field : undefined;
  const colIdFromGroup =
    colDef && Object.hasOwn(colDef, 'groupId') ? (colDef as ColGroupDef<any>).groupId : undefined;
  return colIdFromColDef ?? colIdFromGroup;
}
