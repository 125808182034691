import useSWR, { Fetcher, SWRConfiguration } from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';

export const useAlertHash = (
  config?: SWRConfiguration<any, Error, Fetcher<any, `alerts/hash`>>,
) => {
  const { data, mutate, error } = useSWR(`alerts/hash?status=${status}`, requestFromApi, config);

  return {
    alertHash: data as string,
    refresh: mutate,
    error,
  };
};
