import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';

import useDomId from '../../../core/hooks/useDomId';
import { t } from '../../../core/i18n/i18n';
import { formatNumber } from '../../../core/i18n/l10n';

export const LAYOUT_IDS = ['1', '2'] as const;
export type LayoutId = (typeof LAYOUT_IDS)[number];

export default function HomeTableToolbar({
  onToggleFilter = () => undefined,
  defaultSelectedLayout,
  onLoadLayout,
  onSaveLayout,
  onResetLayout,
  onExcelExport,
  exportButtonDisabled,
  rowCount,
}: {
  onToggleFilter?: () => void;
  rowCount?: number | null;
  onExcelExport: () => void | Promise<void>;
  exportButtonDisabled?: boolean;
} & LayoutPopoverProps) {
  return (
    <ToolbarContainer>
      <Button color="secondary" startIcon={<FilterListIcon />} onClick={onToggleFilter}>
        {t('material_customer.toolbar.toggle_filters', {})}
      </Button>
      <Box>
        {rowCount != null && (
          <>
            <Typography
              component="span"
              mr="14px"
              ml="8px"
              color="secondary"
              aria-hidden
              variant="body2"
            >
              |
            </Typography>
            <Typography component="span" color="secondary" variant="body2">
              {t('table.toolbar.material_count', { count: formatNumber(rowCount) })}
            </Typography>
          </>
        )}
      </Box>
      <Box ml="auto">
        <Button
          disabled={exportButtonDisabled}
          onClick={onExcelExport}
          color="secondary"
          startIcon={<RotatedUpgradeIcon />}
        >
          {t('button.export', {})}
        </Button>
        <LayoutPopover
          defaultSelectedLayout={defaultSelectedLayout}
          onLoadLayout={onLoadLayout}
          onSaveLayout={onSaveLayout}
          onResetLayout={onResetLayout}
        />
      </Box>
    </ToolbarContainer>
  );
}

type LayoutPopoverProps = {
  defaultSelectedLayout: LayoutId;
  onSaveLayout: (layout: LayoutId) => void | Promise<void>;
  onLoadLayout: (layout: LayoutId) => void | Promise<void>;
  onResetLayout: (layout: LayoutId) => void | Promise<void>;
};

function LayoutPopover({
  defaultSelectedLayout,
  onSaveLayout,
  onLoadLayout,
  onResetLayout,
}: LayoutPopoverProps) {
  const menuId = useDomId();
  const menuButtonId = `${menuId}-button`;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [selectedLayout, setSelectedLayout] = useState(defaultSelectedLayout);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label={t('material_customer.toolbar.menu_label', {})}
        id={menuButtonId}
        onClick={handleClick}
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <Box padding="16px 28px" minWidth="280px">
          <StyledTypography variant="h6">
            {t('material_customer.toolbar.layout_title', {})}
          </StyledTypography>
          <FormControl fullWidth>
            <Select
              value={selectedLayout}
              size="small"
              onChange={(e) => setSelectedLayout(e.target.value as LayoutId)}
            >
              {LAYOUT_IDS.map((layout) => (
                <MenuItem key={layout} value={layout}>
                  {t(`material_customer.toolbar.layout_${layout}`, {})}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <StyledDivider />

          <Box textAlign="right" marginX="4px">
            <StyledButton
              onClick={async () => {
                await onLoadLayout(selectedLayout);
                handleClose();
              }}
            >
              {t('material_customer.toolbar.load_layout', {})}
            </StyledButton>
            <br />
            <StyledButton
              onClick={async () => {
                await onSaveLayout(selectedLayout);
                handleClose();
              }}
            >
              {t('material_customer.toolbar.overwrite_layout', {})}
            </StyledButton>
            <br />
            <StyledButton
              color="secondary"
              onClick={async () => {
                await onResetLayout(selectedLayout);
                handleClose();
              }}
            >
              {t('material_customer.toolbar.reset_layout', {})}
            </StyledButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export const ToolbarContainer = styled.aside<{ $position?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $position }) => $position};

  width: 100%;
  height: 48px;

  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
  margin-right: -8px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 32px;
  margin-bottom: 4px;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 24px;
  opacity: 0.6;
`;

const RotatedUpgradeIcon = styled(UpgradeIcon)`
  transform: rotate(0.25turn);
`;
