import { GlobalSelectionCriteriaFields } from '../../domain/globalSelection/model';

const GLOBAL_SELECTION_STORAGE_KEY = 'GlobalSelection';

export function getGlobalSelectionFallback(): GlobalSelectionCriteriaFields | undefined {
  const selectionFromSessStore = sessionStorage.getItem(GLOBAL_SELECTION_STORAGE_KEY);
  if (selectionFromSessStore) {
    return JSON.parse(selectionFromSessStore) as GlobalSelectionCriteriaFields;
  }
}

export function setGlobalSelectionFallback(
  newSelection: GlobalSelectionCriteriaFields | undefined,
) {
  sessionStorage.setItem(GLOBAL_SELECTION_STORAGE_KEY, JSON.stringify(newSelection));
}

export function removeGlobalSelectionFallback() {
  sessionStorage.removeItem(GLOBAL_SELECTION_STORAGE_KEY);
}
