import React, { useMemo, useState } from 'react';

import { OptionsLoadingResult } from '../../../core/hooks/useSelectableOptions';
import { AutocompleteLabelProps } from '../baseComponents/MultiAutocompleteBase';
import {
  SingleAutocompleteValueBaseProps,
  SingleAutocompleteBase,
} from '../baseComponents/SingleAutocompleteBase';
import { SelectableValue, matchOptionIfPossible } from '../baseComponents/selectableValues';

type SingleAutocompletePreLoadedProps = {
  optionsLoadingResult: OptionsLoadingResult;
} & SingleAutocompleteValueBaseProps &
  AutocompleteLabelProps;

export default function SingleAutocompletePreLoaded(props: SingleAutocompletePreLoadedProps) {
  const [inputValue, setInputValue] = useState('');

  const { value, optionsLoadingResult } = props;

  const processedValue: SelectableValue | null = useMemo(
    () => matchOptionIfPossible(value, optionsLoadingResult.options),
    [value, optionsLoadingResult.options],
  );

  function onInputValueChange(_: unknown, inputValue: string) {
    setInputValue(inputValue);
  }

  return (
    <SingleAutocompleteBase
      autocompleteLabel={props.autocompleteLabel}
      value={processedValue}
      onValueChange={props.onValueChange}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      options={props.optionsLoadingResult.options}
      loading={props.optionsLoadingResult.loading}
      loadingError={props.optionsLoadingResult.loadingError}
      getOptionLabel={props.getOptionLabel}
      getOptionLabelInTag={props.getOptionLabelInTag}
      errorHelperTextProps={props.errorHelperTextProps}
      muiProps={{ forcePopupIcon: true, ...props.muiProps }}
    />
  );
}
