import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { GridApis } from '../../agGrid/gridTypes';
import { ActionButton } from '../../components/ActionButton';
import HeaderActionBar from '../../components/HeaderActionBar';
import { StyledSection } from '../../components/StyledSection';
import FilterDropdown from '../../components/inputs/FilterDropdown';
import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';
import { Page } from '../../components/page/Page';
import { useSelectableOptions } from '../../core/hooks/useSelectableOptions';
import { t } from '../../core/i18n/i18n';
import { IMRSubstitution } from '../../domain/internalMaterialReplacement/model';

import DeleteSubstitutionDialog from './modals/DeleteSubstitutionDialog';
import MultiSubstitutionModal from './modals/MultiSubstitutionModal';
import SingleSubstitutionModal from './modals/SingleSubstitutionModal';
import InternalMaterialReplacementTable from './table/InternalMaterialReplacementTable';

export enum IMRModal {
  NEW_SINGLE_SI = 'NEW_SINGLE_SI',
  EDIT_SINGLE_SI = 'EDIT_SINGLE_SI',
  NEW_MULTI_SI = 'NEW_MULTI_SI',
  DELETE_SINGLE_SI = 'DELETE_SINGLE_SI',
}

export default function InternalMaterialReplacementPage() {
  const regionOptions = useSelectableOptions(`global-selection/regions`);
  const [selectedRegion, setSelectedRegion] = useState<SelectableValue | undefined>(undefined);
  const [openModal, setOpenModal] = useState<IMRModal | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<IMRSubstitution | null>(null);
  // Grid lives here to manage updates
  const [grid, setGrid] = useState<GridApis | undefined>();

  useEffect(() => {
    if (!regionOptions.loading && !regionOptions.loadingError && !selectedRegion) {
      setSelectedRegion(regionOptions.options[0]);
    }
  }, [regionOptions, selectedRegion, setSelectedRegion]);

  const changeRegion = (_: unknown, value: SelectableValue) => setSelectedRegion(value);

  const openIMRModal = (modal: IMRModal, selectedEntry: IMRSubstitution) => {
    setSelectedEntry(selectedEntry);
    setOpenModal(modal);
  };

  const closeModalAndUpdateTable = () => {
    setOpenModal(null);
    if (grid) {
      grid.api.refreshServerSide();
    }
  };

  return (
    <Page title={t('internal_material_replacement.title', {})}>
      <StyledSection last={true} grow={true} style={{ position: 'relative' }}>
        <Typography variant="h6" component={'h2'}>
          {t('internal_material_replacement.title', {})}
        </Typography>

        <HeaderActionBar
          actionButtons={
            <>
              <ActionButton color="primary" onClick={() => setOpenModal(IMRModal.NEW_SINGLE_SI)}>
                <AddIcon />
              </ActionButton>
              <ActionButton color="secondary" onClick={() => setOpenModal(IMRModal.NEW_MULTI_SI)}>
                <MenuIcon />
              </ActionButton>
            </>
          }
        >
          <Wrapper>
            <FilterDropdown
              value={selectedRegion}
              label={t('globalSelection.region', {})}
              onChange={changeRegion}
              {...regionOptions}
            />
          </Wrapper>
        </HeaderActionBar>
        {selectedRegion && (
          <InternalMaterialReplacementTable
            selectedRegion={selectedRegion}
            openIMRModal={openIMRModal}
            grid={grid}
            setGrid={setGrid}
          />
        )}
      </StyledSection>

      <MultiSubstitutionModal
        open={openModal == IMRModal.NEW_MULTI_SI}
        onClose={() => setOpenModal(null)}
        onAdded={closeModalAndUpdateTable}
      />

      {selectedRegion && (
        // New substitution
        <SingleSubstitutionModal
          open={openModal == IMRModal.NEW_SINGLE_SI}
          isNewSubstitution={true}
          onClose={() => setOpenModal(null)}
          onSuccess={closeModalAndUpdateTable}
          substitution={newIMRSubstitution(selectedRegion?.id)}
        />
      )}
      {selectedRegion && selectedEntry && (
        // Edit substitution
        <SingleSubstitutionModal
          open={openModal == IMRModal.EDIT_SINGLE_SI}
          isNewSubstitution={false}
          onClose={() => setOpenModal(null)}
          onSuccess={closeModalAndUpdateTable}
          substitution={selectedEntry}
        />
      )}
      {selectedRegion && selectedEntry && (
        <DeleteSubstitutionDialog
          open={openModal == IMRModal.DELETE_SINGLE_SI}
          seletedEntry={selectedEntry}
          onClose={closeModalAndUpdateTable}
        />
      )}
    </Page>
  );
}

export function newIMRSubstitution(region: string): IMRSubstitution {
  return {
    region: region,
    replacementType: null,
    salesArea: null,
    salesOrg: null,
    customerNumber: null,
    predecessorMaterial: null,
    successorMaterial: null,
    replacementDate: null,
    cutoverDate: null,
    startOfProduction: null,
    note: null,
  };
}

const Wrapper = styled.div`
  width: 250px;
`;
