import { useCallback, useEffect, useState } from 'react';

import { useAvailableCurrenciesData } from './infoData';

const DEFAULT_CURRENCY = 'EUR';
export const LOCAL_STORAGE_CURRENCY = 'currency';

export type CurrencyData = {
  currentCurrency: string;
  availableCurrencies: string[];
  setCurrentCurrency: (currency: string) => void;
};

export default function useCurrency(): CurrencyData {
  const [currentCurrency, setCurrentCurrency] = useState<string>(
    localStorage.getItem(LOCAL_STORAGE_CURRENCY) || DEFAULT_CURRENCY,
  );

  const availableCurrencies = useAvailableCurrenciesData();

  useEffect(() => {
    const storedCurrency = localStorage.getItem(LOCAL_STORAGE_CURRENCY);
    if (storedCurrency && availableCurrencies.data) {
      if (availableCurrencies.data.includes(storedCurrency)) {
        setCurrentCurrency(storedCurrency);
      } else {
        setCurrentCurrency(DEFAULT_CURRENCY);
      }
    }
  }, [availableCurrencies.data]);

  const setCurrency = useCallback(
    (currency: string) => {
      if (availableCurrencies.data && availableCurrencies.data.includes(currency)) {
        setCurrentCurrency(currency);
        localStorage.setItem(LOCAL_STORAGE_CURRENCY, currency);
      } else {
        throw new Error('Saving currency failed.');
      }
    },
    [availableCurrencies.data],
  );

  return {
    currentCurrency: currentCurrency,
    availableCurrencies: availableCurrencies.data || [DEFAULT_CURRENCY],
    setCurrentCurrency: setCurrency,
  };
}
