import * as React from 'react';
import { Link } from 'react-router-dom';

export const PrivacyPolicyFR = () => {
  return (
    <>
      <h2>Politique de confidentialité</h2>
      <p>
        Nous vous remercions de l’intérêt que vous portez au groupe Schaeffler (Schaeffler AG et ses
        filiales) et à nos produits. Nous attachons beaucoup d’importance à la protection de votre
        vie privée lors de l’utilisation de notre offre en ligne. Le traitement éventuel de vos
        données à caractère personnel est toujours soumis au respect des lois applicables en matière
        de protection des données.
      </p>

      <h3>I. Informations générales sur le traitement des données</h3>

      <h4>1. Portée et finalité du traitement des données à caractère personnel </h4>
      <p>
        En principe, nous collectons et utilisons vos données à caractère personnel uniquement dans
        la mesure où cela est nécessaire pour assurer le bon fonctionnement de notre site Internet
        ainsi que des contenus et services que nous y proposons. Le traitement de vos données à
        caractère personnel a lieu soit sur la base de votre consentement, soit dans la mesure où
        les dispositions légales nous y autorisent même sans consentement.
      </p>

      <h4>2. Base juridique du traitement des données</h4>
      <p>
        Vos données à caractère personnel sont traitées sur la base du Règlement général sur la
        protection des données de l’UE (RGPD) et de la loi locale applicable en matière de
        protection des données, par exemple la loi fédérale allemande sur la protection des données
        (BDSG).
      </p>
      <p>
        Dans la mesure où vous nous avez donné votre consentement pour traiter les données à
        caractère personnel à des fins spécifiques, l’art. 6 (1) point a) du RGPD sert de fondement
        juridique à ce traitement. Vous pouvez à tout moment révoquer ce consentement. Veuillez
        noter que la révocation ne prend effet que le jour suivant sa déclaration et n’affecte pas
        le traitement antérieur des données.
      </p>
      <p>
        Le traitement des données à caractère personnel dans le cadre de l’exécution d’un contrat
        auquel vous êtes partie ou afin de prendre des mesures préalables à la conclusion d’un
        contrat à votre demande est fondé sur l’art. 6 (1) point b) du RGPD. Les finalités du
        traitement des données sont régies par les documents contractuels respectifs et l’objet du
        contrat.
      </p>
      <p>
        Lorsque le traitement des données à caractère personnel est nécessaire pour se conformer à
        une obligation légale à laquelle nous sommes soumis, l’art. 6 (1) point c) du RGPD sert de
        fondement juridique.
      </p>
      <p>
        Lorsque le traitement est nécessaire aux fins des intérêts légitimes poursuivis par
        Schaeffler ou par un tiers (par exemple, pour faire valoir des droits et se défendre contre
        des litiges, pour assurer la sécurité informatique, pour prévenir des actes criminels, pour
        des mesures de gestion d’entreprise et pour le développement ultérieur de services et de
        produits) et si vos intérêts, vos droits et libertés fondamentaux en tant que personne
        concernée ne prévalent pas sur l’intérêt susmentionné, l’art. 6 (1) point f) du RGPD sert de
        fondement juridique au traitement.
      </p>

      <h4>3. Effacement des données et durée de conservation</h4>
      <p>
        Nous traitons et stockons vos données à caractère personnel aussi longtemps que cela est
        nécessaire pour satisfaire la finalité respective. En outre, ce stockage peut avoir lieu
        afin de se conformer à une obligation légale en vertu d’une loi, d’un règlement ou d’une
        autre disposition de l&apos;Union ou d’un État membre à laquelle nous sommes soumis en tant
        que responsable du traitement. Lorsque les données ne sont plus nécessaires ou si une
        période de conservation prescrite par les lois susmentionnées a expiré, vos données de suivi
        seront effacées régulièrement après 90 jours. Par ailleurs, les prévisions clients sont
        conservées dans Demand360. Dans le cas de prévisions clients nouvellement créées ou
        modifiées, celles-ci sont transférées sous le nom abrégé du client vers SAP et supprimées
        après 36 mois. L’utilisateur peut demander l’effacement de ses données de paramétrage.
      </p>

      <h4>
        4. Accès aux données à caractère personnel au sein du groupe Schaeffler et par des tiers
      </h4>
      <p>
        Au sein du groupe Schaeffler, les entités qui en ont besoin ont accès à vos données dans le
        cadre du principe du «least privilege» (attribution des droits d’utilisation limitée au
        strict minimum) et du principe du «need-to-know» (connaissance des données uniquement si
        nécessaire). Nous ne pouvons transférer des données à des tiers extérieurs au groupe
        Schaeffler que si cela est nécessaire, si une disposition légale l’exige, si vous y avez
        consenti ou si nos sous-traitants se sont engagés contractuellement à respecter les
        exigences du RGPD et de la législation locale applicable en matière de protection des
        données.
      </p>
      <p>
        Dans ces circonstances, les destinataires des données à caractère personnel peuvent inclure
        : Microsoft Azure, SAP API Management et SAP
      </p>

      <h4>
        5. Transfert de données à caractère personnel vers un pays tiers ou vers une organisation
        internationale.
      </h4>
      <p>
        Le transfert de données vers des pays situés en dehors de l’UE/EEE (appelés pays tiers)
        n’aura lieu que si cela est nécessaire ou exigé par la loi, si vous avez donné votre
        consentement ou dans le cadre du traitement des données par un sous-traitant. Si des
        prestataires de services de pays tiers sont déployés, outre les instructions écrites, ils
        sont tenus de respecter les normes de protection des données en Europe en acceptant les
        clauses contractuelles types de l’UE.
      </p>

      <h4>6. Sécurité informatique et liens vers des sites tiers</h4>
      <p>
        Le groupe Schaeffler utilise des mesures de sécurité techniques et organisationnelles pour
        protéger vos données que nous gérons contre la destruction accidentelle ou intentionnelle,
        la manipulation, la perte ou l’accès par des personnes non autorisées. Ces mesures de
        sécurité sont constamment développées en fonction des nouvelles possibilités techniques
        respectives.
      </p>
      <p>
        Nos sites Internet peuvent contenir des liens qui renvoient sur les sites Internet d’autres
        fournisseurs. Nos informations sur la protection des données ne s’appliquent pas à ces
        sites.
      </p>

      <h4>7. Obligation de fournir des données à caractère personnel </h4>
      <p>
        Lorsque nous vous proposons sur ce site des offres et des services que vous pouvez utiliser
        volontairement, il n’y a aucune obligation de nous fournir vos données, mais sans vos
        données à caractère personnel, vous ne pourrez peut-être pas utiliser nos offres et
        services.
      </p>

      <h4>8. «Profilage» et décision automatisée</h4>
      <p>
        Nous n’utilisons pas de décision entièrement automatisée conformément à l’art. 22 du RGPD.
        En principe, Schaeffler n’a pas recours au «profilage». Lorsque nous l’utilisons dans des
        cas particuliers, nous vous en informerons séparément, si la loi l’exige et - si nécessaire
        - nous obtiendrons votre consentement préalable.
      </p>

      <h4>9. Sources de vos données à caractère personnel </h4>
      <p>Nous utilisons les données que vous nous communiquez.</p>

      <h3>
        II. Traitement des données pour la mise à disposition du site Internet et création de
        fichiers journaux
      </h3>
      <p>
        Par défaut, lorsque vous consultez notre site, nos serveurs internet obtiennent et
        collectent le nom de votre fournisseur d’accès internet, votre adresse IP, le site internet
        à partir duquel vous nous consultez, les pages que vous consultez sur notre site, ainsi que
        la date et la durée de la consultation. Ces données sont stockées dans les fichiers journaux
        de nos systèmes et sont utilisées pour l’analyse des problèmes ou des erreurs. L’utilisation
        de l’adresse IP est toutefois limitée à l’étendue techniquement nécessaire et est abrégée et
        donc utilisée uniquement de manière anonyme, de sorte qu’il n&apos;est pas possible
        d’attribuer l’adresse IP à un utilisateur. Les données ne sont pas fusionnées avec les
        données à caractère personnel.
      </p>
      <p>
        La base juridique pour le stockage temporaire des données est l’art. 6 (1) point f) du RGPD.
      </p>
      <p>
        Le stockage temporaire de l’adresse IP abrégée par nos systèmes est techniquement nécessaire
        pour afficher le site internet sur votre terminal. Le stockage dans des fichiers journaux
        est effectué pour assurer la fonctionnalité du site internet. Les données ne sont pas
        analysées à des fins de marketing dans ce contexte.
      </p>
      <p>
        À ces fins, nous avons un intérêt légitime à traiter les données conformément à l’art. 6 (1)
        point f) du RGPD.
      </p>
      <p>
        La collecte de données pour la mise à disposition du site internet et le stockage des
        données dans des fichiers journaux sont essentiels pour le fonctionnement du site internet.
        Par conséquent, il n&apos;existe aucune possibilité pour vous, en tant qu&apos;utilisateur,
        de vous opposer à ce traitement.
      </p>

      <h3>III. Traitement des données dans le cadre des services proposés sur le site internet</h3>
      <p>
        Nous proposons sur notre site internet différents services pour l’utilisation desquels nous
        vous demandons des données à caractère personnel. Dans ce contexte, vous pouvez ou non nous
        fournir ces données. Le cas échéant, nous traitons vos données à caractère personnel
        exclusivement sur une base anonyme afin de permettre les évaluations suivantes :
      </p>
      <ul>
        <li>
          Évaluation des taux de demande, des temps de réponse et des taux d’erreur du site internet
          afin de savoir quelles sont les pages les plus utilisées à quels moments de la journée et
          où se trouvent les utilisateurs.
        </li>
        <li>Évaluations des pages vues et des performances de chargement du site internet.</li>
        <li>Évaluations du nombre d’utilisateurs et de sessions</li>
        <li>Évaluation des événements et des mesures définis par les utilisateurs</li>
      </ul>
      <p>
        En outre, nous traitons vos données à caractère personnel dans le cadre du paramétrage de
        l’utilisateur. Pour améliorer l’expérience utilisateur, les paramètres spécifiques à
        l’application, comme la présentation préférée pour l’affichage des données, sont
        sauvegardés.
      </p>
      <p>
        Par ailleurs, pour soutenir le processus S&OP, les enregistrements de données que vous avez
        modifiées ou créés sont stockés dans SAP.
      </p>

      <h3>IV. Utilisation des cookies</h3>
      <p>
        Nous utilisons des cookies pour vous permettre d’utiliser au mieux le site internet. Les
        cookies sont des fichiers texte qui sont stockés dans le navigateur internet ou enregistrés
        par le navigateur Internet sur votre terminal. Lorsqu’un utilisateur consulte notre site
        internet, un cookie peut être enregistré dans son système d’exploitation. Ce cookie contient
        une chaîne caractéristique qui permet d’identifier clairement le navigateur lors d’un nouvel
        accès au site internet.
      </p>
      <p>
        Nous vous fournissons ci-après des informations générales sur l’utilisation des cookies sur
        notre site internet et le traitement associé des données à caractère personnel. Pour de plus
        amples informations sur les cookies spécifiquement utilisés, veuillez vous référer à la
        politique de Schaeffler en matière de cookies publiée sur ce site. Nous utilisons des
        cookies pour rendre notre site internet plus convivial. Certains éléments de notre site
        internet requièrent l’identification du navigateur demandeur, même après un saut de page.
      </p>
      <p>
        En outre, nous utilisons des cookies sur notre site internet pour permettre l’analyse du
        comportement de navigation de l’utilisateur.
      </p>
      <p>
        Lorsqu’il consulte notre site internet, l’utilisateur est informé de l’utilisation de
        cookies et est renvoyé à la présente politique de confidentialité.
      </p>
      <p>
        L’utilisation de cookies techniquement nécessaires a pour but de simplifier l’utilisation de
        notre site internet pour l’utilisateur. Certaines fonctionnalités de notre site internet ne
        peuvent être proposées sans l’utilisation de cookies. Il est nécessaire que le navigateur
        soit reconnu même après un saut de page. Les données d’utilisateur collectées par le biais
        des cookies techniquement nécessaires ne seront pas utilisées pour créer des profils
        d’utilisateur. Vous trouverez de plus amples informations dans la{' '}
        <Link to={'/cookiePolicy'}>politique de Schaeffler en matière de cookies</Link>.
      </p>
      <p>
        Les cookies d’analyse sont utilisés pour améliorer la qualité de notre site internet et de
        son contenu. Ils nous montrent comment le site internet est utilisé, afin que nous puissions
        constamment optimiser notre offre. Vous trouverez de plus amples informations dans la
        politique en matière de cookies de Schaeffler.
      </p>
      <p>
        La base juridique du traitement des données à caractère personnel à l&apos;aide de cookies
        est l’art. 6 (1) point f) du RGPD.
      </p>
      <p>
        Les cookies sont stockés sur le terminal de l’utilisateur et transmis de celui-ci à notre
        site internet. Par conséquent, en tant qu’utilisateur, vous avez également un contrôle total
        sur l’utilisation des cookies. En modifiant les paramètres de votre navigateur internet,
        vous pouvez désactiver ou restreindre la transmission des cookies. Les cookies qui ont déjà
        été stockés peuvent être supprimés à tout moment. Cela peut également se faire
        automatiquement. Si les cookies sont désactivés pour notre site internet, il se peut qu’il
        ne soit plus possible d’utiliser toutes les fonctions du site internet dans leur
        intégralité.
      </p>

      <h3>V. Vos droits en tant que personne concernée</h3>
      <p>
        Le traitement de vos données à caractère personnel fait de vous une personne concernée
        conformément au RGPD, ce qui vous confère les droits suivants :
      </p>

      <h4>1. Droit d&apos;accès de la personne concernée (article 15 du RGPD)</h4>
      <p>
        Sur demande, vous pouvez obtenir de notre part la confirmation que vos données à caractère
        personnel sont ou non traitées par nos soins. Si tel est le cas, vous pouvez nous demander
        de vous donner accès aux informations prévues par la loi (voir art. 15 (1) du RGPD). Nous
        vous informerons également des mesures de protection appropriées conformément à l’art. 46 du
        RGPD dans le cadre du transfert de données, dans le cas où vos données à caractère personnel
        sont transférées vers un pays tiers ou vers une organisation internationale. Il existe des
        restrictions conformément aux §§ 34 et 35 de la loi fédérale allemande sur la protection des
        données (BDSG).
      </p>

      <h4>2. Droit de rectification (article 16 du RGPD)</h4>
      <p>
        Vous avez un droit de rectification et/ou de complément si les données à caractère personnel
        traitées sont inexactes ou incomplètes. Nous devons rectifier les données dans les meilleurs
        délais.
      </p>

      <h4>3. Droit à la limitation du traitement (article. 18 du RGPD)</h4>
      <p>
        Sous réserve que les exigences légales soient respectées (voir l’art. 18 (1) du RGPD), vous
        avez le droit de limiter le traitement de vos données à caractère personnel. Pour les
        conséquences de cette limitation, veuillez vous référer à l’art. 18 (2) et (3) du RGPD.
      </p>

      <h4>4. Droit à l&apos;effacement (article 17 du RGPD)</h4>
      <p>
        Vous avez le droit d’exiger de nous l’effacement de vos données à caractère personnel dans
        les meilleurs délais, et nous sommes tenus d’effacer immédiatement ces données si l’une des
        raisons conformément à l’art. 17 (1) du RGPD s’applique. Le droit à l’effacement ne
        s’applique pas dans les cas de l’art. 17 (3) du RGPD. En outre, il existe des restrictions
        conformément aux §§ 34 et 35 de la BDSG.
      </p>

      <h4>5. Droit à la notification</h4>
      <p>
        Si vous avez exercé votre droit à la rectification, à l&apos;effacement ou à la limitation
        du traitement, nous sommes tenus de notifier à chaque destinataire auquel les données à
        caractère personnel ont été divulguées cette rectification, cet effacement ou cette
        limitation du traitement, sauf si cela s’avère impossible ou implique des efforts
        disproportionnés. Nous devons vous informer sur ces destinataires à votre demande.
      </p>

      <h4>6. Droit à la portabilité des données (article 20 du RGPD)</h4>
      <p>
        Vous avez le droit de recevoir les données à caractère personnel que vous nous avez fournies
        dans un format structuré, couramment utilisé et lisible par machine. Pour plus de détails,
        veuillez vous référer à l’art. 20 du RGPD.
      </p>

      <h4>7. Droit d’opposition (article 21 du RGPD)</h4>
      <p>
        Vous avez le droit de vous opposer à tout moment au traitement de vos données à caractère
        personnel qui est fondé sur l’art. 6 (1) point e) ou f) du RGPD pour des raisons liées à
        votre situation particulière. Vous trouverez de plus amples informations à l’art. 21 du
        RGPD.
      </p>
      <p>
        En outre, vous avez le droit de former un recours juridictionnel auprès d’une autorité de
        contrôle conformément à l’art. 77 du RGPD et § 19 de la BDSG.
      </p>

      <h3>VI. Nom et coordonnées du responsable du traitement</h3>
      <p>
        Schaeffler Technologies AG & Co. KG <br />
        Industriestraße1-3 <br />
        91074 Herzogenaurach <br />
        Allemagne <br />
        Numéro de téléphone: + 49 9132 82-0 <br />
        Numéro de fax: + 49 9132 82 - 49 50 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>

      <h3>VII. Coordonnées du responsable de la protection des données</h3>
      <p>
        Schaeffler AG <br />
        Responsable de la protection des données (Datenschutzbeauftragter) <br />
        Industriestraße 1-3 <br />
        91074 Herzogenaurach <br />
        Allemagne <br />
        Numéro de téléphone: +49 9132 82-1476 <br />
        Numéro de fax: +49 9132 82-5901 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>
      <small>Status: Version 1.0, 16.03.2022</small>
    </>
  );
};
