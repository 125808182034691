import { Button, ButtonProps, Tooltip } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

export const ActionButton = ({
  variant = 'contained',
  size = 'large',
  children,
  ...props
}: ButtonProps) => {
  return (
    <Tooltip title={props.name ? props.name : ''}>
      {/* Without the span the Tooltip is not shown when the button is disabled */}
      <span>
        <StyledActionButton variant={variant} size={size} aria-label={props.name} {...props}>
          {children}
        </StyledActionButton>
      </span>
    </Tooltip>
  );
};

const StyledActionButton = styled(Button)`
  min-width: 42px;
  max-width: 42px;

  min-height: 42px;
  max-height: 42px;

  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
