import WarningIcon from '@mui/icons-material/Warning';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../../../../core/i18n/i18n';
import { StatusSpecificContentProps } from '../StatusSpecificContent';

const HeadlineSecondPart = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
` as typeof Typography;

export default function InactivateStatusSpecificContent(_props: StatusSpecificContentProps) {
  return (
    <>
      <HeadlineSecondPart component="h3" variant="subtitle2">
        {t('customer_material_portfolio.inactive_modal.headline', {})}
      </HeadlineSecondPart>

      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid container item direction="row">
          <DatePicker
            readOnly
            label={t('customer_material_portfolio.inactive_modal.date_label', {})}
            onChange={() => {}}
            value={new Date(Date.now())}
            disabled={true}
            renderInput={(props) => <TextField {...props} size="small" />}
          ></DatePicker>
        </Grid>
        <Grid container item direction="row">
          <Stack direction="row" alignItems="center" gap={1}>
            <WarningIcon color={'warning'} fontSize="large" />
            <p>{t('customer_material_portfolio.inactive_modal.deletion_warning', {})}</p>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
