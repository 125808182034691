import { Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import React from 'react';
import styled, { css } from 'styled-components';

import { t } from '../../../core/i18n/i18n';
import { formatDate } from '../../../core/i18n/l10n';
import { MaterialListEntry } from '../../../domain/demandValidation/model';
import { grayBackgroundColor } from '../../../styles/colors';

export const SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES = [
  'C',
  'E',
  'F',
  'S',
  'U',
  'M',
  'N',
] as const;

export type SupplyConceptsStochasticType =
  (typeof SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES)[number];

export const PORTFOLIO_STATUS = [
  'PI', // PhaseIn
  'PO', // PhaseOut
  'SP', // Substitution Proposal
  'SE', // Substitution External (Customer)
  'SI', // Substitution Internal (Schaeffler)
  'SB', // Substitution Blocked (Veto)
  'IA', // Inactive
  'AC', // Active
] as const;

export type PortfolioStatusType = (typeof PORTFOLIO_STATUS)[number];

/**
 * SE - series
 * SP - sporadic
 */
export const DEMAND_CHARACTERISTIC = ['SE', 'SP'] as const;

export type DemandCharacteristicType = (typeof DEMAND_CHARACTERISTIC)[number];

function MoreInformation({
  materialListEntity,
}: {
  materialListEntity: MaterialListEntry | undefined;
}) {
  let supplyConcept = t('validation_of_demand.supply_concept.ELSE', {});
  const fixHor = materialListEntity?.fixHor ? formatDate(parseISO(materialListEntity?.fixHor)) : '';
  const stochasticType = materialListEntity?.stochasticType as SupplyConceptsStochasticType;

  // When the supply chain localization is changed, also check ExportDemandValidationService::buildSupplyConceptString
  // The logic is duplicated and must exist in frontend and backend
  if (SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES.includes(stochasticType)) {
    if (materialListEntity?.safetyStockCustomer) {
      supplyConcept = t(`validation_of_demand.supply_concept.${stochasticType}.csss`, {
        fixHor: fixHor,
        safetyStock: materialListEntity?.safetyStockCustomer,
      });
    } else if (materialListEntity?.safetyStock) {
      supplyConcept = t(`validation_of_demand.supply_concept.${stochasticType}.ss`, {
        fixHor: fixHor,
        safetyStock: materialListEntity?.safetyStock,
      });
    } else {
      supplyConcept = t(`validation_of_demand.supply_concept.${stochasticType}`, {
        fixHor: fixHor,
      });
    }
  }

  return (
    <MoreInformationSection $loading={!materialListEntity}>
      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.material_and_text', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.materialNumber || '-'} <br />{' '}
          {materialListEntity?.materialDescription || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.classification', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.materialClassification || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.supply_concept.title', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {supplyConcept}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.pfStatus.title', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {t(
            `validation_of_demand.more_information.pfStatus.${
              materialListEntity?.portfolioStatus as PortfolioStatusType
            }`,
            {},
          )}
          {materialListEntity?.portfolioStatusDate ? <br /> : null}
          {materialListEntity?.portfolioStatusDate
            ? formatDate(new Date(materialListEntity.portfolioStatusDate))
            : ''}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.demandCharacteristics.title', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {t(
            `validation_of_demand.more_information.demandCharacteristics.${
              materialListEntity?.demandCharacteristic as DemandCharacteristicType
            }`,
            {},
          ) || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.product_line_and_text', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.productLine || '-'} {materialListEntity?.productLineName || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.production_segment', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.productionSegment || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.production_line', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.productionLine || '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.current_wbz_schaeffler', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.currentRLTSchaeffler &&
          materialListEntity.currentRLTSchaeffler != '-1'
            ? `${materialListEntity.currentRLTSchaeffler} (${
                materialListEntity.transitTimeBetweenProdPlantAndDistributionPlant || '-'
              })`
            : '-'}
        </Typography>
      </MoreInformationItem>

      <MoreInformationItem>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.more_information.packaging_size', {})}
        </Typography>
        <Typography component="span" variant="body2">
          {materialListEntity?.packagingSize || '-'}
        </Typography>
      </MoreInformationItem>
    </MoreInformationSection>
  );
}

const MoreInformationSection = styled.div<{ $loading: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  background-color: ${grayBackgroundColor};
  margin-top: 24px;
  padding: 20px;

  ${(props) =>
    props.$loading &&
    css`
      > * {
        opacity: 0;
      }
    `}
`;

const MoreInformationItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MoreInformation;
