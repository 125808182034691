import { useMemo } from 'react';

import useGlobalSelectionCriteria from '../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { CustomerEntry } from '../globalSelection/model';

export enum GlobalSelectionStatus {
  DATA_AVAILABLE = 'DATA_AVAILABLE',
  DATA_NO_RESULTS = 'DATA_NO_RESULTS',
  DATA_LOADING = 'DATA_LOADING',
  DATA_ERROR = 'DATA_ERROR',
  DATA_NOTHING_SELECTED = 'DATA_NOTHING_SELECTED',
}

export default function useGlobalSelectionStatus(
  customerData: { data: CustomerEntry[] | undefined; isLoading: boolean },
  selectedCustomer: CustomerEntry | undefined,
): GlobalSelectionStatus {
  const { globalSelection } = useGlobalSelectionCriteria();

  return useMemo(() => {
    if (!globalSelection) {
      return GlobalSelectionStatus.DATA_NOTHING_SELECTED;
    } else if (customerData.isLoading) {
      return GlobalSelectionStatus.DATA_LOADING;
    } else if (globalSelection && customerData.data && customerData?.data?.length == 0) {
      return GlobalSelectionStatus.DATA_NO_RESULTS;
    } else if (selectedCustomer && customerData?.data) {
      return GlobalSelectionStatus.DATA_AVAILABLE;
    }

    return GlobalSelectionStatus.DATA_ERROR;
  }, [customerData, globalSelection, selectedCustomer]);
}
