import { FilterList } from '@mui/icons-material';
import { Button, Paper, Popover, Stack } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { MultiAutocompleteOnType } from '../../../components/inputs/autocomplete/MultiAutocompleteOnType';
import { MultiAutocompletePreLoaded } from '../../../components/inputs/autocomplete/MultiAutocompletePreLoaded';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { useSelectableOptions } from '../../../core/hooks/useSelectableOptions';
import { getPreferredLanguage, t } from '../../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../../core/requests/httpClient';
import {
  DemandValidationFilter,
  DemandValidationFilterName,
  getEmptyDemandValidationFilter,
} from '../../../domain/demandValidation/demandValidationFilters';
import { FormActions } from '../gridUploadModal/ModalFormLayouts';

const StyledButton = styled(Button)`
  max-height: 40px;
`;

const ButtonText = styled.p`
  white-space: nowrap;
`;

export type FilterDemandValidationProps = {
  demandValidationFilters: DemandValidationFilter | undefined;
  onDemandValidationFilterChange: (filter: DemandValidationFilter | undefined) => void;
};

export default function FilterDemandValidation({
  demandValidationFilters,
  onDemandValidationFilterChange: setDemandValidationFilter,
}: FilterDemandValidationProps) {
  const [pickedDemandVaildationFilters, setPickedDemandValidationFilters] = useState<
    DemandValidationFilter | undefined
  >(demandValidationFilters);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const filterCount = useMemo(() => {
    if (!demandValidationFilters) return 0;

    let count = 0;
    Object.values(demandValidationFilters).forEach((filters) => (count = count + filters.length));
    return count;
  }, [demandValidationFilters]);

  const getMaterialCustomerNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/customer-material-numbers', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const getProductionLineOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-production-line', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const productLineOptions = useSelectableOptions(`global-selection/product-line`);
  const stochasticTypesOptions = useSelectableOptions(
    `global-selection/stochastic-types?language=${getPreferredLanguage()}`,
  );

  const handleFilterChange =
    (propertyName: DemandValidationFilterName) =>
    (event: unknown, newSelectedOptions: Array<SelectableValue> | undefined) => {
      setPickedDemandValidationFilters({
        ...(pickedDemandVaildationFilters || getEmptyDemandValidationFilter()),
        [propertyName]: newSelectedOptions || [],
      });
    };

  const apply = () => {
    setDemandValidationFilter(pickedDemandVaildationFilters);
    setAnchorEl(null);
  };

  const reset = () => {
    setPickedDemandValidationFilters(undefined);
    setDemandValidationFilter(undefined);
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        variant="outlined"
        color="secondary"
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        <FilterList />
        <ButtonText>
          {t('validation_of_demand.filter', { count: filterCount.toString() })}
        </ButtonText>
      </StyledButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '500px' },
        }}
      >
        <Paper>
          <Stack padding={'24px'} spacing={2}>
            <MultiAutocompleteOnType
              getOptions={getMaterialCustomerNumberOptions}
              value={pickedDemandVaildationFilters?.customerMaterialNumber}
              onValueChange={handleFilterChange('customerMaterialNumber')}
              autocompleteLabel={t('validation_of_demand.filter.customerMaterialNumber', {})}
              getOptionLabel={(opt) => opt.id}
            />

            <MultiAutocompletePreLoaded
              optionsLoadingResult={productLineOptions}
              value={pickedDemandVaildationFilters?.productLine}
              onValueChange={handleFilterChange('productLine')}
              autocompleteLabel={t('validation_of_demand.filter.product_line', {})}
              getOptionLabelInTag={(opt: SelectableValue) => `${opt.id} - ${opt.text}`}
            />

            <MultiAutocompleteOnType
              getOptions={getProductionLineOptions}
              value={pickedDemandVaildationFilters?.productionLine}
              onValueChange={handleFilterChange('productionLine')}
              autocompleteLabel={t('validation_of_demand.filter.production_line', {})}
              getOptionLabel={(opt) => opt.id}
            />

            <MultiAutocompletePreLoaded
              optionsLoadingResult={stochasticTypesOptions}
              value={pickedDemandVaildationFilters?.stochasticType}
              onValueChange={handleFilterChange('stochasticType')}
              autocompleteLabel={t('validation_of_demand.filter.stochasticType', {})}
              getOptionLabelInTag={(opt: SelectableValue) => `${opt.id} - ${opt.text}`}
            />

            <FormActions justify="flex-end" margin="0 40px 0 0">
              <Button variant="contained" color="white" onClick={reset}>
                {t('button.reset', {})}
              </Button>
              <Button variant="contained" onClick={apply}>
                {t('button.load', {})}
              </Button>
            </FormActions>
          </Stack>
        </Paper>
      </Popover>
    </div>
  );
}
