import {
  DateRange as DateRangeIcon,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  Settings,
} from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ActionButton } from '../../../components/ActionButton';
import { StyledSection } from '../../../components/StyledSection';
import useGlobalSelectionCriteria from '../../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { t } from '../../../core/i18n/i18n';
import { PlanningView } from '../../../domain/demandValidation/planningView';
import { ChartUnitMode, ForecastChartData } from '../../../domain/forecastChart/model';
import useChartSettings from '../../../domain/forecastChart/useChartSettings';
import useForecastChartData from '../../../domain/forecastChart/useForecastChartData';
import {
  emptyGlobalSelectionCriteriaFields,
  globalSelectionCriteriaToFilter,
} from '../../../domain/globalSelection/model';

import { ForecastChart } from './ForecastChart';
import { ForecastChartDatepickerPopover } from './ForecastChartDatepickerPopover';
import { ForecastChartLegendPopover } from './ForecastChartLegendPopover';
import { ForecastChartSettingsPopover } from './ForecastChartSettingsPopover';

export type ChartAreaProps = {
  columnFilters: Record<string, any>;
};

export type ChartModal = 'datepicker' | 'settings' | 'info' | null;

export function ForecastChartSection({ columnFilters }: ChartAreaProps) {
  const [open, setOpen] = useState(true);
  const [modal, setModal] = useState<ChartModal>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [chartData, setChartData] = useState<ForecastChartData>();

  const { chartSettings, updateChartSettings } = useChartSettings();
  const { globalSelection } = useGlobalSelectionCriteria();
  const { data, mutate, isValidating, error } = useForecastChartData(
    globalSelectionCriteriaToFilter(globalSelection),
    columnFilters,
    chartSettings,
  );
  const prevErrorRef = React.useRef(undefined);

  // keep old data until new data is loaded
  useEffect(() => {
    if (data) {
      setChartData(data);
    }
  }, [setChartData, data]);

  // clear chart when 'reset' is clicked or mutate if response had an error
  useEffect(() => {
    if (error !== undefined && prevErrorRef.current === error) {
      void mutate();
      return;
    }

    if (!globalSelection || globalSelection === emptyGlobalSelectionCriteriaFields) {
      setChartData(undefined);
    }
    prevErrorRef.current = error;
  }, [globalSelection, error, mutate]);

  const saveDates = useCallback(
    (startDate: Date, endDate: Date) => {
      updateChartSettings({ ...chartSettings, startDate: startDate, endDate: endDate });
    },
    [chartSettings, updateChartSettings],
  );

  const saveSettings = useCallback(
    (planningView: PlanningView, chartUnitMode: ChartUnitMode) => {
      updateChartSettings({
        ...chartSettings,
        planningView: planningView,
        chartUnitMode: chartUnitMode,
      });
      setModal(null);
      setAnchorEl(null);
    },
    [chartSettings, updateChartSettings],
  );

  return (
    <StyledSection>
      <TopBar>
        <Typography variant={'h6'} component="h2">
          {t('home.chart.title', {})} |{' '}
          {t(`planing_type.title.${chartSettings.planningView as PlanningView}`, {})}
        </Typography>
        <ActionArea>
          {open && (
            <>
              <ActionButton
                onClick={({ currentTarget }) => {
                  setModal('datepicker');
                  setAnchorEl(currentTarget);
                }}
                variant={'text'}
                color={'secondary'}
                disabled={!chartData}
              >
                <DateRangeIcon />
              </ActionButton>
              <ActionButton
                onClick={({ currentTarget }) => {
                  setModal('settings');
                  setAnchorEl(currentTarget);
                }}
                variant={'text'}
                color={'secondary'}
                disabled={!chartData}
                aria-label={t('home.chart.aria.settings_button', {})}
              >
                <Settings />
              </ActionButton>
              <ActionButton
                onClick={({ currentTarget }) => {
                  setModal('info');
                  setAnchorEl(currentTarget);
                }}
                variant={'text'}
                color={'secondary'}
                disabled={!chartData}
                aria-label={t('home.chart.aria.daterange_button', {})}
              >
                <InfoOutlined />
              </ActionButton>{' '}
            </>
          )}
          <StyledIcon disableRipple={true} color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </StyledIcon>
        </ActionArea>
      </TopBar>
      {open && (
        <ChartContainer>
          <ForecastChart chartData={chartData} loading={isValidating} error={!!error} />
        </ChartContainer>
      )}
      <ForecastChartDatepickerPopover
        startDate={chartSettings.startDate}
        endDate={chartSettings.endDate}
        open={modal == 'datepicker'}
        anchorEl={anchorEl}
        onClose={() => {
          setModal(null);
          setAnchorEl(null);
        }}
        onSave={saveDates}
      />
      <ForecastChartSettingsPopover
        planningView={chartSettings.planningView}
        chartUnitMode={chartSettings.chartUnitMode}
        anchorEl={anchorEl}
        open={modal == 'settings'}
        onClose={saveSettings}
      />
      <ForecastChartLegendPopover
        anchorEl={anchorEl}
        open={modal == 'info'}
        onClose={() => {
          setModal(null);
          setAnchorEl(null);
        }}
      />
    </StyledSection>
  );
}

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ChartContainer = styled.div`
  width: 100%;
  min-height: 350px;
  max-height: 200px;
`;

const StyledIcon = styled(IconButton)`
  width: 32px;
  height: 32px;
`;
