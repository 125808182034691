import { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React, {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  getColFilter,
  getDefaultColDef,
  serverSideTableDefaultProps,
  sideBar,
} from '../../../agGrid/gridDefaults';
import { GridApis } from '../../../agGrid/gridTypes';
import useAutosizeColumns from '../../../agGrid/useAutosizeColumns';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { DATA_FETCHED_EVENT } from '../../../core/datasources';
import { t } from '../../../core/i18n/i18n';
import { createCustomerMaterialPortfolioDatasource } from '../../../domain/customerMaterialPortfolio/datasource';
import { CMPEntry } from '../../../domain/customerMaterialPortfolio/model';
import { useCmpCriteriaData } from '../../../domain/customerMaterialPortfolio/useCmpCriteriaData';
import {
  GlobalSelectionCriteriaFields,
  CustomerEntry,
} from '../../../domain/globalSelection/model';
import { CMPData } from '../modal/cmpModalStatusSpecificElements/cmpModalTypes';

import { CMPToolbar } from './CMPToolbar';
import CmpRowMenuButton, { AdditionalCMPModalInfo } from './CmpRowMenuButton';
import columnDefinitions, { CMPColId } from './columnDefinitions';
import { CMPModal } from './statusActions';

export type CustomerMaterialPortfolioTableHandle = {
  refresh: () => void;
};

export type FilterModel = {
  [key: string]: any;
};

type Params = {
  selectedCustomer: CustomerEntry | undefined;
  globalSelection: GlobalSelectionCriteriaFields;
  ref: RefObject<CustomerMaterialPortfolioTableHandle>;
  handleModalChange: (
    newModalOpen: CMPModal,
    selectedCmpData: CMPData,
    additionalModalInfo: AdditionalCMPModalInfo,
  ) => void;
  filterModel: FilterModel;
  setFilterModel: (filterModel: FilterModel) => void;
};

export const CustomerMaterialPortfolioTable = forwardRef<
  CustomerMaterialPortfolioTableHandle,
  Params
>(
  (
    {
      selectedCustomer,
      globalSelection,
      handleModalChange: handleModalChange,
      filterModel,
      setFilterModel,
    },
    ref,
  ) => {
    const [grid, setGrid] = useState<GridApis | undefined>();
    const [rowCount, setRowCount] = useState<number | undefined>(undefined);
    // SAP returns the head and child materials. However, AGGRid does not support providing both at the same time.
    // Instead, a separate request is made for head material with children. Therefore, we build a small cache here
    // containing the child materials of the first request.
    const childMaterialsCache = useRef(new Map<string, Array<CMPEntry>>());
    useAutosizeColumns(grid);

    useEffect(() => {
      if (!grid) return;

      const listener = () => {
        const storeState = grid.api.getServerSideGroupLevelState()[0];
        setRowCount(storeState.rowCount);
      };
      setRowCount(undefined);
      grid.api.addEventListener(DATA_FETCHED_EVENT, listener);
    }, [grid, setRowCount]);

    const { data: criteriaData } = useCmpCriteriaData();

    useEffect(() => {
      if (!grid) return;
      grid.api.setServerSideDatasource(
        createCustomerMaterialPortfolioDatasource({
          selectedCustomer: selectedCustomer?.customerNumber || '',
          globalSelection,
          childMaterialsCache,
        }),
      );
    }, [grid, selectedCustomer, globalSelection]);

    useEffect(() => {
      if (!grid) return;

      grid.api.setFilterModel(filterModel);
      grid.api.onFilterChanged();
    }, [filterModel, grid]);
    useImperativeHandle(
      ref,
      () => {
        return {
          refresh: () => {
            if (grid) {
              const groupIds = Array.from(childMaterialsCache.current?.keys());
              childMaterialsCache.current?.clear();
              grid.api.refreshServerSide();
              groupIds.forEach((key) => {
                grid.api.refreshServerSide({ route: [key] });
              });
            }
          },
        };
      },
      [grid, childMaterialsCache],
    );

    const columnDefs = useMemo(() => {
      const mapColumnData = (def: any) => {
        return {
          field: def.colId,
          lockVisible: def.alwaysVisible,
          hide: !def.visible,
          sortable: true,
          headerName: t(`material_customer.column.${def.colId as CMPColId}`, {}),
          cellRenderer: def.cellRenderer,
          cellRendererParams: def.cellRendererParams,
          filter: getColFilter(def.colId, def.filter, criteriaData),
          tooltipComponent: def.tooltipComponent,
          tooltipField: def.tooltipField,
        };
      };

      return [
        ...columnDefinitions.map((def: any) => {
          return {
            ...getDefaultColDef(def.filter, def.filterParams),
            ...mapColumnData(def),
          };
        }),
        {
          field: 'menu',
          headerName: '',
          cellRenderer: 'cmpMenuRenderer',
          lockVisible: true,
          pinned: 'right',
          lockPinned: true,
          cellRendererParams: {
            handleModalChange: handleModalChange,
          },
          suppressMenu: true,
          maxWidth: 64,
          suppressSizeToFit: true,
        },
      ] as ColDef[];
    }, [handleModalChange, criteriaData]);
    return (
      <>
        <CMPToolbar grid={grid} rowCount={rowCount} />
        <div style={{ display: 'flex', flexDirection: 'row', minHeight: '400px', flexGrow: 1 }}>
          <StyledGridSection>
            <AgGridReact
              {...serverSideTableDefaultProps}
              sideBar={sideBar}
              onGridReady={setGrid}
              components={{
                ...serverSideTableDefaultProps.components,
                cmpMenuRenderer: CmpRowMenuButton,
              }}
              columnDefs={columnDefs}
              autoGroupColumnDef={{
                field: 'materialNumber',
                headerName: t(`material_customer.column.materialNumber`, {}),
                minWidth: 280,
                pinned: 'left',
                lockPinned: true,
              }}
              treeData={true}
              isServerSideGroup={(data) => data.hasChildren}
              isServerSideGroupOpenByDefault={() => false}
              getServerSideGroupKey={(data) => data.materialNumber}
              getRowId={(params) => params.data.successor + '-' + params.data.materialNumber}
              onFilterChanged={(event) => {
                // Check for columns to avoid loading cycle.
                // We have to call this function after setting the filter model,
                // but when we call it from api, there are no columns.
                if (event.columns.length > 0) {
                  setFilterModel(event.api.getFilterModel());
                }
              }}
            ></AgGridReact>
          </StyledGridSection>
        </div>
      </>
    );
  },
);

CustomerMaterialPortfolioTable.displayName = 'CustomerMaterialPortfolioTable';
