import useSWR from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';
import useCurrency from '../info/useCurrency';

import { AlertRuleResponse } from './model';

export default function useAlertRuleData() {
  const { currentCurrency } = useCurrency();
  const { data, mutate } = useSWR<AlertRuleResponse>(
    `alert-rules?currency=${currentCurrency}`,
    requestFromApi,
  );

  return { result: data, refresh: mutate };
}
