import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker';
import { addMonths, endOfMonth, isBefore, startOfMonth } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { DatePicker } from '../../components/DatePicker';
import { datePickerErrorReasonToLocalizedText } from '../../core/datePickerUtils';
import { DateRange, DateRangePeriod } from '../../core/dateRange';
import useDomId from '../../core/hooks/useDomId';
import { t } from '../../core/i18n/i18n';
import {
  datePickerMasks,
  preferredDateFormat,
  preferredDateFormatWithoutDay,
} from '../../core/i18n/l10n';
import { firstViewableDate, lastViewableDate } from '../../domain/demandValidation/limits';

import { FormInputs } from './gridUploadModal/ModalFormLayouts';

type DatePickerPanelParams = {
  dateRange1: Partial<DateRange>;
  onDateRange1Change: (value: Partial<DateRange>) => void;
  dateRange2: Partial<DateRange> | undefined;
  onDateRange2Change: (value: Partial<DateRange> | undefined) => void;
  onDateRangeErrorChange: (value: boolean) => void;
  minDate?: Date;
  maxDate?: Date;

  disableOptionalDate?: boolean;
};

export const DemandValidationDatePicker = ({
  dateRange1,
  onDateRange1Change,
  dateRange2,
  onDateRange2Change,
  onDateRangeErrorChange,
  minDate = firstViewableDate(),
  maxDate = lastViewableDate(),
  disableOptionalDate = false,
}: DatePickerPanelParams) => {
  const [range1FromPickerErrorReason, setRange1FromPickerErrorReason] = React.useState<
    string | null
  >(null);
  const [range1ToPickerErrorReason, setRange1ToPickerErrorReason] = React.useState<string | null>(
    null,
  );
  const [range2ToPickerErrorReason, setRange2ToPickerErrorReason] = React.useState<string | null>(
    null,
  );

  const periodLabelId = useDomId();

  const datePickerFormat = (dateRange?: DateRange | Partial<DateRange>) => {
    return dateRange?.period === 'WEEKLY' ? preferredDateFormat : preferredDateFormatWithoutDay;
  };

  const datePickerMask = (dateRange?: DateRange | Partial<DateRange>) => {
    return dateRange?.period === 'WEEKLY'
      ? datePickerMasks.preferredDateFormat
      : datePickerMasks.preferredDateFormatWithoutDay;
  };

  const datePickerViews = (dateRange?: DateRange | Partial<DateRange>): CalendarPickerView[] => {
    return dateRange?.period === 'MONTHLY' ? ['year', 'month'] : ['year', 'month', 'day'];
  };

  let range1FromErrorText = datePickerErrorReasonToLocalizedText(range1FromPickerErrorReason);
  let range1ToErrorText = datePickerErrorReasonToLocalizedText(range1ToPickerErrorReason);
  let range2ToErrorText = datePickerErrorReasonToLocalizedText(range2ToPickerErrorReason);

  if (dateRange1?.from === undefined) {
    range1FromErrorText = t('error.date.emptyDate', {});
  }

  if (dateRange1?.to === undefined) {
    range1ToErrorText = t('error.date.emptyDate', {});
  }

  if (
    dateRange1?.from !== undefined &&
    dateRange1?.to !== undefined &&
    isBefore(dateRange1.to, dateRange1.from)
  ) {
    range1ToErrorText = t('validation_of_demand.date_picker.error', {});
  }
  if (dateRange2?.from && dateRange2?.to && isBefore(dateRange2.to, dateRange2.from)) {
    range2ToErrorText = t('validation_of_demand.date_picker.error', {});
  }

  useEffect(() => {
    onDateRangeErrorChange(
      range1FromErrorText != null || range1ToErrorText != null || range2ToErrorText != null,
    );
  }, [range1FromErrorText, range1ToErrorText, range2ToErrorText, onDateRangeErrorChange]);

  const minDateRange1To = dateRange1.from;
  const minDateRange2To =
    dateRange1.to === undefined ? firstViewableDate() : startOfMonth(addMonths(dateRange1.to, 1));

  return (
    <Form>
      <Container>
        <FormInputs>
          {/*
            When changing the mask or input format at runtime, the date picker reformats the date wrong.
            Therefore, we have defines to different date pickers, on for weekly and one for monthly.
          */}
          {dateRange1.period == 'MONTHLY' && (
            <DatePicker
              label={t('validation_of_demand.date_picker.start_date', {})}
              value={dateRange1?.from || null}
              minDate={minDate}
              maxDate={maxDate}
              views={datePickerViews(dateRange1)}
              onChange={(newValue) => {
                onDateRange1Change({ ...dateRange1, from: newValue || undefined });
              }}
              onError={(reason, _) => setRange1FromPickerErrorReason(reason)}
              inputFormat={datePickerFormat(dateRange1)}
              mask={datePickerMask(dateRange1)}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  error={range1FromErrorText !== null}
                  helperText={range1FromErrorText}
                  size="small"
                  data-testid={'datePickerRange1StartDate'}
                />
              )}
            />
          )}
          {dateRange1.period == 'WEEKLY' && (
            <DatePicker
              label={t('validation_of_demand.date_picker.start_date', {})}
              value={dateRange1.from || null}
              minDate={minDate}
              maxDate={maxDate}
              views={datePickerViews(dateRange1)}
              onChange={(newValue) => {
                onDateRange1Change({ ...dateRange1, from: newValue || undefined });
              }}
              onError={(reason, _) => setRange1FromPickerErrorReason(reason)}
              inputFormat={datePickerFormat(dateRange1)}
              mask={datePickerMask(dateRange1)}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  error={range1FromErrorText !== null}
                  helperText={range1FromErrorText}
                  size="small"
                  data-testid={'datePickerRange1StartDate'}
                />
              )}
            />
          )}

          {dateRange1.period == 'MONTHLY' && (
            <MuiDatePicker
              label={t('validation_of_demand.date_picker.end_date', {})}
              value={dateRange1.to || null}
              minDate={minDateRange1To}
              maxDate={maxDate}
              views={datePickerViews(dateRange1)}
              onChange={(newValue) => {
                onDateRange1Change({ ...dateRange1, to: newValue || undefined });

                if (dateRange1.period === 'WEEKLY') {
                  onDateRange2Change({
                    ...dateRange2,
                    from: newValue ? addMonths(newValue, 1) : undefined,
                  });
                }
              }}
              onError={(reason, _) => setRange1ToPickerErrorReason(reason)}
              inputFormat={datePickerFormat(dateRange1)}
              mask={datePickerMask(dateRange1)}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  error={range1ToErrorText !== null}
                  helperText={range1ToErrorText}
                  size="small"
                  data-testid={'datePickerRange1EndDate'}
                />
              )}
            />
          )}
          {dateRange1.period == 'WEEKLY' && (
            <DatePicker
              label={t('validation_of_demand.date_picker.end_date', {})}
              value={dateRange1.to || null}
              minDate={minDateRange1To}
              maxDate={maxDate}
              views={datePickerViews(dateRange1)}
              onChange={(newValue) => {
                onDateRange1Change({ ...dateRange1, to: newValue || undefined });

                if (dateRange1.period === 'WEEKLY') {
                  onDateRange2Change({
                    ...dateRange2,
                    from: newValue ? startOfMonth(addMonths(newValue, 1)) : undefined,
                  });
                }
              }}
              onError={(reason, _) => setRange1ToPickerErrorReason(reason)}
              inputFormat={datePickerFormat(dateRange1)}
              mask={datePickerMask(dateRange1)}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  error={range1ToErrorText !== null}
                  helperText={range1ToErrorText}
                  size="small"
                  data-testid={'datePickerRange1EndDate'}
                />
              )}
            />
          )}

          <FormControl fullWidth size={'small'}>
            <InputLabel id={periodLabelId}>
              {t('validation_of_demand.date_picker.period_type', {})}
            </InputLabel>
            <Select
              labelId={periodLabelId}
              label={t('validation_of_demand.date_picker.period_type', {})}
              placeholder={t('validation_of_demand.date_picker.period_type', {})}
              value={dateRange1.period}
              onChange={(newValue) => {
                if (newValue) {
                  const newPeriod = newValue.target.value as DateRangePeriod;
                  onDateRange1Change({ ...dateRange1, period: newPeriod });
                  if (newPeriod === 'MONTHLY') {
                    onDateRange2Change({ ...dateRange2, from: undefined, to: undefined });
                    // When changing from weekly to monthly, we need to reset the error state
                    // otherwise the error state will remain
                    setRange2ToPickerErrorReason(null);
                  }
                  if (newPeriod === 'WEEKLY') {
                    onDateRange2Change({
                      ...dateRange2,
                      from: dateRange1.to ? startOfMonth(addMonths(dateRange1.to, 1)) : undefined,
                    });
                  }
                }
              }}
              size={'small'}
            >
              <MenuItem value={'WEEKLY'}>
                {t('validation_of_demand.date_picker.menu_item_week', {})}
              </MenuItem>
              <MenuItem value={'MONTHLY'}>
                {t('validation_of_demand.date_picker.menu_item_month', {})}
              </MenuItem>
            </Select>
          </FormControl>
        </FormInputs>
      </Container>

      {dateRange1.period === 'WEEKLY' && !disableOptionalDate && (
        <div>
          <Typography variant={'subtitle2'}>
            {t('validation_of_demand.date_picker.optional', {})}
          </Typography>

          <Container>
            <FormInputs>
              <DatePicker
                disabled
                label={t('validation_of_demand.date_picker.start_date', {})}
                value={dateRange2?.from || null}
                views={datePickerViews(dateRange2)}
                onChange={(newValue) => {
                  if (newValue && dateRange2) {
                    onDateRange2Change({ ...dateRange2, from: newValue });
                  }
                }}
                inputFormat={datePickerFormat(dateRange2)}
                mask={datePickerMask(dateRange2)}
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} size="small" data-testid={'datePickerRange2StartDate'} />
                )}
              />

              <DatePicker
                label={t('validation_of_demand.date_picker.end_date', {})}
                value={dateRange2?.to || null}
                minDate={minDateRange2To}
                maxDate={maxDate}
                views={datePickerViews(dateRange2)}
                onChange={(newValue) => {
                  if (dateRange2) {
                    onDateRange2Change({ ...dateRange2, to: newValue ? newValue : undefined });
                  }
                }}
                onError={(reason, _) => setRange2ToPickerErrorReason(reason)}
                inputFormat={datePickerFormat(dateRange2)}
                mask={datePickerMask(dateRange2)}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    helperText={range2ToErrorText}
                    size="small"
                    data-testid={'datePickerRange2EndDate'}
                  />
                )}
              />

              <FormControl fullWidth size={'small'} disabled>
                <InputLabel>{t('validation_of_demand.date_picker.period_type', {})}</InputLabel>
                <Select
                  label={t('validation_of_demand.date_picker.period_type', {})}
                  placeholder={t('validation_of_demand.date_picker.period_type', {})}
                  defaultValue={'MONTHLY'}
                  value={dateRange2?.period}
                  size={'small'}
                >
                  <MenuItem value={'MONTHLY'}>
                    {t('validation_of_demand.date_picker.menu_item_month', {})}
                  </MenuItem>
                </Select>
              </FormControl>
            </FormInputs>
          </Container>
        </div>
      )}
    </Form>
  );
};

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  padding: 20px 0;
`;

/**
 * When date range 1 ends before the start of date range 2 the missing gap need to befilled.
 * E.g. dateRange1 ends at the 20.10.2020 then the monthly dateRange2 will start at the 1.11.2020
 * -> dateRange1 needs to be shifted to the 31.10.2020 in order to have a concise interval.
 * @param dateRange1
 * @param dateRange2
 */
export function fillGapBetweenRanges(
  dateRange1: Partial<DateRange>,
  dateRange2: Partial<DateRange> | undefined,
): { range1: DateRange; range2?: DateRange } | undefined {
  if (dateRange1?.from && dateRange1?.to && dateRange1?.period) {
    return {
      range1:
        dateRange1.period === 'WEEKLY' && dateRange2?.to
          ? {
              ...dateRange1,
              from: dateRange1.from,
              to: endOfMonth(dateRange1.to),
              period: dateRange1.period,
            }
          : {
              from: dateRange1.from,
              to: dateRange1.to,
              period: dateRange1.period,
            },
      range2:
        dateRange2?.from && dateRange2?.to && dateRange2?.period
          ? {
              from: dateRange2.from,
              to: dateRange2.to,
              period: dateRange2.period,
            }
          : undefined,
    };
  }
  return;
}
