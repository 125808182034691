import React from 'react';

import { Page } from '../components/page/Page';
import { t } from '../core/i18n/i18n';

const RegionalApPortfolio = () => {
  const title = `${t('tabbar.functions.label', {})} | ${t(
    'tabbarMenu.regional-ap-portfolio.label',
    {},
  )}`;

  return <Page title={title}></Page>;
};

export default RegionalApPortfolio;
