import { CheckCircle } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useGlobalSelectionCriteria from '../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { GlobalSelectionCriteriaFields } from '../../domain/globalSelection/model';
import { SelectableValue } from '../inputs/baseComponents/selectableValues';

const MinimizedFilterContainer = styled.div`
  display: flex;
  width: 85%;
  overflow: hidden;
`;

const ChipsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  padding: 0 3px;
  margin: 0 5px;
`;

const BlurryEnd = styled.div`
  position: relative;
  padding: 0 25px;
  left: -50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

type SelectableOfGlobalWithKey = {
  key: keyof GlobalSelectionCriteriaFields;
  selectable: SelectableValue;
};

export default function MinimizedGlobalSelectionCriteria() {
  const { globalSelection, updateGlobalSelectionOnPage } = useGlobalSelectionCriteria();

  const activeSelectionFilters: SelectableOfGlobalWithKey[] = useMemo(() => {
    if (!globalSelection) return [];

    return Object.entries(globalSelection)
      .map(([key, value]) => {
        // key is the property name / filter name, value is an array of filters as SelectableValues
        if (!value || value.length < 1) return undefined;

        return value.map((sel) => {
          return { key: key, selectable: sel } as SelectableOfGlobalWithKey;
        });
      })
      .flat()
      .filter((val) => val != undefined) as SelectableOfGlobalWithKey[];
  }, [globalSelection]);

  const removeOneFilterFromGlobalSelection = useCallback(
    (filterToBeRemoved: SelectableOfGlobalWithKey) => {
      if (!globalSelection) return;

      const newFilter = globalSelection[filterToBeRemoved.key].filter(
        (filter) => filter.id !== filterToBeRemoved.selectable.id,
      );

      const newSelection = {
        ...globalSelection,
        [filterToBeRemoved.key]: newFilter,
      };

      updateGlobalSelectionOnPage(newSelection);
    },
    [globalSelection, updateGlobalSelectionOnPage],
  );

  const makeLabel = (
    selectable: SelectableValue,
    key: keyof GlobalSelectionCriteriaFields,
  ): string => {
    if (key === 'alertType') {
      return selectable.text;
    }

    return selectable.id == selectable.text
      ? selectable.text
      : `${selectable.id} - ${selectable.text}`;
  };

  return (
    <MinimizedFilterContainer>
      <ChipsContainer>
        {activeSelectionFilters.map((filterElement) => {
          return (
            <StyledChip
              icon={<CheckCircle color="primary" />}
              size={'small'}
              key={filterElement.selectable.id}
              label={makeLabel(filterElement.selectable, filterElement.key)}
              onDelete={() => removeOneFilterFromGlobalSelection(filterElement)}
            />
          );
        })}
      </ChipsContainer>
      <BlurryEnd />
    </MinimizedFilterContainer>
  );
}
