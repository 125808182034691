import useSWR from 'swr';

import { getPreferredLanguage } from '../../core/i18n/i18n';
import { requestFromApi } from '../../core/requests/httpClient';
import useCurrency from '../info/useCurrency';

import { AlertTypeDescription } from './model';

export default function useRuleTypeData() {
  const { currentCurrency } = useCurrency();
  const { data } = useSWR<AlertTypeDescription[]>(
    `alert-rules/alert-type-set?language=${getPreferredLanguage()}&currency=${currentCurrency}`,
    requestFromApi,
  );

  return data;
}
