import { PostResult } from '../../core/errorhandling';
import { getErrorMessage } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';

import { IMRSubstitution, IMRSubstitutionResponse } from './model';
import { dataToIMRSubstitutionRequest } from './requestHelper';

export async function saveMultiIMRSubstitution(
  substitutions: IMRSubstitution[],
  dryrun: boolean,
): Promise<PostResult<IMRSubstitutionResponse>> {
  try {
    const request = substitutions.map((sub) => dataToIMRSubstitutionRequest(sub));

    const url = encodeURI(`internal-material-replacement/multi-substitution?dryRun=${dryrun}`);
    const result = (await requestFromApi(url, {
      method: 'POST',
      body: JSON.stringify(request),
    })) as IMRSubstitutionResponse[];

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: result,
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}
