import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { GridApis } from '../../agGrid/gridTypes';
import { StyledSection } from '../../components/StyledSection';
import { Page } from '../../components/page/Page';
import { t } from '../../core/i18n/i18n';
import { minutesToMilliseconds } from '../../core/timeUtils';
import { AlertStatus } from '../../domain/alerts/model';
import { useAlertCount } from '../../domain/alerts/useAlertCount';
import { useAlertHash } from '../../domain/alerts/useAlertHash';

import AlertTable from './alertTable/AlertTable';

type AlertStatusLowerCase = 'active' | 'completed' | 'deactivated';

const Alerts = () => {
  const [grid, setGrid] = useState<GridApis | undefined>();
  const [alertStatusToDisplay, setAlertStatusToDisplay] = useState<AlertStatus>('ACTIVE');
  const alertCount = useAlertCount(alertStatusToDisplay);

  const alertHashFetcher = useAlertHash({
    refreshInterval: minutesToMilliseconds(5),
    revalidateOnFocus: true,
  });
  const [oldAlertHash, setOldAlertHash] = useState<string | undefined>();
  const snackbar = useSnackbar();

  const handleUpdateAlert = useCallback(() => {
    grid?.api.refreshServerSideStore();
    alertCount.refresh();
  }, [grid, alertCount]);
  const handleUpdateAlertWithOutRefreshToast = () => {
    setOldAlertHash(undefined);
    alertHashFetcher.refresh();
    handleUpdateAlert();
  };

  useEffect(() => {
    if (
      alertHashFetcher.alertHash != oldAlertHash &&
      !!oldAlertHash &&
      !!alertHashFetcher.alertHash
    ) {
      const action = (snackbarId: SnackbarKey) => (
        <>
          <Button
            onClick={() => {
              handleUpdateAlert();
              snackbar.closeSnackbar(snackbarId);
            }}
          >
            {t('alert.refresh', {})}
          </Button>
        </>
      );

      snackbar.enqueueSnackbar(t('alert.new_data', {}), { action });
    }
  }, [alertHashFetcher.alertHash, handleUpdateAlert, snackbar, oldAlertHash]);

  useEffect(() => {
    setOldAlertHash(alertHashFetcher.alertHash);
  }, [alertHashFetcher.alertHash]);

  let count = alertCount.count;
  if (count === undefined) {
    count = '';
  }
  const title = t(`alert.${alertStatusToDisplay.toLowerCase() as AlertStatusLowerCase}_alerts`, {
    count: count,
  });

  return (
    <Page title={t('tabbar.tasks.label', {})}>
      <StyledSection last={true} grow={true}>
        <Stack direction="row" spacing={2}>
          <StyledBox>
            <Typography variant="h6">{title}</Typography>
          </StyledBox>
          <Divider orientation="vertical" flexItem />
          <FormControl size="small">
            <InputLabel id="status-select-label">{t('alert.select.label', {})}</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={alertStatusToDisplay}
              label={t('alert.select.label', {})}
              onChange={(value) => {
                setOldAlertHash(undefined);
                setAlertStatusToDisplay(value.target.value as AlertStatus);
              }}
            >
              <MenuItem value={'ACTIVE'}>{t('alert.select.active', {})}</MenuItem>
              <MenuItem value={'COMPLETED'}>{t('alert.select.completed', {})}</MenuItem>
              <MenuItem value={'DEACTIVATED'}>{t('alert.select.deactivated', {})}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Divider sx={{ mt: 1 }} />
        <AlertTable
          onGridReady={setGrid}
          onUpdateAlert={handleUpdateAlertWithOutRefreshToast}
          alertStatus={alertStatusToDisplay}
        />
      </StyledSection>
    </Page>
  );
};

const StyledBox = styled(Box)`
  align-content: center;
  align-items: center;
  display: flex;
`;

export default Alerts;
