import {
  ColumnMovedEvent,
  ColumnState,
  ColumnVisibleEvent,
  DragStoppedEvent,
  FilterChangedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { GridReadyEvent } from '@ag-grid-community/core/dist/cjs/es5/events';
import { AgGridReactProps, AgReactUiProps } from '@ag-grid-community/react/lib/shared/interfaces';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { authenticatedFetch, requestFromApi } from '../core/requests/httpClient';

import { formatFilterModelForBackend } from './FilterModel';

export type ColumnSetting<COLUMN_KEYS extends string> = {
  colId: COLUMN_KEYS;
  visible: boolean;
  sort?: 'asc' | 'desc' | null;
  filterModel?: any;
};

export type ColumnDefinition<COLUMN_KEYS extends string> = {
  colId: COLUMN_KEYS;
  visible: boolean;
  alwaysVisible: boolean;
  sort?: 'asc' | 'desc' | null;
};

/**
 * Ensures the given column settings are valid regarding the given column definitions.
 * This removes unknown columns and adds missing ones.
 */
function ensureColumnSettingsValidity<
  COLUMN_KEYS extends string,
  COLDEF extends ColumnDefinition<COLUMN_KEYS>,
>(
  columnDefinitions: Readonly<Array<COLDEF>>,
  columnSettings: Array<ColumnSetting<COLUMN_KEYS>>,
): Array<ColumnSetting<COLUMN_KEYS> & COLDEF> {
  // map column definitions to object and populate order field
  const sortMap = Object.fromEntries(
    columnDefinitions.map((col, i) => [
      col.colId,
      {
        ...col,

        // set order so that columns that are not sorted by the user appear after those who are
        order: i + columnSettings.length,
      } as ColumnSetting<COLUMN_KEYS> & COLDEF & { order: number },
    ]),
  );

  // modify sortMap entries based on order and visibility of columnSettings
  columnSettings
    .filter((col) => sortMap[col.colId])
    .forEach((col, i) => {
      sortMap[col.colId].order = i;
      sortMap[col.colId].visible = sortMap[col.colId].alwaysVisible || col.visible;
      sortMap[col.colId].sort = col.sort;
      sortMap[col.colId].filterModel = col.filterModel;
    });

  // map object back to array and sort by order field
  return Object.values(sortMap).sort(
    (a, b) => (a.order > b.order ? 1 : 0) - (a.order < b.order ? 1 : 0),
  );
}

function useColumnSettings<
  COLUMN_KEYS extends string,
  COLDEF extends ColumnDefinition<COLUMN_KEYS>,
>(tableName: string, columnDefinitions: Readonly<Array<COLDEF>>) {
  const { data, mutate, error } = useSWR<Array<ColumnSetting<COLUMN_KEYS>> | null>(
    `user-settings/tables/${tableName}/columns`,
    requestFromApi,
    {
      // do not auto refresh as this might discard local changes
      revalidateOnFocus: false,
    },
  );

  const save = useCallback(
    async (settings: Array<ColumnSetting<COLUMN_KEYS>>) => {
      await authenticatedFetch(`user-settings/tables/${tableName}/columns`, {
        method: 'POST',
        body: JSON.stringify(settings),
      });

      await mutate(settings);
    },
    [mutate, tableName],
  );

  const saveFromAgGridEvent = useCallback(
    (
      event:
        | SortChangedEvent
        | ColumnMovedEvent
        | ColumnVisibleEvent
        | FilterChangedEvent
        | DragStoppedEvent,
    ) => {
      const rawFilterModel = event.api.getFilterModel();
      const filterModel = rawFilterModel && formatFilterModelForBackend(rawFilterModel);

      const columnState: ColumnSetting<COLUMN_KEYS>[] = event.columnApi
        .getColumnState()
        .map((col: ColumnState) => ({
          colId: col.colId as COLUMN_KEYS,
          visible: !col.hide,
          sort: col.sort,
          filterModel: filterModel && filterModel[col.colId],
        }));
      return save(columnState);
    },
    [save],
  );

  const columns: Array<ColumnSetting<string> & COLDEF> | undefined = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }

    return ensureColumnSettingsValidity(columnDefinitions, data || []);
  }, [data, columnDefinitions]);

  const storedFilterModel = useMemo(() => {
    return columns?.reduce(
      (obj, colSetting) => ({ ...obj, [colSetting.colId]: colSetting.filterModel }),
      {},
    );
  }, [columns]);

  const addAgGridEvents = useCallback(
    (agGridProps: AgGridReactProps | AgReactUiProps) => {
      const saveFromAgGridEventAndThenCall = <
        E extends
          | SortChangedEvent
          | ColumnMovedEvent
          | ColumnVisibleEvent
          | FilterChangedEvent
          | DragStoppedEvent,
      >(
        fn: ((event: E) => void) | undefined,
      ) => {
        return (e: E) => {
          saveFromAgGridEvent(e);
          if (fn) {
            fn(e);
          }
        };
      };
      return {
        ...agGridProps,
        onSortChanged: saveFromAgGridEventAndThenCall(agGridProps.onSortChanged),
        onColumnVisible: saveFromAgGridEventAndThenCall(agGridProps.onColumnVisible),
        onDragStopped: saveFromAgGridEventAndThenCall(agGridProps.onDragStopped),
        onFilterChanged: saveFromAgGridEventAndThenCall(agGridProps.onFilterChanged),
        onGridReady: (event: GridReadyEvent) => {
          // would be nice to have our filter model set before first rendering, but, apparently,
          // there is nothing earlier than onGridReady:
          // https://stackoverflow.com/questions/64426323/set-ag-filter-before-rows-are-rendered
          event.api.setFilterModel(storedFilterModel);
          event.api.redrawRows(); // force redraw, otherwise filtered rows sometimes will appear as blank rows

          if (agGridProps.onGridReady) {
            agGridProps.onGridReady(event);
          }
        },
      };
    },
    [saveFromAgGridEvent, storedFilterModel],
  );

  return useMemo(
    () => ({
      columns,
      error,
      save,
      refresh: mutate,
      saveFromAgGridEvent,
      addAgGridEvents,
    }),
    [columns, error, save, mutate, saveFromAgGridEvent, addAgGridEvents],
  );
}

export default useColumnSettings;
