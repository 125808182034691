import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { isValid, startOfDay } from 'date-fns';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ErrorHandlingDatePicker } from '../../../../../components/DatePicker';
import { t } from '../../../../../core/i18n/i18n';
import { StatusSpecificContentProps } from '../StatusSpecificContent';

const HeadlineSecondPart = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
` as typeof Typography;

const MAX_DATE = new Date(9999, 12, 31);

export default function PhaseInStatusSpecificContent({
  data,
  onDataChange,
  showValidation,
  setValid: setIsValid,
}: StatusSpecificContentProps) {
  const [isPhaseInDateSet, setIsPhaseInDateSet] = useState<boolean>(Boolean(data.autoSwitchDate));

  const handlePhaseInDateChange = (date: Date | null, _: string | undefined) => {
    if (!isValid(date) || !date) {
      setIsValid(false);
      setIsPhaseInDateSet(false);
      return;
    }

    if (date < startOfDay(Date.now())) {
      setIsValid(false);
      onDataChange({ ...data, autoSwitchDate: date });
      setIsPhaseInDateSet(true);
      return;
    }

    setIsValid(true);
    onDataChange({ ...data, autoSwitchDate: date });
    setIsPhaseInDateSet(true);
  };

  return (
    <>
      <HeadlineSecondPart component="h3" variant="subtitle2">
        {t('customer_material_portfolio.modal.subheader.phase_in', {})}
      </HeadlineSecondPart>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ErrorHandlingDatePicker
            maxDate={MAX_DATE}
            minDate={new Date(Date.now())}
            value={data.autoSwitchDate}
            onChange={handlePhaseInDateChange}
            label={t('customer_material_portfolio.modal.start', {})}
            errorMessages={
              !isValid(data.autoSwitchDate) ? [t('generic.validation.missing_fields', {})] : []
            }
            fullWidth
            validate={isPhaseInDateSet || showValidation}
          />
        </Grid>
      </Grid>
    </>
  );
}
