import React from 'react';

export const StyledGridSection = ({
  children,
  hidden,
  smallHeight,
}: {
  children: React.ReactNode;
  hidden?: boolean;
  smallHeight?: boolean;
}) => {
  return (
    <div
      style={{
        opacity: hidden ? 0 : 1,
        overflow: 'hidden',
        flexGrow: 1,
        minHeight: smallHeight ? 10 : 400,
      }}
    >
      <div className={'ag-theme-material'} style={{ height: '100%' }}>
        {children}
      </div>
    </div>
  );
};
