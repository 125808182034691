import { Autocomplete, AutocompleteProps, Chip, FormControl, TextField } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

import { OptionsLoadingResult } from '../../../core/hooks/useSelectableOptions';
import { t } from '../../../core/i18n/i18n';

import { SelectableValue } from './selectableValues';

// Don`t use AutocompleteMultiValueBaseProps here because value should be just
// SelectableValue[] here, the parent components should handle the processing
type MultiAutocompleteProps = {
  value: SelectableValue[];
  onValueChange: (event: unknown, values: SelectableValue[]) => void;
  inputValue: string;
  onInputValueChange: (event: SyntheticEvent, inputVal: string) => void;
  openDelayedAfterTyping?: number;
  muiProps?: Partial<AutocompleteProps<SelectableValue, true, false, false>>;
} & AutocompleteLabelProps &
  OptionsLoadingResult;

export type AutocompleteLabelProps = {
  autocompleteLabel: React.ReactNode;
  getOptionLabel?: (option: SelectableValue) => string;
  getOptionLabelInTag?: (option: SelectableValue) => string;
};

export type AutocompleteMultiValueBaseProps = {
  value: SelectableValue[] | string[] | undefined;
  onValueChange: (event: unknown, values: SelectableValue[]) => void;
  muiProps?: Partial<AutocompleteProps<SelectableValue, true, false, false>>;
};

const StyledChip = styled(Chip)`
  max-height: 22px;
`;

export const MultiAutocompleteBase = (props: MultiAutocompleteProps) => {
  const [open, setOpen] = useState(false);

  return (
    <FormControl fullWidth size={'small'}>
      <Autocomplete
        size={'small'}
        value={props.value}
        onChange={props.onValueChange}
        inputValue={props.inputValue}
        onInputChange={props.onInputValueChange}
        multiple
        forcePopupIcon={false}
        limitTags={2}
        open={
          props.openDelayedAfterTyping == undefined ||
          props.inputValue.length >= props.openDelayedAfterTyping
            ? open
            : false
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={
          props.getOptionLabel
            ? props.getOptionLabel
            : (opt: SelectableValue) => `${opt.id} - ${opt.text}`
        }
        options={props.options}
        loading={props.loading || !!props.loadingError}
        loadingText={
          props.loadingError ? props.loadingError : t('globalSelection.dropdown.loading', {})
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // Key comes from getTagProps
            // eslint-disable-next-line react/jsx-key
            <StyledChip
              variant="outlined"
              size="small"
              label={props.getOptionLabelInTag ? props.getOptionLabelInTag(option) : option.text}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} size={'small'} label={props.autocompleteLabel} />
        )}
        {...props.muiProps}
        filterOptions={(options, params) => {
          const cleanedInputIdValue = params.inputValue.toLowerCase().trimEnd().replaceAll('-', '');
          const cleanedInputTextValue = params.inputValue.toLowerCase().trimEnd();

          return options.filter((option) => {
            const cleanedId = option.id.toLowerCase().trimEnd().replaceAll('-', '');
            const cleanedText = option.text.toLowerCase().trimEnd();

            return (
              cleanedId.includes(cleanedInputIdValue) || cleanedText.includes(cleanedInputTextValue)
            );
          });
        }}
      />
    </FormControl>
  );
};
