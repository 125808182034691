import { Button, Grid, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/modal/Modal';
import { ModalHeadlineSeparator } from '../../../components/modal/ModalHeadline';
import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { saveCMPChange } from '../../../domain/customerMaterialPortfolio/saveCMPChange';
import { textVeryLightGrey } from '../../../styles/colors';
import { AdditionalCMPModalInfo } from '../table/CmpRowMenuButton';

import { CMPData } from './cmpModalStatusSpecificElements/cmpModalTypes';

type SchaefflerSuccessorModalProps = {
  open: boolean;
  cmpData: CMPData | null;
  additionalInfo: AdditionalCMPModalInfo | undefined;
  onClose: () => void;
  closeAndRefresh: () => void;
};

export default function SchaefflerSuccessorModal({
  open,
  cmpData,
  additionalInfo,
  onClose,
  closeAndRefresh,
}: SchaefflerSuccessorModalProps) {
  const customerSuccessor = cmpData?.successorMaterial ?? t('error.valueUnknown', {});
  const schaefflerSuccessor =
    additionalInfo?.divergentSchaefflerSuccessor ?? t('error.valueUnknown', {});
  const text = t('customer.material_portfolio.modal.change_to_schaeffler.text', {
    sucessor: customerSuccessor,
    schaefflerSuccessor: schaefflerSuccessor,
  });

  const save = async () => {
    if (
      cmpData?.customerNumber == null ||
      cmpData?.materialNumber == null ||
      !additionalInfo?.divergentSchaefflerSuccessor
    ) {
      enqueueSnackbar(t('generic.validation.check_inputs', {}), { variant: 'error' });
      return;
    }

    const cmpDataWitchChanges: CMPData = {
      ...cmpData,
      portfolioStatus: 'SI',
      successorMaterial: additionalInfo?.divergentSchaefflerSuccessor,
    };

    const postResult = await saveCMPChange(cmpDataWitchChanges, false, 'change-to-schaeffler');

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('generic.validation.save.success', {}),
    );
    enqueueSnackbar(userMessage.message, { variant: userMessage.variant });
    closeAndRefresh();
  };

  return (
    <>
      <Modal onClose={onClose} fullWidth open={open} maxWidth={'md'}>
        <Modal.Headline
          onClose={onClose}
          text={t('customer_material_portfolio.substitution_modal.headline', {})}
        ></Modal.Headline>
        <ModalHeadlineSeparator />
        <Modal.Body>
          <Typography variant={'body1'}>{text}</Typography>
          <TypographyHint variant={'body2'}>
            {t('customer.material_portfolio.modal.change_to_schaeffler.hint', {})}
          </TypographyHint>

          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button onClick={onClose}>{t('button.no', {})}</Button>
            </Grid>
            <Grid item>
              <Button onClick={save} autoFocus>
                {t('button.yes', {})}
              </Button>
            </Grid>
          </Grid>
        </Modal.Body>
      </Modal>
    </>
  );
}

const TypographyHint = styled(Typography)`
  font-size: 0.85rem;
  color: ${textVeryLightGrey};
  padding-top: 15px;
  padding-bottom: 10px;
`;
