import { DrawerProps, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';

import { secondaryColor, textDarkGrey } from '../../../styles/colors';
import { headerHeight } from '../Header';

export const SlideOut = styled.div<Partial<DrawerProps>>`
  position: absolute;
  z-index: ${(props) => props.theme.zIndex.appBar - 1};

  width: 306px;
  background: white;
  display: flex;
  flex-direction: column;
  top: ${headerHeight};
  bottom: 0;

  box-shadow: ${(props) => props.theme.shadows[16]};

  will-change: transform;
  transition: ${(props) =>
    props.theme.transitions.create(['transform'], {
      duration: props.open
        ? props.theme.transitions.duration.leavingScreen
        : props.theme.transitions.duration.enteringScreen,
      easing: props.theme.transitions.easing.easeInOut,
    })};
  transform: translateX(${(props) => (props.open ? '0' : '-360px')});
`;

export const BackButton = styled(IconButton)`
  margin-right: 27px;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 12px 8px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px;

  #current-user {
    font-weight: bold;
  }

  #current-user-department {
    color: rgba(0, 0, 0, 0.8);
  }
`;

export const UserSettings = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Version = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const VersionNumber = styled(Typography).attrs({ variant: 'subtitle2' })`
  color: ${secondaryColor};

  ::first-letter {
    color: ${textDarkGrey};
  }
`;
