import React, { useMemo } from 'react';
import useSWR from 'swr';

import { t } from '../../core/i18n/i18n';
import { requestFromApi } from '../../core/requests/httpClient';

import { UserData } from './model';

function getDefaultUserData(): UserData {
  return {
    givenName: t('error.no_given_name', {}),
    familyName: t('error.no_family_name', {}),
    department: t('error.no_department', {}),
    roles: [],
    successfullyLoaded: false,
  };
}

export const UserDataContext = React.createContext<UserData>({
  givenName: '',
  familyName: '',
  department: '',
  roles: [],
  successfullyLoaded: false,
});

export default function UserDataProvider({ children }: { children: React.ReactNode }) {
  const { data } = useSWR<UserData>('user/me', requestFromApi);

  const userData = useMemo(() => {
    const defaultUserData = getDefaultUserData();
    return {
      givenName: data?.givenName || defaultUserData.givenName,
      familyName: data?.familyName || defaultUserData.familyName,
      department: data?.department || defaultUserData.department,
      roles: data?.roles || defaultUserData.roles,
      successfullyLoaded: !!data,
    };
  }, [data]);

  return <UserDataContext.Provider value={userData}>{children}</UserDataContext.Provider>;
}
