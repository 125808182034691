import { VariantType } from 'notistack';

import { t } from './i18n/i18n';
import { messageFromSAP } from './sapLocalisation';

export type WriteResult = 'SUCCESS' | 'ERROR' | 'WARNING';

export type PostResult<T extends ResponseWithResultMessage> = {
  overallStatus: WriteResult;
  overallErrorMsg: string | null;
  response: T[];
};

export interface ResponseWithResultMessage {
  result: ResultMessage;
}

export interface ResultMessage {
  messageType: WriteResult;
  messageClass: string | null;
  messageNumber: number | null;
  fallbackMessage: string | null;
  param1: string | null;
  param2: string | null;
  param3: string | null;
  param4: string | null;
}

export type ToastResult = {
  variant: VariantType;
  message: string;
};

export function singlePostResultToUserMessage<T extends ResponseWithResultMessage>(
  result: PostResult<T>,
  specificErrorToTextFn: (resultMsg: ResultMessage) => string,
  successText: string,
): ToastResult {
  if (result.overallStatus == 'ERROR') {
    return { variant: 'error', message: result.overallErrorMsg || t('error.unknown', {}) };
  }

  // There should be only one response, but the function can handle more than one. If there is one error, the result will be error.
  let errorMsg: string | null = null;
  let warningMsg: string | null = null;

  result.response.forEach((resp) => {
    const currResult = resp.result;
    if (currResult.messageType == 'ERROR') {
      const currErrorMsg = specificErrorToTextFn(currResult);
      errorMsg = errorMsg ? errorMsg.concat(', ', currErrorMsg) : currErrorMsg;
    }

    if (currResult.messageType == 'WARNING') {
      const currWarnMsg = specificErrorToTextFn(currResult);
      warningMsg = warningMsg ? warningMsg.concat(', ', currWarnMsg) : currWarnMsg;
    }
  });

  if (errorMsg) return { variant: 'error', message: errorMsg };
  if (warningMsg) return { variant: 'warning', message: warningMsg };

  return { variant: 'success', message: successText };
}

export function multiPostResultsToUserMessages<T extends ResponseWithResultMessage>(
  result: PostResult<T>,
  getCountedSuccessString: (count: number) => string,
  getCountedErrorString: (count: number) => string,
  getCountedWarningString: (count: number) => string,
  additionalErrorCount?: number,
): ToastResult[] {
  if (result.overallStatus == 'ERROR') {
    return [{ variant: 'error', message: result.overallErrorMsg || t('error.unknown', {}) }];
  }

  const successCount = result.response.filter((msg) => msg.result.messageType == 'SUCCESS').length;
  const warningCount = result.response.filter((msg) => msg.result.messageType == 'WARNING').length;
  const errorCount =
    result.response.filter((msg) => msg.result.messageType == 'ERROR').length +
    (additionalErrorCount ?? 0);

  const messages: ToastResult[] = [];
  if (successCount > 0)
    messages.push({ variant: 'success', message: getCountedSuccessString(successCount) });
  if (warningCount > 0)
    messages.push({ variant: 'warning', message: getCountedWarningString(warningCount) });
  if (errorCount > 0)
    messages.push({ variant: 'error', message: getCountedErrorString(errorCount) });

  return messages;
}

export function errorsFromSAPtoMessage(resultMessage: ResultMessage): string {
  return messageFromSAP(
    resultMessage.fallbackMessage,
    resultMessage.messageNumber,
    resultMessage.messageClass,
    resultMessage.param1,
    resultMessage.param2,
    resultMessage.param3,
    resultMessage.param4,
  );
}
