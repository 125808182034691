import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useDomId from '../../core/hooks/useDomId';
import { OptionsLoadingResult } from '../../core/hooks/useSelectableOptions';
import { t } from '../../core/i18n/i18n';
import { errorColor } from '../../styles/colors';

import {
  mapToOptionsIfPossible,
  matchOptionIfPossible,
  SelectableValue,
} from './baseComponents/selectableValues';

const LessPaddedCheckbox = styled(Checkbox)`
  padding: 2px 10px 2px 0;
`;

export type FilterDropdownPropsBase<T> = {
  label: string;
  value?: T;
  onChange?: (event: unknown, value: T) => void;
  disabled?: boolean;
  tooltipText?: string;
  formatSelectedValue?: (value: SelectableValue) => string;
  formatOptionValue?: (value: SelectableValue) => string;
} & OptionsLoadingResult;

export type FilterDropdownPropsSingleSelect = FilterDropdownPropsBase<SelectableValue> & {
  multiSelect?: false;
};

export type FilterDropdownPropsMultiSelect = FilterDropdownPropsBase<SelectableValue[]> & {
  multiSelect: true;
};

export default function FilterDropdown({
  value,
  label,
  options,
  loading = false,
  loadingError = null,
  onChange = () => undefined,
  multiSelect = false,
  disabled = false,
  tooltipText = '',
  formatSelectedValue = (value) => value.id,
  formatOptionValue = (value: SelectableValue): string => {
    return value.id == value.text ? value.id : `${value.id} - ${value.text}`;
  },
}: FilterDropdownPropsSingleSelect | FilterDropdownPropsMultiSelect) {
  // mui does not automatically add IDs to Select components
  const domId = useDomId();
  const labelId = `${domId}-label`;

  const defaultValue = useMemo(
    () => (multiSelect ? [] : ({ id: '', text: '' } as SelectableValue)),
    [multiSelect],
  );

  const [filterValue, setFilterValue] = useState<string | string[]>(multiSelect ? [] : '');

  // update the visible value when the value is changed from outside
  useEffect(() => {
    if (!value) {
      setFilterValue(multiSelect ? [] : '');
      return;
    }

    const idFilter = Array.isArray(value) ? value.map((v) => v.id) : value.id;
    setFilterValue(idFilter);
  }, [value, defaultValue, multiSelect]);

  const handleChange = ({ target }: SelectChangeEvent<string | string[]>) => {
    // Do nothing if component is loading or has an error
    if (loading || loadingError) {
      return;
    }

    const newRaw = target.value;
    const newValue = Array.isArray(newRaw)
      ? mapToOptionsIfPossible(newRaw, options) || []
      : matchOptionIfPossible(newRaw, options) || { id: '', text: '' };

    // newValue has the correct type since the onChange of the Select component returns the correct
    // values based on the multiple prop
    onChange(undefined, newValue as any);
  };

  return (
    <>
      <Tooltip title={tooltipText} arrow>
        <FormControl fullWidth size={'small'}>
          <InputLabel htmlFor={domId} id={labelId}>
            {label}
          </InputLabel>
          <Select
            disabled={disabled}
            id={domId}
            labelId={labelId}
            multiple={multiSelect}
            value={filterValue}
            onChange={handleChange}
            renderValue={(selected) => {
              // Prevent showing the messages as selected input
              if (loading || loadingError) {
                return '';
              }

              const getSelectedValue = (val: string): SelectableValue => {
                const option = options.find((option) => option.id === val);
                return option || { id: val, text: val };
              };

              if (Array.isArray(selected)) {
                const selectedValues = selected.map(getSelectedValue);
                return selectedValues.map(formatSelectedValue).join(', ');
              } else {
                const selectedValue = getSelectedValue(selected);
                return formatSelectedValue(selectedValue);
              }
            }}
            label={label}
          >
            {renderSelectableOptions(
              options,
              formatOptionValue,
              loading,
              loadingError,
              filterValue,
            )}
          </Select>
        </FormControl>
      </Tooltip>
      <Box width="45px">{/* spacer for multiselect buttons */}</Box>
    </>
  );
}

function renderSelectableOptions(
  options: SelectableValue[],
  formatOptionValue: (value: SelectableValue) => string,
  loading: boolean,
  loadingError: string | null,
  filterValue: string | string[] | undefined,
) {
  if (loading) {
    return (
      <MenuItem>
        <ListItemText primary={t('globalSelection.dropdown.loading', {})} />
      </MenuItem>
    );
  } else if (loadingError) {
    return (
      <MenuItem>
        <ListItemText primary={loadingError} primaryTypographyProps={{ color: errorColor }} />
      </MenuItem>
    );
  }
  return options.map((entry, index) => {
    return (
      <MenuItem key={index} value={entry.id}>
        <LessPaddedCheckbox
          checked={
            Array.isArray(filterValue) ? filterValue.includes(entry.id) : filterValue == entry.id
          }
        />
        <ListItemText primary={formatOptionValue(entry)} />
      </MenuItem>
    );
  });
}
