import { useCallback } from 'react';

import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';
import { generateUrlWithSearchTerm, requestFromApi } from '../requests/httpClient';

export function useSelectableOptionsWithSearchParam(
  url: string,
): (searchTerm: string, signal: AbortSignal | null | undefined) => Promise<SelectableValue[]> {
  return useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(generateUrlWithSearchTerm(url, searchTerm), {
        signal,
      });
    },
    [url],
  );
}
