import { Add } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';

import {
  MultiselectFromClipboardModal,
  MultiselectFromClipboardProps,
} from './MultiselectFromClipboardModal';

export default function MultiselectFromClipboard(props: MultiselectFromClipboardProps) {
  const [open, setOpen] = useState(false);

  return (
    <Box width="45px">
      <IconButton disabled={props.optionsLoadingResult?.loading} onClick={() => setOpen(true)}>
        <Add />
      </IconButton>
      <MultiselectFromClipboardModal open={open} onClose={() => setOpen(false)} {...props} />
    </Box>
  );
}
