const columnDefinitions = [
  {
    colId: 'materialNumber',
    filter: undefined,
    filterParams: undefined,
    width: 170,
  },
  {
    colId: 'materialDescription',
    width: 180,
  },
  {
    colId: 'customerMaterialNumber',
    cellRenderer: 'customerMaterialNumberCellRenderer',
    width: 160,
  },
  {
    colId: 'materialClassification',
    width: 80,
  },
  {
    colId: 'demandPlanValue',
    cellRenderer: 'numberRenderer',
    width: 120,
  },
] as const;

export default columnDefinitions;
