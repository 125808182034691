import * as React from 'react';
import styled from 'styled-components';

import { t } from '../../core/i18n/i18n';

const CookieBtn = styled.button`
  margin: 20px 0;
`;

export const CookiePolicyEN = () => {
  return (
    <>
      <h3>Cookie Policy</h3>
      <h5>Notice on the use of cookies</h5>
      <p>
        When you visit one of the websites of Schaeffler AG and companies in which Schaeffler AG,
        directly or indirectly, holds a majority interest (Schaeffler Group), information will be
        stored on your terminal device in the form of a so-called “cookie”. Cookies are small text
        files which enable analysis of the use of the website, record settings and data in your
        browser, as well as allow customizing website to your interests and displaying
        interest-based advertising on other websites. We do not collect any personal data by means
        of cookies unless you have expressly given us your consent to do so.
      </p>

      <div>
        <CookieBtn id="ot-sdk-btn" className="ot-sdk-show-settings">
          {t('cookie_policy_page.open_settings_button', {})}
        </CookieBtn>
      </div>

      <div id="ot-sdk-cookie-policy"></div>
    </>
  );
};
