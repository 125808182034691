import React, { useMemo, useState } from 'react';

import { OptionsLoadingResult } from '../../../core/hooks/useSelectableOptions';
import {
  MultiAutocompleteBase,
  AutocompleteLabelProps,
  AutocompleteMultiValueBaseProps,
} from '../baseComponents/MultiAutocompleteBase';
import { SelectableValue, mapToOptionsIfPossible } from '../baseComponents/selectableValues';

type MultiAutocompletePreLoadedProps = {
  optionsLoadingResult: OptionsLoadingResult;
} & AutocompleteMultiValueBaseProps &
  AutocompleteLabelProps;

export const MultiAutocompletePreLoaded = (props: MultiAutocompletePreLoadedProps) => {
  const [inputValue, setInputValue] = useState('');
  const { value, optionsLoadingResult } = props;

  const processedValue: SelectableValue[] = useMemo(
    () => mapToOptionsIfPossible(value, optionsLoadingResult.options),
    [value, optionsLoadingResult.options],
  );

  function onInputValueChange(_: unknown, inputValue: string) {
    setInputValue(inputValue);
  }

  return (
    <MultiAutocompleteBase
      autocompleteLabel={props.autocompleteLabel}
      value={processedValue}
      onValueChange={props.onValueChange}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      options={props.optionsLoadingResult.options}
      loading={props.optionsLoadingResult.loading}
      loadingError={props.optionsLoadingResult.loadingError}
      getOptionLabel={props.getOptionLabel}
      getOptionLabelInTag={props.getOptionLabelInTag}
      muiProps={{ forcePopupIcon: true, ...props.muiProps }}
    />
  );
};
