import { ITooltipParams } from '@ag-grid-community/core';
import React from 'react';

import { messageFromSAP } from '../../core/sapLocalisation';

import { TooltipText, TooltipWrapper } from './GridTooltip';

export const TrafficLightTooltip = (props: ITooltipParams) => {
  const fallbackMessage = props.value;

  // It is very important to use props.node.data not props.data
  // because the data in the latter is not up to date and may return values from old renderings
  const message = messageFromSAP(
    fallbackMessage,
    props.node?.data.tlMessageNumber,
    props.node?.data.tlMessageId,
    props.node?.data.tlMessageV1,
    props.node?.data.tlMessageV2,
    props.node?.data.tlMessageV3,
    props.node?.data.tlMessageV4,
  );

  // Show no tooltip if there is no message
  const tooltipMessage: string | null = !!message && message != '' ? message : null;

  return (
    <>
      {tooltipMessage !== null ? (
        <TooltipWrapper>
          <TooltipText>{tooltipMessage}</TooltipText>
        </TooltipWrapper>
      ) : (
        <></>
      )}
    </>
  );
};
