import { requestFromApi } from '../../core/requests/httpClient';

export function fetchMaterialData(selectedIds: string[]) {
  const swrParams = getSwrMaterialParams(selectedIds);
  return requestFromApi(...swrParams);
}

function getSwrMaterialParams(data: string[]): [string, RequestInit] {
  return [
    'material-customer/materials',
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  ];
}
