import {
  ICellRendererParams,
  ISetFilterParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';

import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { t } from '../../../core/i18n/i18n';
import { AlertCategory } from '../../../domain/alerts/model';
import { materialClassifications } from '../../../domain/materialCustomer/model';

import { CommentTooltip } from './CommentTooltip';

export function getAlertTypeFilterParams(alertTypes: SelectableValue[]): ISetFilterParams {
  // values can only be strings for the filter so we use this function to get the texts back for the user view
  const findText = (alertId: string) => {
    const text = alertTypes.find((alertType) => alertType.id == alertId)?.text;
    return text ? text : alertId;
  };

  return {
    values: alertTypes.map((v) => v.id),
    valueFormatter: (params: ValueFormatterParams) => findText(params.value),
    showTooltips: true,
  };
}

export const getAlertTableColumnDefinitions = (alertFilterParams: ISetFilterParams) =>
  [
    {
      property: 'customerNumber',
      colId: 'alert.customer_number.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'customerName',
      colId: 'alert.customer_name.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'materialNumber',
      colId: 'alert.material_number.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'materialDescription',
      colId: 'alert.material_description.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'type',
      colId: 'alert.category.column_header',
      cellRenderer: (params: ICellRendererParams) => {
        // Try to find translation, use id as fallback (possible for new alert types)
        return t(`alert.category.${params.value as AlertCategory}`, {}, params.value);
      },
      type: undefined,
      flex: 1,
      minWidth: 200,
      maxWidth: undefined,
      filter: 'agSetColumnFilter',
      filterParams: alertFilterParams,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'materialClassification',
      colId: 'alert.material_classification.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: materialClassifications,
      },
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'customerMaterialNumber',
      colId: 'alert.customer_material_number.column_header',
      cellRenderer: 'customerMaterialNumberCellRenderer',
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agTextColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'createdAt',
      colId: 'alert.report_date.column_header',
      cellRenderer: 'dateRenderer',
      type: 'rightAligned',
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: undefined,
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'dueDate',
      colId: 'alert.due_date.column_header',
      cellRenderer: 'dateRenderer',
      type: 'rightAligned',
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agDateColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'comment',
      colId: 'alert.comment.column_header',
      cellRenderer: 'undefined',
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: 375,
      filter: undefined,
      filterParams: undefined,
      sortable: false,
      tooltipComponent: CommentTooltip,
      tooltipField: 'comment',
    },
    {
      property: 'thresholdDeviation',
      colId: 'alert.thresholdDeviation.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'threshold1',
      colId: 'alert.threshold1.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'threshold1Description',
      colId: 'alert.threshold1Description.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: undefined,
      filterParams: undefined,
      sortable: false,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'threshold2',
      colId: 'alert.threshold2.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'threshold2Description',
      colId: 'alert.threshold2Description.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: undefined,
      filterParams: undefined,
      sortable: false,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'threshold3',
      colId: 'alert.threshold3.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: 'agNumberColumnFilter',
      filterParams: undefined,
      sortable: true,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
    {
      property: 'threshold3Description',
      colId: 'alert.threshold3Description.column_header',
      cellRenderer: undefined,
      type: undefined,
      flex: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      filter: undefined,
      filterParams: undefined,
      sortable: false,
      tooltipComponent: undefined,
      tooltipField: undefined,
    },
  ] as const;
