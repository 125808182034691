import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { Box, Button, Paper, Popover, Stack, Typography } from '@mui/material';
import { addMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ActionButton } from '../../../components/ActionButton';
import { DateRange } from '../../../core/dateRange';
import { t } from '../../../core/i18n/i18n';
import { firstViewableDate, lastViewableDate } from '../../../domain/demandValidation/limits';
import { KpiDateRanges } from '../../../domain/demandValidation/model';
import { saveLocalStorageTimeRange } from '../../../domain/demandValidation/timeRange';
import { DemandValidationDatePicker, fillGapBetweenRanges } from '../DemandValidationDatePicker';

const Wrapper = styled.div`
  padding: 20px 0;
`;

export type DatePickerSettingDVProps = {
  dateRange: KpiDateRanges;
  changeDateRangeConsideringUnSavedChanges: (range: KpiDateRanges) => void;
};

export default function DatePickerSettingDemandValidation({
  dateRange,
  changeDateRangeConsideringUnSavedChanges,
}: DatePickerSettingDVProps) {
  const [dateRange1, setDateRange1] = useState<Partial<DateRange>>(dateRange.range1);
  const [dateRange2, setDateRange2] = useState<Partial<DateRange> | undefined>(dateRange.range2);
  const [dateRangeError, setDateRangeError] = React.useState(false);

  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setDateRange1(dateRange.range1);
    setDateRange2(
      dateRange.range2 || {
        from: addMonths(dateRange.range1.to, 1),
        period: 'MONTHLY',
      },
    );
  }, [dateRange]);

  return (
    <>
      <ActionButton
        onClick={({ currentTarget }) => setDatePickerAnchorEl(currentTarget)}
        variant={'text'}
        color={'secondary'}
      >
        <DateRangeIcon />
      </ActionButton>
      <Popover
        open={Boolean(datePickerAnchorEl)}
        anchorEl={datePickerAnchorEl}
        onClose={() => setDatePickerAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: '800px' },
        }}
      >
        <Paper>
          <Stack padding={'24px'} direction="column">
            <Typography padding={0} variant="h6">
              {t('validation_of_demand.date_picker.title', {})}
            </Typography>

            <Typography variant={'subtitle2'}>
              {t('validation_of_demand.date_picker.sub_title', {})}
            </Typography>
            <Wrapper>
              <DemandValidationDatePicker
                dateRange1={dateRange1}
                onDateRange1Change={(value) => setDateRange1(value)}
                dateRange2={dateRange2}
                onDateRange2Change={(value) => setDateRange2(value)}
                onDateRangeErrorChange={setDateRangeError}
                minDate={firstViewableDate()}
                maxDate={lastViewableDate()}
              />
            </Wrapper>
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                size="large"
                disabled={dateRangeError}
                onClick={() => {
                  setDatePickerAnchorEl(null);

                  const filledRange = fillGapBetweenRanges(dateRange1, dateRange2);
                  if (filledRange) {
                    saveLocalStorageTimeRange(dateRange1, dateRange2);
                    changeDateRangeConsideringUnSavedChanges(filledRange);
                  }
                }}
              >
                {t('button.save', {})}
              </Button>
            </Box>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
}
