import { useEffect, useState } from 'react';

import { GridApis } from '../../../agGrid/gridTypes';
import { getColumnSettingsFromGrid, applyColumnSettings } from '../../../agGrid/gridUtils';
import useColumnSettings, { ColumnSetting } from '../../../agGrid/useColumnSettings';

import { LayoutId, LAYOUT_IDS } from './HomeTableToolbar';
import columnDefinitions, { ColId, CustomerMaterialColumnDefinition } from './columnDefinitions';

const defaultColumnSettings: Array<ColumnSetting<ColId>> = columnDefinitions.map(
  ({ colId, visible }) => ({
    colId,
    visible,
  }),
);

const LOCALSTORAGE_LAYOUT = 'material-customer-selected-layout';

function getStoredLayout() {
  const storedLayout = localStorage.getItem(LOCALSTORAGE_LAYOUT);
  if (storedLayout && LAYOUT_IDS.includes(storedLayout as LayoutId)) {
    return storedLayout as LayoutId;
  } else {
    return null;
  }
}

function storeLayout(newLayout: LayoutId) {
  localStorage.setItem(LOCALSTORAGE_LAYOUT, newLayout);
}

function useMaterialCustomerColumnLayouts(grid: GridApis | undefined) {
  const [currentLayoutId, setCurrentLayoutId] = useState<LayoutId>(getStoredLayout() || '1');
  const [initialColumns, setInitialColumns] = useState<Readonly<
    Array<CustomerMaterialColumnDefinition>
  > | null>(null);

  // we use two "table names" to store the two layouts for this one table
  const layouts = {
    '1': useColumnSettings<ColId, CustomerMaterialColumnDefinition>(
      `material-customer-1`,
      columnDefinitions,
    ),
    '2': useColumnSettings<ColId, CustomerMaterialColumnDefinition>(
      `material-customer-2`,
      columnDefinitions,
    ),
  };
  const currentLayout = layouts[currentLayoutId];

  // apply column settings
  useEffect(() => {
    if (!grid) return;

    if (grid.columnApi && currentLayout.columns) {
      if (!initialColumns) {
        setInitialColumns(currentLayout.columns);
      } else {
        applyColumnSettings(grid.columnApi, currentLayout.columns);
      }
    }
  }, [grid, currentLayout, initialColumns]);

  // sync selected layout to localStorage
  useEffect(() => {
    storeLayout(currentLayoutId);
  }, [currentLayoutId]);

  return {
    resetLayout: async (newLayoutId: LayoutId) => {
      const newLayout = layouts[newLayoutId];

      if (grid && newLayout.columns) {
        // The save below does not necessarily overwrite the current columns, since we only
        // trigger the corresponding hooks when the fetched data differs. So we also apply the
        // cached layout.
        applyColumnSettings(grid.columnApi, newLayout.columns || []);
      }
      await newLayout.save(defaultColumnSettings);
      setCurrentLayoutId(newLayoutId);
    },
    saveLayout: async (newLayout: LayoutId) => {
      if (grid) {
        await layouts[newLayout].save(getColumnSettingsFromGrid<ColId>(grid.columnApi));
        setCurrentLayoutId(newLayout);
      }
    },
    loadLayout: async (newLayoutId: LayoutId) => {
      if (grid) {
        const newLayout = layouts[newLayoutId];

        if (newLayout.columns) {
          // The refresh below does not necessarily overwrite the current columns, since we only
          // trigger the corresponding hooks when the fetched data differs. So we also apply the
          // cached layout.
          applyColumnSettings(grid.columnApi, newLayout.columns);
        }
        // refresh to get potential changes from server
        await newLayout.refresh();
        setCurrentLayoutId(newLayoutId);
      }
    },
    currentLayoutId,
    initialColumns,
  };
}

export default useMaterialCustomerColumnLayouts;
