import * as React from 'react';

import { StyledSection } from '../../components/StyledSection';
import { Page } from '../../components/page/Page';
import { getPreferredLanguage, t } from '../../core/i18n/i18n';

import { PrivacyPolicyDE } from './PrivacyPolicyDE';
import { PrivacyPolicyEN } from './PrivacyPolicyEN';
import { PrivacyPolicyES } from './PrivacyPolicyES';
import { PrivacyPolicyFR } from './PrivacyPolicyFR';
import { PrivacyPolicyIT } from './PrivacyPolicyIT';

const privacyPolicy = {
  debug: <PrivacyPolicyDE />, // just use default language when displaying raw keys
  de: <PrivacyPolicyDE />,
  en: <PrivacyPolicyEN />,
  es: <PrivacyPolicyES />,
  fr: <PrivacyPolicyFR />,
  it: <PrivacyPolicyIT />,
  ptBr: <PrivacyPolicyEN />, // TODO Missing translation: Add portuguese (brazilian) version
  zhCn: <PrivacyPolicyEN />, // TODO Missing translation: Add chinese version
};

export const PrivacyPolicy = () => {
  const currentLang = getPreferredLanguage();

  return (
    <Page title={t('privacy_policy_page.headline', {})}>
      <StyledSection>{privacyPolicy[currentLang]}</StyledSection>
    </Page>
  );
};
