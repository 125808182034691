import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select/Select';
import React, { ReactNode } from 'react';

import useDomId from '../core/hooks/useDomId';

import { errorHelperTextProps } from './FieldError';

const NULL_VALUE = '' as const;
type NULL_VALUE_TYPE = typeof NULL_VALUE;

export type Option<T> = {
  key: T | NULL_VALUE_TYPE;
  displayValue: ReactNode;
};

export type SimpleSelectArgs<T> = Omit<
  Omit<Omit<SelectProps<T | NULL_VALUE_TYPE>, 'onChange'>, 'value'>,
  'displayEmpty'
> & {
  errorMessages?: Array<string>;
  value: T | null;
  onChange?: (value: T | null) => void;
  options: Array<Option<T>>;
};

/**
 * material-ui select that
 *  <li>nicely renders options from a simple list of strings
 *  <li>converts null (no selection) to empty string and back
 *  <li>renders error state and displays error messages below the input if error messages are given
 *  <li>uses 'null' for empty selection
 */
export const ErrorHandlingSelect = <T,>({
  label,
  value,
  onChange,
  options,
  errorMessages = [],
  ...rest
}: SimpleSelectArgs<T>) => {
  const errMsgProps = errorHelperTextProps(errorMessages);
  const selectId = useDomId();
  return (
    <FormControl fullWidth size={'small'}>
      <InputLabel id={selectId} error={errMsgProps.error}>
        {label}
      </InputLabel>
      <Select<T | NULL_VALUE_TYPE>
        labelId={selectId}
        label={label}
        value={value || NULL_VALUE}
        {...rest}
        error={errMsgProps.error}
        onChange={React.useCallback(
          (e: SelectChangeEvent<T | NULL_VALUE_TYPE>) => {
            if (onChange) {
              const value = e.target.value as T | NULL_VALUE_TYPE;
              onChange(value === NULL_VALUE ? null : value);
            }
          },
          [onChange],
        )}
      >
        {options.map((opt) => (
          <MenuItem value={'' + opt.key} key={`listItem_${opt.key}`}>
            {opt.displayValue}
          </MenuItem>
        ))}
      </Select>
      {errorMessages && errorMessages.length > 0 && (
        <FormHelperText component={'div'} error={true}>
          {errMsgProps.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
