import {
  ColDef,
  GridSizeChangedEvent,
  IRowModel,
  IRowNode,
  ProcessDataFromClipboardParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Check, Close } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { ProviderContext, useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { clientSideTableDefaultProps } from '../../agGrid/gridDefaults';
import { ensureEmptyRowAtBottom, resetGrid } from '../../agGrid/gridUtils';
import { parseFromClipboard } from '../../agGrid/parseFromClipboard';
import {
  PostResult,
  ResponseWithResultMessage,
  multiPostResultsToUserMessages,
} from '../../core/errorhandling';
import { t } from '../../core/i18n/i18n';
import { combineParseFunctionsForFields } from '../../core/parseValues';
import { textLight } from '../../styles/colors';
import { ActionButton } from '../ActionButton';
import { StyledGridSection } from '../StyledGridSection';
import { DeleteButtonCellRenderer } from '../agGrid/cellRenderers/DeleteButtonCellRenderer';
import { rowIsEmpty, buildValidationProps } from '../agGrid/validatationFunctions';
import { LoadingSpinnerModal } from '../loadingSpinner/LoadingSpinnerDialog';
import Modal from '../modal/Modal';

import { isPartialDataMatching } from './tableUploadsHelperFunctions';

export type TableUploadModalProps<DATA, RES extends ResponseWithResultMessage> = {
  title: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  onAdded?: () => void;
  applyFunction: (data: DATA[], dryRun: boolean) => Promise<PostResult<RES>>;
  parseErrorsFromResult: (res: PostResult<RES>) => ErrorMessage<DATA>[];
  columnDefinitions: ColumnForUploadTable<DATA>[];
  checkDataForErrors: (data: DATA[]) => ErrorMessage<DATA>[];
  specialParseFunctionsForFields?: Map<keyof DATA, (value: string) => string>;
  wideModal?: boolean;
  maxRows?: number;
  modalMode?: ModalMode;
};

export enum ModalMode {
  SAVE = 'save',
  DElETE = 'delete',
}

export type ColumnForUploadTable<DATA> = {
  // t functions in constants are not working properly, so use a function here that the TableUpload component can call
  headerNameFn: () => string;
  field: keyof DATA;
  editable: true;
  validationFn?: (value: string, rowData: IRowNode) => string | null | undefined;
} & Partial<ColDef<DATA>>;

export type ErrorMessage<DATA> = {
  dataIdentifier: Partial<DATA>;
  specificField?: keyof DATA;
  errorMessage: string;
};

export function TableUploadModal<DATA, RES extends ResponseWithResultMessage>(
  props: TableUploadModalProps<DATA, RES>,
) {
  const {
    title,
    description,
    open,
    onClose,
    applyFunction,
    parseErrorsFromResult,
    columnDefinitions,
    checkDataForErrors,
    specialParseFunctionsForFields,
    onAdded,
    wideModal,
    maxRows,
    modalMode,
  } = props;

  const gridRef = useRef<AgGridReact | null>(null);
  const [loading, setLoading] = useState(false);
  const [backendErrorMessages, setBackendErrorMessages] = useState<ErrorMessage<DATA>[]>([]);
  const [frontendErrorMessages, setFrontendErrorMessages] = useState<ErrorMessage<DATA>[]>([]);
  const snackbar = useSnackbar();

  // Combination of the validation of the value itself with other errors
  // Returning a string from a validation function means error
  const validateFunctionWithErrors = useCallback(
    (valFn?: (value: string, rowData: IRowNode) => string | null | undefined) =>
      (value: string, rowData: IRowNode, colId: string | undefined): string | null | undefined => {
        // An error in validating the value itself is more important than a row error
        // We want to have errors on the fields but we don't want to this checks with empty values
        if (valFn && value) {
          const resultOfValidationFunc = valFn(value, rowData);
          if (resultOfValidationFunc) {
            return resultOfValidationFunc;
          }
        }

        // Check the errors from backend and frontent for errors for this field or row
        const allErrorMsg = backendErrorMessages.concat(frontendErrorMessages);
        if (allErrorMsg.length == 0) return undefined;

        const matchingErrorsForThisRow = allErrorMsg.filter((errMsg) =>
          isPartialDataMatching(errMsg.dataIdentifier, rowData.data),
        );
        if (matchingErrorsForThisRow.length == 0) return undefined;

        // Check if there is a specific error for this field in this row (identified through row id and column id)
        const errorMsgForThisField = matchingErrorsForThisRow.find(
          (errMsg) => errMsg.specificField && colId && errMsg.specificField == colId,
        );
        if (errorMsgForThisField) return errorMsgForThisField.errorMessage;
        // Check for errors for this complete row, that means errors for this row with no specific field.
        // Also count an error as row error if we get no colId because we cannot be sure of it
        const errorMsgForThisRow = matchingErrorsForThisRow.find(
          (errMsg) => !errMsg.specificField || !colId,
        );
        return errorMsgForThisRow?.errorMessage;
      },
    [backendErrorMessages, frontendErrorMessages],
  );

  const columns: ColDef[] = useMemo(() => {
    const customCols = columnDefinitions.map((colDef) => {
      return {
        ...colDef,
        field: colDef.field.toString(),
        headerName: colDef.headerNameFn(),
        minWidth: colDef.minWidth ?? 200,
        validationFn: undefined,
        ...buildValidationProps(validateFunctionWithErrors(colDef.validationFn), true, textLight),
      };
    });
    return [...customCols, deleteColumn];
  }, [columnDefinitions, validateFunctionWithErrors]);

  const applyUpload = async (dryRun: boolean) => {
    setBackendErrorMessages([]);
    setFrontendErrorMessages([]);
    const rowModel = gridRef.current?.api.getModel();
    if (!rowModel) return;

    const data: DATA[] = dataFromRowModel(rowModel);

    if (checkForNoRows(data, snackbar)) return;
    if (checkForTooManyRows(rowModel, snackbar, maxRows)) return;

    setLoading(true);
    const action = dryRun ? 'check' : 'save';
    try {
      const errorsFromValidation = getValidationErrors(rowModel, columnDefinitions);

      const errorsFromDataCheck = checkDataForErrors(data);
      setFrontendErrorMessages(errorsFromDataCheck);

      // The desired behavior is sending all valid rows to backend while ignoring the invalid rows
      const allErrorsFromValidations = errorsFromValidation.concat(errorsFromDataCheck);
      const validData = data.filter((row) => {
        const matchingError = allErrorsFromValidations.find((error) =>
          isPartialDataMatching(error.dataIdentifier, row),
        );
        return matchingError == undefined;
      });
      const errorRowCount = countErrorRows(allErrorsFromValidations);

      if (validData.length == 0) {
        snackbar.enqueueSnackbar(getErrorWarningMessageFn(action)(errorRowCount), {
          variant: 'error',
        });
        setLoading(false);
        return;
      }

      const postResult = await applyFunction(validData, dryRun);

      // Set error messages to show them in grid
      setBackendErrorMessages(parseErrorsFromResult(postResult));

      const userMessages = multiPostResultsToUserMessages(
        postResult,
        getSuccessMessageFn(action),
        getErrorWarningMessageFn(action),
        getErrorWarningMessageFn(action),
        errorRowCount,
      );

      userMessages.forEach((msg) =>
        snackbar.enqueueSnackbar(msg.message, { variant: msg.variant }),
      );

      if (
        userMessages.length == 1 &&
        (userMessages[0].variant == 'success' || userMessages[0].variant == 'warning') &&
        onAdded
      )
        if (!dryRun) {
          onAdded();
        }
    } finally {
      setLoading(false);
    }
  };

  const resetModal = () => {
    setFrontendErrorMessages([]);
    setBackendErrorMessages([]);
    resetGrid(gridRef);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth={wideModal ? false : 'md'}
      fullWidth={wideModal ?? false}
    >
      <LoadingSpinnerModal open={loading} />

      <Modal.Headline text={title} onClose={onClose}>
        <ActionButton color={'secondary'} name={t('button.reset', {})} onClick={resetModal}>
          <Close />
        </ActionButton>
        <ActionButton
          color={'secondary'}
          name={t('button.validate', {})}
          onClick={() => applyUpload(true)}
        >
          <Check />
        </ActionButton>
        <Button
          variant="contained"
          size="large"
          color={!modalMode || modalMode == ModalMode.SAVE ? 'primary' : 'error'}
          onClick={() => {
            applyUpload(false);
          }}
        >
          {!modalMode || modalMode == ModalMode.SAVE
            ? t('button.save', {})
            : t('button.delete', {})}
        </Button>
      </Modal.Headline>

      {description && <Modal.SubHeadline text={description} />}

      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledGridSection smallHeight>
            <AgGridReactStyled
              {...clientSideTableDefaultProps}
              ref={gridRef}
              domLayout={'autoHeight'}
              defaultColDef={{
                suppressMenu: true,
              }}
              onCellEditingStopped={() => {
                ensureEmptyRowAtBottom(gridRef);
              }}
              onRowDataUpdated={() => {
                ensureEmptyRowAtBottom(gridRef);
              }}
              suppressRowHoverHighlight={true}
              tooltipShowDelay={500}
              components={{
                deleteButton: DeleteButtonCellRenderer,
              }}
              onGridReady={(e: any) => {
                e.api.applyTransaction({ add: [{}] });
                e.api.sizeColumnsToFit();
                e.api.addEventListener('gridSizeChanged', (event: GridSizeChangedEvent) => {
                  event.api.sizeColumnsToFit();
                });
              }}
              processDataFromClipboard={(params: ProcessDataFromClipboardParams) =>
                parseFromClipboard(
                  gridRef,
                  params.data,
                  combineParseFunctionsForFields(specialParseFunctionsForFields),
                )
              }
              columnDefs={columns}
            />
          </StyledGridSection>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// We need to adapt the css of the ag grid rich selection, to fix the size. Otherwise, the popup is too big.
export const AgGridReactStyled = styled(AgGridReact)`
  div.ag-rich-select .ag-rich-select-list {
    width: 100%;
    min-width: 200px;
    height: 100% !important;
  }
`;

function getValidationErrors<DATA>(
  rowModel: IRowModel,
  columnDefinitions: readonly ColumnForUploadTable<DATA>[],
): ErrorMessage<DATA>[] {
  const errors: ErrorMessage<DATA>[] = [];
  columnDefinitions.forEach((def) => {
    const validationFnColumn = def.validationFn;
    if (validationFnColumn) {
      rowModel.forEachNode((row) => {
        const data = row.data;
        if (def.field && Object.hasOwn(data, def.field) && data[def.field]) {
          const resultOfValidationFunc = validationFnColumn(data[def.field], row);
          if (resultOfValidationFunc) {
            errors.push({
              dataIdentifier: data,
              specificField: def.field,
              errorMessage: resultOfValidationFunc,
            });
          }
        }
      });
    }
  });
  return errors;
}

function checkForTooManyRows(
  rowModel: IRowModel,
  snackbar: ProviderContext,
  maxRows?: number,
): boolean {
  // More than max rows (default 200 rows) are not allowed because we would wait to long,
  //  one row is always empty at the end.
  const maxAllowedRows = maxRows ?? 200;

  if (rowModel.getRowCount() > maxAllowedRows + 1) {
    snackbar.enqueueSnackbar(
      t('generic.validation.upload.too_many_entries', { maxRows: maxAllowedRows }),
    );
    return true;
  }
  return false;
}

function checkForNoRows<DATA>(data: DATA[], snackbar: ProviderContext): boolean {
  // Check the data not the rows because rows can be empty
  if (data.length < 1) {
    snackbar.enqueueSnackbar(t('generic.validation.upload.no_entries', {}));
    return true;
  }
  return false;
}

function dataFromRowModel<DATA>(rowModel: IRowModel): DATA[] {
  const rowData: DATA[] = [];
  rowModel.forEachNode((row) => {
    if (rowIsEmpty(row)) return;
    rowData.push(row.data as DATA);
  });
  return rowData;
}

function countErrorRows<DATA>(errors: ErrorMessage<DATA>[]): number {
  const rowsIdentifiersWithErrors: Partial<DATA>[] = [];
  errors.forEach((err) => {
    const foundIdentifier = rowsIdentifiersWithErrors.find((rowIdentifier) =>
      isPartialDataMatching(rowIdentifier, err.dataIdentifier),
    );
    if (foundIdentifier == undefined) rowsIdentifiersWithErrors.push(err.dataIdentifier);
  });
  return rowsIdentifiersWithErrors.length;
}

const getErrorWarningMessageFn = (action: 'save' | 'check') => (count: number) =>
  t(`generic.validation.upload.${action}.error`, {
    count: count,
  });

const getSuccessMessageFn = (action: 'save' | 'check') => (count: number) =>
  t(`generic.validation.upload.${action}.success`, {
    count: count,
  });

const deleteColumn: ColDef = {
  field: 'DELETE',
  headerName: '',
  cellRenderer: DeleteButtonCellRenderer,
  minWidth: 68,
  maxWidth: 68,
} as ColDef;
