import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { deleteSingleAlterRule } from '../../../domain/alertrules/alertRuleActions';
import { AlertRule } from '../../../domain/alertrules/model';

type DeleteRuleDialogProps = {
  open: boolean;
  onClose: () => void;
  alertRule: AlertRule;
};

export default function DeleteRuleDialog({ open, onClose, alertRule }: DeleteRuleDialogProps) {
  const deleteEntry = async () => {
    if (!alertRule) return;

    const postResult = await deleteSingleAlterRule(alertRule);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('alert_rules.action_menu_deleted', {}),
    );

    enqueueSnackbar(userMessage.message, { variant: userMessage.variant });

    if (userMessage.variant !== 'error') {
      onClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>{t('alert_rules.delete_modal.confirmation_text', {})}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('button.no', {})}</Button>
        <Button onClick={deleteEntry} autoFocus>
          {t('button.yes', {})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
