import { Typography } from '@mui/material';
import * as React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { t } from '../../core/i18n/i18n';
import { secondaryColor } from '../../styles/colors';

const Outer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 32px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.1),
    0px 1px 5px 0px rgba(0, 0, 0, 0.08);
`;

const LinkStyle = css`
  position: relative;
  text-decoration: none;
  color: ${secondaryColor};

  &:not(:last-child) {
    margin-right: 16px;

    &:after {
      position: absolute;
      content: '';
      top: 4px;
      right: -8px;
      height: 13px;
      width: 1px;
      background: currentColor;
    }
  }
`;

const Link = styled(RouteLink)`
  ${LinkStyle}
`;

const ALink = styled.a`
  ${LinkStyle}
`;

const Links = styled.div`
  display: inline-flex;
`;

const Copyright = styled.div`
  display: inline-flex;
  padding-right: 60px;
  color: ${secondaryColor};
`;

export const Footer = () => {
  return (
    <Outer>
      <Links>
        <ALink href={'/cookiePolicy'}>
          <Typography variant={'caption'} fontSize={13}>
            {t('cookie_policy_page.headline', {})}
          </Typography>
        </ALink>
        <Link to={'/imprint'}>
          <Typography variant={'caption'} fontSize={13}>
            {t('imprint_page.headline', {})}
          </Typography>
        </Link>
        <Link to={'/privacyPolicy'}>
          <Typography variant={'caption'} fontSize={13}>
            {t('privacy_policy_page.headline', {})}
          </Typography>
        </Link>
      </Links>
      <Copyright>
        <Typography variant={'caption'} fontSize={13}>
          &copy;&nbsp;Schaeffler Technologies AG &amp; Co. KG
        </Typography>
      </Copyright>
    </Outer>
  );
};
