import { PostResult } from '../../core/errorhandling';
import { getErrorMessage } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';

import { CMPBulkPhaseInRequest, CMPBulkPhaseInResponse, CMPMaterialPhaseInResponse } from './model';

export async function saveBulkPhaseIn(
  phaseIn: CMPBulkPhaseInRequest,
  dryRun: boolean,
): Promise<PostResult<CMPMaterialPhaseInResponse>> {
  try {
    const body = JSON.stringify(phaseIn);
    const result = (await requestFromApi(
      `customer-material-portfolio/bulk-phase-in?dryRun=${dryRun}`,
      {
        method: 'POST',
        body: body,
      },
    )) as CMPBulkPhaseInResponse;

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: result.materialResults,
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}
