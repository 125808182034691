import { AgGridReact } from '@ag-grid-community/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

import {
  formatFilterModelForAgGrid,
  formatFilterModelForBackend,
} from '../../../agGrid/FilterModel';
import {
  getColFilter,
  getDefaultColDef,
  serverSideTableDefaultProps,
  sideBar,
} from '../../../agGrid/gridDefaults';
import { GridApis } from '../../../agGrid/gridTypes';
import { showFloatingFilters } from '../../../agGrid/gridUtils';
import useAutosizeColumns from '../../../agGrid/useAutosizeColumns';
import useGridErrorNotification from '../../../agGrid/useGridErrorNotification';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import { createEmptyDatasource, DATA_FETCHED_EVENT, getIdForRow } from '../../../core/datasources';
import useGlobalSelectionCriteria from '../../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { t } from '../../../core/i18n/i18n';
import {
  emptyGlobalSelectionCriteriaFields,
  globalSelectionCriteriaToFilter,
} from '../../../domain/globalSelection/model';
import { createMaterialCustomerDatasource } from '../../../domain/materialCustomer/datasource';
import { useCriteriaData } from '../../../domain/materialCustomer/useCriteriaData';

import { CustomTooltip } from './CustomTooltip';
import HomeTableToolbar from './HomeTableToolbar';
import { exportMaterialCustomer } from './exportMaterialCustomer';
import useMaterialCustomerColumnLayouts from './useCustomerMaterialColumnLayouts';

type MaterialCustomerTableProps = {
  columnFilters: Record<string, any>;
  setColumnFilters: (columnFilters: Record<string, any>) => void;
};

function MaterialCustomerTable({ columnFilters, setColumnFilters }: MaterialCustomerTableProps) {
  const [grid, setGrid] = useState<GridApis | undefined>();
  useAutosizeColumns(grid);
  useGridErrorNotification(grid);

  const showFloatingFiltersRef = useRef(false);
  const { saveLayout, resetLayout, currentLayoutId, loadLayout, initialColumns } =
    useMaterialCustomerColumnLayouts(grid);

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [rowCount, setRowCount] = useState<number | null>(null);
  const snackbar = useSnackbar();
  const appInsights = useAppInsightsContext();

  const { globalSelection } = useGlobalSelectionCriteria();

  const { data: criteriaData } = useCriteriaData();

  const setDefaultColDefs = React.useCallback(() => {
    if (grid?.api && initialColumns) {
      const colDefs = initialColumns.map(
        ({
          filter,
          colId,
          visible,
          alwaysVisible,
          valueGetter,
          valueFormatter,
          cellRenderer,
          filterParams,
        }) => ({
          ...getDefaultColDef(filter, filterParams),
          key: colId,
          colId,
          field: colId,
          lockVisible: alwaysVisible,
          hide: !visible,
          headerName: t(`material_customer.column.${colId}`, {}),
          sortable: criteriaData?.sortableFields.includes(colId),
          tooltipComponent: CustomTooltip,
          tooltipField: colId,
          filter: getColFilter(colId, filter, criteriaData),
          cellRenderer,
          valueGetter,
          valueFormatter,
          lockPinned: true,
        }),
      );

      grid?.api?.setColumnDefs(colDefs);
    }
  }, [grid, initialColumns, criteriaData]);

  // apply initial cols to grid
  useEffect(() => {
    if (!(grid && initialColumns)) return;
    setDefaultColDefs();
  }, [grid, criteriaData, initialColumns, setDefaultColDefs]);

  // apply datasource
  useEffect(() => {
    if (!grid) return;

    // hide row counts until new data is loaded
    setRowCount(null);
    grid.api.deselectAll();

    // row count logic and setting column filters after loading
    const listener = () => {
      const storeState = grid.api.getServerSideGroupLevelState()[0];
      setRowCount(storeState.rowCount);
    };
    grid.api.addEventListener(DATA_FETCHED_EVENT, listener);

    // TODO Please Refactor later. SetFilterModel needs to be called after columns are set.
    const columnFilterListener = () => {
      grid.api.setFilterModel(formatFilterModelForAgGrid(columnFilters));
    };
    grid.api.addEventListener('columnEverythingChanged', columnFilterListener);

    // Setting the datasource via props leads to duplicated requests being sent by agGrid when
    // the datasource changes. So we set the datasource manually via the gridApi.
    if (globalSelection) {
      grid.api.setServerSideDatasource(
        createMaterialCustomerDatasource({
          selectionFilters: globalSelectionCriteriaToFilter(globalSelection),
        }),
      );
    } else {
      // reset filter and datasource to empty table
      grid.api.setServerSideDatasource(createEmptyDatasource());
      grid.api.setFilterModel({});
    }

    return () => {
      grid.api.removeEventListener(DATA_FETCHED_EVENT, listener);
      grid.api.removeEventListener('columnEverythingChanged', columnFilterListener);
    };
  }, [grid, globalSelection, columnFilters]);

  const toggleFloatingFilter = () => {
    showFloatingFiltersRef.current = !showFloatingFiltersRef.current;

    if (grid) {
      showFloatingFilters(grid.api, showFloatingFiltersRef.current);
    }
  };

  const handleExcelExport = async () =>
    exportMaterialCustomer(
      grid,
      globalSelectionCriteriaToFilter(globalSelection),
      setExportModalOpen,
      appInsights,
      snackbar,
    );

  const getVisibilityBackground = (params: { data: { portfolioStatus: string } | undefined }) => {
    if (params.data != undefined) {
      if (params.data.portfolioStatus == 'IA') {
        return { backgroundColor: '#F6F7F9', color: '#646464' };
      }
    }

    return undefined;
  };

  return (
    <>
      <HomeTableToolbar
        onResetLayout={resetLayout}
        onSaveLayout={saveLayout}
        onLoadLayout={loadLayout}
        onToggleFilter={toggleFloatingFilter}
        defaultSelectedLayout={currentLayoutId}
        exportButtonDisabled={
          !globalSelection || globalSelection === emptyGlobalSelectionCriteriaFields
        }
        onExcelExport={handleExcelExport}
        rowCount={rowCount}
      />
      <LoadingSpinnerModal
        open={exportModalOpen}
        text={t('material_customer.export.in_progress', {})}
      />
      <div style={{ display: 'flex', flexDirection: 'row', minHeight: '400px', flexGrow: 1 }}>
        <StyledGridSection>
          <AgGridReact
            {...serverSideTableDefaultProps}
            sideBar={sideBar}
            getRowStyle={getVisibilityBackground}
            tooltipShowDelay={0}
            onGridReady={setGrid}
            getRowId={getIdForRow}
            onFilterChanged={(event) => {
              const filterModel = formatFilterModelForBackend(event.api.getFilterModel());
              setColumnFilters(filterModel);

              appInsights.trackEvent(
                {
                  name: '[Home] Apply Field List Filter',
                },
                { appliedFilter: event.columns[0].getColDef().headerName, allFilters: filterModel },
              );
            }}
          />
        </StyledGridSection>
      </div>
    </>
  );
}

export default MaterialCustomerTable;
