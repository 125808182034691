import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';
import {
  ReplacementType,
  replacementTypeValues,
} from '../../domain/internalMaterialReplacement/model';
import { DemandCharacteristic, demandCharacteristics } from '../../domain/materialCustomer/model';
import { t } from '../i18n/i18n';
import { parseToStringLiteralTypeIfPossible } from '../parseValues';

export function validateReplacementType(value: string): string | undefined {
  const localizationKeyCreation = (val: ReplacementType) => t(`replacement_type.${val}`, {});
  const parsedValue = parseToStringLiteralTypeIfPossible(
    value,
    replacementTypeValues,
    localizationKeyCreation,
  );
  if (!parsedValue) {
    return t('generic.validation.check_inputs', {});
  }
  return undefined;
}

export function validateDemandCharacteristicType(value: string): string | undefined {
  const localizationKeyCreation = (val: DemandCharacteristic) =>
    t(`demand_characterictics.${val}`, {});
  const parsedValue = parseToStringLiteralTypeIfPossible(
    value,
    demandCharacteristics,
    localizationKeyCreation,
  );
  if (!parsedValue) {
    return t('generic.validation.check_inputs', {});
  }
  return undefined;
}

export const validateSelectableOptions =
  (options: SelectableValue[]) =>
  (value: string): string | undefined => {
    const foundValue = options.find((option) => option.id == value || option.text == value);
    if (!foundValue) return t('generic.validation.check_inputs', {});
    return undefined;
  };
