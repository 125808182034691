import { Button, Grid } from '@mui/material';
import {
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import { FieldContainer } from '../../../components/FieldContainer';
import MultiselectFromClipboard from '../../../components/inputs/MultiselectFromClipboard';
import { MultiAutocompleteOnType } from '../../../components/inputs/autocomplete/MultiAutocompleteOnType';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { DateRange } from '../../../core/dateRange';
import { errorsFromSAPtoMessage } from '../../../core/errorhandling';
import { getErrorMessage } from '../../../core/errors';
import { t } from '../../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../../core/requests/httpClient';
import { deleteValidatedDemandBatch } from '../../../domain/demandValidation/deleteValidatedDemand';
import { firstEditableDate, lastEditableDate } from '../../../domain/demandValidation/limits';
import { CustomerEntry } from '../../../domain/globalSelection/model';
import { resolveMaterialNumbers } from '../../../domain/globalSelection/resolve';
import { DemandValidationDatePicker } from '../DemandValidationDatePicker';

type Props = {
  customer: CustomerEntry;
  onSave: () => void;
  onClose: () => void;
  open: boolean;
};

export function BatchDeletionModal(props: Props) {
  const [dateRange1, setDateRange1] = useState<Partial<DateRange>>({
    from: new Date(),
    to: startOfMonth(endOfYear(addYears(new Date(), 1))), // start of last month of next year
    period: 'MONTHLY',
  });
  const [loading, setLoading] = useState(false);
  const [dateRangeError, setDateRangeError] = React.useState(false);
  const [materialNumbers, setMaterialNumbers] = useState<Array<string>>([]);
  const snackbar = useSnackbar();

  const getMaterialNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-materials', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  async function deleteOnConfirmation() {
    if (!dateRange1.from || !dateRange1.to || materialNumbers.length < 1) {
      snackbar.enqueueSnackbar(
        t('validation_of_demand.deletion_modal.selection_not_complete', {}),
        { variant: 'warning' },
      );
      return;
    }

    if (!window.confirm(t('validation_of_demand.deletion_modal.confirm_deletion', {}))) {
      return;
    }

    setLoading(true);

    try {
      const result = await deleteValidatedDemandBatch(
        {
          customerNumber: props.customer.customerNumber,
          fromDate:
            dateRange1.period == 'WEEKLY'
              ? formatISO(startOfWeek(dateRange1.from, { weekStartsOn: 1 }), {
                  representation: 'date',
                })
              : formatISO(startOfMonth(dateRange1.from), { representation: 'date' }),
          toDate:
            dateRange1.period == 'WEEKLY'
              ? formatISO(endOfWeek(dateRange1.to, { weekStartsOn: 1 }), {
                  representation: 'date',
                })
              : formatISO(endOfMonth(dateRange1.to), { representation: 'date' }),
          materialNumbers: materialNumbers,
        },
        false,
      );

      if (result.result.messageType === 'ERROR') {
        throw new Error(errorsFromSAPtoMessage(result.result));
      }

      const materialsWithError = result.results.filter((res) => res.result.messageType === 'ERROR');

      if (materialsWithError.length > 0) {
        materialsWithError.forEach((entry) => {
          snackbar.enqueueSnackbar(
            entry.materialNumber + ': ' + errorsFromSAPtoMessage(entry.result),
            { variant: 'error' },
          );
        });
      } else {
        snackbar.enqueueSnackbar(t('validation_of_demand.deletion_modal.deletion_success', {}), {
          variant: 'success',
        });
        props.onSave();
        props.onClose();
        setMaterialNumbers([]);
      }
    } catch (e) {
      snackbar.enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal open={props.open} onClose={props.onClose} maxWidth="md" fullWidth={true}>
      <LoadingSpinnerModal open={loading} />
      <Modal.Headline
        onClose={props.onClose}
        text={`${t('validation_of_demand.deletion_modal.title', {})} - ${
          props.customer.customerName
        }`}
      />
      <Modal.SubHeadline />

      <Modal.Body>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item width={'100%'}>
            <DemandValidationDatePicker
              dateRange1={dateRange1}
              onDateRange1Change={(value) => setDateRange1(value)}
              dateRange2={undefined}
              onDateRange2Change={() => {}}
              onDateRangeErrorChange={setDateRangeError}
              minDate={firstEditableDate(dateRange1.period || 'MONTHLY')}
              maxDate={lastEditableDate()}
              disableOptionalDate={true}
            />
          </Grid>
          <Grid item width={'100%'}>
            <FieldContainer>
              <MultiAutocompleteOnType
                value={materialNumbers}
                onValueChange={(_, values: SelectableValue[]) =>
                  setMaterialNumbers(values.map((v) => v.id))
                }
                autocompleteLabel={t('globalSelection.material', {})}
                getOptions={getMaterialNumberOptions}
              />
              <MultiselectFromClipboard
                value={materialNumbers}
                onValueChange={(_, values: SelectableValue[]) =>
                  setMaterialNumbers(values.map((v) => v.id))
                }
                autocompleteLabel={t('globalSelection.material', {})}
                getOptions={getMaterialNumberOptions}
                entityName={t('globalSelection.material.entity_name', {})}
                entityNamePlural={t('globalSelection.material.entity_name_plural', {})}
                selectableValuesByKeys={resolveMaterialNumbers}
                muiProps={{
                  filterOptions: (options, state) => {
                    return options.filter((option) => {
                      // ignore minuses in material numbers when filtering options by text
                      if (
                        option.id
                          .replaceAll('-', '')
                          .toLowerCase()
                          .includes(state.inputValue.replaceAll('-', '').toLowerCase())
                      ) {
                        return true;
                      }

                      if (option.text.toLowerCase().includes(state.inputValue.toLowerCase())) {
                        return true;
                      }

                      return false;
                    });
                  },
                }}
              />
            </FieldContainer>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button
              variant={'contained'}
              color={'error'}
              disabled={dateRangeError}
              onClick={() => deleteOnConfirmation()}
            >
              {t('validation_of_demand.deletion_modal.delete_button', {})}
            </Button>
          </Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );
}
