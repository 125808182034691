import { TextFieldProps } from '@mui/material/TextField/TextField';
import * as React from 'react';
import styled from 'styled-components';

export type ErrorProps = Pick<TextFieldProps, 'error' | 'helperText'>;

/**
 * uses the given messages to construct mui TextField props for error state end error rendering
 */
export function errorHelperTextProps(
  errorMessages: Array<string>,
  nonErrorText?: string,
): ErrorProps {
  const helperText = (
    <>
      {nonErrorText}
      {errorMessages.length > 0 && (
        <ErrorList>
          {errorMessages.map((msg) => (
            <li key={msg}>{msg}</li>
          ))}
        </ErrorList>
      )}
    </>
  );

  return {
    error: errorMessages.length > 0,
    helperText,
  };
}

export const ErrorList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
