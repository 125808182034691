import { ICellRendererParams } from '@ag-grid-community/core';
import { Delete } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

import { errorColor } from '../../../styles/colors';

export const DeleteButtonCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  if (params.value === null) {
    return '';
  }

  return (
    <IconWrapper>
      <Delete color={'secondary'} onClick={onDeleteRow} />
    </IconWrapper>
  );

  function onDeleteRow() {
    params.api.applyTransaction({ remove: [params.node.data] });
  }
};

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  > {
    :hover {
      color: ${errorColor};
    }
  }
`;
