import { ReplacementTypeCellRenderer } from '../../../components/agGrid/cellRenderers/ReplacementTypeCellRenderer';
import { ReplacementTypeOrOriginalCellRenderer } from '../../../components/agGrid/cellRenderers/ReplacementTypeOrOriginalCellRenderer';
import { ColumnForUploadTable } from '../../../components/tableUploadModal/TableUploadModal';
import { t } from '../../../core/i18n/i18n';
import { validateReplacementType } from '../../../core/validation/dataValidation';
import {
  validateCustomerNumber,
  validateMaterialNumber,
} from '../../../core/validation/filterValidation';
import {
  condenseErrorsFromValidation,
  validateDateFormatAndGreaterEqualThanToday,
} from '../../../core/validation/validationHelper';
import {
  IMRSubstitution,
  replacementTypeValues,
} from '../../../domain/internalMaterialReplacement/model';

export const multiSubstitutionModalColumns: ColumnForUploadTable<IMRSubstitution>[] = [
  {
    field: 'replacementType',
    headerNameFn: () => t('internal_material_replacement.column.replacementType', {}),
    editable: true,
    validationFn: validateReplacementType,
    cellRenderer: ReplacementTypeOrOriginalCellRenderer,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    cellEditorParams: {
      cellRenderer: ReplacementTypeCellRenderer,
      values: [...replacementTypeValues],
    },
  },
  {
    field: 'region',
    headerNameFn: () => t('globalSelection.region', {}),
    editable: true,
  },
  {
    field: 'salesArea',
    headerNameFn: () => t('globalSelection.sales_area', {}),
    editable: true,
  },
  {
    field: 'salesOrg',
    headerNameFn: () => t('globalSelection.sales_org', {}),
    editable: true,
  },
  {
    field: 'customerNumber',
    headerNameFn: () => t('globalSelection.customer', {}),
    editable: true,
    validationFn: condenseErrorsFromValidation(validateCustomerNumber),
  },
  {
    field: 'predecessorMaterial',
    headerNameFn: () => t('internal_material_replacement.column.predecessorMaterial', {}),
    editable: true,
    validationFn: condenseErrorsFromValidation(validateMaterialNumber),
  },
  {
    field: 'successorMaterial',
    headerNameFn: () => t('internal_material_replacement.column.successorMaterial', {}),
    editable: true,
    validationFn: condenseErrorsFromValidation(validateMaterialNumber),
  },
  {
    field: 'startOfProduction',
    headerNameFn: () => t('internal_material_replacement.column.startOfProduction', {}),
    editable: true,
    validationFn: validateDateFormatAndGreaterEqualThanToday,
  },
  {
    field: 'cutoverDate',
    headerNameFn: () => t('internal_material_replacement.column.cutoverDate', {}),
    editable: true,
    validationFn: validateDateFormatAndGreaterEqualThanToday,
  },
  {
    field: 'replacementDate',
    headerNameFn: () => t('internal_material_replacement.column.replacementDate', {}),
    editable: true,
    validationFn: validateDateFormatAndGreaterEqualThanToday,
  },
  {
    field: 'note',
    headerNameFn: () => t('alert_rules.edit_modal.label.comment', {}),
    editable: true,
  },
] as const;
