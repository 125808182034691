import { SelectableValueOrOriginalCellRenderer } from '../../../components/agGrid/cellRenderers/SelectableValueOrOriginalCellRenderer';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { ColumnForUploadTable } from '../../../components/tableUploadModal/TableUploadModal';
import { t } from '../../../core/i18n/i18n';
import { validateSelectableOptions } from '../../../core/validation/dataValidation';
import {
  validateCustomerNumber,
  validateMaterialNumber,
} from '../../../core/validation/filterValidation';
import {
  condenseErrorsFromValidation,
  validateDateFormatAndGreaterEqualThanToday,
  validateForFloat,
} from '../../../core/validation/validationHelper';
import { valueParserForSelectableOptions } from '../../../domain/alertrules/alertRuleValueParser';
import { AlertRule } from '../../../domain/alertrules/model';

export function getMultiAlertRuleModalColumns(
  typeOptions: SelectableValue[],
  intervalOptions: SelectableValue[],
  execDayOptions: SelectableValue[],
  regionOptions: SelectableValue[],
  demandPlannerOptions: SelectableValue[],
  sectorManagementOptions: SelectableValue[],
  salesOrgOptions: SelectableValue[],
  salesAreaOptions: SelectableValue[],
  productLineOptions: SelectableValue[],
  gkamOptions: SelectableValue[],
): ColumnForUploadTable<AlertRule>[] {
  return [
    {
      field: 'type',
      headerNameFn: () => t('alert_rules.edit_modal.label.type', {}),
      valueParser: valueParserForSelectableOptions(typeOptions),
      editable: true,
      validationFn: validateSelectableOptions(typeOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: typeOptions,
        getLabel: (v: SelectableValue) => v.text,
      },
      minWidth: 300,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        cellRenderer: SelectableValueOrOriginalCellRenderer,
        cellRendererParams: {
          options: typeOptions,
          getLabel: (v: SelectableValue) => v.text,
        },
        values: typeOptions.map((o) => o.id),
      },
    },
    {
      field: 'region',
      headerNameFn: () => t('alert_rules.edit_modal.label.region', {}),
      editable: true,
      //TODO: Ensure parser is called on copy from clipboard!
      valueParser: valueParserForSelectableOptions(regionOptions),
      validationFn: validateSelectableOptions(regionOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: regionOptions,
        values: regionOptions.map((o) => o.text),
      },
    },
    {
      field: 'salesArea',
      headerNameFn: () => t('alert_rules.edit_modal.label.sales_area', {}),
      valueParser: valueParserForSelectableOptions(salesAreaOptions),
      editable: true,
      validationFn: validateSelectableOptions(salesAreaOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: salesAreaOptions,
      },
    },
    {
      field: 'salesOrg',
      headerNameFn: () => t('alert_rules.edit_modal.label.sales_org', {}),
      valueParser: valueParserForSelectableOptions(salesOrgOptions),
      editable: true,
      validationFn: validateSelectableOptions(salesOrgOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: salesOrgOptions,
        values: salesOrgOptions.map((o) => o.id),
      },
    },

    {
      field: 'sectorManagement',
      headerNameFn: () => t('alert_rules.edit_modal.label.sector_management', {}),
      valueParser: valueParserForSelectableOptions(sectorManagementOptions),
      editable: true,
      validationFn: validateSelectableOptions(sectorManagementOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: sectorManagementOptions,
        values: sectorManagementOptions.map((o) => o.id),
      },
    },

    {
      field: 'demandPlannerId',
      headerNameFn: () => t('alert_rules.edit_modal.label.demandPlannerId', {}),
      valueParser: valueParserForSelectableOptions(demandPlannerOptions),
      editable: true,
      validationFn: validateSelectableOptions(demandPlannerOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: demandPlannerOptions,
        values: demandPlannerOptions.map((o) => o.id),
      },
    },
    {
      field: 'gkamNumber',
      headerNameFn: () => t('alert_rules.edit_modal.label.gkamNumber', {}),
      valueParser: valueParserForSelectableOptions(gkamOptions),
      editable: true,
      validationFn: validateSelectableOptions(gkamOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: gkamOptions,
        values: gkamOptions.map((o) => o.id),
      },
    },
    {
      field: 'customerNumber',
      headerNameFn: () => t('alert_rules.multi_modal.customer', {}),
      editable: true,
      validationFn: condenseErrorsFromValidation(validateCustomerNumber),
    },
    {
      field: 'materialClassification',
      headerNameFn: () => t('alert_rules.edit_modal.label.materialClassification', {}),
      editable: true,
    },
    {
      field: 'productLine',
      headerNameFn: () => t('alert_rules.edit_modal.label.product_line', {}),
      valueParser: valueParserForSelectableOptions(productLineOptions),
      editable: true,
      validationFn: validateSelectableOptions(productLineOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: productLineOptions,
      },
    },
    {
      field: 'productionLine',
      headerNameFn: () => t('alert_rules.edit_modal.label.production_line', {}),
      editable: true,
    },
    {
      field: 'materialNumber',
      headerNameFn: () => t('alert_rules.multi_modal.material', {}),
      editable: true,
      validationFn: condenseErrorsFromValidation(validateMaterialNumber),
    },
    {
      field: 'threshold1',
      headerNameFn: () => t('rules.threshold1', {}),
      validationFn: validateForFloat,
      editable: true,
    },
    {
      field: 'threshold2',
      headerNameFn: () => t('rules.threshold2', {}),
      validationFn: validateForFloat,
      editable: true,
    },
    {
      field: 'threshold3',
      headerNameFn: () => t('rules.threshold3', {}),
      validationFn: validateForFloat,
      editable: true,
    },
    {
      field: 'startDate',
      headerNameFn: () => t('alert_rules.edit_modal.label.start', {}),
      editable: true,
      validationFn: validateDateFormatAndGreaterEqualThanToday,
    },
    {
      field: 'execInterval',
      headerNameFn: () => t('alert_rules.edit_modal.label.interval', {}),
      valueParser: valueParserForSelectableOptions(intervalOptions),
      editable: true,
      validationFn: validateSelectableOptions(intervalOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: intervalOptions,
        getLabel: (v: SelectableValue) => `${v.text}`,
      },
    },
    {
      field: 'execDay',
      headerNameFn: () => t('alert_rules.edit_modal.label.when', {}),
      valueParser: valueParserForSelectableOptions(execDayOptions),
      editable: true,
      validationFn: validateSelectableOptions(execDayOptions),
      cellRenderer: SelectableValueOrOriginalCellRenderer,
      cellRendererParams: {
        options: execDayOptions,
        getLabel: (v: SelectableValue) => `${v.text}`,
      },
    },
    {
      field: 'endDate',
      headerNameFn: () => t('alert_rules.edit_modal.label.end', {}),
      editable: true,
      validationFn: validateDateFormatAndGreaterEqualThanToday,
    },
    {
      field: 'alertComment',
      headerNameFn: () => t('alert_rules.edit_modal.label.comment', {}),
      editable: true,
    },
  ];
}
