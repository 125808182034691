import { Dialog, DialogProps } from '@mui/material';
import * as React from 'react';

import { ModalBody } from './ModalBody';
import { ModalHeadline } from './ModalHeadline';
import { ModalSubHeadline } from './ModalSubHeadline';

const Modal = ({ children, ...rest }: DialogProps) => {
  return <Dialog {...rest}>{children}</Dialog>;
};

Modal.Headline = ModalHeadline;
Modal.SubHeadline = ModalSubHeadline;
Modal.Body = ModalBody;

export default Modal;
