import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../../core/i18n/i18n';
import { formatNumber } from '../../../core/i18n/l10n';

const Container = styled.div`
  padding-left: 23px;
  margin-top: 4px;
`;

function ToolbarCounter({ rowCount }: { rowCount: number | undefined }) {
  return (
    <Container>
      {rowCount != null && (
        <Typography component="span" color="secondary" variant="body2">
          {t('table.toolbar.material_count', { count: formatNumber(rowCount) })}
        </Typography>
      )}
    </Container>
  );
}

export default ToolbarCounter;
