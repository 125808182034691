import { ExpandMore } from '@mui/icons-material';
import { Box, Chip, Menu, MenuItem, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getTabItemForRoute, routeConfig, TabItem } from '../../config/routeConfig';
import { checkRoles } from '../../core/auth/roles';
import useGlobalSelectionCriteria from '../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import useDomId from '../../core/hooks/useDomId';
import { t } from '../../core/i18n/i18n';
import { formatNumber } from '../../core/i18n/l10n';
import { minutesToMilliseconds } from '../../core/timeUtils';
import { useAlertNotificationCount } from '../../domain/alerts/useAlertNotificationCount';
import { useUserData } from '../../domain/user/useUserData';
import { errorColor, textLight, warningColor } from '../../styles/colors';

import { headerHeight } from './Header';

type Tabs = 'start-page' | 'functions' | 'tasks' | 'demandValidation';

export const TabBarNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabItem>(getTabItemForRoute(location.pathname));
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { alertCount } = useAlertNotificationCount({ refreshInterval: minutesToMilliseconds(10) });
  const functionsMenuId = useDomId();
  const { roles } = useUserData();
  const { globalSelection, navigateWithGlobalSelection } = useGlobalSelectionCriteria();

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <StyledTabMenu>
      <Tabs
        variant={'fullWidth'}
        value={activeTab}
        onChange={(_e, v) => {
          setActiveTab(v);
        }}
        aria-label={t('tabbar.navigation.label', {})}
      >
        <StyledTab
          value={'startPage'}
          label={t('tabbar.start-page.label', {})}
          onClick={() => navigateWithGlobalSelection(routeConfig.startPage.path, globalSelection)}
        />

        <StyledTab
          icon={<ExpandMore />}
          iconPosition="end"
          value={'functions'}
          onClick={toggleMenu}
          aria-controls={open ? functionsMenuId : undefined}
          label={t('tabbar.functions.label', {})}
        />

        <StyledTab
          value={'tasks'}
          label={
            <TasksWrapper>
              <StyledTabTypography variant="button">
                {t('tabbar.tasks.label', {})}
              </StyledTabTypography>
              {alertCount && (
                <TasksStack direction="row" spacing={1}>
                  <TodoChip
                    size={'small'}
                    label={formatNumber(alertCount.openNonCritical)}
                    fontcolor={textLight}
                    backgroundcolor={warningColor}
                  />
                  <TodoChip
                    size={'small'}
                    label={formatNumber(alertCount.openCritical)}
                    fontcolor={textLight}
                    backgroundcolor={errorColor}
                  />
                </TasksStack>
              )}
            </TasksWrapper>
          }
          onClick={() => navigate(routeConfig.tasks.path)}
        />
      </Tabs>
      <Menu id={functionsMenuId} open={open} onClose={toggleMenu} anchorEl={anchorEl}>
        {routeConfig.functions
          .filter((menu) => menu.visible)
          .map((menu) => (
            <StyledMenuItem
              disabled={
                menu.roleRestrictions != undefined && !checkRoles(roles, menu.roleRestrictions)
              }
              key={menu.path}
              onClick={() => navigateWithGlobalSelection(menu.path, globalSelection)}
            >
              <TruncatedText>{t(menu.label, {})}</TruncatedText>
            </StyledMenuItem>
          ))}
      </Menu>
    </StyledTabMenu>
  );
};

export default TabBarNavigation;

export const tabMenuHeight = '48px';

const StyledTabMenu = styled(Box)`
  position: fixed;
  top: ${headerHeight};
  height: ${tabMenuHeight};
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledTab = styled(Tab)`
  font-weight: 400;
  letter-spacing: 1.25px;
  min-height: ${tabMenuHeight};
`;

const TasksWrapper = styled(Box)`
  position: relative;
  width: 100%;
`;

const TasksStack = styled(Stack)`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
`;

const StyledTabTypography = styled(Typography)`
  font-weight: 400;
  letter-spacing: 1.25px;
`;

const TruncatedText = styled.span`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TodoChip = styled(Chip).attrs(
  (props: { fontcolor: string; backgroundcolor: string }) => props,
)`
  line-height: 0.8125rem;
  min-width: 40px;
  color: ${(props) => props.fontcolor};
  background-color: ${(props) => props.backgroundcolor};
`;

const StyledMenuItem = styled(MenuItem)`
  min-height: 48px;
  width: 33.3333vw;
`;
