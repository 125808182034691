export function isPartialDataMatching<DATA>(dataIdentifier: Partial<DATA>, data: any): boolean {
  const propertiesIdentifier = Object.entries(dataIdentifier);
  let isMatching = true;
  propertiesIdentifier.forEach((entryIdent) => {
    const fieldName = entryIdent[0];
    const fieldValue = entryIdent[1];

    // SAP sends back empty strings for empty fields but agGrid just leaves the field out. So don't use it.
    if (!fieldValue || fieldValue == '') return;

    if (
      // Use and operators and negation here to end the checks if one of the conditions does not match
      !(
        Object.hasOwn(data, fieldName) &&
        data[fieldName] != undefined &&
        data[fieldName].toString().replaceAll('-', '') == fieldValue.toString().replaceAll('-', '')
      )
    ) {
      isMatching = false;
    }
  });
  return isMatching;
}
