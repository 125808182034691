import React, { useEffect, useRef, useState } from 'react';

import useSelectableOptionsOnType from '../../../core/hooks/useSelectableOptionsOnType';
import { AutocompleteLabelProps } from '../baseComponents/MultiAutocompleteBase';
import {
  SingleAutocompleteValueBaseProps,
  SingleAutocompleteBase,
} from '../baseComponents/SingleAutocompleteBase';
import { SelectableValue, isSelectableValue } from '../baseComponents/selectableValues';

type SingleAutocompleteOnTypeProps = {
  getOptions: (searchTerm: string, signal?: AbortSignal) => Promise<SelectableValue[]>;
} & SingleAutocompleteValueBaseProps &
  AutocompleteLabelProps;

export const SingleAutocompleteOnType = (props: SingleAutocompleteOnTypeProps) => {
  const { value, getOptions } = props;

  const [processedValue, setProcessedValue] = useState<SelectableValue | null>(
    isSelectableValue(value) ? value : null, // if value is a string, useEffect will take care of it
  );

  const { inputValue, onInputValueChange, options, loading, loadingError } =
    useSelectableOptionsOnType(props.getOptions);

  const clickedValue = useRef<SelectableValue | undefined>(undefined);

  useEffect(() => {
    if (value == undefined || value == '') {
      setProcessedValue(null);
      return;
    }

    if (isSelectableValue(value)) {
      setProcessedValue(value);
      return;
    }

    if (clickedValue.current?.id == value) {
      setProcessedValue(clickedValue.current);
      return;
    }

    // return a SelectableValue with the string we have as both id and text while we wait
    // because its better to show what we have than show nothing at all
    setProcessedValue({ id: value, text: value });

    getOptions(value).then((results: SelectableValue[]) => setProcessedValue(results[0]));
  }, [value, getOptions]);

  const onValueChange = (event: unknown, values: SelectableValue | null) => {
    if (isSelectableValue(values)) {
      clickedValue.current = values;
    }
    props.onValueChange(event, values);
  };

  return (
    <SingleAutocompleteBase
      autocompleteLabel={props.autocompleteLabel}
      value={processedValue}
      onValueChange={onValueChange}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      options={options}
      openDelayedAfterTyping={2}
      getOptionLabel={props.getOptionLabel}
      getOptionLabelInTag={props.getOptionLabelInTag}
      loading={loading}
      loadingError={loadingError}
      errorHelperTextProps={props.errorHelperTextProps}
      muiProps={{ forcePopupIcon: false, ...props.muiProps }}
    />
  );
};
