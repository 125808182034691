import { COOKIE_GROUPS, ONE_TRUST_SCRIPT_PATH } from './oneTrustConfig';
import { CookiesGroups } from './types';

export const cookiesPermissionMap = (foundCookies: Array<string>): Map<CookiesGroups, boolean> => {
  const cookiesGroups = new Map<CookiesGroups, boolean>();

  // map cookies
  Object.keys(COOKIE_GROUPS).forEach((key: string) => {
    const cookieValue = COOKIE_GROUPS[key as CookiesGroups];
    if (foundCookies.includes(cookieValue)) {
      cookiesGroups.set(key as CookiesGroups, true);
    }
  });

  return cookiesGroups;
};

export const areMapsEquals = (
  prev: Map<CookiesGroups, boolean>,
  next: Map<CookiesGroups, boolean>,
): boolean => {
  // different sizes means something changed
  if (prev.size !== next.size) {
    return false;
  }
  // check if keys from next are the same as keys from prev

  return [...Array.from(next.keys())].every((key: CookiesGroups) => prev.has(key));
};

const initializeOneTrust = (id: string): void => {
  const head = window.document.getElementsByTagName('head')[0];
  const oneTrustScript = window.document.createElement('script');
  oneTrustScript.id = 'one-trust-script';
  oneTrustScript.src = ONE_TRUST_SCRIPT_PATH;
  oneTrustScript.setAttribute('type', 'text/javascript');
  oneTrustScript.setAttribute('charset', 'UTF-8');
  oneTrustScript.setAttribute('data-domain-script', id);
  oneTrustScript.setAttribute('data-document-language', 'true');
  head.insertBefore(oneTrustScript, head.firstChild);
  const oneTrustFuncScript = window.document.createElement('script');
  oneTrustFuncScript.id = 'optanon-wrapper';
  oneTrustFuncScript.type = 'text/javascript';
  oneTrustFuncScript.innerHTML = `function OptanonWrapper() { }`;
  head.appendChild(oneTrustFuncScript);
};

export const loadOneTrust = (id: string): void => {
  initializeOneTrust(id);
};
