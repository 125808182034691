import { useMemo } from 'react';
import useSWR from 'swr';

import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';
import { t } from '../i18n/i18n';
import { requestFromApi } from '../requests/httpClient';

export type OptionsLoadingResult = {
  options: SelectableValue[];
  loading?: boolean;
  loadingError?: string | null;
};

export function useSelectableOptions(
  path: string,
  currentSelection?: SelectableValue[],
): OptionsLoadingResult {
  const { data, error, isLoading } = useSWR<SelectableValue[] | null>(path, requestFromApi);

  const options = useMemo(() => {
    const opts = data ? data : [];

    // add current selection to options if not already present
    // otherwise there might be values that cannot be deselected
    if (currentSelection) {
      currentSelection.forEach((selectedOption) => {
        if (opts.find((v) => v.id === selectedOption.id) === undefined) {
          opts.push(selectedOption);
        }
      });
    }

    return opts;
  }, [data, currentSelection]);

  return {
    options,
    loading: isLoading,
    loadingError: error ? t('globalSelection.dropdown.loading_error', {}) : null,
  };
}
