import { requestFromApi } from '../../core/requests/httpClient';
import { CMPData } from '../../pages/customerMaterialPortfolio/modal/cmpModalStatusSpecificElements/cmpModalTypes';

import { CfcrActionRequest, CfcrActionResponse } from './model';

export default async function fetchForecastActionData(cmpData: CMPData | null) {
  if (!cmpData?.customerNumber || !cmpData?.materialNumber || !cmpData?.successorMaterial) {
    return;
  }

  const request: CfcrActionRequest = {
    customerNumber: cmpData?.customerNumber,
    materialNumber: cmpData?.materialNumber,
    successorMaterial: cmpData?.successorMaterial,
  };

  const result = (await requestFromApi('customer-material-portfolio/cfcr-action', {
    method: 'POST',
    body: JSON.stringify(request),
  })) as CfcrActionResponse;

  return result;
}
