import { useEffect, useState } from 'react';

import { loadOneTrust } from './oneTrustUtils';
import { OneTrust } from './types';

const DEFAULT_OPTIONS = {
  config: { childList: true },
};

const oneTrustLoaded = (record: MutationRecord) => {
  if (record.addedNodes.length !== 1) {
    return;
  }

  const elem = record.addedNodes.item(0) as HTMLElement;

  return (
    elem &&
    typeof elem.getAttribute === 'function' &&
    elem.getAttribute('id') === 'onetrust-consent-sdk'
  );
};

function getOneTrustFromGlobalScope() {
  return (window as any)['OneTrust'] as OneTrust | undefined;
}

let oneTrustLoading = false;

export function useOneTrust(options = DEFAULT_OPTIONS) {
  const [oneTrust, setOneTrust] = useState<OneTrust | undefined>(getOneTrustFromGlobalScope());

  useEffect(() => {
    if (!oneTrust && !oneTrustLoading) {
      oneTrustLoading = true;
      loadOneTrust(`${process.env.ONE_TRUST_COOKIE_ID}`);
    }
  }, [oneTrust]);

  const [observer, setObserver] = useState<MutationObserver>();

  useEffect(() => {
    const handleMutationObservable = (mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        if (oneTrustLoaded(mutation)) {
          setOneTrust(getOneTrustFromGlobalScope());
        }
      });
    };

    const obs = new MutationObserver(handleMutationObservable);
    setObserver(obs);
  }, [options]);

  useEffect(() => {
    if (!observer) return;

    const { config } = options;

    observer.observe(document.body, config);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, options]);

  return oneTrust;
}
