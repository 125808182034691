import { AgGridReact } from '@ag-grid-community/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { addMonths, parseISO, startOfDay, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { GridApis } from '../../agGrid/gridTypes';
import { showFloatingFilters } from '../../agGrid/gridUtils';
import useGridErrorNotification from '../../agGrid/useGridErrorNotification';
import { DATA_FETCHED_EVENT } from '../../core/datasources';
import useUnsavedChangesWarning from '../../core/hooks/useUnsavedChangesWarning';
import { t } from '../../core/i18n/i18n';
import { strictlyParseFloat } from '../../core/number';
import { createDemandMaterialCustomerDatasource } from '../../domain/demandValidation/datasource';
import {
  DemandValidationFilter,
  demandValidationFilterToStringFilter,
} from '../../domain/demandValidation/demandValidationFilters';
import {
  KpiBucketType,
  KpiDateRanges,
  KpiEntry,
  MaterialListEntry,
  WriteKpiData,
} from '../../domain/demandValidation/model';
import { PlanningView } from '../../domain/demandValidation/planningView';
import { saveValidatedDemandSingleMcc } from '../../domain/demandValidation/saveValidatedDemand';
import { readLocalStorageTimeRange } from '../../domain/demandValidation/timeRange';
import useKpiData from '../../domain/demandValidation/useKpiData';
import {
  GlobalSelectionCriteriaFields,
  globalSelectionCriteriaToFilter,
  CustomerEntry,
} from '../../domain/globalSelection/model';

import { ActionBar } from './actionBar/ActionBar';
import DemandValidationTable from './forecastTable/DemandValidationTable';
import MaterialList from './materialListTable/MaterialList';

export default function DemandValidationContent({
  selectedCustomer,
  customers,
  setSelectedCustomer,
  globalSelection,
  planningView,
  onPlanningViewChange,
}: {
  selectedCustomer: CustomerEntry;
  setSelectedCustomer: (customer: CustomerEntry) => void;
  customers: CustomerEntry[];
  globalSelection: GlobalSelectionCriteriaFields;
  planningView: PlanningView;
  onPlanningViewChange: (newValue: PlanningView) => void;
}): React.JSX.Element {
  const showFloatingFiltersRef = useRef(false);
  const snackbar = useSnackbar();

  const [grid, setGrid] = useState<GridApis>();
  const contentGridRef = useRef<AgGridReact>();

  const [demandValidationFilters, setDemandValidationFilters] = useState<
    DemandValidationFilter | undefined
  >(undefined);
  const [materialListVisible, setMaterialListVisible] = useState(true);
  const [selectedMaterialListEntry, setSelectedMaterialListEntry] = useState<MaterialListEntry>();
  const [visibleEditButtons, setVisibleEditButtons] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<KpiDateRanges>(() => {
    const localStorageTimeRange = readLocalStorageTimeRange();

    if (localStorageTimeRange != undefined) {
      return localStorageTimeRange;
    }

    return {
      range1: {
        from: startOfDay(addMonths(new Date(), -3)),
        to: startOfDay(addMonths(new Date(), 12)),
        period: 'MONTHLY',
      },
      range2: undefined,
    };
  });
  const appInsights = useAppInsightsContext();

  const validatedDemandToWrite = useRef<WriteKpiData | null>(null);
  const errorInputIdentifiers = useRef<Set<string>>(new Set<string>());

  const [kpiRangeExceptions, setKpiRangeExceptions] = useState<Array<Date>>([]);
  const {
    data: kpiData,
    mutate,
    isValidating,
    error: kpiLoadingError,
  } = useKpiData(selectedMaterialListEntry, dateRange, kpiRangeExceptions);

  const [rowCount, setRowCount] = useState<number | undefined>(undefined);

  const kpiError = useMemo(() => {
    if (!kpiData && kpiLoadingError) {
      return t('error.loading_failed', {});
    }

    if (!isValidating && !kpiLoadingError && rowCount == 0) {
      return t('hint.noData', {});
    }

    return undefined;
  }, [kpiLoadingError, kpiData, rowCount, isValidating]);

  // currently, validatedDemandToWrite is implemented as ref. Therefore, we need a simple workaround here to check
  // for unsaved changes. The flag unsavedChanges is set in the checkCurrentChangeStatus callback
  const [unsavedChanges, setUnsavedChanges] = useState(true);
  const { confirmContinueAndLooseUnsavedChanges } = useUnsavedChangesWarning(unsavedChanges);

  useEffect(() => {
    setKpiRangeExceptions([]);
  }, [dateRange]);

  const checkCurrentChangeStatus = useCallback(() => {
    const hasUnsavedChanges =
      validatedDemandToWrite.current?.kpiEntries &&
      validatedDemandToWrite.current?.kpiEntries?.length > 0;

    setUnsavedChanges(hasUnsavedChanges || false);

    // Context: Confirmed view is just a view, no forecast editing should be possible. OP materials have no plannable forecast.
    if (
      planningView === PlanningView.CONFIRMED ||
      selectedMaterialListEntry?.materialClassification === 'OP'
    ) {
      setVisibleEditButtons(false);
    } else {
      // The user can only use the buttons if there is some input to work with. Disable them otherwise.
      if (hasUnsavedChanges || errorInputIdentifiers.current.size > 0) {
        setVisibleEditButtons(true);
      } else {
        setVisibleEditButtons(false);
      }
    }
  }, [validatedDemandToWrite, selectedMaterialListEntry, planningView]);

  useGridErrorNotification(grid);

  // clear data to write and errors and grid if new global selection is selected
  useEffect(() => {
    // clear the changed data an errors
    validatedDemandToWrite.current = null;
    errorInputIdentifiers.current = new Set<string>();
    checkCurrentChangeStatus();
    mutate(undefined, true);
  }, [checkCurrentChangeStatus, mutate]);

  // set datasource
  useEffect(() => {
    if (!grid) return;

    // refresh kpi data
    setSelectedMaterialListEntry(undefined);
    mutate(undefined);

    // row count logic
    const listener = () => {
      const storeState = grid.api.getServerSideGroupLevelState()[0];
      setRowCount(storeState.rowCount);
    };
    // hide row counts until new data is loaded
    setRowCount(undefined);
    grid.api.addEventListener(DATA_FETCHED_EVENT, listener);

    const globalFilters = globalSelectionCriteriaToFilter(globalSelection);
    const demandValidationFilterValues =
      demandValidationFilterToStringFilter(demandValidationFilters);

    const selectionFilters = {
      ...globalFilters,
      ...demandValidationFilterValues,
      customerNumber: [selectedCustomer.customerNumber],
    };

    grid.api.setServerSideDatasource(
      createDemandMaterialCustomerDatasource({
        selectionFilters: selectionFilters,
      }),
    );

    return () => {
      grid.api.removeEventListener(DATA_FETCHED_EVENT, listener);
    };
  }, [grid, selectedCustomer, globalSelection, demandValidationFilters, mutate]);

  // After a new fetch (e.g. when providing more filter values), we always select the first element.
  // This prevents showing kpis for a material which is no longer in the updated list.
  useEffect(() => {
    if (!grid) return;

    const listener = () => {
      const nodes = grid.api.getRenderedNodes();

      if (nodes?.length) {
        grid.api.deselectAll();
        nodes[0].setSelected(true);
        setSelectedMaterialListEntry(nodes[0]?.data);
      }
    };

    grid.api.addEventListener(DATA_FETCHED_EVENT, listener);

    return () => {
      grid.api.removeEventListener(DATA_FETCHED_EVENT, listener);
    };
  }, [grid, setSelectedMaterialListEntry, selectedMaterialListEntry]);

  const toggleFloatingFilter = () => {
    showFloatingFiltersRef.current = !showFloatingFiltersRef.current;

    if (grid) {
      showFloatingFilters(grid.api, showFloatingFiltersRef.current);
    }
  };

  const saveForecast = (dryrun: boolean) => async () => {
    contentGridRef?.current?.api?.showLoadingOverlay();

    if (!dryrun) {
      appInsights.trackEvent({
        name: '[Validated Sales Planning] Upload Single Entries',
      });
    }

    const result = await saveValidatedDemandSingleMcc(
      validatedDemandToWrite.current,
      errorInputIdentifiers.current,
      dryrun,
    );

    if (result) {
      snackbar.enqueueSnackbar(result, {
        variant: 'error',
      });
    } else {
      snackbar.enqueueSnackbar(t(`validation_of_demand.${dryrun ? 'check' : 'save'}.success`, {}), {
        variant: 'success',
      });

      if (!dryrun) {
        // clear all saved elements
        if (validatedDemandToWrite.current?.kpiEntries) {
          validatedDemandToWrite.current.kpiEntries = [];
        }

        checkCurrentChangeStatus();
        await mutate(undefined, true);
      }
    }

    contentGridRef?.current?.api?.hideOverlay();
  };

  const deleteUnsavedForecast = async () => {
    validatedDemandToWrite.current = null;
    errorInputIdentifiers.current = new Set<string>();
    checkCurrentChangeStatus();
    await mutate(undefined, true); // revalidate
    snackbar.enqueueSnackbar(t('validation_of_demand.reset.success', {}), {
      variant: 'success',
    });
  };

  const updateValidatedForecastToWrite = (
    date: string,
    value: string,
    period: KpiBucketType,
    customerNumber: string,
    materialNumber: string,
  ) => {
    // Check if the current customer and material is correct
    if (
      !validatedDemandToWrite.current ||
      validatedDemandToWrite.current?.materialNumber !== materialNumber ||
      validatedDemandToWrite.current?.customerNumber !== customerNumber
    ) {
      // Empty or changed from another customer material combination without saving
      errorInputIdentifiers.current.clear();
      validatedDemandToWrite.current = {
        customerNumber: customerNumber,
        materialNumber: materialNumber,
        kpiEntries: [],
      };
    }

    // Check if the value is valid
    const parsedValue = value == '' || value == null ? null : strictlyParseFloat(value);
    if (parsedValue !== null && (Number.isNaN(parsedValue) || parsedValue < 0)) {
      errorInputIdentifiers.current.add(date);
      checkCurrentChangeStatus();
      return;
    } else {
      errorInputIdentifiers.current.delete(date);
    }

    // Save element
    if (validatedDemandToWrite.current) {
      validatedDemandToWrite.current.kpiEntries = validatedDemandToWrite.current.kpiEntries.filter(
        (v) => v.fromDate != date,
      );
      validatedDemandToWrite.current.kpiEntries.push({
        fromDate: date,
        bucketType: period,
        validatedForecast: parsedValue,
      });
    }
    checkCurrentChangeStatus();
  };

  const onClickHeader = (entry: KpiEntry) => {
    if (!confirmContinueAndLooseUnsavedChanges()) {
      return;
    }

    // An exception describes a full month and never a part of the month.
    // Therefore, we use the start of month date to identify the exception.
    const startOfMonthPeriod = startOfMonth(parseISO(entry.fromDate));

    const index = kpiRangeExceptions?.map(Number).indexOf(+startOfMonthPeriod);
    const newKpiRangeExceptions = [...kpiRangeExceptions];
    if (index !== -1) {
      newKpiRangeExceptions?.splice(index, 1);
    } else {
      newKpiRangeExceptions?.push(startOfMonthPeriod);
    }
    setKpiRangeExceptions(newKpiRangeExceptions);

    // clear the changed data an errors
    validatedDemandToWrite.current = null;
    errorInputIdentifiers.current = new Set<string>();
    checkCurrentChangeStatus();
    // to invalidate changes in the table, we mutate here
    // we do not need to reload it directly, so the second parameter is not required
    // this can be changed after refactoring the refactoring of the ag grid state handling
    mutate(undefined);
  };

  const changeCustomerConsideringUnSavedChanges = useCallback(
    (customer: CustomerEntry) => {
      if (
        selectedCustomer.customerNumber === customer.customerNumber ||
        !confirmContinueAndLooseUnsavedChanges()
      ) {
        return;
      }
      setSelectedCustomer(customer);
    },
    [selectedCustomer, setSelectedCustomer, confirmContinueAndLooseUnsavedChanges],
  );

  const changeDateRangeConsideringUnSavedChanges = useCallback(
    (range: KpiDateRanges) => {
      if (!confirmContinueAndLooseUnsavedChanges()) {
        return;
      }

      validatedDemandToWrite.current = null;
      errorInputIdentifiers.current = new Set<string>();
      checkCurrentChangeStatus();

      setDateRange(range);
    },
    [
      confirmContinueAndLooseUnsavedChanges,
      validatedDemandToWrite,
      errorInputIdentifiers,
      checkCurrentChangeStatus,
      setDateRange,
    ],
  );

  return (
    <>
      <ActionBar
        toggleMaterialListVisible={() => setMaterialListVisible(!materialListVisible)}
        changeCustomerConsideringUnSavedChanges={changeCustomerConsideringUnSavedChanges}
        planningView={planningView}
        currentCustomer={selectedCustomer}
        customers={customers}
        dateRange={dateRange}
        reloadData={mutate}
        changeDateRangeConsideringUnSavedChanges={changeDateRangeConsideringUnSavedChanges}
        onPlanningViewChange={onPlanningViewChange}
        onDeleteUnsavedForecast={deleteUnsavedForecast}
        onSaveForecast={saveForecast}
        isDisabledForecastEditing={!visibleEditButtons}
        demandValidationFilters={demandValidationFilters}
        onDemandValidationFilterChange={setDemandValidationFilters}
      />
      <TableContentWrapper>
        <MaterialList
          materialListVisible={materialListVisible}
          rowCount={rowCount}
          selectedMaterialListEntry={selectedMaterialListEntry}
          setSelectedMaterialListEntry={setSelectedMaterialListEntry}
          setGrid={setGrid}
          confirmContinueAndLooseUnsavedChanges={confirmContinueAndLooseUnsavedChanges}
          toggleFloatingFilter={toggleFloatingFilter}
        />
        <DemandValidationTable
          kpiData={isValidating ? undefined : kpiData}
          kpiError={kpiError}
          materialListEntry={selectedMaterialListEntry}
          onClickHeader={onClickHeader}
          onUpdateValidatedForecast={updateValidatedForecastToWrite}
          contentGridRef={contentGridRef}
          planningView={planningView}
        />
      </TableContentWrapper>
    </>
  );
}

const TableContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-top: 3px solid #e2e2e2;
  margin-top: 20px;
`;
