import { Button, FormControl, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { deduplicateArray } from '../../core/array';
import { OptionsLoadingResult } from '../../core/hooks/useSelectableOptions';
import { t } from '../../core/i18n/i18n';
import { secondaryColor } from '../../styles/colors';
import { LoadingSpinnerModal } from '../loadingSpinner/LoadingSpinnerDialog';
import Modal from '../modal/Modal';

import { MultiAutocompleteOnType } from './autocomplete/MultiAutocompleteOnType';
import { MultiAutocompletePreLoaded } from './autocomplete/MultiAutocompletePreLoaded';
import {
  AutocompleteMultiValueBaseProps,
  AutocompleteLabelProps,
} from './baseComponents/MultiAutocompleteBase';
import {
  ResolveSelectableValueResult,
  SelectableValue,
  mapToOptionsIfPossible,
  toOptionsWithSearchCallIfNecessary,
} from './baseComponents/selectableValues';

type MultiselectFromClipboardModalProps = {
  open: boolean;
  onClose?: () => void;
} & MultiselectFromClipboardProps;

export type MultiselectFromClipboardProps = {
  // Use getOptions for OnType or optionsLoadingResult for preLoaded
  getOptions?: (searchTerm: string, signal?: AbortSignal) => Promise<SelectableValue[]>;
  optionsLoadingResult?: OptionsLoadingResult;
  selectableValuesByKeys: (value: Array<string>) => Promise<Array<ResolveSelectableValueResult>>;
} & MultiselectFromClipboardLabelProps &
  AutocompleteMultiValueBaseProps &
  AutocompleteLabelProps;

export type MultiselectFromClipboardLabelProps = {
  entityName: string;
  entityNamePlural: string;
};

export function MultiselectFromClipboardModal(props: MultiselectFromClipboardModalProps) {
  const [selectedValues, setSelectedValues] = useState<SelectableValue[]>([]);
  const [showLoading, setShowLoading] = useState(false);

  const { value, getOptions, optionsLoadingResult } = props;

  // Process values from outside and integrate them
  useEffect(() => {
    if (getOptions) {
      toOptionsWithSearchCallIfNecessary(value, getOptions).then(
        (selectedValues: SelectableValue[]) => {
          setSelectedValues(selectedValues);
        },
      );
    } else if (optionsLoadingResult?.options) {
      setSelectedValues(mapToOptionsIfPossible(value, optionsLoadingResult.options));
    }
  }, [value, getOptions, optionsLoadingResult]);

  const [clipboardErrors, setClipboardErrors] = useState<{
    messages: string[];
    ignoredValues: string[];
  }>({ messages: [], ignoredValues: [] });

  function deduplicateSelectableValues(values: SelectableValue[]) {
    const deduped: SelectableValue[] = [];

    values.forEach((val) => {
      if (!deduped.some((v) => v.id == val.id)) {
        deduped.push(val);
      }
    });

    return deduped;
  }

  const handleOnClose = () => {
    clearErrors();
    props.onClose?.();
  };

  const pasteFromClipboard = async () => {
    try {
      setShowLoading(true);
      clearErrors();

      const clipText = await navigator.clipboard.readText();
      const clipValues = clipText
        .split(/[\t\r\n]+/)
        .map((val) => val.trim())
        .filter((val) => val);

      const results = await props.selectableValuesByKeys(clipValues);

      const validValues = results
        .filter((entry) => entry?.selectableValue != null)
        .map((entry) => entry?.selectableValue) as Array<SelectableValue>;
      const errors = results.filter((entry) => entry?.error);

      setClipboardErrors({
        messages: deduplicateArray(errors.flatMap((entry) => (entry.error ? entry.error : []))),
        ignoredValues: errors.map((entry) => entry.id),
      });

      if (validValues.length > 0) {
        setSelectedValues(deduplicateSelectableValues([...selectedValues, ...validValues]));
      }
    } finally {
      setShowLoading(false);
    }
  };

  const onAutocompleteValueChange = (_: unknown, values: SelectableValue[]) => {
    setSelectedValues(values);
  };

  const resetSelection = () => {
    clearErrors();
    setSelectedValues([]);
  };

  const done = () => {
    clearErrors();
    props.onValueChange(null, selectedValues);
    props.onClose?.();
  };

  const clearErrors = () => {
    setClipboardErrors({ messages: [], ignoredValues: [] });
  };

  return (
    <Modal open={props.open} fullWidth maxWidth="md" onClose={handleOnClose}>
      <Modal.Headline
        onClose={handleOnClose}
        text={t('multiselect.title', { entities: props.entityNamePlural })}
      />
      <Modal.Body>
        <Instructions>
          {t('multiselect.instructions', { entities: props.entityNamePlural })}
        </Instructions>

        <ColumnWrapper>
          <LeftColumn>
            <FormControl fullWidth size={'small'}>
              {getOptions && (
                <MultiAutocompleteOnType
                  getOptions={getOptions}
                  value={selectedValues}
                  onValueChange={onAutocompleteValueChange}
                  autocompleteLabel={props.autocompleteLabel}
                  muiProps={{ limitTags: undefined, ...props.muiProps }}
                />
              )}
              {!getOptions && optionsLoadingResult && (
                <MultiAutocompletePreLoaded
                  optionsLoadingResult={optionsLoadingResult}
                  value={selectedValues}
                  onValueChange={onAutocompleteValueChange}
                  autocompleteLabel={props.autocompleteLabel}
                  muiProps={{ limitTags: undefined, ...props.muiProps }}
                />
              )}
            </FormControl>
          </LeftColumn>

          <RightColumn>
            <ButtonWithMargin color="white" onClick={pasteFromClipboard}>
              {t('paste_from_clipboard', {})}
            </ButtonWithMargin>
            <ButtonWithMargin color="white" onClick={resetSelection}>
              {t('multiselect.clear_selection', {})}
            </ButtonWithMargin>

            {clipboardErrors.messages.length > 0 && (
              <Typography variant="subtitle2" color="error">
                {t('multiselect.error.check_input', {})}
              </Typography>
            )}
            <ErrorList>
              {clipboardErrors.messages.map((message, i) => {
                return <li key={i}>{message}</li>;
              })}
            </ErrorList>
            {clipboardErrors.ignoredValues.length > 0 && (
              <IgnoredValues
                title={t('multiselect.error.ignored_values', {
                  values: clipboardErrors.ignoredValues.join(', '),
                })}
                color="error"
              >
                {t('multiselect.error.ignored_values', {
                  values: clipboardErrors.ignoredValues.join(', '),
                })}
              </IgnoredValues>
            )}
          </RightColumn>
        </ColumnWrapper>

        <ButtonBar>
          <Button onClick={handleOnClose} color="white" variant="contained">
            {t('button.cancel', {})}
          </Button>
          <Button onClick={done} sx={{ ml: 'auto' }} variant="contained">
            {t('multiselect.apply', {})}
          </Button>
        </ButtonBar>
      </Modal.Body>
      <LoadingSpinnerModal open={showLoading} />
    </Modal>
  );
}

const IgnoredValues = styled(Typography).attrs({ variant: 'body2', paragraph: true })`
  max-height: 200px;
  overflow: hidden;
`;

const Instructions = styled(Typography).attrs({ variant: 'body2', paragraph: true })`
  color: ${secondaryColor};
  margin-bottom: 24px;
`;

const ColumnWrapper = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  width: 50%;
`;

const RightColumn = styled(LeftColumn)`
  margin-left: 50px;
  padding: 0 20px;
`;

const ButtonWithMargin = styled(Button).attrs({ variant: 'contained', fullWidth: true })`
  margin-bottom: 10px;
`;

const ButtonBar = styled.div`
  display: flex;
  margin-top: 32px;
`;

const ErrorList = styled(Typography).attrs({
  component: 'ul',
  paragraph: true,
  variant: 'body2',
  color: 'error',
})`
  padding-left: 16px;
`;
