import React from 'react';
import styled from 'styled-components';

import { t } from '../../../../core/i18n/i18n';

import { CMPData, PortfolioStatus } from './cmpModalTypes';
import InactivateStatusSpecificContent from './statusSpecificContent/InactivateStatusSpecificContent';
import PhaseInStatusSpecificContent from './statusSpecificContent/PhaseInStatusSpecificContent';
import PhaseOutStatusSpecificContent from './statusSpecificContent/PhaseOutStatusSpecificContent';
import SubstitutionStatusSpecificContent from './statusSpecificContent/SubstitutionStatusSpecificContent';

export type StatusSpecificContentProps = {
  data: CMPData;
  onDataChange: (newData: CMPData) => void;
  showValidation: boolean;
  setValid: (isValid: boolean) => void;
};

/**
 * Component shows the specific content needed for each modal. The upper part stays the same for all edit and status change modals.
 */
export default function StatusSpecificContent(props: StatusSpecificContentProps) {
  const statusToUse: PortfolioStatus | 'NO' = props.data.portfolioStatus || 'NO';
  const noStatusError = (
    <InfoMessage>{t('customer_material_portfolio.modal.error.no_status', {})}</InfoMessage>
  );

  switch (statusToUse) {
    case 'PI':
      return <PhaseInStatusSpecificContent {...props} />;
    case 'PO':
      return <PhaseOutStatusSpecificContent {...props} />;
    case 'SE':
      return <SubstitutionStatusSpecificContent {...props} />;
    case 'IA':
      return <InactivateStatusSpecificContent {...props} />;
    case 'SB':
    case 'SI':
    case 'SP':
    case 'AC':
      return <></>;
    case 'NO':
      return noStatusError;
  }
}

const InfoMessage = styled.div`
  margin: 20px 10px 10px;
`;
