import { ICellRendererParams, ValueFormatterParams } from '@ag-grid-community/core';
import { parseISO } from 'date-fns';

import { t } from '../../core/i18n/i18n';
import { ExecDay, ExecInterval } from '../../domain/alertrules/model';

const dateFilterCellValueComparator = (filterLocalDateAtMidnight: Date, cellValue: any) => {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }

  const cellDate = parseISO(dateAsString);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const alertRuleColumnDefinitions = [
  {
    colId: 'region',
    title: 'material_customer.column.region',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'salesArea',
    title: 'material_customer.column.salesArea',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'salesOrg',
    title: 'material_customer.column.salesOrg',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'customerNumber',
    title: 'material_customer.column.customerNumber',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: true,
    sortable: true,
    sort: null,
  },
  {
    colId: 'customerName',
    title: 'material_customer.column.customerName',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'materialNumber',
    title: 'material_customer.column.materialNumber',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'materialDescription',
    title: 'material_customer.column.materialDescription',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'materialClassification',
    title: 'material_customer.column.materialClassification',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'sectorManagement',
    title: 'material_customer.column.sectorManagement',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'productionLine',
    title: 'material_customer.column.productionLine',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'productLine',
    title: 'material_customer.column.productLine',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'gkamNumber',
    title: 'material_customer.column.gkamNumber',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'gkamName',
    title: 'material_customer.column.gkamName',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'demandPlanner',
    title: 'material_customer.column.demandPlanner',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'threshold1',
    title: 'rules.threshold1',
    filter: 'agNumberColumnFilter',
    filterParams: undefined,
    cellRenderer: 'numberRenderer',
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'threshold1Description',
    title: 'rules.threshold1Description',
    cellRenderer: undefined,
    filter: undefined,
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: false,
    sort: null,
  },
  {
    colId: 'threshold2',
    title: 'rules.threshold2',
    filter: 'agNumberColumnFilter',
    filterParams: undefined,
    cellRenderer: 'numberRenderer',
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'threshold2Description',
    title: 'rules.threshold2Description',
    cellRenderer: undefined,
    filter: undefined,
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: false,
    sort: null,
  },
  {
    colId: 'threshold3',
    title: 'rules.threshold3',
    filter: 'agNumberColumnFilter',
    filterParams: undefined,
    cellRenderer: 'numberRenderer',
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'threshold3Description',
    title: 'rules.threshold3Description',
    cellRenderer: undefined,
    filter: undefined,
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: false,
    sort: null,
  },
  {
    colId: 'startDate',
    title: 'rules.startDate',
    cellRenderer: 'dateRenderer',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateFilterCellValueComparator,
    },
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'execDay',
    title: 'rules.execDay',
    cellRenderer: (params: ICellRendererParams) => {
      return t(`alert_rules.edit_modal.label.when.${params.value as ExecDay}`, {});
    },
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'execInterval',
    title: 'rules.execInterval',
    cellRenderer: (params: ICellRendererParams) => {
      return t(`alert_rules.edit_modal.label.interval.${params.value as ExecInterval}`, {});
    },
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'endDate',
    title: 'rules.endDate',
    cellRenderer: 'dateRenderer',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateFilterCellValueComparator,
    },
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'deactivated',
    title: 'rules.deactivated',
    cellRenderer: (params: ICellRendererParams) => {
      return t(`alert_rules.table.deactivated.${params.value as boolean}`, {});
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [true, false],
      valueFormatter: (params: ValueFormatterParams) => {
        return t(`alert_rules.table.deactivated.${params.value as boolean}`, {});
      },
    },
    visible: true,
    alwaysVisible: true,
    sortable: true,
    sort: null,
  },
  {
    colId: 'activeCount',
    title: 'rules.activeCount',
    cellRenderer: undefined,
    filter: 'agNumberColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'inactiveCount',
    title: 'rules.inactiveCount',
    cellRenderer: undefined,
    filter: 'agNumberColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'completedCount',
    title: 'rules.completedCount',
    cellRenderer: undefined,
    filter: 'agNumberColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'usernameCreated',
    title: 'alert_rules.table.column.usernameCreated',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
  {
    colId: 'usernameLastChanged',
    title: 'alert_rules.table.column.usernameLastChanged',
    cellRenderer: undefined,
    filter: 'agTextColumnFilter',
    filterParams: undefined,
    visible: true,
    alwaysVisible: false,
    sortable: true,
    sort: null,
  },
] as const;
