import { LicenseManager } from '@ag-grid-enterprise/core';

/**
 * Set agGrid license key (this is public by design)
 */
export default function setupGridLicense() {
  LicenseManager.setLicenseKey(
    `CompanyName=SparkDynamic GmbH_on_behalf_of_Schaeffler Technologies AG & Co. KG,LicensedApplication=Schaeffler Demand360,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-031177,SupportServicesEnd=1_November_2023_[v2]_MTY5ODc5NjgwMDAwMA==8dd3791e563724e6d6951993a1997a79`,
  );
}
