import { PostResult } from '../../core/errorhandling';
import { getErrorMessage } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';

import { AlertRule, AlertRuleSaveResponse, dataToAlertRuleRequest } from './model';

const MULTI_ALERT_RULES_API = 'alert-rules/multi-alerts';

export async function saveMultiAlertRules(
  data: AlertRule[],
  dryRun?: boolean,
): Promise<PostResult<AlertRuleSaveResponse>> {
  try {
    if (dryRun) {
      return {
        overallStatus: 'SUCCESS',
        overallErrorMsg: null,
        response: [],
      };
    }
    const request = data.map((d) => dataToAlertRuleRequest(d));

    const result = (await requestFromApi(MULTI_ALERT_RULES_API, {
      method: 'POST',
      body: JSON.stringify(request),
    })) as AlertRuleSaveResponse[];

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: result,
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}

export async function deleteMultiAlterRules(
  data: AlertRule[],
  dryRun: boolean,
): Promise<PostResult<AlertRuleSaveResponse>> {
  try {
    if (dryRun) {
      return {
        overallStatus: 'SUCCESS',
        overallErrorMsg: null,
        response: [],
      };
    }
    const request = data.map((d) => dataToAlertRuleRequest(d));

    const result = (await requestFromApi(MULTI_ALERT_RULES_API, {
      method: 'DELETE',
      body: JSON.stringify(request),
    })) as AlertRuleSaveResponse[];

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: result,
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}

export async function deleteSingleAlterRule(
  data: AlertRule,
): Promise<PostResult<AlertRuleSaveResponse>> {
  try {
    const request = dataToAlertRuleRequest(data);

    const url = 'alert-rules/single-alert';
    const result = (await requestFromApi(url, {
      method: 'DELETE',
      body: JSON.stringify(request),
    })) as AlertRuleSaveResponse[];

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: result,
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}
