/**
 * This maps our role names to roles from Azure AD
 */
const RoleMapping = {
  salesUser: ['ROLE_SD-D360_SALES_DIR', 'ROLE_SD-D360_SALES_IND'],
  salesManagement: ['ROLE_SD-D360_SALES_MGMT_DIR', 'ROLE_SD-D360_SALES_MGMT_IND'],
  demandPlanning: ['ROLE_SD-D360_DM_DPLANNER'],
  readOnly: ['ROLE_SD-D360_RO'],
  readOnlyRestricted: ['ROLE_SD-D360_RO_RESTRICTED'],
  superUser: ['ROLE_SD-D360_ADMIN'],
};

export type Role = keyof typeof RoleMapping;

export function checkRoles(givenRoles: string[], allowedRoles: readonly Role[]): boolean {
  // use roles without territory information and without business area information
  const givenRolesWithoutTerritory = givenRoles.map((role) => role.split('=')[0]);
  const allowedRoleNames = allowedRoles.flatMap((role) => RoleMapping[role]);
  return givenRolesWithoutTerritory.some((role) => allowedRoleNames.includes(role));
}

export const demandValidationChangeAllowedRoles: Role[] = [
  'salesUser',
  'demandPlanning',
  'superUser',
];

export const apPortfolioAllowedRoles: Role[] = [
  'salesManagement',
  'demandPlanning',
  'readOnly',
  'superUser',
];

export const internalMaterialReplacementAllowedRoles: Role[] = ['demandPlanning', 'superUser'];

export const workflowManagementAllowedRoles: Role[] = ['demandPlanning', 'superUser'];
