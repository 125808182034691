import { Box, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { GridApis } from '../../../agGrid/gridTypes';
import TableToolbar from '../../../components/agGrid/TableToolbar';
import { DATA_FETCHED_EVENT } from '../../../core/datasources';
import { t } from '../../../core/i18n/i18n';

export function CMPToolbar({
  grid,
  rowCount,
}: {
  grid: GridApis | undefined;
  rowCount: number | undefined;
}) {
  const [showChains, setShowChains] = useState(false);

  useEffect(() => {
    if (!grid) return;

    // We register a listener to expand rows when new data is fetched
    // This is needed because the expandAll just expands the rows that are already loaded
    // By default, rows are collapsed when new data is fetched. Therefore, we do not need to collapse them manually
    // We register a new listener every time the showChains settings is changed, because the value is not updated here
    // The old listener is removed in the return statement of this hook
    const listener = () => {
      if (showChains) {
        grid?.api.expandAll();
      }
    };
    grid.api.addEventListener(DATA_FETCHED_EVENT, listener);

    return () => {
      grid.api.removeEventListener(DATA_FETCHED_EVENT, listener);
    };
  }, [grid, showChains]);

  const toggleShowChains = (_: any, checked: boolean) => {
    setShowChains(checked);

    if (checked) {
      grid?.api.expandAll();
    } else {
      grid?.api.collapseAll();
    }
  };

  return (
    <TableToolbar rowCount={rowCount} grid={grid}>
      <Box ml="auto" marginRight={'10px'}>
        <Typography component="span" color="secondary" variant="body2" marginRight={'10px'}>
          {t('customer_material_portfolio.toolbar.collapseAll', {})}
        </Typography>
        <Switch checked={showChains} onChange={toggleShowChains} size={'small'} />
        <Typography component="span" color="secondary" variant="body2" marginLeft={'10px'}>
          {t('customer_material_portfolio.toolbar.expandAll', {})}
        </Typography>
      </Box>
    </TableToolbar>
  );
}
