import { formatISO } from 'date-fns';
import useSWR from 'swr';

import { DateRangePeriod } from '../../core/dateRange';

import { KpiData, KpiDateRanges, MaterialListEntry, SelectedKpis } from './model';

type KpiDataRequest = {
  selectedKpis: SelectedKpis;
  customerNumber: string;
  materialNumber: string;
  range1: KpiDataRequestDateRange;
  range2?: KpiDataRequestDateRange;
  exceptions: Array<string>;
};

export type KpiDataRequestDateRange = {
  from: string;
  to: string;
  period: DateRangePeriod;
};

export default function useKpiData(
  materialListEntry: MaterialListEntry | undefined,
  kpiDateRanges: KpiDateRanges,
  exceptions: Array<Date>,
) {
  const url = `demand-validation/kpis`;
  const requestParams: KpiDataRequest | undefined =
    materialListEntry?.materialNumber && materialListEntry?.customerNumber
      ? {
          customerNumber: materialListEntry.customerNumber,
          materialNumber: materialListEntry.materialNumber,
          // At the moment, we request all kpis. Therefore, selectedKpis is set here directly. If required,
          // this can be implements as a method parameter.
          selectedKpis: {
            activeAndPredecessor: true,
            deliveries: true,
            firmBusiness: true,
            opportunities: true,
            forecastProposal: true,
            forecastProposalDemandPlanner: true,
            validatedForecast: true,
            indicativeDemandPlan: true,
            currentDemandPlan: true,
            confirmedDeliveries: true,
            confirmedFirmBusiness: true,
            confirmedDemandPlan: true,
          },
          range1: {
            from: formatISO(kpiDateRanges.range1.from, { representation: 'date' }),
            to: formatISO(kpiDateRanges.range1.to, { representation: 'date' }),
            period: kpiDateRanges.range1.period,
          },
          range2: kpiDateRanges.range2
            ? {
                from: formatISO(kpiDateRanges.range2.from, { representation: 'date' }),
                to: formatISO(kpiDateRanges.range2.to, { representation: 'date' }),
                period: kpiDateRanges.range2.period,
              }
            : undefined,
          exceptions: exceptions.map((e) => formatISO(e, { representation: 'date' })),
        }
      : undefined;

  const result = useSWR<KpiData>(
    requestParams
      ? [
          url,
          {
            method: 'POST',
            body: JSON.stringify(requestParams),
          },
        ]
      : undefined,
  );

  return result;
}
