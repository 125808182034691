import { ViewSidebarOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ActionButton } from '../../../components/ActionButton';
import SingleAutocompletePreLoaded from '../../../components/inputs/autocomplete/SingleAutocompletePreLoaded';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { t } from '../../../core/i18n/i18n';
import { CustomerEntry } from '../../../domain/globalSelection/model';

import ActionButtonsDemandValidation, {
  ActionButtonsDVProps,
} from './ActionButtonsDemandVaildation';
import DatePickerSettingDemandValidation, {
  DatePickerSettingDVProps,
} from './DatePickerSettingDemandValidation';
import {
  DemandValidationSettings,
  DemandValidationSettingsProps,
} from './DemandValidationSettings';
import FilterDemandValidation, { FilterDemandValidationProps } from './FilterDemandValidation';
import ForecastEditingActionButtons, {
  ForecastEditingActionButtonProps,
} from './ForecastEditingActionButtons';

type ActionBarProps = {
  toggleMaterialListVisible: () => void;
  changeCustomerConsideringUnSavedChanges: (customer: CustomerEntry) => void;
} & ActionButtonsDVProps &
  DatePickerSettingDVProps &
  DemandValidationSettingsProps &
  ForecastEditingActionButtonProps &
  FilterDemandValidationProps;

export const ActionBar = (props: ActionBarProps) => {
  const { customers, changeCustomerConsideringUnSavedChanges } = props;

  const customerSelectables = useMemo(
    () =>
      customers.map((customer) => {
        return {
          id: customer.customerNumber,
          text: customer.customerName || '',
        } as SelectableValue;
      }),
    [customers],
  );

  const onCustomerChanged = useCallback(
    (event: unknown, values: SelectableValue | null) => {
      const newCustomer = customers.find((c) => c.customerNumber === values?.id);
      if (newCustomer) {
        changeCustomerConsideringUnSavedChanges(newCustomer);
      }
    },
    [customers, changeCustomerConsideringUnSavedChanges],
  );

  return (
    <Layout>
      <Part>
        <ActionButton variant="text" color={'secondary'} onClick={props.toggleMaterialListVisible}>
          <ViewSidebarOutlined />
        </ActionButton>

        <ActionDivider />

        <ActionButtonsDemandValidation
          planningView={props.planningView}
          currentCustomer={props.currentCustomer}
          customers={props.customers}
          dateRange={props.dateRange}
          reloadData={props.reloadData}
          demandValidationFilters={props.demandValidationFilters}
        />

        <ActionDivider />

        <VkOrgDisplay>
          <Typography variant={'subtitle1'} color={'#646464'}>
            {t('validation_of_demand.vkorg', {})}
          </Typography>
          <Typography>{props.currentCustomer.salesOrg || ''}</Typography>
        </VkOrgDisplay>

        <ActionDivider />

        <SingleAutocompletePreLoaded
          value={props.currentCustomer.customerNumber}
          optionsLoadingResult={{ options: customerSelectables }}
          onValueChange={onCustomerChanged}
          autocompleteLabel={t('validation_of_demand.customer', {})}
          muiProps={{ sx: { width: 300 } }}
        />

        <FilterDemandValidation
          demandValidationFilters={props.demandValidationFilters}
          onDemandValidationFilterChange={props.onDemandValidationFilterChange}
        />
      </Part>

      <Part>
        <DatePickerSettingDemandValidation
          dateRange={props.dateRange}
          changeDateRangeConsideringUnSavedChanges={props.changeDateRangeConsideringUnSavedChanges}
        />

        <DemandValidationSettings
          planningView={props.planningView}
          onPlanningViewChange={props.onPlanningViewChange}
        />

        <ActionDivider />

        <ForecastEditingActionButtons
          isDisabledForecastEditing={props.isDisabledForecastEditing}
          onDeleteUnsavedForecast={props.onDeleteUnsavedForecast}
          onSaveForecast={props.onSaveForecast}
        />
      </Part>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Part = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const ActionDivider = styled.div`
  height: 40px;
  border-right: 1px solid #646464;
`;

const VkOrgDisplay = styled.span`
  display: inline-flex;
  flex-direction: column;

  > * {
    white-space: nowrap;
    line-height: unset;
  }
`;
