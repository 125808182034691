import { requestFromApi } from '../../core/requests/httpClient';

export async function completeAlert(alertId: string) {
  return await requestFromApi(`alerts/${alertId}/complete`, {
    method: 'POST',
  });
}

export async function activateAlert(alertId: string) {
  return await requestFromApi(`alerts/${alertId}/activate`, {
    method: 'POST',
  });
}

export async function deactivateAlert(alertId: string) {
  return await requestFromApi(`alerts/${alertId}/deactivate`, {
    method: 'POST',
  });
}
