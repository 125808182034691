import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fillZeroFunc } from '../../core/validation/validationHelper';

export const useUrlCustomerNumbers = (): string[] => {
  const [urlSearchParams] = useSearchParams();
  return useMemo(
    () =>
      (urlSearchParams.get('customerNumber') || '')
        .split(',')
        .filter(Boolean)
        .map(fillZeroFunc(10)),
    [urlSearchParams],
  );
};
