import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { useUserData } from '../../domain/user/useUserData';

import {
  handleOneTrustCookies,
  initializeApplicationInsights,
  trackCustomUserProperties,
} from './applicationInsights';
import { areMapsEquals, cookiesPermissionMap } from './oneTrust/oneTrustUtils';
import { CookiesGroups, OneTrust } from './oneTrust/types';

export default function AppInsightsProvider({
  children,
  oneTrust,
}: {
  children: React.ReactNode;
  oneTrust: OneTrust | undefined;
}) {
  const [aiReactPlugin, setAiReactPlugin] = useState<ReactPlugin>();
  const [cookiesGroupsMap, setCookiesGroupsMap] = useState<Map<CookiesGroups, boolean>>(new Map());
  const [appInsights, setAppInsights] = useState<ApplicationInsights>();

  const { pathname } = useLocation();
  const userData = useUserData();

  useEffect(() => {
    // application insights initialization
    const aiReactPlugin = new ReactPlugin();
    const appInsights = initializeApplicationInsights(
      `${process.env.AI_INSTRUMENTATION_KEY}`,
      aiReactPlugin,
    );
    setAiReactPlugin(aiReactPlugin);
    setAppInsights(appInsights);
  }, []);

  const trackPageView = useCallback(() => {
    const trackingAllowed = !appInsights?.config.disableTelemetry;

    if (trackingAllowed) {
      appInsights?.trackPageView({ name: pathname });
    }
  }, [appInsights, pathname]);

  const triggerConsentChanged = useCallback(
    (isInitialTrigger: boolean) => {
      const activeGroups = ((window as any)['OnetrustActiveGroups'] as string)
        .split(',')
        .filter(Boolean);

      const nextMap = cookiesPermissionMap(activeGroups);

      if (!areMapsEquals(cookiesGroupsMap, nextMap)) {
        const trackingAllowed = handleOneTrustCookies(appInsights as ApplicationInsights, nextMap);
        setCookiesGroupsMap(nextMap);
        if (trackingAllowed && !isInitialTrigger) {
          trackPageView();
        }
      }
    },
    [appInsights, cookiesGroupsMap, trackPageView],
  );

  useEffect(() => {
    if (oneTrust) {
      // initial consent
      triggerConsentChanged(true);

      // listen to consent changes
      oneTrust.OnConsentChanged(() => {
        triggerConsentChanged(false);
      });
    }
  }, [oneTrust, triggerConsentChanged]);

  useEffect(() => {
    if (userData.successfullyLoaded && appInsights) {
      trackCustomUserProperties(appInsights, userData);
      trackPageView();
    }
  }, [userData, appInsights, trackPageView]);

  return (
    <AppInsightsContext.Provider value={aiReactPlugin as ReactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
}
