import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar as MuiToolbar, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import brand from '../../assets/img/brand.svg';
import { t } from '../../core/i18n/i18n';

export const headerHeight = '56px';

interface HeaderProps {
  onMenuClick?: (e: React.MouseEvent) => void;
}

export function Header({ onMenuClick }: HeaderProps) {
  return (
    <AppBar position="fixed" elevation={2}>
      <Toolbar variant="dense">
        <StyledIconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </StyledIconButton>
        <StyledHeader variant="h6">{t('header.title', {})}</StyledHeader>
        <img src={brand} alt={t('header.brand.alt', {})} height={24} width={210} />
      </Toolbar>
    </AppBar>
  );
}

const Toolbar = styled(MuiToolbar)`
  padding: 0 16px;
  height: 56px;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 20px;
`;

const StyledHeader = styled(Typography)`
  font-weight: 500;
  letter-spacing: 0.25px;
  flex-grow: 1;
`;
