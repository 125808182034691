import useSWR, { Fetcher, SWRConfiguration } from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';

import { AlertStatus } from './model';

export const useAlertCount = (
  status: AlertStatus,
  config?: SWRConfiguration<any, Error, Fetcher<any, `alerts/count`>>,
) => {
  const { data, mutate, error } = useSWR(`alerts/count?status=${status}`, requestFromApi, config);

  return {
    count: data,
    refresh: mutate,
    error,
  };
};
