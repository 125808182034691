import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';

export const valueParserForSelectableOptions =
  (options: SelectableValue[]) =>
  (params: any): string => {
    const value = options.find(
      (option) =>
        option.id == params.newValue || (option.text == params.newValue && params.newValue !== ''),
    )?.id;
    return value !== undefined ? value : params.newValue;
  };
