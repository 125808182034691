/* l10n -> localization */
import { format, isValid, parse, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

import { strictlyParseFloat } from '../number';

// DATE FORMAT
export const availableDateFormats = ['dd.MM.yyyy', 'MM/dd/yyyy', 'MM-dd-yyyy'] as const;
export type AvailableDateFormats = (typeof availableDateFormats)[number];

// SHORT DATE FORMAT
// Used when omitting day from date`
// e.g. inside header of demand validation
export const availableDateFormatsWithoutDay = ['MM.yyyy', 'MM/yyyy', 'MM-yyyy'] as const;
export type AvailableDateFormatsWithoutDay = (typeof availableDateFormatsWithoutDay)[number];

const LOCAL_STORAGE_DATE_FORMAT = 'preferredDateFormat';

export function setPreferredDateFormat(dateFormat: AvailableDateFormats) {
  localStorage.setItem(LOCAL_STORAGE_DATE_FORMAT, dateFormat);
}

function getPreferredDateFormat(): AvailableDateFormats {
  const storedDateFormat = localStorage.getItem(LOCAL_STORAGE_DATE_FORMAT) || '';
  if (availableDateFormats.includes(storedDateFormat as AvailableDateFormats)) {
    return storedDateFormat as AvailableDateFormats;
  }
  return 'dd.MM.yyyy';
}

function getPreferredDateFormatWithoutDay(): AvailableDateFormatsWithoutDay {
  const storedFullDateFormat = getPreferredDateFormat();
  const originalIndex = availableDateFormats.indexOf(storedFullDateFormat as AvailableDateFormats);

  if (originalIndex >= 0) {
    return availableDateFormatsWithoutDay[originalIndex];
  }

  return 'MM.yyyy';
}

// DECIMAL SEPARATOR
export const availableDecimalSeparators = ['PERIOD', 'COMMA'] as const;
export type AvailableDecimalSeparators = (typeof availableDecimalSeparators)[number];

const LOCAL_STORAGE_DECIMAL_SEPARATOR = 'decimalSeparator';

export function setPreferredDecimalSeparator(dateFormat: AvailableDecimalSeparators) {
  localStorage.setItem(LOCAL_STORAGE_DECIMAL_SEPARATOR, dateFormat);
}

function getPreferredDecimalSeparator(): AvailableDecimalSeparators {
  const storedDecimalSeparator = localStorage.getItem(LOCAL_STORAGE_DECIMAL_SEPARATOR) || '';
  if (availableDecimalSeparators.includes(storedDecimalSeparator as AvailableDecimalSeparators)) {
    return storedDecimalSeparator as AvailableDecimalSeparators;
  }
  return 'PERIOD';
}

export const preferredDateFormat = getPreferredDateFormat();
export const preferredDateFormatWithoutDay = getPreferredDateFormatWithoutDay();
export const datePickerMasks = {
  preferredDateFormat: preferredDateFormat.replace(/\w/gi, '_'),
  preferredDateFormatWithoutDay: preferredDateFormatWithoutDay.replace(/\w/gi, '_'),
};
export const preferredDecimalSeparator = getPreferredDecimalSeparator();

export const formatNumber = (number: number): string => {
  return number.toLocaleString(preferredDecimalSeparator === 'COMMA' ? 'de-DE' : 'en-US');
};

export const parseAndFormatNumber = (value: any): string => {
  switch (typeof value) {
    case 'string': {
      const parsed = strictlyParseFloat(value);
      return isNaN(parsed) ? value : formatNumber(parsed);
    }
    case 'number': {
      return formatNumber(value);
    }
    default:
      return value;
  }
};

export const parseDate = (value: string): Date | undefined => {
  const preferedFormat = getPreferredDateFormat();
  const orderedFormats = [...availableDateFormats].sort((format) =>
    format == preferedFormat ? -1 : 1,
  );
  for (const format of orderedFormats) {
    const parsed = parse(value, format, new Date());
    if (isValid(parsed)) {
      return parsed;
    }
  }
  // Try ISO if everything else has failed
  const parsedISO = parseISO(value);
  if (isValid(parsedISO)) {
    return parsedISO;
  }
  return undefined;
};

export const formatDate = (date: Date | number): string => {
  return format(date, preferredDateFormat, { weekStartsOn: 1 });
};

export const getCalendarWeek = (date: Date) => {
  return format(date, 'ww', { locale: de });
};
