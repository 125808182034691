import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

import { UserData } from '../../domain/user/model';

import * as appInsightsService from './applicationInsights';
import { CookiesGroups } from './oneTrust/types';

const AI_COOKIES = ['ai_session', 'ai_user'];
const APPLICATION_INSIGHTS_DEPARTMENT = 'department';
const APPLICATION_INSIGHTS_FUNCTIONAL_ROLE = 'role';
const BASIC_ACCESS_ROLE = 'ROLE_SD-D360_BASIC_ACCESS=SW';
const ROLE_PREFIX = 'ROLE_SD-D360_';

export const addCustomPropertyToTelemetryData = (
  appInsights: ApplicationInsights,
  tag: string,
  value: string,
): void => {
  const telemetryInitializer = (envelope: ITelemetryItem) => {
    envelope.data = { ...envelope.data, [tag]: value };
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
};

export const trackCustomUserProperties = (
  appInsights: ApplicationInsights,
  userData: UserData,
): void => {
  if (!appInsights.config.disableTelemetry) {
    // log custom property department
    appInsightsService.addCustomPropertyToTelemetryData(
      appInsights,
      APPLICATION_INSIGHTS_DEPARTMENT,
      userData.department || 'unknown',
    );

    const functionalRole =
      userData.roles?.find((role) => role !== BASIC_ACCESS_ROLE)?.replace(ROLE_PREFIX, '') ||
      'unknown';

    // log custom property role(s)
    appInsightsService.addCustomPropertyToTelemetryData(
      appInsights,
      APPLICATION_INSIGHTS_FUNCTIONAL_ROLE,
      functionalRole,
    );
  }
};

export const startTracking = (appInsights: ApplicationInsights): void => {
  if (appInsights) {
    appInsights.config.disableTelemetry = false;
    appInsights.getCookieMgr().setEnabled(true);

    // start tracking if not already initialized
    if (!(appInsights.core.isInitialized && appInsights.core.isInitialized())) {
      appInsights.loadAppInsights();
    }
  }
};

export const deleteCookies = (appInsights: ApplicationInsights): void => {
  if (appInsights) {
    // delete cookies since app insights only disable usage of them
    AI_COOKIES.forEach((aiCookie) => appInsights.getCookieMgr().del(aiCookie));
    appInsights.getCookieMgr().setEnabled(false);
    appInsights.config.disableTelemetry = true;
  }
};

export const isTrackingAllowed = (cookiesGroups: any) => {
  return cookiesGroups.get(CookiesGroups.PerformanceCookies);
};

export const handleOneTrustCookies = (
  appInsights: ApplicationInsights,
  cookiesGroups: Map<CookiesGroups, boolean>,
): boolean => {
  if (!appInsightsService.isTrackingAllowed(cookiesGroups)) {
    // only when opting out after consent was given before
    appInsightsService.deleteCookies(appInsights);
    return false;
  } else {
    appInsightsService.startTracking(appInsights);
    return true;
  }
};

export const initializeApplicationInsights = (
  instrumentationKey: string,
  reactPlugin: ReactPlugin,
): ApplicationInsights => {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();

  return appInsights;
};
