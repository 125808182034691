import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';
import styled, { css } from 'styled-components';

import { borderColor, dimmedGreen, dimmedRed, dimmedYellow } from '../../../styles/colors';

export default function TrafficLightCellRenderer(props: ICellRendererParams) {
  return (
    <Container>
      <TrafficLight>
        <Light $color={props.value == 'RED' ? dimmedRed : borderColor} />
        <Light $color={props.value == 'YELLOW' ? dimmedYellow : borderColor} />
        <Light $color={props.value == 'GREEN' ? dimmedGreen : borderColor} />
      </TrafficLight>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TrafficLight = styled.div`
  display: flex;
  border-width: 1px;
  padding: 0 3px;
  background-color: #0000001a;
  border-radius: 16px;
`;

const Light = styled.div<{ $color: string }>`
  width: 15px;
  height: 15px;
  margin: 2px;
  border-radius: 50%;
  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}
`;
