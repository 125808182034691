import * as React from 'react';
import styled from 'styled-components';

import { t } from '../../core/i18n/i18n';

const CookieBtn = styled.button`
  margin: 20px 0;
`;

export const CookiePolicyDE = () => {
  return (
    <>
      <h3>Cookie-Richtline</h3>
      <h5>Hinweise zur Verwendung von Cookies</h5>
      <p>
        Wenn Sie eine der Webseiten der Schaeffler AG und Gesellschaften, an denen die Schaeffler AG
        selbst direkt oder indirekt mehrheitlich beteiligt ist (Schaeffler Gruppe) besuchen, werden
        auf Ihrem Endgerät Informationen in Form eines sog. „Cookies“ gespeichert. Cookies sind
        kleine Textdateien, die die Analyse der Benutzung der Webseite ermöglichen, Einstellungen
        und Daten in Ihrem Browser speichern sowie die Anpassung der Webseite auf Ihre Interessen
        und die Anzeige interessenbasierter Werbung auf anderen Webseiten erlauben. Wir erheben
        mittels Cookies keine personenbezogenen Daten, es sei denn, Sie haben uns hierzu Ihre
        ausdrückliche Einwilligung erteilt.
      </p>

      <div>
        <CookieBtn id="ot-sdk-btn" className="ot-sdk-show-settings">
          {t('cookie_policy_page.open_settings_button', {})}
        </CookieBtn>
      </div>

      <div id="ot-sdk-cookie-policy"></div>
    </>
  );
};
