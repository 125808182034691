import * as React from 'react';

export const ImprintDE = () => {
  return (
    <>
      <h2>Impressum</h2>
      <h3>Verantwortlich für den Inhalt dieser Seiten</h3>
      <p>
        Schaeffler Technologies AG & Co. KG
        <br />
        Industriestraße 1-3
        <br />
        91074 Herzogenaurach
        <br />
        Deutschland
      </p>
      <p>
        Telefon: +49 9132 82-0
        <br />
        Fax: +49 9132 82-49 50
      </p>
      <p>
        <a
          className="icon icon-mail"
          data-widget="GATrakken"
          data-ar-event-category="Engagement"
          data-ar-event-action="Content/Mailto"
          data-ar-event-label="info@schaeffler.com mailto:info@schaeffler.com"
          href="mailto:info@schaeffler.com"
          target="_self"
          title="info@schaeffler.com "
        >
          info@schaeffler.com
        </a>
      </p>
      <p>
        <strong>Sitz:</strong> Herzogenaurach
        <br />
        <strong>Handelsregisternummer:</strong> Amtsgericht Fürth HRA 10129
        <br />
        <strong>Umsatzsteuer-Identifikationsnummer (UStIdNr.):</strong>
        DE 291 636 029
        <br />
      </p>
      <p>
        <strong>Persönlich haftende Gesellschafterin:</strong> Schaeffler AG
        <br />
        <strong>Sitz:</strong> Herzogenaurach
        <br />
        <strong>Registergericht:</strong> Amtsgericht Fürth, HRB 14738
        <br />
      </p>
      <p>
        <strong>Vorsitzender des Aufsichtsrats:</strong> Georg F. W. Schaeffler
      </p>
      <p>
        <strong>Vorstand:</strong> Klaus Rosenfeld (Vors.), Claus Bauer, Andreas Schick, Corinna
        Schittenhelm, Jens Schüler, Dr. Stefan Spindler, Uwe Wagner, Matthias Zink
      </p>
      <p>
        <strong>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</strong> Denis Wiegel
        <br />
        Schaeffler Technologies AG & Co. KG
      </p>
      <p className="g_lastchild">
        <strong>Haftungshinweis:</strong> Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir
        keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
        ausschließlich deren Betreiber verantwortlich.
      </p>
    </>
  );
};
