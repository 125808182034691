import useSWR from 'swr';

import { useSwrErrorNotification } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';
import { CriteriaFields } from '../materialCustomer/model';

export function useCmpCriteriaData() {
  const result = useSWR<CriteriaFields>(
    'customer-material-portfolio/criteria-fields',
    requestFromApi,
  );
  useSwrErrorNotification(result.error);
  return result;
}
