import styled from 'styled-components';

export const FormLayout = styled.div.attrs((props: { direction: string }) => props)`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 16px;
`;

export const FormInputs = styled.div`
  flex: 1 0 auto;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 20px;
  row-gap: 32px;
`;

export const FormActions = styled.div.attrs((props: { justify: string; margin?: string }) => props)`
  flex: 0 1 auto;
  width: 100%;
  align-items: flex-end;
  justify-content: ${(props) => props.justify};
  margin: ${(props) => props.margin};
  display: inline-flex;
  gap: 16px;
  padding: 24px 0 0;
`;

export const FormValidationActions = styled.div`
  flex: 0 1 auto;
  align-items: flex-end;
  justify-content: flex-end;
  display: inline-flex;
  gap: 16px;
`;
