import { formatISO } from 'date-fns';
import React from 'react';

import { DateOrOriginalCellRenderer } from '../../../components/agGrid/cellRenderers/DateOrOriginalCellRenderer';
import { DemandTypeOrOriginalCellRenderer } from '../../../components/agGrid/cellRenderers/DemandTypeOrOriginalCellRenderer';
import { DemandTypeRenderer } from '../../../components/agGrid/cellRenderers/DemandTypeRenderer';
import {
  ColumnForUploadTable,
  ErrorMessage,
  TableUploadModal,
} from '../../../components/tableUploadModal/TableUploadModal';
import { PostResult, errorsFromSAPtoMessage } from '../../../core/errorhandling';
import { t } from '../../../core/i18n/i18n';
import { parseDate } from '../../../core/i18n/l10n';
import {
  parseDateIfPossible,
  parseDemandCharacteristicIfPossible,
} from '../../../core/parseValues';
import { validateDemandCharacteristicType } from '../../../core/validation/dataValidation';
import { validateMaterialNumber } from '../../../core/validation/filterValidation';
import {
  condenseErrorsFromValidation,
  validateAnyDateFormatAndGreaterEqualThanToday,
} from '../../../core/validation/validationHelper';
import {
  CMPBulkPhaseInEntity,
  CMPMaterialPhaseInResponse,
} from '../../../domain/customerMaterialPortfolio/model';
import { saveBulkPhaseIn } from '../../../domain/customerMaterialPortfolio/saveBulkPhaseIn';
import { DemandCharacteristic } from '../../../domain/materialCustomer/model';

type AddMultiCustomerMaterialModalProps = {
  onAdded: () => void;
  onClose: () => void;
  modalOpen: boolean;
  customerNumber: string;
};

type MultiPhaseInData = {
  materialNumber: string | null;
  phaseInDate: Date | null;
  demandCharacteristic: DemandCharacteristic | null;
};

export const AddMultiCustomerMaterialModal = ({
  onAdded,
  onClose,
  modalOpen,
  customerNumber,
}: AddMultiCustomerMaterialModalProps) => {
  const addCustomerNumberAndSave = async (data: MultiPhaseInData[], dryRun: boolean) => {
    const phaseInEntities = data.map((dataRow) => {
      const phaseInDate =
        typeof dataRow.phaseInDate == 'string'
          ? parseDate(dataRow.phaseInDate)
          : dataRow.phaseInDate;

      return {
        materialNumber: dataRow.materialNumber,
        phaseInDate: phaseInDate ? formatISO(phaseInDate, { representation: 'date' }) : null,
        demandCharacteristic: dataRow.demandCharacteristic
          ? dataRow.demandCharacteristic.toString()
          : null,
      } as CMPBulkPhaseInEntity;
    });

    return saveBulkPhaseIn(
      {
        customerNumber: customerNumber,
        phaseInEntities: phaseInEntities,
      },
      dryRun,
    );
  };

  return (
    <TableUploadModal
      title={t('new-customer_material_portfolio.modal.headline', {})}
      description={t('new-customer_material_portfolio.modal.subheadline', {})}
      open={modalOpen}
      onClose={onClose}
      onAdded={onAdded}
      applyFunction={addCustomerNumberAndSave}
      parseErrorsFromResult={parseErrorsFromResult}
      columnDefinitions={columnDefinitions}
      checkDataForErrors={checkData}
      specialParseFunctionsForFields={specialParseFunctionsForFields}
    />
  );
};

const columnDefinitions: ColumnForUploadTable<MultiPhaseInData>[] = [
  {
    headerNameFn: () => t('validation_of_demand.upload_modal.material_number', {}),
    field: 'materialNumber',
    editable: true,
    validationFn: condenseErrorsFromValidation(validateMaterialNumber),
  },
  {
    headerNameFn: () => t('customer-material-portfolio.upload_modal.modal.phase_in', {}),
    editable: true,
    field: 'phaseInDate',
    validationFn: validateAnyDateFormatAndGreaterEqualThanToday,
    cellRenderer: DateOrOriginalCellRenderer,
  },
  {
    headerNameFn: () => t('customer-material-portfolio.upload_modal.modal.demand_type', {}),
    field: 'demandCharacteristic',
    editable: true,
    validationFn: validateDemandCharacteristicType,
    cellRenderer: DemandTypeOrOriginalCellRenderer,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    cellEditorParams: {
      cellRenderer: DemandTypeRenderer,
      values: ['SE', 'SP'],
    },
  },
];

function parseErrorsFromResult(
  res: PostResult<CMPMaterialPhaseInResponse>,
): ErrorMessage<MultiPhaseInData>[] {
  const errors: ErrorMessage<MultiPhaseInData>[] = [];
  res.response.forEach((r) => {
    if (r.result.messageType == 'ERROR') {
      errors.push({
        dataIdentifier: {
          materialNumber: r.materialNumber,
        },
        errorMessage: errorsFromSAPtoMessage(r.result),
      });
    }
  });
  return errors;
}

function checkData(data: MultiPhaseInData[]): ErrorMessage<MultiPhaseInData>[] {
  const errors: ErrorMessage<MultiPhaseInData>[] = [];
  data.forEach((rowData) => {
    if (!rowData.demandCharacteristic) {
      errors.push({
        dataIdentifier: rowData,
        specificField: 'demandCharacteristic',
        errorMessage: t('generic.validation.missing_fields', {}),
      });
    }

    if (!rowData.materialNumber) {
      errors.push({
        dataIdentifier: rowData,
        specificField: 'materialNumber',
        errorMessage: t('generic.validation.missing_fields', {}),
      });
    }
    if (!rowData.phaseInDate) {
      errors.push({
        dataIdentifier: rowData,
        specificField: 'phaseInDate',
        errorMessage: t('generic.validation.missing_fields', {}),
      });
    }
  });
  return errors;
}

const specialParseFunctionsForFields: Map<keyof MultiPhaseInData, (value: string) => string> =
  new Map([
    ['demandCharacteristic', parseDemandCharacteristicIfPossible],
    ['phaseInDate', parseDateIfPossible],
  ]);
