import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { schaefflerColor } from '../../styles/colors';

import { SpinnerSvg } from './SpinnerSvg';

export function LoadingSpinner({ pageCentered }: { pageCentered?: boolean }) {
  return (
    <SpinnerBox $pageCentered={pageCentered} role={'progressbar'}>
      <RotatingImg />
    </SpinnerBox>
  );
}

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingImg = styled(SpinnerSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 60px;
  animation-name: ${rotationAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: ${schaefflerColor};
`;

const SpinnerBox = styled.div<{ $pageCentered?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 1000;

  ${(props) =>
    props.$pageCentered &&
    css`
      position: fixed;
      top: 50vh;
      left: 50vw;
    `}
`;
