import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { IconButton, Link, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import styled from 'styled-components';

import { t } from '../../core/i18n/i18n';
import { schaefflerColor } from '../../styles/colors';

const HelpButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;

  .open {
    background: white !important;
    color: black !important;
  }
`;

const StyledIconButton = styled(IconButton)`
  float: right;
  margin-bottom: 16px;
  margin-right: 16px;
  background: ${schaefflerColor};
  color: white;
  z-index: 1000 !important;

  :hover {
    background: #005f2a;
  }
`;

const StyledLink = styled(Link)`
  color: #7f7f7f;

  :hover {
    color: ${schaefflerColor};
  }
`;

const HelpButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HelpButtonContainer>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typography sx={{ px: 2, pt: 2, pb: 2 }}>
          <StyledLink
            href="https://sconnect.schaeffler.com/docs/DOC-368621"
            target="_blank"
            underline="none"
            variant="body1"
          >
            {t('help_menu.label.help_center', {})}
          </StyledLink>
        </Typography>
        <Typography sx={{ px: 2, pb: 2 }}>
          <StyledLink
            href="https://schaefflerprod.service-now.com/sup?id=sc_cat_item&sys_id=2d1e91cfdb5ba20038c2b6bffe961953&sysparm_category=19634e32dbb73e00d624b14ffe961977"
            target="_blank"
            underline="none"
            variant="body1"
          >
            {t('help_menu.label.service_now', {})}
          </StyledLink>
        </Typography>
      </Popover>
      <StyledIconButton
        sx={{ boxShadow: 2 }}
        size="medium"
        className={open ? 'open' : ''}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {!open && <QuestionMarkIcon />}
        {open && <CloseIcon />}
      </StyledIconButton>
    </HelpButtonContainer>
  );
};

export default HelpButton;
