import * as React from 'react';

import { StyledSection } from '../../components/StyledSection';
import { Page } from '../../components/page/Page';
import { t, getPreferredLanguage } from '../../core/i18n/i18n';

import { CookiePolicyDE } from './CookiePolicyDE';
import { CookiePolicyEN } from './CookiePolicyEN';

const cookiePolicy = {
  debug: <CookiePolicyDE />, // just use default language when displaying raw keys
  de: <CookiePolicyDE />,
  en: <CookiePolicyEN />,
  es: <CookiePolicyEN />, // TODO Missing translation: Add spanish version
  fr: <CookiePolicyEN />, // TODO Missing translation: Add french version
  it: <CookiePolicyEN />, // TODO Missing translation: Add italian version
  ptBr: <CookiePolicyEN />, // TODO Missing translation: Add portugese (brasilian) version
  zhCn: <CookiePolicyEN />, // TODO Missing translation: Add chinese version
};

export const CookiePolicy = () => {
  const currentLang = getPreferredLanguage();

  return (
    <Page title={t('cookie_policy_page.headline', {})}>
      <StyledSection>{cookiePolicy[currentLang]}</StyledSection>
    </Page>
  );
};
