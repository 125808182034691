import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Menu as MuiMenu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import { t } from '../../../core/i18n/i18n';
import { errorColor, schaefflerColor, warningColor } from '../../../styles/colors';
import { ToolbarContainer } from '../../home/table/HomeTableToolbar';

export type FilterValues = {
  deliveries: boolean;
  firmBusiness: boolean;
  opportunities: boolean;
  forecastProposal: boolean;
  forecastProposalDemandPlanner: boolean;
  indicativeDemandPlanning: boolean;
  currentDemandPlan: boolean;
  activeAndPredecessor: boolean;
};

function ToolbarKpiTable(props: {
  filterValues: FilterValues;
  onFilterValuesChanged: (filterValues: FilterValues) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const handleOpenOptionsMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  return (
    <ToolbarContainer $position={'flex-end'}>
      <LegendContainer>
        <Typography component="span" color="secondary" variant="body2">
          {t('validation_of_demand.legend.title', {})}
        </Typography>

        <LegendItem>
          <HorizontalLine color={schaefflerColor} />
          <Typography component="span" color="secondary" variant="body2">
            {t('validation_of_demand.legend.current_date', {})}
          </Typography>
        </LegendItem>

        <LegendItem>
          <HorizontalLine color={errorColor} />
          <Typography component="span" color="secondary" variant="body2">
            {t('validation_of_demand.legend.frozen_zone', {})}
          </Typography>
        </LegendItem>

        <LegendItem>
          <HorizontalLine color={warningColor} />
          <Typography component="span" color="secondary" variant="body2">
            {t('validation_of_demand.legend.replenishment_lead_time', {})}
          </Typography>
        </LegendItem>
      </LegendContainer>
      <Button color="secondary" endIcon={<MoreVertIcon />} onClick={handleOpenOptionsMenu} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseOptionsMenu}>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.deliveries}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      deliveries: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.deliveries', {})}
            />
          </FormGroup>
        </StyledMenuItem>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.firmBusiness}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      firmBusiness: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.firmBusiness', {})}
            />
          </FormGroup>
        </StyledMenuItem>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.opportunities}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      opportunities: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.opportunities', {})}
            />
          </FormGroup>
        </StyledMenuItem>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.forecastProposal}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      forecastProposal: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.forecastProposal', {})}
            />
          </FormGroup>
        </StyledMenuItem>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.forecastProposalDemandPlanner}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      forecastProposalDemandPlanner: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.forecastProposalDemandPlanner', {})}
            />
          </FormGroup>
        </StyledMenuItem>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.indicativeDemandPlanning}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      indicativeDemandPlanning: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.indicativeDemandPlan', {})}
            />
          </FormGroup>
        </StyledMenuItem>
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.currentDemandPlan}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      currentDemandPlan: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={
                t('validation_of_demand.menu_item.demandPlan', {}) +
                ' / ' +
                t('validation_of_demand.menu_item.opAdjustment', {})
              }
            />
          </FormGroup>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={props.filterValues.activeAndPredecessor}
                  onChange={(e) =>
                    props.onFilterValuesChanged({
                      ...props.filterValues,
                      activeAndPredecessor: e.currentTarget.checked,
                    })
                  }
                />
              }
              label={t('validation_of_demand.menu_item.activeAndPredecessor', {})}
            />
          </FormGroup>
        </StyledMenuItem>
      </Menu>
    </ToolbarContainer>
  );
}

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 8px;
`;

const Menu = styled(MuiMenu)`
  > * {
    min-width: 160px;
    padding: 0 16px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const HorizontalLine = styled.span.attrs((props: { color: string }) => props)`
  display: inline-block;
  content: ' ';
  margin-inline: 8px;
  width: 26px;
  height: 1px;
  border-bottom: 2px dotted ${(props) => props.color};
`;

export default ToolbarKpiTable;
