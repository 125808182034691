import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { t } from '../../../core/i18n/i18n';
import { parseToStringLiteralTypeIfPossible } from '../../../core/parseValues';
import {
  DemandCharacteristic,
  demandCharacteristics,
} from '../../../domain/materialCustomer/model';

export const DemandTypeRenderer = (params: ICellRendererParams): React.ReactNode => {
  const value = params.value;

  if (value == undefined) {
    return null;
  }

  const localizationKeyCreation = (val: DemandCharacteristic) =>
    t(`demand_characterictics.${val}`, {});
  const parsed = parseToStringLiteralTypeIfPossible<DemandCharacteristic>(
    value,
    demandCharacteristics,
    localizationKeyCreation,
  );

  if (parsed == undefined) {
    return null;
  }

  return <span>{t(`field.demandCharacteristic.value.${parsed}`, {})}</span>;
};
