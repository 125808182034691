import { GlobalSelectionCriteriaFilters } from '../globalSelection/model';

export type MaterialCustomerEntry = {
  id?: string;
  region?: string;
  salesArea?: string;
  salesOrg?: string;
  mainCustomerNumber?: string;
  mainCustomerName?: string;
  customerNumber?: string;
  customerName?: string;
  customerCountry?: string;
  sector?: string;
  sectorManagement?: string;
  customerClassification?: string;
  deliveryPlant?: string;
  planningPlant?: string;
  materialNumber?: string;
  materialDescription?: string;
  mrpGroup?: string;
  productionPlant?: string;
  productionPlantName?: string;
  productionSegment?: string;
  productionLine?: string;
  productionLineText?: string;
  packagingSize?: number;
  customerMaterialNumber?: string;
  customerMaterialNumberCount?: number;
  materialClassification?: MaterialClassification;
  demandCharacteristic?: DemandCharacteristic;
  currentRLTSchaeffler?: string;
  currentRLTCustomer?: string;
  portfolioStatus?: string;
  stochasticType?: string;
  successorSchaefflerMaterial?: string;
  successorSchaefflerMaterialDescription?: string;
  successorSchaefflerMaterialPackagingSize?: number;
  successorMaterialClassificationSchaeffler?: string;
  successorMaterialCustomer?: string;
  successorCustomerMaterialDescription?: string;
  successorCustomerMaterialPackagingSize?: number;
  successorMaterialClassificationCustomer?: string;
  pfStatusAutoSwitch?: string;
  repDate?: string;
  accountOwner?: string;
  accountOwnerID?: string;
  internalSales?: string;
  internalSalesID?: string;
  demandPlanner?: string;
  demandPlannerID?: string;
  gkam?: string;
  gkamID?: string;
  kam?: string;
  kamID?: string;
  gkamNumber?: string;
  gkamName?: string;
  subKeyAccount?: string;
  subKeyAccountName?: string;
  productLine?: string;

  forecastMaintained?: boolean;
  forecastValidated?: string;
  forecastValidatedFrom?: string;
  forecastValidatedTo?: string;
  forecastValidatedAt?: string;
  forecastValidatedBy?: string;
  abcxClassification?: string;
  gpsd?: string;
  gpsdName?: string;
};

export type CriteriaFields = {
  filterableFields: string[];
  sortableFields: string[];
};

export type MaterialCustomerSelectionFilters = {
  id?: Array<string>;
  region?: Array<string>;
  salesArea?: Array<string>;
  sectorManagement?: Array<string>;
  salesOrg?: Array<string>;
  gkamNumber?: Array<string>;
  customerNumber?: Array<string>;
  materialNumber?: Array<string>;
};

export type MaterialCustomerRequest = {
  selectionFilters: GlobalSelectionCriteriaFilters;
  columnFilters: Array<Record<string, any>>;
  endRow: number;
};

export type MaterialCustomerCustomerRequest = {
  selectionFilters: GlobalSelectionCriteriaFilters | undefined;
  columnFilters: Record<string, any>;
  startRow: number;
  endRow: number;
  sortModel: Array<object>;
};

export const materialClassifications = ['AP', 'SPs', 'SPc', 'OP'] as const;

export type MaterialClassification = (typeof materialClassifications)[number];

export const abcxClassifications = ['A', 'B', 'C', 'X', ''] as const;

export type AbcxClassification = (typeof abcxClassifications)[number];

/**
 * SE - series
 * SP - sporadic
 */
export const demandCharacteristics = ['SE', 'SP'] as const;

export type DemandCharacteristic = (typeof demandCharacteristics)[number];
