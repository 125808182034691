import { format, formatISO, parseISO, startOfMonth } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { t } from '../../../core/i18n/i18n';
import { parseAndFormatNumber, preferredDateFormatWithoutDay } from '../../../core/i18n/l10n';
import {
  ChartUnitMode,
  chartSeriesConfig,
  ChartValues,
  ForecastChartData,
} from '../../../domain/forecastChart/model';
import { disabledGrey, schaefflerColor, secondaryColor } from '../../../styles/colors';

import { ChartOverlay } from './ChartOverlay';
import { previewData } from './previewData';

const FONT_SIZE = 14;

export type ChartProps = {
  chartData: ForecastChartData | undefined;
  loading: boolean;
  error: boolean;
};

export const ForecastChart = ({ chartData, loading, error }: ChartProps) => {
  // show preview data when no data is provided
  const isPreviewData = !chartData;
  chartData = chartData || previewData;

  const yAxisText =
    chartData.chartUnitMode == ChartUnitMode.QUANTITY
      ? t('home.chart.pieces_option', {})
      : chartData.currency;
  const showRollingForecast = chartData.chartEntries.some(
    (entry) => entry.rollingSalesForecast !== null,
  );

  const axisColor = useMemo(() => {
    return isPreviewData || loading ? disabledGrey : secondaryColor;
  }, [isPreviewData, loading]);

  const thisMonth = formatISO(startOfMonth(new Date()), { representation: 'date' });

  const legendFormatter = (value: any, name: string) => {
    try {
      const labelProp = name as ChartValues;
      return [parseAndFormatNumber(value), t(`home.chart.legend.${labelProp}`, {})];
    } catch (e) {
      return [value, name];
    }
  };

  const dateFormatter = (value: string) => {
    try {
      return format(parseISO(value), preferredDateFormatWithoutDay);
    } catch (e) {
      return value;
    }
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={chartData.chartEntries}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="1 3" />
        <XAxis
          dataKey="yearMonth"
          tick={{ fill: axisColor }}
          axisLine={{ stroke: axisColor }}
          tickFormatter={dateFormatter}
          minTickGap={16}
          fontSize={FONT_SIZE}
        />
        <YAxis
          tick={{ fill: axisColor }}
          axisLine={{ stroke: axisColor }}
          tickFormatter={parseAndFormatNumber}
          width={100}
          fontSize={FONT_SIZE}
        >
          <Label value={yAxisText} position={'left'} angle={-90} fill={axisColor} />
        </YAxis>
        {!isPreviewData && <Tooltip formatter={legendFormatter} labelFormatter={dateFormatter} />}
        <Area
          type="linear"
          dataKey="deliveries"
          stackId="1"
          stroke="none"
          fill={chartSeriesConfig['deliveries'].color}
          fillOpacity="1"
        />
        <Area
          type="linear"
          dataKey="orders"
          stackId="1"
          stroke="none"
          fill={chartSeriesConfig['orders'].color}
          fillOpacity="1"
        />
        <Area
          type="linear"
          dataKey="demandPlan"
          stackId="1"
          stroke="none"
          fill={chartSeriesConfig['demandPlan'].color}
          fillOpacity="1"
        />
        <Area
          type="linear"
          dataKey="opAdjustment"
          stackId="1"
          stroke="none"
          fill={chartSeriesConfig['opAdjustment'].color}
          fillOpacity="1"
        />
        <Area
          type="linear"
          dataKey="opportunities"
          stackId="1"
          stroke="none"
          fill={chartSeriesConfig['opportunities'].color}
          fillOpacity="1"
        />
        {!isPreviewData && <ReferenceLine x={thisMonth} stroke={schaefflerColor} />}
        {showRollingForecast && (
          <Line
            type="linear"
            dataKey="rollingSalesForecast"
            stroke={chartSeriesConfig['rollingSalesForecast'].color}
            strokeDasharray="5 5"
          />
        )}
        {!loading && (isPreviewData || error) && (
          // This config of reference area creates an area filling the whole chart
          // and having no visible style such as background color or border
          <ReferenceArea y1={0} shape={<></>}>
            <Label
              content={<ChartOverlay />}
              value={
                error ? t('home.chart.loading_failed', {}) : t('home.chart.select_to_load', {})
              }
            />
          </ReferenceArea>
        )}
        {loading && (
          <ReferenceArea y1={0} shape={<></>}>
            <Label content={<ChartOverlay loadingSpinner={true} />} />
          </ReferenceArea>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
