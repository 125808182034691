export type SelectableValue = {
  id: string;
  text: string;
};

export type ResolveSelectableValueResult = {
  id: string;
  selectableValue?: SelectableValue;
  error?: Array<string>;
};

export function selectableToStringFilter(value: SelectableValue): string {
  return value.id;
}

export function isSelectableValue(
  value: string | SelectableValue | null | undefined,
): value is SelectableValue {
  if (!value) return false;

  return (
    (value as SelectableValue).id !== undefined && (value as SelectableValue).text !== undefined
  );
}

export function mapToOptionsIfPossible(
  initialValues: string[] | SelectableValue[] | undefined,
  options: SelectableValue[] | undefined,
): SelectableValue[] {
  return initialValues && options
    ? initialValues
        .map((v) => matchOptionIfPossible(v, options))
        .filter((o): o is SelectableValue => !!o)
    : [];
}

export function matchOptionIfPossible(
  initialValue: string | SelectableValue | null | undefined,
  options: SelectableValue[] | undefined,
): SelectableValue | null {
  if (initialValue == undefined) {
    return null;
  }

  // If it is already a SelectableValue, we don't need to have options
  if (isSelectableValue(initialValue)) {
    return initialValue;
  }

  // If there are no options, return a SelectableValue with the string we have as both id and text
  // because its better to show what we have than show nothing at all
  if (options == undefined) {
    return { id: initialValue, text: initialValue };
  }

  // The string representation of an SelectableValue option should always be its id to be unique.
  // This means the option can be found checking the id.
  return options.find((o) => o.id == initialValue) || null;
}

export async function toOptionsWithSearchCallIfNecessary(
  values: string[] | SelectableValue[] | undefined,
  getOptions: (searchTerm: string, signal?: AbortSignal) => Promise<SelectableValue[]>,
): Promise<SelectableValue[]> {
  if (values == undefined) {
    return [];
  }

  return Promise.all(
    values.map((value: string | SelectableValue) => {
      return typeof value == 'string' ? getOptions(value).then((results) => results[0]) : value;
    }),
  );
}
