import { Button, FormControl, Grid, TextField } from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { useSnackbar } from 'notistack';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import { ErrorHandlingDatePicker } from '../../../components/DatePicker';
import { ErrorHandlingSelect } from '../../../components/ErrorHandlingSelect';
import { ErrorProps, errorHelperTextProps } from '../../../components/FieldError';
import { SingleAutocompleteOnType } from '../../../components/inputs/autocomplete/SingleAutocompleteOnType';
import SingleAutocompletePreLoaded from '../../../components/inputs/autocomplete/SingleAutocompletePreLoaded';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { ModalHeadlineSeparator } from '../../../components/modal/ModalHeadline';
import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../../core/errorhandling';
import { useSelectableOptions } from '../../../core/hooks/useSelectableOptions';
import { getPreferredLanguage, t } from '../../../core/i18n/i18n';
import { generateUrlWithSearchTerm, requestFromApi } from '../../../core/requests/httpClient';
import {
  IMRSubstitution,
  ReplacementType,
  replacementTypeValues,
} from '../../../domain/internalMaterialReplacement/model';
import { saveSingleIMRSubstitution } from '../../../domain/internalMaterialReplacement/saveSingleIMRSubstitution';

import { checkMissingFields, getReplacementTypeLogic } from './replacementTypeLogicHelpers';

const MAX_DATE = new Date(9999, 12, 31);

type SingleSubstitutionModalProps = {
  open: boolean;
  isNewSubstitution: boolean;
  onClose: () => void;
  onSuccess: () => void;
  substitution: IMRSubstitution;
};

export default function SingleSubstitutionModal(props: SingleSubstitutionModalProps) {
  const { open, onClose, onSuccess, substitution, isNewSubstitution } = props;
  const snackbar = useSnackbar();

  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [currentSubstitution, setCurrentSubstitution] = useState<IMRSubstitution>(substitution);
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState<Array<keyof IMRSubstitution>>([]);

  const regionOptions = useSelectableOptions(`global-selection/regions`);
  const salesAreaOptions = useSelectableOptions(`global-selection/sales-areas`);
  const salesOrgOptions = useSelectableOptions(
    `global-selection/sales-organisations?language=${getPreferredLanguage()}`,
  );

  const title = isNewSubstitution
    ? t('internal_material_replacement.title.newSubstitution', {})
    : t('internal_material_replacement.title.editSubstitution', {});

  // Refresh the current substitution when the params change to get a fresh start every time
  useEffect(() => {
    setCurrentSubstitution(substitution);
  }, [substitution]);

  const disabledFields = useMemo(() => {
    const type = currentSubstitution.replacementType;
    if (type == null) {
      return [];
    }
    return getReplacementTypeLogic(isNewSubstitution, type).deactivatedFields;
  }, [currentSubstitution, isNewSubstitution]);

  const missingFields: Array<keyof IMRSubstitution> | undefined = useMemo(() => {
    return checkMissingFields(currentSubstitution);
  }, [currentSubstitution]);

  const getMaterialNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-materials', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const getCustomerNumberOptions = useCallback(
    (searchTerm: string, signal: AbortSignal | null | undefined) => {
      return requestFromApi(
        generateUrlWithSearchTerm('global-selection/search-customers', searchTerm),
        {
          signal,
        },
      );
    },
    [],
  );

  const onSaveModal = async () => {
    if (
      (missingFields && missingFields?.length > 0) ||
      (errorFields.length > 0 &&
        // Ignore errors for fields which are untouched by the user
        !errorFields.every((field) => currentSubstitution[field] == substitution[field]))
    ) {
      setShowValidation(true);
      snackbar.enqueueSnackbar(t('generic.validation.check_inputs', {}), { variant: 'error' });
      return;
    }

    const postResult = await saveSingleIMRSubstitution(currentSubstitution, false);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t(`customer_material_portfolio.phase_in_out_single_modal.save.success`, {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });
    setLoading(false);
    if (userMessage.variant == 'success' || userMessage.variant == 'warning') {
      resetModal();
      onSuccess();
    }
  };

  const resetModal = (newType?: ReplacementType | null) => {
    setCurrentSubstitution((oldSub) => ({
      region: oldSub.region,
      replacementType: newType != undefined ? newType : oldSub.replacementType,
      salesArea: null,
      salesOrg: null,
      customerNumber: null,
      predecessorMaterial: null,
      successorMaterial: null,
      replacementDate: null,
      cutoverDate: null,
      startOfProduction: null,
      note: null,
    }));
  };

  const onTypeChange = (newValue: ReplacementType | null) => {
    if (newValue == currentSubstitution.replacementType || newValue == null) return;
    setShowValidation(false);
    resetModal(newValue);
  };

  const onAutocompleteChange =
    (valueName: keyof IMRSubstitution) => (_: unknown, newValue: SelectableValue | null) => {
      setCurrentSubstitution((oldSub) => ({ ...oldSub, [valueName]: newValue?.id }));
    };

  const handleDateChange = (valueName: keyof IMRSubstitution) => (newDateValue: Date | null) => {
    setCurrentSubstitution((oldSub) => ({ ...oldSub, [valueName]: newDateValue }));
  };

  const closeAndReset = () => {
    setShowValidation(false);
    resetModal(null);
    onClose();
  };

  function isDisabled(field: keyof IMRSubstitution): boolean {
    // When entering a new substitution, all fields should be disabled except type
    if (!currentSubstitution.replacementType && field !== 'replacementType') {
      return true;
    }

    return disabledFields.find((dis) => dis == field) != undefined;
  }

  function isMissing(field: keyof IMRSubstitution): [string] | undefined {
    if (!showValidation) {
      return undefined;
    }

    return missingFields?.find((miss) => miss == field)
      ? [t('generic.validation.missing_fields', {})]
      : undefined;
  }

  function isAutocompleteValueMissing(field: keyof IMRSubstitution): ErrorProps | undefined {
    const errorString = isMissing(field);
    return errorString ? errorHelperTextProps(errorString) : undefined;
  }

  const setErrorForDate = (field: keyof IMRSubstitution) => (reason: DateValidationError) => {
    if (reason != null) {
      setErrorFields((old) => [...old, field]);
    } else {
      setErrorFields((old) => old.filter((e) => e !== field));
    }
  };

  return (
    <Modal onClose={closeAndReset} fullWidth open={open} maxWidth={'md'}>
      <LoadingSpinnerModal open={loading} />
      <Modal.Headline onClose={closeAndReset} text={title}>
        <Button size="medium" variant="contained" color="primary" onClick={onSaveModal}>
          {t('button.save', {})}
        </Button>
      </Modal.Headline>
      <ModalHeadlineSeparator />
      <Modal.Body>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Wrapper>
              <FormControl fullWidth size={'small'}>
                <ErrorHandlingSelect<ReplacementType>
                  label={t('internal_material_replacement.column.replacementType', {})}
                  value={currentSubstitution.replacementType}
                  onChange={onTypeChange}
                  disabled={isDisabled('replacementType')}
                  options={replacementTypeValues.map((rt) => ({
                    key: rt,
                    displayValue: t(`replacement_type.${rt}`, {}),
                  }))}
                  errorMessages={
                    showValidation && !currentSubstitution.replacementType
                      ? [t('generic.validation.missing_fields', {})]
                      : []
                  }
                />
              </FormControl>
            </Wrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleAutocompletePreLoaded
              value={currentSubstitution.region}
              optionsLoadingResult={regionOptions}
              autocompleteLabel={t('globalSelection.region', {})}
              onValueChange={onAutocompleteChange('region')}
              errorHelperTextProps={isAutocompleteValueMissing('region')}
              muiProps={{
                disabled: isDisabled('region'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleAutocompletePreLoaded
              value={currentSubstitution.salesArea}
              optionsLoadingResult={salesAreaOptions}
              autocompleteLabel={t('globalSelection.sales_area', {})}
              onValueChange={onAutocompleteChange('salesArea')}
              errorHelperTextProps={isAutocompleteValueMissing('salesArea')}
              muiProps={{
                disabled: isDisabled('salesArea'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleAutocompletePreLoaded
              value={currentSubstitution.salesOrg}
              optionsLoadingResult={salesOrgOptions}
              autocompleteLabel={t('globalSelection.sales_org', {})}
              onValueChange={onAutocompleteChange('salesOrg')}
              errorHelperTextProps={isAutocompleteValueMissing('salesOrg')}
              muiProps={{
                disabled: isDisabled('salesOrg'),
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SingleAutocompleteOnType
              value={currentSubstitution.customerNumber}
              onValueChange={onAutocompleteChange('customerNumber')}
              autocompleteLabel={t('globalSelection.customer', {})}
              getOptions={getCustomerNumberOptions}
              errorHelperTextProps={isAutocompleteValueMissing('customerNumber')}
              muiProps={{
                disabled: isDisabled('customerNumber'),
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SingleAutocompleteOnType
              value={currentSubstitution.predecessorMaterial}
              onValueChange={onAutocompleteChange('predecessorMaterial')}
              autocompleteLabel={t('internal_material_replacement.column.predecessorMaterial', {})}
              getOptions={getMaterialNumberOptions}
              errorHelperTextProps={isAutocompleteValueMissing('predecessorMaterial')}
              muiProps={{
                disabled: isDisabled('predecessorMaterial'),
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SingleAutocompleteOnType
              value={currentSubstitution.successorMaterial}
              onValueChange={onAutocompleteChange('successorMaterial')}
              autocompleteLabel={t('internal_material_replacement.column.successorMaterial', {})}
              getOptions={getMaterialNumberOptions}
              errorHelperTextProps={isAutocompleteValueMissing('successorMaterial')}
              muiProps={{
                disabled: isDisabled('successorMaterial'),
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <ErrorHandlingDatePicker
              maxDate={MAX_DATE}
              minDate={new Date(Date.now())}
              value={currentSubstitution.startOfProduction}
              onChange={handleDateChange('startOfProduction')}
              label={t('internal_material_replacement.column.startOfProduction', {})}
              errorMessages={isMissing('startOfProduction')}
              onError={setErrorForDate('startOfProduction')}
              fullWidth
              validate={
                // Live validation of given date, exept it came from the table and was not changed.
                // Validate for missing field after pressing save button.
                (Boolean(currentSubstitution.startOfProduction) || showValidation) &&
                (!substitution.startOfProduction ||
                  currentSubstitution.startOfProduction !== substitution.startOfProduction)
              }
              disabled={isDisabled('startOfProduction')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ErrorHandlingDatePicker
              maxDate={MAX_DATE}
              minDate={new Date(Date.now())}
              value={currentSubstitution.cutoverDate}
              onChange={handleDateChange('cutoverDate')}
              label={t('internal_material_replacement.column.cutoverDate', {})}
              errorMessages={isMissing('cutoverDate')}
              onError={setErrorForDate('cutoverDate')}
              fullWidth
              validate={
                // Live validation of given date, exept it came from the table and was not changed.
                // Validate for missing field after pressing save button.
                (Boolean(currentSubstitution.cutoverDate) || showValidation) &&
                (!substitution.cutoverDate ||
                  currentSubstitution.cutoverDate !== substitution.cutoverDate)
              }
              disabled={isDisabled('cutoverDate')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ErrorHandlingDatePicker
              maxDate={MAX_DATE}
              minDate={new Date(Date.now())}
              value={currentSubstitution.replacementDate}
              onChange={handleDateChange('replacementDate')}
              label={t('internal_material_replacement.column.replacementDate', {})}
              helperText={t('internal_material_replacement.helper_text.replacementDate', {})}
              errorMessages={isMissing('replacementDate')}
              onError={setErrorForDate('replacementDate')}
              fullWidth
              validate={
                // Live validation of given date, exept it came from the table and was not changed.
                // Validate for missing field after pressing save button.
                (Boolean(currentSubstitution.replacementDate) || showValidation) &&
                (!substitution.replacementDate ||
                  currentSubstitution.replacementDate !== substitution.replacementDate)
              }
              disabled={isDisabled('replacementDate')}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              value={currentSubstitution.note || ''}
              onChange={(e) => {
                setCurrentSubstitution((oldSub) => ({ ...oldSub, note: e.target.value }));
              }}
              minRows={4}
              multiline
              label={t('alert_rules.edit_modal.label.comment', {})}
              size={'small'}
              helperText={t('internal_material_replacement.helper_text.note', {})}
            />
          </Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );
}

const Wrapper = styled.div`
  width: 31%;
  padding-bottom: 20px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;
