import * as React from 'react';

import { StyledSection } from '../../components/StyledSection';
import { Page } from '../../components/page/Page';
import { getPreferredLanguage, t } from '../../core/i18n/i18n';

import { ImprintDE } from './ImprintDE';
import { ImprintEN } from './ImprintEN';

const imprint = {
  debug: <ImprintDE />, // just use default language when displaying raw keys
  de: <ImprintDE />,
  en: <ImprintEN />,
  es: <ImprintEN />, // TODO Missing translation: Add spanish version
  fr: <ImprintEN />, // TODO Missing translation: Add french version
  it: <ImprintEN />, // TODO Missing translation: Add italian version
  ptBr: <ImprintEN />, // TODO Missing translation: Add portuguese (brazilian) version
  zhCn: <ImprintEN />, // TODO Missing translation: Add chinese version
};

export const Imprint = () => {
  const currentLang = getPreferredLanguage();

  return (
    <Page title={t('imprint_page.headline', {})}>
      <StyledSection>{imprint[currentLang]}</StyledSection>
    </Page>
  );
};
