import { PostResult } from '../../core/errorhandling';
import { getErrorMessage } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';
import { CMPData } from '../../pages/customerMaterialPortfolio/modal/cmpModalStatusSpecificElements/cmpModalTypes';

import { CMPWriteRequest, CMPWriteResponse } from './model';
import { dataToCMPWriteRequest } from './requestHelper';

export async function saveCMPChange(
  cmpData: CMPData,
  dryrun: boolean,
  actionURL: string,
): Promise<PostResult<CMPWriteResponse>> {
  try {
    const request: CMPWriteRequest = dataToCMPWriteRequest(cmpData);

    const url = encodeURI(`customer-material-portfolio/${actionURL}?dryRun=${dryrun}`);
    const result = (await requestFromApi(url, {
      method: 'POST',
      body: JSON.stringify(request),
    })) as CMPWriteResponse;

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: [result],
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}

export async function acceptSubstitution(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'accept-substitution');
}

export async function saveInactive(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'single-inactivation');
}

export async function saveReactivation(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'single-reactivation');
}

export async function saveEdit(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'update');
}

export async function savePhaseIn(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'single-phase-in');
}

export async function savePhaseOut(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'single-phase-out');
}

export async function saveVeto(cmpData: CMPData, dryrun: boolean) {
  return saveCMPChange(cmpData, dryrun, 'veto-substitution');
}
