import styled from 'styled-components';

export const StyledSection = styled.section<{
  last?: boolean;
  grow?: boolean;
  $fullHeight?: boolean;
}>`
  border-radius: 3px;
  background: #ffffff;
  padding: 16px;
  display: flex;
  flex-direction: column;

  ${({ last }) => (last ? '' : 'margin-bottom: 24px;')}
  ${({ grow }) => (grow ? 'flex-grow: 1;' : '')}

  box-shadow: ${(props) => props.theme.shadows[2]};

  ${({ $fullHeight }) => ($fullHeight ? 'min-height: 100%;' : '')}
`;
