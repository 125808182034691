import * as React from 'react';
import { Link } from 'react-router-dom';

export const PrivacyPolicyES = () => {
  return (
    <>
      <h2>Política de privacidad</h2>
      <p>
        Agradecemos su interés en el Grupo Schaeffler (Schaeffler AG y sus empresas filiales) y en
        nuestros productos. La protección de su privacidad cuando accede a nuestra oferta en línea
        es muy importante para nosotros. Cuando tratamos sus datos personales, observamos las leyes
        aplicables en materia de protección de datos.
      </p>

      <h3>I. Información general sobre el tratamiento de datos</h3>

      <h4>1. Alcance y finalidad del tratamiento de los datos personales</h4>
      <p>
        En principio, recopilamos y utilizamos sus datos personales solo en la medida en que sea
        necesario para ofrecerle una web funcional, así como nuestros contenidos y servicios
        disponibles en la web. El tratamiento de sus datos personales se lleva a cabo sobre la base
        de su consentimiento o en la medida en que las regulaciones legales permitan dicho
        procesamiento de datos, incluso sin consentimiento.
      </p>

      <h4>2. Base jurídica para el tratamiento de datos</h4>
      <p>
        Sus datos personales se procesan sobre la base del Reglamento General de Protección de Datos
        de la UE (RGPD) y la ley local de protección de datos aplicable, por ejemplo, la Ley
        Orgánica de Protección de Datos Personales y Garantía de los Derechos Digitales (LOPDGDD).
      </p>
      <p>
        En la medida en que hayamos obtenido su consentimiento para procesar sus datos personales
        para fines específicos, el Art. 6 (1) RGPD sirve como base legal para el procesamiento de
        datos personales. Usted puede revocar su consentimiento en cualquier momento. Tenga en
        cuenta que la revocación solo tiene efectos a futuro. El procesamiento que tuvo lugar antes
        de la revocación no se verá afectado.
      </p>
      <p>
        El procesamiento de datos personales en el contexto de la ejecución de un contrato en el que
        usted es parte o para tomar medidas antes de celebrar un contrato a petición suya se basa en
        el Art. 6 (1) b RGPD. Los fines del procesamiento de datos se rigen por los respectivos
        documentos contractuales y el objeto del contrato.
      </p>
      <p>
        Si el procesamiento de datos personales es necesario para cumplir con una obligación legal a
        la que estamos sujetos, el art. 6 (1) c RGPD sirve como base legal.
      </p>
      <p>
        Si el procesamiento es necesario para los cumplir los intereses legítimos perseguidos por
        Schaeffler o por un tercero (por ejemplo, para hacer valer reclamaciones legales y
        defenderse contra disputas legales; para garantizar la seguridad de TI; para prevenir actos
        delictivos; para medidas de gestión empresarial y para el desarrollo posterior de servicios
        y productos) y si sus intereses, derechos y libertades fundamentales como interesado no
        anulan el interés antes mencionado, el art. 6 (1) f RGPD sirve como base legal para el
        procesamiento.
      </p>

      <h4>3. Supresión de datos y período de retención</h4>
      <p>
        Procesamos y almacenamos sus datos personales durante el tiempo que sea necesario para
        cumplir con nuestro propósito. Además, dicho almacenamiento puede tener lugar para cumplir
        con una obligación prevista en una Ley, regulación u otra disposición de la Unión Europea o
        de los Estados miembros a la que estamos sujetos como controladores. Si los datos ya no son
        necesarios o si ha expirado el período de retención prescrito por las leyes mencionadas
        anteriormente, sus datos de seguimiento se borrarán pasados 90 días. Además, las previsiones
        de los clientes se mantienen en Demand360. En el caso de pronósticos de clientes recién
        creados o modificados, estos se escriben junto con la abreviatura del nombre sap y se
        eliminan después de 36 meses. Los datos de configuración del usuario se pueden eliminar a
        petición suya.
      </p>

      <h4>4. Acceso a los datos personales dentro del Grupo Schaeffler y por parte de terceros</h4>
      <p>
        Dentro del Grupo Schaeffler, los departamentos que lo necesiten tendrán acceso a sus datos
        conforme a los principios «Least Privilege» (asignación de los mínimos derechos de acceso
        que sea posible) y «Need-to-Know» (acceso a los datos solo si es necesario).
      </p>
      <p>
        Solo transmitiremos datos a terceros ajenos al Grupo Schaeffler si es necesario, si así lo
        exige la ley, si usted ha dado su consentimiento o si los encargados del tratamiento de
        datos contratados por nosotros se han comprometido contractualmente a cumplir los requisitos
        del RGPD y de la LOPDGDD.
      </p>
      <p>
        Conforme a esto, podrán ser destinatarios de datos personales: Microsoft Azure, SAP API
        Management y SAP.
      </p>

      <h4>
        5. Transferencia de datos personales a un tercer país o a una organización internacional
      </h4>
      <p>
        Solo se transferirán datos a países fuera de la UE/EEE (es decir, terceros países) si es
        necesario o lo exige la ley, si nos ha dado su consentimiento o si hemos subcontratado el
        tratamiento de los datos. En caso de recurrir a proveedores de servicios de terceros países,
        estos, aparte de estar sujetos a las instrucciones por escrito estipuladas en las cláusulas
        contractuales tipo equivalentes a las de la UE, estarán obligados a cumplir el mismo nivel
        de protección de datos que en Europa.
      </p>

      <h4>6. Seguridad informática y enlaces a sitios web de terceros</h4>
      <p>
        El Grupo Schaeffler utiliza medidas de seguridad técnicas y organizativas para proteger los
        datos que gestionamos contra la destrucción, manipulación, pérdida o acceso accidental o
        intencionado por parte de personas no autorizadas. Estas salvaguardias se desarrollan
        constantemente conforme a los avances de la técnica.
      </p>
      <p>
        Nuestros sitios web pueden contener enlaces a sitios web de otros proveedores. Nuestra
        política sobre protección de datos no se aplica a estos sitios web.
      </p>

      <h4>7. Obligación de facilitar datos personales</h4>
      <p>
        Si le proporcionamos ofertas y servicios en este sitio web cuyo uso es voluntario, no hay
        obligación de proporcionarnos sus datos, pero sin sus datos personales, es posible que no
        pueda utilizar nuestros servicios.
      </p>

      <h4>8. “Elaboración de perfiles&quot; y toma de decisiones automatizada</h4>
      <p>
        No utilizamos sistemas automatizados para la toma de decisiones conforme al artículo 22 del
        RGPD. En principio, Schaeffler no elabora perfiles. Si en algún caso concreto lo hiciéramos,
        le informaremos expresamente si así lo exige la ley y, en su caso, le pediremos su
        consentimiento.
      </p>

      <h4>9. Origen de sus datos personales</h4>
      <p>Utilizamos los datos que usted nos proporciona.</p>

      <h3>
        II. Procesos de tratamiento de datos para ofrecer el sitio web y para crear archivos de
        registro
      </h3>
      <p>
        Cuando usted visita nuestro sitio web, nuestros servidores recogen de manera estándar el
        nombre de su proveedor de servicios de internet, su dirección IP, la página web desde la que
        ha llegado a la nuestra, las páginas que visita en nuestro sitio web y la fecha y duración
        de su visita. Esta información se almacena en los archivos de registro de nuestros sistemas
        y se utiliza para el análisis de fallos y problemas. No obstante, el uso de la dirección IP
        se limita a lo técnicamente necesario y se utiliza en forma truncada y, por tanto, anónima
        para que no sea posible relacionar una dirección IP con un usuario. Los datos no se fusionan
        con datos personales.
      </p>
      <p>La base legal para el almacenamiento temporal de los datos es el art. 6 párr. 1 f RGPD.</p>
      <p>
        El almacenamiento temporal de la dirección IP abreviada por nuestros sistemas es
        técnicamente necesario para enviar el sitio web a su dispositivo terminal. El almacenamiento
        en archivos de registro se realiza para garantizar la funcionalidad del sitio web. En este
        contexto, los datos no se analizan con fines de marketing.
      </p>
      <p>
        La base jurídica para el tratamiento de estos datos es nuestro interés legítimo en el
        tratamiento de los datos conforme al artículo 6, apartado 1, letra f), del RGPD.
      </p>
      <p>
        La recogida de datos para ofrecer el sitio web y su almacenamiento en archivos de registro
        son absolutamente necesarios para el funcionamiento del sitio web. Por tanto, como usuario,
        usted no tiene posibilidad de oponerse a dicho procesamiento.
      </p>

      <h3>III. Tratamiento de datos en relación con los servicios ofrecidos en el sitio web</h3>
      <p>
        En nuestro sitio web se ofrecen varios servicios, para cuyo uso le solicitamos sus datos
        personales. En este contexto, siempre es opcional que nos proporcione datos personales. Sus
        datos personales se procesan se forma anónima para permitir las siguientes:
      </p>
      <ul>
        <li>
          Evaluaciones de las tasas de solicitud, tiempos de respuesta y tasas de error del sitio
          web para averiguar qué páginas se utilizan con mayor frecuencia en qué momentos del día y
          dónde se encuentran los usuarios.
        </li>
        <li>Evaluaciones de las páginas vistas y el rendimiento de carga del sitio web</li>
        <li>Evaluaciones del número de usuarios y sesiones</li>
        <li>Evaluación de eventos y métricas definidos por el usuario</li>
      </ul>
      <p>
        Además, procesamos sus datos personales en relación con la configuración del usuario. Para
        mejorar la experiencia del usuario, se guardan configuraciones específicas de la aplicación,
        como la configuración de tabla favorita para mostrar datos.
      </p>
      <p>
        Además, para apoyar el proceso de S&OP, los datos que ha modificado o creado se procesan en
        SAP.
      </p>

      <h3>IV. Uso de cookies</h3>
      <p>
        Utilizamos cookies para permitirle hacer el mejor uso posible del sitio web. Las cookies son
        archivos de texto que se almacenan en el navegador de Internet o que el navegador de
        Internet guarda en el terminal del usuario. Si un usuario visita nuestro sitio web, se puede
        almacenar una cookie en el sistema operativo del usuario. Esta cookie contiene una cadena
        característica que permite una identificación clara del navegador cuando se accede de nuevo
        al sitio web.
      </p>
      <p>
        A continuación, le proporcionamos información general sobre el uso de cookies en nuestro
        sitio web y el procesamiento asociado de datos personales. Para obtener más información
        sobre las cookies utilizadas, consulte la Política de cookies de Schaeffler publicada en
        este sitio web. Utilizamos cookies para hacer que nuestro sitio web sea más fácil de usar.
        Algunos elementos de nuestro sitio web requieren que el navegador de búsqueda se identifique
        incluso después de un salto de página.
      </p>
      <p>
        Además, utilizamos cookies en nuestro sitio web para permitir el análisis del comportamiento
        de navegación del usuario.
      </p>
      <p>
        Al visitar nuestro sitio web, el usuario es informado sobre el uso de cookies y se refiere a
        esta política de privacidad.
      </p>
      <p>
        El propósito de utilizar cookies técnicamente necesarias es simplificar el uso de nuestro
        sitio web para el usuario. Algunas características de nuestro sitio web no se pueden ofrecer
        sin el uso de cookies. Es necesario que el navegador sea reconocido incluso después de un
        salto de página. Los datos de usuario recogidos a través de las cookies técnicamente
        necesarias no se utilizarán para crear perfiles de usuario. Puede encontrar más información
        en la <Link to={'/cookiePolicy'}>Política de cookies de Schaeffler</Link>.
      </p>
      <p>
        Las cookies de análisis se utilizan para mejorar la calidad de nuestro sitio web y su
        contenido. Las cookies de análisis nos muestran cómo se utiliza el sitio web, para que
        podamos optimizar constantemente nuestra oferta. Puede encontrar más información en la
        Política de cookies de Schaeffler.
      </p>
      <p>
        La base legal para el procesamiento de datos personales utilizando cookies es el Art. 6 (1)
        (f) RGPD.
      </p>
      <p>
        Las cookies se almacenan en el dispositivo terminal del usuario y se transmiten desde él a
        nuestro sitio web. Por lo tanto, usted como usuario también tiene control total sobre el uso
        de cookies. Al cambiar la configuración de su navegador de Internet, puede deshabilitar o
        restringir la transmisión de cookies. Las cookies que ya se han almacenado se pueden
        eliminar en cualquier momento. Esto también se puede hacer automáticamente. Si se desactivan
        las cookies para nuestro sitio web, es posible que ya no sea posible utilizar todas las
        funciones del sitio web en su totalidad.
      </p>

      <h3>V. Sus derechos como interesado</h3>
      <p>
        Si se procesan sus datos personales, usted es el interesado de conformidad con el RGPD y
        tiene los siguientes derechos:
      </p>

      <h4>1. Derecho de acceso (art. 15 RGPD)</h4>
      <p>
        Previa solicitud, puede pedirnos confirmación sobre si sus datos personales están siendo
        procesados o no por nosotros. Si este es el caso, puede solicitarnos que le demos acceso a
        la información prevista por la ley (consulte el art. 15 (1) RGPD). También le notificaremos
        las garantías adecuadas de conformidad con el art. 46 del RGPD en el contexto de la
        transferencia de datos, en caso de que sus datos personales se transfieran a un tercer país
        o a una organización internacional.
      </p>

      <h4>2. Derecho de rectificación (art. 16 RGPD)</h4>
      <p>
        Tiene derecho a la rectificación y / o finalización si los datos personales procesados son
        inexactos o incompletos. Estamos obligados a rectificar sus datos a la mayor brevedad
        posible.
      </p>

      <h4>3. Derecho a la limitación del tratamiento (art. 18 RGPD)</h4>
      <p>
        Siempre que se cumplan los requisitos legales (consulte el art. 18 (1) RGPD), tiene derecho
        a restringir el procesamiento de sus datos personales. Para conocer las consecuencias de las
        restricciones, consulte el art. 18(2) y (3) del RGPD.
      </p>

      <h4>4. Derecho de supresión (art. 17 RGPD)</h4>
      <p>
        Tiene derecho a exigirnos la eliminación de sus datos personales sin demora indebida, y
        estamos obligados a borrar inmediatamente estos datos si se aplica alguna de las razones de
        conformidad con el Art. 17 (1) RGPD. El derecho de supresión no se aplica en los casos del
        artículo 17, apartado 3, del RGPD.
      </p>

      <h4>5. Derecho de información</h4>
      <p>
        Si ha ejercido su derecho a la rectificación, eliminación o restricción del procesamiento,
        estamos obligados a notificar a cada destinatario al que se han divulgado los datos
        personales sujetos a esta rectificación, eliminación o restricción del procesamiento, a
        menos que esto resulte imposible o implique un esfuerzo desproporcionado. Tenemos que
        informarle sobre esos destinatarios cuando usted lo solicite.
      </p>

      <h4>6. Derecho a la portabilidad de los datos (art. 20 RGPD)</h4>
      <p>
        Tiene derecho a recibir los datos personales que nos haya proporcionado en un formato
        estructurado, de uso común y legible. Para obtener más información, consulte el artículo 20
        del RGPD.
      </p>

      <h4>7. Derecho de oposición (art. 21 RGPD)</h4>
      <p>
        Tiene derecho a oponerse en cualquier momento al procesamiento de sus datos personales que
        se basa en el Art. 6 (1) (e) o (f) RGPD por motivos relacionados con su situación personal.
        Puede encontrar más detalles en Art. 21 RGPD.
      </p>
      <p>
        Además, tiene derecho a presentar una queja ante una autoridad supervisora de conformidad
        con el Art. 77 RGPD.
      </p>

      <h3>VI. Nombre y datos de contacto del responsable del tratamiento</h3>
      <p>
        Schaeffler Technologies AG & Co. KG <br />
        Industriestraße 1-3 <br />
        91074 Herzogenaurach <br />
        Alemania <br />
        Teléfono: + 49 9132 82-0 <br />
        Número de fax: + 49 9132 82 - 49 50 <br />
        Correo electrónico: Datenschutz@schaeffler.com <br />
      </p>

      <h3>VII. Datos de contacto del delegado de protección de datos</h3>
      <p>
        Schaeffler AG <br />
        Datenschutzbeauftragter <br />
        Industriestraße 1-3 <br />
        91074 Herzogenaurach <br />
        Alemania <br />
        Teléfono: +49 9132 82-1476 <br />
        Número de fax: +49 9132 82-5901 <br />
        Correo electrónico: Datenschutz@schaeffler.com <br />
      </p>
      <small>Estado: Versión 1.0, 16.03.2022</small>
    </>
  );
};
