import { PostResult } from '../../core/errorhandling';
import { getErrorMessage } from '../../core/errors';
import { t } from '../../core/i18n/i18n';
import { requestFromApi } from '../../core/requests/httpClient';
import { CMPData } from '../../pages/customerMaterialPortfolio/modal/cmpModalStatusSpecificElements/cmpModalTypes';

import { CMPSingleSubstitutionResponse, CMPWriteRequest } from './model';
import { dataToCMPWriteRequest } from './requestHelper';

export async function saveSubstitution(
  cmpData: CMPData,
  dryrun: boolean,
  confirmation?: boolean,
): Promise<PostResult<CMPSingleSubstitutionResponse>> {
  try {
    const request: CMPWriteRequest = dataToCMPWriteRequest(cmpData);

    const url = `customer-material-portfolio/single-substitution?dryRun=${dryrun}`.concat(
      confirmation !== undefined ? `&addMaterial=${confirmation}` : '',
    );

    const result = (await requestFromApi(url, {
      method: 'POST',
      body: JSON.stringify(request),
    })) as CMPSingleSubstitutionResponse;

    if (result.confirmationNeeded) {
      return {
        overallStatus: 'WARNING',
        overallErrorMsg: t(
          'customer.material_portfolio.modal.substitution.warning.add_material',
          {},
        ),
        response: [],
      };
    }

    return {
      overallStatus: 'SUCCESS',
      overallErrorMsg: null,
      response: [result],
    };
  } catch (e) {
    return {
      overallStatus: 'ERROR',
      overallErrorMsg: getErrorMessage(e),
      response: [],
    };
  }
}
