import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { t } from '../../../core/i18n/i18n';

export type ListUploadPeriodType = 'week' | 'month';

export const ListUploadPeriodTypeRenderer = (params: ICellRendererParams): React.ReactNode => {
  const value = params.value as ListUploadPeriodType | undefined;

  if (value == undefined) {
    return null;
  }
  return <span>{t(`validation_of_demand.upload_modal.list.menu_item_${value}`, {})}</span>;
};
