import { FilterList } from '@mui/icons-material';
import { Icon, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../core/i18n/i18n';
import { formatNumber } from '../../core/i18n/l10n';

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 20px;
`;

export default function ResultCountTitle({
  resultCount,
  loading,
}: {
  resultCount: number | undefined;
  loading: boolean;
}) {
  const count = resultCount || 0;
  const text = loading
    ? t('globalSelection.dropdown.loading', {})
    : `${formatNumber(count)}  ${t('globalSelection.results', {})}`;

  return (
    <TitleContainer>
      <StyledIcon>
        <FilterList />
      </StyledIcon>
      <Typography variant={'h6'} component="h2">
        {text}
      </Typography>
    </TitleContainer>
  );
}
