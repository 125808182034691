import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { ActionButton } from '../../components/ActionButton';
import CustomerDropdown from '../../components/CustomerDropdown';
import DataHint from '../../components/DataHint';
import HeaderActionBar from '../../components/HeaderActionBar';
import { StyledSection } from '../../components/StyledSection';
import GlobalSelectionCriteria from '../../components/globalSelectionCriteria/GlobalSelectionCriteria';
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner';
import { Page } from '../../components/page/Page';
import { PostResult } from '../../core/errorhandling';
import useGlobalSelectionCriteria from '../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { t } from '../../core/i18n/i18n';
import {
  saveEdit,
  saveInactive,
  savePhaseIn,
  savePhaseOut,
  saveReactivation,
} from '../../domain/customerMaterialPortfolio/saveCMPChange';
import { saveSubstitution } from '../../domain/customerMaterialPortfolio/saveSubstitution';
import { CustomerEntry } from '../../domain/globalSelection/model';
import { useCustomersData } from '../../domain/globalSelection/useCustomersData';
import useGlobalSelectionStatus, {
  GlobalSelectionStatus,
} from '../../domain/globalSelection/useGlobalSelectionStatus';

import { AddMultiCustomerMaterialModal } from './modal/AddMultiCustomerMaterialModal';
import { AddSingleCustomerMaterialModal } from './modal/AddSingleCustomerMaterialModal';
import CMPMaterialChangeModal from './modal/CMPMaterialChangeModal';
import { CMPSubstitutionProposalModal } from './modal/CMPSubstitutionProposalModal';
import SchaefflerSuccessorModal from './modal/SchaefflerSuccessorModal';
import { CMPData } from './modal/cmpModalStatusSpecificElements/cmpModalTypes';
import { AdditionalCMPModalInfo } from './table/CmpRowMenuButton';
import {
  CustomerMaterialPortfolioTable,
  CustomerMaterialPortfolioTableHandle,
  FilterModel,
} from './table/CustomerMaterialPortfolioTable';
import { CMPChangeModalFlavor, CMPModal, CMPSpecificModal } from './table/statusActions';

export default function CustomerMaterialPortfolio() {
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntry | undefined>(undefined);
  const { globalSelection } = useGlobalSelectionCriteria();
  const { data: customerData, isLoading } = useCustomersData(globalSelection);

  const [openModal, setOpenModal] = useState<CMPModal>(null);
  const [additionalModalInfo, setAdditionalModalInfo] = useState<
    AdditionalCMPModalInfo | undefined
  >(undefined);
  const [filterModel, setFilterModel] = useState<FilterModel>({});

  const globalSelectionStatus = useGlobalSelectionStatus(
    { data: customerData, isLoading },
    selectedCustomer,
  );

  // The selected data is necessary for the different modals which often refer to a single customer
  // material combination in the portfolio. It is set from the table / row.
  const [selectionForModal, setSelectionForModal] = useState<CMPData | null>(null);

  const title = `${t('tabbar.functions.label', {})} | ${t(
    'tabbarMenu.customer-material-portfolio.label',
    {},
  )}`;

  useEffect(() => {
    setSelectedCustomer(customerData ? customerData[0] : undefined);
  }, [customerData]);

  const tableRef = useRef<CustomerMaterialPortfolioTableHandle>(null);

  const closeModal = () => {
    setOpenModal(null);
    setSelectionForModal(null);
    setAdditionalModalInfo(undefined);
  };

  const closeModalAndRefreshTable = () => {
    closeModal();
    tableRef.current?.refresh();
  };

  const handleModalChange = (
    modalToOpen: CMPModal,
    selectedCmpDataForModal: CMPData,
    additionalInfo: AdditionalCMPModalInfo,
  ) => {
    setOpenModal(modalToOpen);
    setSelectionForModal(selectedCmpDataForModal);
    setAdditionalModalInfo(additionalInfo);
  };

  return (
    <Page title={title}>
      <GlobalSelectionCriteria />
      <StyledSection last={true} grow={true} style={{ position: 'relative' }}>
        <Typography variant="h6" component={'h2'}>
          {t('customer_material_portfolio.title', {})}
        </Typography>
        {globalSelectionStatus === GlobalSelectionStatus.DATA_AVAILABLE &&
          // this is for the compiler, because it does not understand that the field are check in useGlobalSelectionStatus
          customerData &&
          selectedCustomer &&
          globalSelection && (
            <>
              <HeaderActionBar
                actionButtons={
                  <>
                    <ActionButton
                      color="primary"
                      onClick={() => setOpenModal(CMPSpecificModal.SINGLE_PHASE_IN)}
                    >
                      <AddIcon />
                    </ActionButton>
                    <ActionButton
                      disabled={!selectedCustomer}
                      color="secondary"
                      onClick={() => setOpenModal(CMPSpecificModal.MULTI_PHASE_IN)}
                    >
                      <MenuIcon />
                    </ActionButton>
                  </>
                }
              >
                <CustomerDropdown
                  customers={customerData}
                  selectedCustomer={selectedCustomer}
                  onChangeSelectedCustomer={setSelectedCustomer}
                />
              </HeaderActionBar>
              <CustomerMaterialPortfolioTable
                selectedCustomer={selectedCustomer}
                globalSelection={globalSelection}
                ref={tableRef}
                handleModalChange={handleModalChange}
                filterModel={filterModel}
                setFilterModel={setFilterModel}
              />
            </>
          )}{' '}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_LOADING && <LoadingSpinner />}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_NO_RESULTS && (
          <DataHint text={t('hint.noData', {})} />
        )}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_ERROR && (
          <DataHint text={t('hint.errorLoadingData', {})} />
        )}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_NOTHING_SELECTED && (
          <DataHint text={t('hint.selectData', {})} />
        )}
      </StyledSection>

      <AddSingleCustomerMaterialModal
        open={openModal == CMPSpecificModal.SINGLE_PHASE_IN}
        onAdded={closeModalAndRefreshTable}
        onCancel={closeModal}
        customerNumber={selectedCustomer ? selectedCustomer.customerNumber : null}
      />

      {selectedCustomer && (
        <AddMultiCustomerMaterialModal
          modalOpen={openModal == CMPSpecificModal.MULTI_PHASE_IN}
          onAdded={closeModalAndRefreshTable}
          onClose={closeModal}
          customerNumber={selectedCustomer.customerNumber}
        />
      )}

      <CMPSubstitutionProposalModal
        open={openModal == CMPSpecificModal.SUBSTITUTION_PROPOSAL}
        close={closeModal}
        closeAndRefresh={closeModalAndRefreshTable}
        cmpData={selectionForModal}
      />

      <CMPMaterialChangeModal
        title={t(
          `customer_material_portfolio.modal_headline.${openModal as CMPChangeModalFlavor}`,
          {},
          '',
        )}
        open={openModal !== null && openModal in CMPChangeModalFlavor}
        changeModalFlavor={openModal as CMPChangeModalFlavor}
        onClose={closeModal}
        closeAndRefresh={closeModalAndRefreshTable}
        cmpData={selectionForModal}
        onSave={getSaveMethodForChangeModal(openModal)}
      />

      <SchaefflerSuccessorModal
        open={openModal == CMPSpecificModal.SCHAEFFLER_SUBSTITUTION}
        cmpData={selectionForModal}
        additionalInfo={additionalModalInfo}
        onClose={closeModal}
        closeAndRefresh={closeModalAndRefreshTable}
      />
    </Page>
  );
}

function getSaveMethodForChangeModal(
  modal: CMPModal,
): (cmpData: CMPData, dryrun: boolean, confirmation?: boolean) => Promise<PostResult<any>> {
  switch (modal) {
    case CMPChangeModalFlavor.EDIT_MODAL:
      return saveEdit;
    case CMPChangeModalFlavor.STATUS_TO_PHASE_OUT:
      return savePhaseOut;
    case CMPChangeModalFlavor.STATUS_TO_PHASE_IN:
      return savePhaseIn;
    case CMPChangeModalFlavor.STATUS_TO_SUBSTITUTION:
      return saveSubstitution;
    case CMPChangeModalFlavor.STATUS_TO_INACTIVE:
      return saveInactive;
    case CMPChangeModalFlavor.STATUS_TO_ACTIVE:
      return saveReactivation;
    case CMPChangeModalFlavor.REVERT_SUBSTITUTION:
      return saveReactivation;
    default:
      return () => {
        return Promise.resolve({
          overallStatus: 'ERROR',
          overallErrorMsg: t('error.unknown', {}),
          response: [],
        });
      };
  }
}
