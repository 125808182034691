export interface GeoLocationData {
  country: string;
  state: string;
}

export interface ConsentEvent extends CustomEvent {
  detail: Array<string>;
}

export interface OneTrust {
  AllowAll: () => any;
  BlockGoogleAnalytics: (e: any, t: any) => any;
  Close: (e: any) => any;
  FetchAndDownloadPC: () => any;
  GetDomainData: () => any;
  Init: (e: any) => any;
  InitializeBanner: () => any;
  IsAlertBoxClosed: () => boolean;
  IsAlertBoxClosedAndValid: () => boolean;
  LoadBanner: () => any;
  OnConsentChanged: (e: () => void) => ConsentEvent;
  ReconsentGroups: () => any;
  RejectAll: (e: any) => any;
  SetAlertBoxClosed: (e: any) => any;
  ToggleInfoDisplay: () => any;
  changeLanguage: (lang: string) => any;
  getDataSubjectId: () => any;
  getGeolocationData: () => GeoLocationData;
  setGeoLocation: (e: any, t: any) => any;
  useGeoLocationService: boolean;
}

export enum CookiesGroups {
  StrictlyNecessaryCookies = 'StrictlyNecessaryCookies',
  PerformanceCookies = 'PerformanceCookies',
  FunctionalCookies = 'FunctionalCookies',
  TargetingCookies = 'TargetingCookies',
  SocialMediaCookies = 'SocialMediaCookies',
}

export const ONE_TRUST_LOADED_EVENT = 'oneTrustLoaded';
