import {
  AgEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SortModelItem,
} from '@ag-grid-community/core';
import { RefObject } from 'react';

import { formatFilterModelForBackend } from '../../agGrid/FilterModel';
import { DATA_FETCHED_EVENT, FETCH_ERROR_EVENT } from '../../core/datasources';
import { requestFromApi } from '../../core/requests/httpClient';
import {
  GlobalSelectionCriteriaFields,
  globalSelectionCriteriaToFilter,
} from '../globalSelection/model';

import { CMPEntry } from './model';

type CMPResponse = {
  headMaterials: {
    rows: Array<CMPEntry>;
    rowCount: number;
  };
  childOfHeadMaterial: Map<string, Array<CMPEntry>>;
};

function fetchData(
  startRow: number | undefined,
  endRow: number | undefined,
  sortModel: SortModelItem[],
  selectionFilters: any,
  columnFilters: Record<string, any>,
) {
  return requestFromApi(`customer-material-portfolio/`, {
    method: 'POST',
    body: JSON.stringify({
      startRow,
      endRow,
      sortModel,
      selectionFilters: selectionFilters,
      columnFilters: [columnFilters].filter(
        (x: Record<string, any>) => x && Object.keys(x).length > 0,
      ),
    }),
  });
}

function getRowsForGroup(
  childMaterialsCache: React.RefObject<Map<string, Array<CMPEntry>>>,
  groupKeys: string[],
  startRow: number | undefined,
  endRow: number | undefined,
  sortModel: SortModelItem[],
  selectedCustomer: string,
  params: IServerSideGetRowsParams<any, any>,
) {
  const headMaterialNumberOfGroup = groupKeys[0];
  if (childMaterialsCache.current?.has(headMaterialNumberOfGroup)) {
    const cachedValue = childMaterialsCache.current?.get(groupKeys[0]);
    const childMaterials = cachedValue ? cachedValue : [];
    params.success({ rowData: childMaterials, rowCount: childMaterials.length });
  } else {
    fetchData(
      0,
      500,
      sortModel,
      {
        customerNumber: [selectedCustomer],
        materialNumber: [groupKeys[0]],
      },
      {},
    )
      .then(({ childOfHeadMaterial }: CMPResponse) => {
        for (const [key, value] of Object.entries(childOfHeadMaterial)) {
          childMaterialsCache.current?.set(key, value);
        }
        const cachedValue = childMaterialsCache.current?.get(headMaterialNumberOfGroup);
        const childMaterials = cachedValue ? cachedValue : [];
        params.success({ rowData: childMaterials, rowCount: childMaterials.length });
        params.api.dispatchEvent({ type: DATA_FETCHED_EVENT });
      })
      .catch((e) => {
        params.fail();
        params.api.dispatchEvent({ type: FETCH_ERROR_EVENT, error: e } as AgEvent);
      });
  }
}

export function createCustomerMaterialPortfolioDatasource({
  selectedCustomer,
  globalSelection,
  childMaterialsCache,
}: {
  selectedCustomer: string;
  globalSelection: GlobalSelectionCriteriaFields;
  childMaterialsCache: RefObject<Map<string, Array<CMPEntry>>>;
}): IServerSideDatasource {
  return {
    // SAP returns the head and child materials. However, AGGRid does not support providing both at the same time.
    // Instead, a separate request is made for head material with children. Therefore, we use a small cache here
    // containing the child materials of the first request. After some edit operations, parts of the cache must be
    // invalidated. Therefore, the cache is hold outside the data source.
    getRows(params) {
      const { startRow, endRow, sortModel, filterModel, groupKeys } = params.request;

      if (groupKeys?.length > 0) {
        getRowsForGroup(
          childMaterialsCache,
          groupKeys,
          startRow,
          endRow,
          sortModel,
          selectedCustomer,
          params,
        );
      } else {
        const columnFilters = formatFilterModelForBackend(filterModel);
        const selectionFilters = {
          ...globalSelectionCriteriaToFilter(globalSelection),
          customerNumber: [selectedCustomer],
        };

        fetchData(startRow, endRow, sortModel, selectionFilters, columnFilters)
          .then(({ headMaterials, childOfHeadMaterial }: CMPResponse) => {
            for (const [key, value] of Object.entries(childOfHeadMaterial)) {
              childMaterialsCache.current?.set(key, value);
            }
            params.success({ rowData: headMaterials.rows, rowCount: headMaterials.rowCount });
            params.api.dispatchEvent({ type: DATA_FETCHED_EVENT });
          })
          .catch((e) => {
            params.fail();
            params.api.dispatchEvent({ type: FETCH_ERROR_EVENT, error: e } as AgEvent);
          });
      }
    },
  };
}
