import { ITooltipParams } from '@ag-grid-community/core';
import React from 'react';
import styled from 'styled-components';

export const GridTooltip = (props: ITooltipParams) => {
  return (
    <TooltipWrapper>
      <TooltipText>{props.value}</TooltipText>
    </TooltipWrapper>
  );
};

export const TooltipWrapper = styled.div`
  width: 230px;
  height: 100%;
  background-color: #666;
  border-radius: 4px;
  padding: 6px 3px;
`;

export const TooltipText = styled.p`
  color: #fff;
  text-align: center;
`;
