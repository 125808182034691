import { AvailableDecimalSeparators, preferredDecimalSeparator } from './i18n/l10n';
import { validateForLocalFloat } from './validation/validationHelper';

export function strictlyParseInteger(value: string): number {
  if (/^\d+$/.test(value)) {
    return Number(value);
  }
  return NaN;
}

export const strictlyParseFloat = (value: string): number =>
  strictlyParseLocalFloat(value, preferredDecimalSeparator);

export function strictlyParseLocalFloat(
  value: string | number,
  decimalSeparator: AvailableDecimalSeparators,
): number {
  if (typeof value == 'number') return value;
  if (validateForLocalFloat(value, decimalSeparator) !== null) return NaN;

  const valueToCheck =
    decimalSeparator == 'COMMA' ? value.replace('.', '').replace(',', '.') : value.replace(',', '');

  return Number(valueToCheck);
}
