import WarningIcon from '@mui/icons-material/Warning';
import { Grid, Typography, Stack } from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ErrorHandlingDatePicker } from '../../../../../components/DatePicker';
import { t } from '../../../../../core/i18n/i18n';
import { StatusSpecificContentProps } from '../StatusSpecificContent';

const MAX_DATE = new Date(9999, 12, 31);

export default function PhaseOutStatusSpecificContent({
  data,
  onDataChange,
  showValidation,
  setValid: setIsValid,
}: StatusSpecificContentProps) {
  const [phaseOutDateError, setPhaseOutDateError] = useState<DateValidationError | null>(null);

  useEffect(() => {
    if (phaseOutDateError || !data.autoSwitchDate) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  }, [data, setIsValid, phaseOutDateError]);

  return (
    <>
      <HeadlineSecondPart component="h3" variant="subtitle2">
        {t('customer_material_portfolio.modal.subheader.phase_out', {})}
      </HeadlineSecondPart>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ErrorHandlingDatePicker
            maxDate={MAX_DATE}
            minDate={new Date()}
            value={data.autoSwitchDate}
            onChange={(phaseOutDate) => onDataChange({ ...data, autoSwitchDate: phaseOutDate })}
            label={t('customer_material_portfolio.modal.phase_out.label', {})}
            errorMessages={
              showValidation && !data.autoSwitchDate
                ? [t('generic.validation.missing_fields', {})]
                : []
            }
            onError={setPhaseOutDateError}
            fullWidth
            validate={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" gap={1}>
            <WarningIcon color={'warning'} fontSize="large" />
            <Typography variant="body2" component="p">
              {t('customer_material_portfolio.modal.phase_out.warning', {})}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

const HeadlineSecondPart = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 10px;
` as typeof Typography;
