import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { t } from '../../../core/i18n/i18n';
import { PortfolioStatus } from '../../../pages/customerMaterialPortfolio/modal/cmpModalStatusSpecificElements/cmpModalTypes';

export const PortfolioStatusCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  const unknownStatus = t(`material_customer.portfolio_status.unknown`, {});
  return (
    <span>
      {t(
        `material_customer.portfolio_status.${params.value as PortfolioStatus}`,
        {},
        unknownStatus,
      )}
    </span>
  );
};
