import { addYears, endOfYear, startOfMonth } from 'date-fns';
import React, { useState } from 'react';

import { KpiDateRanges } from '../../../domain/demandValidation/model';
import { MaterialType } from '../../../domain/demandValidation/saveValidatedDemand';
import { CustomerEntry } from '../../../domain/globalSelection/model';

import { GridUploadConfigurationModal } from './GridUploadConfigurationModal';
import { GridUploadTableModal } from './GridUploadTableModal';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  customer: CustomerEntry;
};

export function GridUploadModal(props: Props) {
  const [dateRange, setDateRange] = useState<KpiDateRanges>({
    range1: {
      from: new Date(),
      to: startOfMonth(endOfYear(addYears(new Date(), 1))), // start of last month of next year
      period: 'MONTHLY',
    },
    range2: undefined,
  });

  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialType>('schaeffler');
  const [configured, setConfigured] = useState(false);

  return (
    <>
      <GridUploadConfigurationModal
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
        selectedMaterialType={selectedMaterialType}
        onSelectedMaterialTypeChanged={setSelectedMaterialType}
        customer={props.customer}
        onCreate={() => {
          setConfigured(true);
        }}
        open={props.open && !configured}
        onClose={props.onClose}
      />
      <GridUploadTableModal
        dateRange={dateRange}
        materialType={selectedMaterialType}
        customer={props.customer}
        open={props.open && configured}
        onClose={() => {
          setConfigured(false);
          props.onClose();
        }}
        onSave={props.onSave}
      />
    </>
  );
}
