import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { formatNumber } from '../../../core/i18n/l10n';

export const IntlNumberCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  if (params.value == undefined || params.value == null) {
    return '';
  }
  return formatNumber(params.value);
};
