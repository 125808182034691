import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';

import { SelectableValue } from '../../inputs/baseComponents/selectableValues';

type AdditionalProps = {
  options: SelectableValue[];
  getLabel?: (v: SelectableValue) => string;
};

export const SelectableValueOrOriginalCellRenderer = (
  params: ICellRendererParams & AdditionalProps,
): React.ReactNode => {
  const value = params.value;
  const defaultFunc = (v: SelectableValue) => `${v.id} - ${v.text}`;
  const getLabel = params.getLabel ?? defaultFunc;

  if (value == undefined || value == null) {
    return null;
  }

  const foundValue = params.options.find(
    (option) => option.id == value || (option.text == value && option.text != ''),
  );
  if (foundValue) return getLabel(foundValue);

  return value;
};
