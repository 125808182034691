import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { t } from '../../core/i18n/i18n';

import { Footer } from './Footer';
import { Header } from './Header';
import HelpButton from './HelpButton';
import { TabBarNavigation } from './TabBarNavigation';
import { Drawer } from './drawer/Drawer';

export type PageProps = {
  children?: React.ReactNode;
  title?: string;
};

export const Page = ({ children, title }: PageProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const defaultPageTitle = t('header.title', {});

  return (
    <>
      <Helmet>
        <title>{title ? `${defaultPageTitle} - ${title}` : defaultPageTitle}</title>
      </Helmet>
      <Drawer open={menuOpen} onBackButtonClick={toggleMenu} />
      <Header onMenuClick={toggleMenu} />
      <TabBarNavigation />
      <MainContainer>{children}</MainContainer>
      <HelpButton />
      <Footer />
    </>
  );
};

export const MainContainer = styled.main`
  padding: 34px 16px;
  overflow-y: auto;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`;
