import { Divider, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { secondaryColor } from '../../styles/colors';

interface ModalSubheadlineProps {
  text?: string;
}

const SubHeadline = styled.div`
  padding: 8px 24px 16px 24px;
  color: ${secondaryColor};
`;

export const ModalSubHeadline = ({ text }: ModalSubheadlineProps) => {
  return (
    <SubHeadline>
      {text ? <Typography variant="body1">{text}</Typography> : <Divider />}
    </SubHeadline>
  );
};
