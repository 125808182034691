import { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  getDefaultColDef,
  serverSideTableDefaultProps,
  sideBar,
} from '../../../agGrid/gridDefaults';
import { GridApis } from '../../../agGrid/gridTypes';
import useAutosizeColumns from '../../../agGrid/useAutosizeColumns';
import DataHint from '../../../components/DataHint';
import { StyledGridSection } from '../../../components/StyledGridSection';
import TableToolbar from '../../../components/agGrid/TableToolbar';
import { SelectableValue } from '../../../components/inputs/baseComponents/selectableValues';
import { DATA_FETCHED_EVENT } from '../../../core/datasources';
import { t } from '../../../core/i18n/i18n';
import { createInternalMaterialReplacementDatasource } from '../../../domain/internalMaterialReplacement/datasource';
import { IMRSubstitution } from '../../../domain/internalMaterialReplacement/model';
import { IMRModal } from '../InternalMaterialReplacementPage';

import IMRRowMenuButton from './IMRRowMenuButton';
import getIMRColumnDefinitons from './columnDefinitions';

type IMRProps = {
  selectedRegion: SelectableValue;
  openIMRModal: (modal: IMRModal, selectedEntry: IMRSubstitution) => void;
  grid: GridApis | undefined;
  setGrid: (grid: GridApis | undefined) => void;
};

export default function InternalMaterialReplacementTable({
  selectedRegion,
  grid,
  setGrid,
  openIMRModal,
}: IMRProps) {
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  useAutosizeColumns(grid);

  useEffect(() => {
    if (!grid) return;

    const listener = () => {
      const storeState = grid.api.getServerSideGroupLevelState()[0];
      setRowCount(storeState.rowCount);
    };
    setRowCount(undefined);
    grid.api.addEventListener(DATA_FETCHED_EVENT, listener);
  }, [grid, setRowCount]);

  useEffect(() => {
    if (!grid) return;
    if (rowCount == 0) {
      grid.api.showNoRowsOverlay();
    } else {
      grid.api.hideOverlay();
    }
  }, [grid, rowCount]);

  useEffect(() => {
    if (!grid) return;
    grid.api.setServerSideDatasource(
      createInternalMaterialReplacementDatasource({
        selectedRegion: selectedRegion.id,
      }),
    );
  }, [grid, selectedRegion]);

  const columnDefs = useMemo(() => {
    const columnDefinitions = getIMRColumnDefinitons();

    return [
      ...(columnDefinitions.map((col) => ({
        ...getDefaultColDef(col.filter, col.filterParams),
        colId: col.property,
        field: col.property,
        headerName: t(col.colId, {}),
        sortable: true,
        filter: col.filter,
        cellRenderer: col.cellRenderer,
        tooltipComponent: col.tooltipComponent,
        tooltipField: col.tooltipField,
      })) || []),
      // Add row menu
      {
        field: 'menu',
        headerName: '',
        cellRenderer: IMRRowMenuButton,
        lockVisible: true,
        pinned: 'right',
        lockPinned: true,
        cellRendererParams: {
          handleModalChange: openIMRModal,
        },
        suppressMenu: true,
        maxWidth: 64,
        suppressSizeToFit: true,
      },
    ] as ColDef[];
  }, [openIMRModal]);

  const noRows = () => {
    return <DataHint text={t('hint.noData', {})} />;
  };

  return (
    <TableWrapper>
      <TableToolbar rowCount={rowCount} grid={grid} />
      <StyledGridSection>
        <AgGridReact
          {...serverSideTableDefaultProps}
          sideBar={sideBar}
          onGridReady={setGrid}
          columnDefs={columnDefs}
          tooltipShowDelay={0}
          components={{
            ...serverSideTableDefaultProps.components,
          }}
          getRowId={(params) => JSON.stringify(params.data)}
          noRowsOverlayComponent={noRows}
        ></AgGridReact>
      </StyledGridSection>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  transition: all 0.25s ease-in-out;
  will-change: width;
`;
