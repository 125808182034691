import {
  apPortfolioAllowedRoles,
  internalMaterialReplacementAllowedRoles,
  workflowManagementAllowedRoles,
} from '../core/auth/roles';
import ApPortfolioOptimization from '../pages/ApPortfolioOptimization';
import CustomerSpecificRequirements from '../pages/CustomerSpecificRequirements';
import RegionalApPortfolio from '../pages/RegionalApPortfolio';
import AlertRuleManagement from '../pages/alertRules/AlertRuleManagement';
import Alerts from '../pages/alerts/Alerts';
import { CookiePolicy } from '../pages/cookiePolicy/CookiePolicy';
import CustomerMaterialPortfolio from '../pages/customerMaterialPortfolio/CustomerMaterialPortfolio';
import DemandValidationPage from '../pages/demandValidation/DemandValidationPage';
import Home from '../pages/home/Home';
import { Imprint } from '../pages/imprint/Imprint';
import InternalMaterialReplacementPage from '../pages/internalMaterialReplacement/InternalMaterialReplacementPage';
import { PrivacyPolicy } from '../pages/privacyPolicy/PrivacyPolicy';

export type TabItem = 'startPage' | 'functions' | 'tasks';

export const routeConfig = {
  startPage: {
    label: 'tabbar.start-page.label',
    path: '/',
    component: Home,
  },
  functions: [
    {
      label: 'tabbarMenu.validation-of-demand.label',
      path: '/validationOfDemand',
      roleRestrictions: undefined,
      visible: true,
      component: DemandValidationPage,
    },
    {
      label: 'tabbarMenu.customer-material-portfolio.label',
      path: '/customerMaterialPortfolio',
      roleRestrictions: undefined,
      visible: true,
      component: CustomerMaterialPortfolio,
    },
    {
      label: 'tabbarMenu.internal-material-replacement.label',
      path: '/internalMaterialReplacement',
      roleRestrictions: internalMaterialReplacementAllowedRoles,
      visible: true,
      component: InternalMaterialReplacementPage,
    },
    {
      label: 'tabbarMenu.ap-portfolio-optimization.label',
      path: '/apPortfolafterioOptimization',
      roleRestrictions: undefined,
      visible: false,
      component: ApPortfolioOptimization,
    },
    {
      label: 'tabbarMenu.regional-ap-portfolio.label',
      path: '/regionalApPortfolio',
      roleRestrictions: apPortfolioAllowedRoles,
      visible: false,
      component: RegionalApPortfolio,
    },
    {
      label: 'tabbarMenu.customer-specific-requirements.label',
      path: '/customerSpecificRequirements',
      roleRestrictions: undefined,
      visible: false,
      component: CustomerSpecificRequirements,
    },
    {
      label: 'tabbarMenu.alert-rule-editor.label',
      path: '/taskRules',
      visible: true,
      roleRestrictions: workflowManagementAllowedRoles,
      component: AlertRuleManagement,
    },
  ],
  tasks: {
    label: 'tabbar.tasks.label',
    path: '/tasks',
    component: Alerts,
  },
  privacyPolicy: {
    label: 'privacy_policy_page.headline',
    path: '/privacyPolicy',
    component: PrivacyPolicy,
  },
  cookiePolicy: {
    label: 'cookie_policy_page.headline',
    path: '/cookiePolicy',
    component: CookiePolicy,
  },
  imprint: {
    label: 'imprint_page.headline',
    path: '/imprint',
    component: Imprint,
  },
} as const;

export const getTabItemForRoute = (route: string): TabItem => {
  if (route === '/') {
    return 'startPage';
  } else if (route === '/tasks') {
    return 'tasks';
  }
  return 'functions';
};
