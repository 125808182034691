import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

import { ErrorHandlingDatePicker } from '../../components/DatePicker';
import { SingleAutocompleteOnType } from '../../components/inputs/autocomplete/SingleAutocompleteOnType';
import SingleAutocompletePreLoaded from '../../components/inputs/autocomplete/SingleAutocompletePreLoaded';
import { SelectableValue } from '../../components/inputs/baseComponents/selectableValues';
import { LoadingSpinnerModal } from '../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../components/modal/Modal';
import { errorsFromSAPtoMessage, singlePostResultToUserMessage } from '../../core/errorhandling';
import useDomId from '../../core/hooks/useDomId';
import { useSelectableOptions } from '../../core/hooks/useSelectableOptions';
import { useSelectableOptionsWithSearchParam } from '../../core/hooks/useSelectableOptionsWithSearchParam';
import { getPreferredLanguage, t } from '../../core/i18n/i18n';
import { preferredDecimalSeparator } from '../../core/i18n/l10n';
import { fillZeroOnValueFunc } from '../../core/validation/validationHelper';
import { saveMultiAlertRules } from '../../domain/alertrules/alertRuleActions';
import {
  AlertRule,
  AlertTypeDescription,
  ExecDay,
  ExecInterval,
} from '../../domain/alertrules/model';
import useRuleTypeData from '../../domain/alertrules/useRuleTypeData';
import useCurrency from '../../domain/info/useCurrency';

import {
  execIntervalOptions,
  possibleWhenOptions,
  thresholdTypeWithParameter,
} from './AlertRuleOptionsConfig';

export type AlertRuleModalTitle = 'create' | 'edit';

type Props = {
  open: boolean;
  onClose?: () => void;
  alertRule: AlertRule;
  onAlertRuleChange: (alertRule: AlertRule) => void;
  title: AlertRuleModalTitle;
};

// TODO fix this stupid shit later
/* eslint-disable react/display-name */
export default function AlertRuleEditModal({
  open,
  onClose,
  alertRule,
  onAlertRuleChange,
  title,
}: Props) {
  const handleOnClose = () => {
    onClose?.();
    // Reset valid state on close
    setShowInputError(false);
    setFromPickerErrorReason(null);
    setToPickerErrorReason(null);
    setAlertTypeDescriptionWithParameter(null);
    onAlertRuleChange({
      id: '00000000-0000-0000-0000-000000000000',
      startDate: new Date(Date.now()),
      endDate: new Date('9999-12-31'),
    });
  };

  const regionLabelId = useDomId();
  const salesAreaLabelId = useDomId();
  const intervalLabelId = useDomId();
  const whenLabelId = useDomId();
  const sectorMgmtLabelId = useDomId();

  const [loading, setLoading] = useState(false);
  const [showInputError, setShowInputError] = useState(false);
  // datepickers use their own errors because they are evaluated before pressing the save button
  const [fromPickerErrorReason, setFromPickerErrorReason] = useState<string | null>(null);
  const [toPickerErrorReason, setToPickerErrorReason] = useState<string | null>(null);
  const [threshold1Required, setThreshold1Required] = useState<boolean>();
  const [threshold2Required, setThreshold2Required] = useState<boolean>();
  const [threshold3Required, setThreshold3Required] = useState<boolean>();

  const [alertTypeDescriptionWithParameter, setAlertTypeDescriptionWithParameter] =
    useState<AlertTypeDescription | null>(null);

  const alertTypes = useSelectableOptions(
    `global-selection/alert-types?language=${getPreferredLanguage()}&isRuleEditor=true`,
  );
  const demandPlanners = useSelectableOptions(`global-selection/demand-planners`);
  const regionOptions = useSelectableOptions(`global-selection/regions`);
  const sectorMgmtOptions = useSelectableOptions(`global-selection/sector-mgmt`);
  const salesAreaOptions = useSelectableOptions(`global-selection/sales-areas`);
  const salesOrgOptions = useSelectableOptions(
    `global-selection/sales-organisations?language=${getPreferredLanguage()}`,
  );
  const gkamOptions = useSelectableOptions(`global-selection/key-accounts`);
  const productLineOptions = useSelectableOptions(`global-selection/product-line`);

  const getMaterialNumberOptions = useSelectableOptionsWithSearchParam(
    'global-selection/search-materials',
  );
  const getCustomerNumberOptions = useSelectableOptionsWithSearchParam(
    'global-selection/search-customers',
  );
  const getProductionLineOptions = useSelectableOptionsWithSearchParam(
    'global-selection/search-production-line',
  );

  const [whenOptions, setWhenOptions] = useState<ExecDay[]>([]);

  const { currentCurrency } = useCurrency();

  const ruleTypeDescriptionSet = useRuleTypeData();

  const snackbar = useSnackbar();

  const decimalSeparator = preferredDecimalSeparator === 'COMMA' ? ',' : '.';
  const thousandSeparator = preferredDecimalSeparator === 'COMMA' ? '.' : ',';

  useEffect(() => {
    const typeDescription = ruleTypeDescriptionSet?.find(
      (element) => element.alertType === alertRule.type,
    );

    const isThresholdRequired = (thresholdType: string) =>
      thresholdTypeWithParameter.includes(thresholdType || '');

    if (typeDescription && thresholdTypeWithParameter.includes(typeDescription?.threshold1Type)) {
      const { threshold1Type, threshold2Type, threshold3Type } = typeDescription;
      setAlertTypeDescriptionWithParameter(typeDescription);
      setThreshold1Required(isThresholdRequired(threshold1Type));
      setThreshold2Required(isThresholdRequired(threshold2Type));
      setThreshold3Required(isThresholdRequired(threshold3Type));
      return;
    }

    setAlertTypeDescriptionWithParameter(null);
    setThreshold1Required(false);
    setThreshold2Required(false);
    setThreshold3Required(false);
  }, [ruleTypeDescriptionSet, alertRule.type]);

  useEffect(() => {
    if (!alertRule?.execInterval) {
      return;
    }
    const options = possibleWhenOptions[alertRule.execInterval];
    setWhenOptions(options || []);
  }, [alertRule.execInterval]);

  const handleIntervalChange = (execInterval: ExecInterval) => {
    switch (execInterval) {
      case 'M1':
      case 'M2':
      case 'M3':
      case 'M6':
        if (!alertRule.execDay || !possibleWhenOptions[execInterval].includes(alertRule.execDay)) {
          onAlertRuleChange({ ...alertRule, execDay: 'M01', execInterval: execInterval });
        } else {
          onAlertRuleChange({ ...alertRule, execInterval: execInterval });
        }
        break;
      case 'W1':
        // There is only one option here so we can set it directly
        onAlertRuleChange({ ...alertRule, execDay: 'W6', execInterval: execInterval });
        break;
      case 'D1':
        // There is only one option here so we can set it directly
        onAlertRuleChange({ ...alertRule, execDay: 'D', execInterval: execInterval });
        break;
      default:
        setWhenOptions([]);
        onAlertRuleChange({ ...alertRule, execDay: null, execInterval: execInterval });
        break;
    }
  };

  const getEndDateErrorText = () => {
    if (!alertRule.endDate) {
      return [t('generic.validation.missing_fields', {})];
    }

    if (alertRule.startDate && alertRule.endDate && alertRule.endDate < alertRule.startDate) {
      return [t('error.toDateAfterFromDate', {})];
    }

    return undefined;
  };

  const isAtLeastOneFieldSet =
    !alertRule.salesArea &&
    !alertRule.salesOrg &&
    !alertRule.customerNumber &&
    !alertRule.sectorManagement &&
    !alertRule.demandPlannerId &&
    !alertRule.gkamNumber;

  const handleRuleTypeChange = (_: unknown, value: SelectableValue | null) => {
    onAlertRuleChange({
      ...alertRule,
      type: value ? value.id : null,
      threshold1: null,
      threshold2: null,
      threshold3: null,
      currency: alertRule.currency || currentCurrency,
    });

    setShowInputError(false);
  };

  const handleSave = async () => {
    if (
      !alertRule.region ||
      !alertRule.type ||
      !alertRule.execInterval ||
      !alertRule.execDay ||
      !alertRule.startDate ||
      !alertRule.endDate ||
      isAtLeastOneFieldSet
    ) {
      snackbar.enqueueSnackbar(t('generic.validation.missing_fields', {}), {
        variant: 'error',
      });
      setShowInputError(true);
      return;
    }

    if (
      toPickerErrorReason ||
      fromPickerErrorReason ||
      alertRule.startDate > alertRule.endDate ||
      (Number.isNaN(alertRule.threshold1) && threshold1Required) ||
      (Number.isNaN(alertRule.threshold2) && threshold2Required) ||
      (!alertRule.threshold3 && threshold3Required)
    ) {
      snackbar.enqueueSnackbar(t('generic.validation.check_inputs', {}), {
        variant: 'error',
      });
      setShowInputError(true);
      return;
    }

    setShowInputError(false);
    setLoading(true);

    const refinedAlertRule: AlertRule = {
      ...alertRule,
      gkamNumber: alertRule.gkamNumber && fillZeroOnValueFunc(6, alertRule.gkamNumber),
      materialNumber: alertRule.materialNumber && fillZeroOnValueFunc(15, alertRule.materialNumber),
      customerNumber: alertRule.customerNumber && fillZeroOnValueFunc(10, alertRule.customerNumber),
    };

    const postResult = await saveMultiAlertRules([refinedAlertRule]);

    const userMessage = singlePostResultToUserMessage(
      postResult,
      errorsFromSAPtoMessage,
      t('alert_rules.edit_modal.success.alert_rule_created', {}),
    );

    snackbar.enqueueSnackbar(userMessage.message, { variant: userMessage.variant });

    if (userMessage.variant !== 'error') {
      handleOnClose();
    }
    setLoading(false);
  };

  const renderInputWithErrors =
    (label: string, error: boolean) => (params: AutocompleteRenderInputParams) => {
      return <StyledTextField {...params} error={error} label={label} />;
    };

  const renderOptionWithText = (props: React.HTMLAttributes<unknown>, option: SelectableValue) => (
    <Box component="li" key={option.id} {...props}>
      {option.text}
    </Box>
  );

  return (
    <Modal open={open} fullWidth maxWidth="lg" onClose={handleOnClose}>
      <LoadingSpinnerModal open={loading} />

      <Modal.Headline
        onClose={handleOnClose}
        text={t(`alert_rules.edit_modal.title.${title as AlertRuleModalTitle}`, {})}
      >
        <Button variant="contained" size="large" color="primary" onClick={handleSave}>
          {t('button.save', {})}
        </Button>
      </Modal.Headline>

      <Modal.Body>
        <Grid
          className={'ruleEditModal'}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={{ xs: 1, md: 4 }}
        >
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 1, md: 4 }}
          >
            <Grid item xs={12} md={4}>
              <StyledFormControl size={'small'} error={!alertRule.type && showInputError}>
                <SingleAutocompletePreLoaded
                  value={alertRule.type || ''}
                  onValueChange={handleRuleTypeChange}
                  autocompleteLabel={t('alert_rules.edit_modal.label.type', {})}
                  optionsLoadingResult={alertTypes}
                  getOptionLabel={(elem) => elem.text}
                  muiProps={{
                    renderInput: renderInputWithErrors(
                      t('alert_rules.edit_modal.label.type', {}),
                      !alertRule.type && showInputError,
                    ),
                    renderOption: renderOptionWithText,
                  }}
                />
              </StyledFormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledFormControl size={'small'} error={!alertRule.region && showInputError}>
                <InputLabel id={regionLabelId}>
                  {t('alert_rules.edit_modal.label.region', {})}
                </InputLabel>
                <Select
                  labelId={regionLabelId}
                  label={t('alert_rules.edit_modal.label.region', {})}
                  value={alertRule.region || ''}
                  onChange={(e) => onAlertRuleChange({ ...alertRule, region: e.target.value })}
                >
                  {regionOptions.options.map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.text}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 1, md: 4 }}
          >
            <Grid item xs={12} md={4}>
              <StyledFormControl size={'small'} error={isAtLeastOneFieldSet && showInputError}>
                <InputLabel id={salesAreaLabelId}>
                  {t('alert_rules.edit_modal.label.sales_area', {})}
                </InputLabel>
                <Select
                  labelId={salesAreaLabelId}
                  label={t('alert_rules.edit_modal.label.sales_area', {})}
                  value={alertRule.salesArea || ''}
                  onChange={(e) => onAlertRuleChange({ ...alertRule, salesArea: e.target.value })}
                >
                  <MenuItem value="">
                    <em>{t('alert_rules.edit_modal.label.none', {})}</em>
                  </MenuItem>
                  {salesAreaOptions.options.map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.text}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <SingleAutocompletePreLoaded
                value={alertRule.salesOrg}
                onValueChange={(_, value) => {
                  onAlertRuleChange({ ...alertRule, salesOrg: value ? value.id : null });
                }}
                autocompleteLabel={t('alert_rules.edit_modal.label.sales_org', {})}
                optionsLoadingResult={salesOrgOptions}
                muiProps={{
                  renderInput: renderInputWithErrors(
                    t('alert_rules.edit_modal.label.sales_org', {}),
                    isAtLeastOneFieldSet && showInputError,
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <StyledFormControl size={'small'} error={isAtLeastOneFieldSet && showInputError}>
                <InputLabel id={sectorMgmtLabelId}>
                  {t('alert_rules.edit_modal.label.sector_management', {})}
                </InputLabel>
                <Select
                  labelId={sectorMgmtLabelId}
                  label={t('alert_rules.edit_modal.label.sector_management', {})}
                  value={alertRule.sectorManagement || ''}
                  onChange={(e) =>
                    onAlertRuleChange({ ...alertRule, sectorManagement: e.target.value })
                  }
                >
                  <MenuItem value="">
                    <em>{t('alert_rules.edit_modal.label.none', {})}</em>
                  </MenuItem>
                  {sectorMgmtOptions.options.map((element) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.text}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 1, md: 4 }}
          >
            <Grid item xs={12} md={4}>
              <SingleAutocompletePreLoaded
                value={alertRule.demandPlannerId || ''}
                onValueChange={(_, value) => {
                  onAlertRuleChange({ ...alertRule, demandPlannerId: value ? value.id : null });
                }}
                autocompleteLabel={t('alert_rules.edit_modal.label.demandPlanner', {})}
                optionsLoadingResult={demandPlanners}
                muiProps={{
                  renderInput: renderInputWithErrors(
                    t('alert_rules.edit_modal.label.demandPlanner', {}),
                    isAtLeastOneFieldSet && showInputError,
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SingleAutocompletePreLoaded
                value={alertRule.gkamNumber}
                onValueChange={(_, value) => {
                  onAlertRuleChange({ ...alertRule, gkamNumber: value ? value.id : null });
                }}
                autocompleteLabel={t('alert_rules.edit_modal.label.gkam', {})}
                optionsLoadingResult={gkamOptions}
                muiProps={{
                  renderInput: renderInputWithErrors(
                    t('alert_rules.edit_modal.label.gkam', {}),
                    isAtLeastOneFieldSet && showInputError,
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SingleAutocompleteOnType
                value={alertRule.customerNumber || ''}
                onValueChange={(_e, value) =>
                  onAlertRuleChange({ ...alertRule, customerNumber: value?.id })
                }
                autocompleteLabel={t('alert_rules.edit_modal.label.customer', {})}
                getOptions={getCustomerNumberOptions}
                getOptionLabel={(o) => o.text}
                muiProps={{
                  renderInput: renderInputWithErrors(
                    t('alert_rules.edit_modal.label.customer', {}),
                    isAtLeastOneFieldSet && showInputError,
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 1, md: 4 }}
          >
            <Grid item xs={12} md={4}>
              <StyledFormControl size={'small'}>
                <InputLabel>
                  {t('alert_rules.edit_modal.label.materialClassification', {})}
                </InputLabel>
                <Select
                  label={t('alert_rules.edit_modal.label.materialClassification', {})}
                  value={alertRule.materialClassification || ''}
                  onChange={(e) =>
                    onAlertRuleChange({ ...alertRule, materialClassification: e.target.value })
                  }
                >
                  <MenuItem value={''}>
                    <em>{t('alert_rules.edit_modal.label.none', {})}</em>
                  </MenuItem>
                  <MenuItem value={'AP'}>AP</MenuItem>
                  <MenuItem value={'SP'}>SP</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <SingleAutocompletePreLoaded
                value={alertRule.productLine}
                onValueChange={(_, value) => {
                  onAlertRuleChange({ ...alertRule, productLine: value ? value.id : null });
                }}
                autocompleteLabel={t('alert_rules.edit_modal.label.product_line', {})}
                optionsLoadingResult={productLineOptions}
                getOptionLabel={(o) => o.text}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SingleAutocompleteOnType
                value={alertRule.productionLine || ''}
                onValueChange={(_e, value) =>
                  onAlertRuleChange({ ...alertRule, productionLine: value?.id })
                }
                autocompleteLabel={t('alert_rules.edit_modal.label.production_line', {})}
                getOptions={getProductionLineOptions}
                getOptionLabel={(o) => o.text}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 1, md: 4 }}
          >
            <Grid item xs={12} md={4}>
              <SingleAutocompleteOnType
                value={alertRule.materialNumber || ''}
                onValueChange={(_e, value) =>
                  onAlertRuleChange({ ...alertRule, materialNumber: value?.id })
                }
                autocompleteLabel={t('alert_rules.edit_modal.label.material', {})}
                getOptions={getMaterialNumberOptions}
                getOptionLabel={(o) => o.text}
              />
            </Grid>
          </Grid>
        </Grid>

        {alertTypeDescriptionWithParameter != null && (
          <>
            <StyledHr />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={{ xs: 1, md: 4 }}
            >
              <Grid item xs={12} md={4}>
                {threshold1Required && (
                  <NumericFormat
                    value={alertRule?.threshold1}
                    defaultValue=""
                    customInput={StyledTextField}
                    label={alertTypeDescriptionWithParameter.threshold1Description}
                    size="small"
                    error={showInputError && !alertRule.threshold1}
                    onValueChange={(values) => {
                      onAlertRuleChange({ ...alertRule, threshold1: values.value });
                    }}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    decimalScale={0}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {threshold2Required && (
                  <NumericFormat
                    value={alertRule?.threshold2}
                    defaultValue=""
                    customInput={StyledTextField}
                    label={alertTypeDescriptionWithParameter.threshold2Description}
                    size="small"
                    error={showInputError && !alertRule.threshold2}
                    onValueChange={(values) => {
                      onAlertRuleChange({ ...alertRule, threshold2: values.value });
                    }}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    decimalScale={0}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {threshold3Required && (
                  <NumericFormat
                    value={alertRule?.threshold3}
                    defaultValue=""
                    customInput={StyledTextField}
                    label={alertTypeDescriptionWithParameter.threshold3Description}
                    size="small"
                    error={showInputError && !alertRule.threshold3}
                    onValueChange={(values) => {
                      onAlertRuleChange({ ...alertRule, threshold3: values.value });
                    }}
                    thousandSeparator={thousandSeparator}
                    decimalSeparator={decimalSeparator}
                    decimalScale={0}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}

        <StyledHr />

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={{ xs: 1, md: 4 }}
        >
          <Grid item xs={12} md={3}>
            <ErrorHandlingDatePicker
              maxDate={new Date(9999, 12, 31)}
              value={alertRule.startDate}
              onChange={(startDateVal) => {
                onAlertRuleChange({ ...alertRule, startDate: startDateVal });
              }}
              label={t('alert_rules.edit_modal.label.start', {})}
              fullWidth
              onError={(reason, _) => setFromPickerErrorReason(reason)}
              errorMessages={
                !alertRule.startDate ? [t('generic.validation.missing_fields', {})] : undefined
              }
              validate={!!alertRule.startDate || showInputError || !!fromPickerErrorReason}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StyledFormControl size={'small'} error={!alertRule.execInterval && showInputError}>
              <InputLabel id={intervalLabelId}>
                {t('alert_rules.edit_modal.label.interval', {})}
              </InputLabel>
              <Select
                labelId={intervalLabelId}
                label={t('alert_rules.edit_modal.label.interval', {})}
                value={alertRule.execInterval || ''}
                onChange={(e) => {
                  handleIntervalChange(e.target.value as ExecInterval);
                }}
              >
                {execIntervalOptions.map((element) => (
                  <MenuItem value={element} key={element}>
                    {t(`alert_rules.edit_modal.label.interval.${element as ExecInterval}`, {})}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <StyledFormControl size={'small'} error={!alertRule.execDay && showInputError}>
              <InputLabel id={whenLabelId}>{t('alert_rules.edit_modal.label.when', {})}</InputLabel>
              <Select
                labelId={whenLabelId}
                label={t('alert_rules.edit_modal.label.when', {})}
                // Suppresses the warning about the value not being in the list of options while we rerender after a data change
                value={whenOptions.find((elem) => elem == alertRule.execDay) || ''}
                disabled={whenOptions.length == 0}
                onChange={(e) =>
                  onAlertRuleChange({ ...alertRule, execDay: e.target.value as ExecDay })
                }
              >
                {whenOptions.map((element) => (
                  <MenuItem value={element} key={element}>
                    {t(`alert_rules.edit_modal.label.when.${element as ExecDay}`, {})}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <ErrorHandlingDatePicker
              maxDate={new Date(9999, 12, 31)}
              value={alertRule.endDate}
              onChange={(endDateVal) => {
                onAlertRuleChange({ ...alertRule, endDate: endDateVal });
              }}
              label={t('alert_rules.edit_modal.label.end', {})}
              fullWidth
              onError={(reason, _) => setToPickerErrorReason(reason)}
              errorMessages={getEndDateErrorText()}
              validate={!!alertRule.endDate || showInputError || !!toPickerErrorReason}
            />
          </Grid>

          <Grid item xs={12}>
            <StyledTextField
              value={alertRule.alertComment || ''}
              onChange={(e) => {
                onAlertRuleChange({ ...alertRule, alertComment: e.target.value });
              }}
              minRows={4}
              multiline
              label={t('alert_rules.edit_modal.label.comment', {})}
              size={'small'}
            />
          </Grid>
        </Grid>
      </Modal.Body>
    </Modal>
  );
}

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledHr = styled.hr`
  margin-top: 32px;
  margin-bottom: 32px;
  border: none;
  height: 1px;
  background-color: #bbb;
`;
