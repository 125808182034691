import { useMemo } from 'react';
import useSWR from 'swr';

import { requestFromApi } from '../../core/requests/httpClient';
import { MaterialCustomerCustomerRequest } from '../materialCustomer/model';

import {
  GlobalSelectionCriteriaFields,
  globalSelectionCriteriaToFilter,
  CustomerEntry,
} from './model';

export function useCustomersData(globalSelection: GlobalSelectionCriteriaFields | undefined) {
  const customerFetchOptions = useMemo(() => {
    if (!globalSelection) {
      return undefined;
    }

    const requestBody: MaterialCustomerCustomerRequest = {
      startRow: 0,
      endRow: 1,
      sortModel: [],
      columnFilters: [],
      selectionFilters: globalSelectionCriteriaToFilter(globalSelection),
    };

    return {
      method: 'POST',
      body: JSON.stringify(requestBody),
    };
  }, [globalSelection]);

  return useSWR<CustomerEntry[]>(
    customerFetchOptions ? ['global-selection/customers', customerFetchOptions] : null,
    {
      revalidateOnFocus: false,
    },
  );
}

export function fetchData(globalSelection: GlobalSelectionCriteriaFields) {
  const swrParams = getSwrCustomerParams(globalSelection);
  return requestFromApi(...swrParams);
}

function getSwrCustomerParams(
  globalSelection: GlobalSelectionCriteriaFields,
): [string, RequestInit] {
  const requestBody = {
    startRow: 0,
    endRow: 1,
    sortModel: [],
    columnFilters: [],
    selectionFilters: globalSelectionCriteriaToFilter(globalSelection),
  };

  return [
    'material-customer/customers',
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
    },
  ];
}
