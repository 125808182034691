import { AuthenticationResult } from '@azure/msal-common';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';

import setupGridLicense from './agGrid/setupGridLicense';
import { LoadingSpinner } from './components/loadingSpinner/LoadingSpinner';
import { loginRequest } from './config/authConfig';
import { routeConfig } from './config/routeConfig';
import { msalInstance } from './core/auth/authentication';
import { getErrorMessage, useRateLimitedErrorNotification } from './core/errors';
import { getPreferredLanguage, initializeTranslation } from './core/i18n/i18n';
import { swrFetcher } from './core/requests/httpClient';
import AppInsightsProvider from './core/tracking/AppInsightsProvider';
import { useOneTrust } from './core/tracking/oneTrust/useOneTrustLoaded';
import UserDataProvider from './domain/user/UserDataProvider';
import AgGridStyles from './styles/agGrid';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

setupGridLicense();

let authPromise: Promise<void> | null = null;

// Perform authentification actions when app is running client-side
if (typeof window !== 'undefined') {
  const handleRedirectResponse = (response: AuthenticationResult | null) => {
    let account = response?.account;

    // if this was no login redirect try to get logged in account
    if (!account) {
      const currentAccounts = msalInstance.getAllAccounts();

      if (currentAccounts.length === 0) {
        // no accounts logged in, trigger login
        // return the promise since the authentication continues
        return msalInstance.loginRedirect(loginRequest);
      } else if (currentAccounts.length > 1) {
        // more than one account logged in, trigger selection
        // TODO: implement account selection UI, for now we naively use the first account
        account = currentAccounts[0];
      } else if (currentAccounts.length === 1) {
        // one account logged in, use it
        account = currentAccounts[0];
      }
    }

    if (account) {
      msalInstance.setActiveAccount(account);
    }
  };

  authPromise = msalInstance.handleRedirectPromise().then(handleRedirectResponse);
}

function App() {
  const [authFinished, setAuthFinished] = useState(false);
  const [translationInitialized, setTranslationInitialized] = useState(false);
  const oneTrust = useOneTrust();
  const showError = useRateLimitedErrorNotification();

  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>();

  useEffect(() => {
    if (authPromise) {
      authPromise.then(() => {
        setAuthFinished(true);
      });
    } else {
      setAuthFinished(true);
    }
  }, []);

  useEffect(() => {
    initializeTranslation().then((dateFnsLocaleModule) => {
      setDateFnsLocale(dateFnsLocaleModule);
      setTranslationInitialized(true);
    });
  }, []);

  useEffect(() => {
    const lang = getPreferredLanguage();
    const html = window.document.getElementsByTagName('html')[0];
    html.setAttribute('lang', lang);
  }, []);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        loadingTimeout: 10_000,
        /**
         * If a different behavior is wanted for certain hook you can override the shouldRetryOnError and onError property
         * in that hook explicitly.
         */
        shouldRetryOnError: false,
        onError: (err) => showError(getErrorMessage(err)),
        fetcher: swrFetcher,
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalStyles />
          <AgGridStyles />
          {authFinished && translationInitialized ? (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
              {authFinished && (
                <UserDataProvider>
                  <AppInsightsProvider oneTrust={oneTrust}>
                    <Routes>
                      {[
                        routeConfig.startPage,
                        routeConfig.tasks,
                        routeConfig.cookiePolicy,
                        routeConfig.privacyPolicy,
                        routeConfig.imprint,
                        ...routeConfig.functions,
                      ].map((menuFunction) => {
                        const { path, component: RouteComponent } = menuFunction;
                        return <Route key={path} path={path} element={<RouteComponent />} />;
                      })}
                    </Routes>
                  </AppInsightsProvider>
                </UserDataProvider>
              )}
            </LocalizationProvider>
          ) : (
            <LoadingSpinner pageCentered={true} />
          )}
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  );
}

export default App;
