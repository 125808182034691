import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useGlobalSelectionCriteria from '../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { useSelectableOptions } from '../../core/hooks/useSelectableOptions';
import { getPreferredLanguage, t } from '../../core/i18n/i18n';
import { validateProductionSegment } from '../../core/validation/filterValidation';
import { AlertCategory } from '../../domain/alerts/model';
import {
  emptyGlobalSelectionCriteriaFields,
  GlobalSelectionCriteriaFields,
  GlobalSelectionCriteriaType,
  isGlobalSelectionCriteriaEmpty,
} from '../../domain/globalSelection/model';
import {
  filterOptionsIgnoreMinus,
  resolveCustomerNumbers,
  resolveGkamNumber,
  resolveMaterialNumbers,
  resolveOptionsOnType,
  resolveProductionPlants,
  resolveSalesOrg,
  resolveSectors,
} from '../../domain/globalSelection/resolve';
import useGlobalSelectionResultCount from '../../domain/globalSelection/useGlobalSelectionResultCount';
import { materialClassifications } from '../../domain/materialCustomer/model';
import { FormActions } from '../../pages/demandValidation/gridUploadModal/ModalFormLayouts';
import { useUrlCustomerNumbers } from '../../pages/home/useUrlCustomerNumbers';
import { StyledSection } from '../StyledSection';
import FilterDropdown from '../inputs/FilterDropdown';
import { SelectableValue } from '../inputs/baseComponents/selectableValues';

import { GlobalSelectionFieldContainer } from './GlobalSelectionFieldContainer';
import MinimizedGlobalSelectionCriteria from './MinimizedGlobalSelectionCriteria';
import OnTypeAutocompleteWithMultiselect from './OnTypeAutocompleteWithMultiselect';
import PreLoadedAutocompleteWithMultiselect from './PreLoadedAutocompleteWithMultiselect';
import ResultCountTitle from './ResultCountTitle';

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledIcon = styled(IconButton)`
  width: 32px;
  height: 32px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 16px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export default function GlobalSelectionCriteria() {
  const [currentSelectionCriteria, setCurrentSelectionCriteria] = useState<
    GlobalSelectionCriteriaFields | undefined
  >(undefined);

  const { globalSelection, updateGlobalSelectionOnPage } = useGlobalSelectionCriteria();
  const [open, setOpen] = useState(isGlobalSelectionCriteriaEmpty(globalSelection));
  const { resultCount, loading } = useGlobalSelectionResultCount(globalSelection);
  const customerNumbersFromUrl = useUrlCustomerNumbers();

  const regionOptions = useSelectableOptions(
    `global-selection/regions`,
    currentSelectionCriteria?.region,
  );
  const salesAreaOptions = useSelectableOptions(
    `global-selection/sales-areas`,
    currentSelectionCriteria?.salesArea,
  );
  const sectorManagementOptions = useSelectableOptions(
    `global-selection/sector-mgmt`,
    currentSelectionCriteria?.sectorManagement,
  );
  const alertTypeOptions = useSelectableOptions(
    `global-selection/alert-types-open?language=${getPreferredLanguage()}`,
    currentSelectionCriteria?.alertType,
  );

  const { enqueueSnackbar } = useSnackbar();

  // Initially set the selection criteria
  useEffect(() => {
    setCurrentSelectionCriteria(globalSelection);
  }, [globalSelection]);

  // Get customer numbers from url and override if there are some
  useEffect(() => {
    if (customerNumbersFromUrl?.length > 0) {
      resolveCustomerNumbers(customerNumbersFromUrl).then((resolveResult) => {
        const resolvedCustomers = resolveResult
          .map((v) => v.selectableValue)
          .filter((v) => v != undefined) as Array<SelectableValue>;
        const newGlobalSelection = emptyGlobalSelectionCriteriaFields;
        newGlobalSelection.customerNumber = resolvedCustomers;
        updateGlobalSelectionOnPage(newGlobalSelection);
      });
    }
  }, [customerNumbersFromUrl, updateGlobalSelectionOnPage]);

  const handleSelectionChange =
    (propertyName: GlobalSelectionCriteriaType) =>
    (event: unknown, newSelectedOptions: Array<SelectableValue> | undefined) => {
      setCurrentSelectionCriteria({
        ...(currentSelectionCriteria || emptyGlobalSelectionCriteriaFields),
        [propertyName]: newSelectedOptions || [],
      });
    };

  const applyGlobalSelectionCriteria = () => {
    // Raise warning but no need to return, the global selection criteria can be empty & undefined
    if (
      !currentSelectionCriteria ||
      !Object.values(currentSelectionCriteria).some((selectionField) => selectionField.length > 0)
    ) {
      enqueueSnackbar(t('globalSelection.selection_empty', {}), {
        variant: 'warning',
      });
    }

    updateGlobalSelectionOnPage(currentSelectionCriteria);
  };

  const reset = () => {
    updateGlobalSelectionOnPage(undefined);
    setCurrentSelectionCriteria(undefined);
  };

  return (
    <StyledSection>
      <HeaderRow>
        <ResultCountTitle resultCount={resultCount} loading={loading} />
        {!open && <MinimizedGlobalSelectionCriteria />}
        <StyledIcon disableRipple={true} color="secondary" onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </StyledIcon>
      </HeaderRow>
      {open && (
        <Container>
          <GlobalSelectionFieldContainer>
            <FilterDropdown
              value={currentSelectionCriteria?.region}
              label={t('globalSelection.region', {})}
              onChange={handleSelectionChange('region')}
              multiSelect={true}
              {...regionOptions}
            />
          </GlobalSelectionFieldContainer>
          <GlobalSelectionFieldContainer>
            <FilterDropdown
              value={currentSelectionCriteria?.salesArea}
              label={t('globalSelection.sales_area', {})}
              onChange={handleSelectionChange('salesArea')}
              multiSelect={true}
              {...salesAreaOptions}
            />
          </GlobalSelectionFieldContainer>
          <GlobalSelectionFieldContainer>
            <FilterDropdown
              value={currentSelectionCriteria?.sectorManagement}
              label={t('globalSelection.sector_management', {})}
              onChange={handleSelectionChange('sectorManagement')}
              multiSelect={true}
              {...sectorManagementOptions}
            />
          </GlobalSelectionFieldContainer>
          <PreLoadedAutocompleteWithMultiselect
            value={currentSelectionCriteria?.salesOrg}
            onValueChange={handleSelectionChange('salesOrg')}
            autocompleteLabel={t('globalSelection.sales_org', {})}
            selectableOptionsPath={`global-selection/sales-organisations?language=${getPreferredLanguage()}`}
            entityName={t('globalSelection.sales_org.entity_name', {})}
            entityNamePlural={t('globalSelection.sales_org.entity_name_plural', {})}
            resolveFunction={resolveSalesOrg}
          />
          <PreLoadedAutocompleteWithMultiselect
            value={currentSelectionCriteria?.gkamNumber}
            onValueChange={handleSelectionChange('gkamNumber')}
            autocompleteLabel={t('globalSelection.gkam', {})}
            selectableOptionsPath={`global-selection/key-accounts`}
            getOptionLabel={(o) => `${o.id} - ${o.text}`}
            entityName={t('globalSelection.gkam_name.entity_name', {})}
            entityNamePlural={t('globalSelection.gkam_name.entity_name', {})}
            resolveFunction={resolveGkamNumber}
          />
          <OnTypeAutocompleteWithMultiselect
            value={currentSelectionCriteria?.customerNumber}
            onValueChange={handleSelectionChange('customerNumber')}
            autocompleteLabel={t('globalSelection.customer', {})}
            urlBegin={'global-selection/search-customers'}
            entityName={t('globalSelection.customer.entity_name', {})}
            entityNamePlural={t('globalSelection.customer.entity_name_plural', {})}
            resolveFunction={resolveCustomerNumbers}
          />
          <OnTypeAutocompleteWithMultiselect
            value={currentSelectionCriteria?.materialNumber}
            onValueChange={handleSelectionChange('materialNumber')}
            autocompleteLabel={t('globalSelection.material', {})}
            urlBegin={'global-selection/search-materials'}
            entityName={t('globalSelection.material.entity_name', {})}
            entityNamePlural={t('globalSelection.material.entity_name_plural', {})}
            resolveFunction={resolveMaterialNumbers}
            muiProps={{ filterOptions: filterOptionsIgnoreMinus }}
          />
          <GlobalSelectionFieldContainer>
            <FilterDropdown
              value={currentSelectionCriteria?.materialClassification}
              label={t('globalSelection.materialClassification', {})}
              onChange={handleSelectionChange('materialClassification')}
              multiSelect={true}
              options={materialClassifications.map((v) => {
                return { id: v, text: v };
              })}
            />
          </GlobalSelectionFieldContainer>
          <PreLoadedAutocompleteWithMultiselect
            value={currentSelectionCriteria?.sector}
            onValueChange={handleSelectionChange('sector')}
            autocompleteLabel={t('globalSelection.sector', {})}
            selectableOptionsPath={`global-selection/sectors?language=${getPreferredLanguage()}`}
            getOptionLabel={(o) => `${o.id} - ${o.text}`}
            entityName={t('globalSelection.sector.entity_name', {})}
            entityNamePlural={t('globalSelection.sector.entity_name_plural', {})}
            resolveFunction={resolveSectors}
          />
          {/*TODO add production plant here*/}
          <PreLoadedAutocompleteWithMultiselect
            value={currentSelectionCriteria?.productionPlant}
            onValueChange={handleSelectionChange('productionPlant')}
            autocompleteLabel={t('globalSelection.production_plant', {})}
            getOptionLabel={(o) => `${o.id} - ${o.text}`}
            selectableOptionsPath={`global-selection/product-plants`}
            entityName={t('globalSelection.production_plant.entity_name', {})}
            entityNamePlural={t('globalSelection.production_plant.entity_name_plural', {})}
            resolveFunction={resolveProductionPlants}
          />
          <OnTypeAutocompleteWithMultiselect
            value={currentSelectionCriteria?.productionSegment}
            onValueChange={handleSelectionChange('productionSegment')}
            autocompleteLabel={t('globalSelection.production_segment', {})}
            urlBegin={'global-selection/production-segments'}
            entityName={t('globalSelection.production_segment.entity_name', {})}
            entityNamePlural={t('globalSelection.production_segment.entity_name_plural', {})}
            resolveFunction={(values: string[]) => {
              if (values.length > 150) {
                enqueueSnackbar(t('error.tooManyValues', { maxNumber: '150' }), {
                  variant: 'error',
                });
                return Promise.resolve([]);
              }
              return resolveOptionsOnType(
                values,
                'global-selection/production-segments',
                validateProductionSegment,
              );
            }}
          />

          <GlobalSelectionFieldContainer>
            <FilterDropdown
              disabled={alertTypeOptions.options?.length <= 0}
              tooltipText={
                alertTypeOptions?.options?.length <= 0
                  ? t('globalSelection.no_open_alert_types', {})
                  : ''
              }
              value={currentSelectionCriteria?.alertType}
              label={t('globalSelection.tasks', {})}
              onChange={handleSelectionChange('alertType')}
              multiSelect={true}
              {...alertTypeOptions}
              // use translations from frontend instead of SAP and fallback to SAP translation
              options={alertTypeOptions.options?.map((v) => ({
                id: v.id,
                text: t(`alert.category.${v.id as AlertCategory}`, {}, v.text),
              }))}
              formatSelectedValue={(value) => value.text}
              formatOptionValue={(value) => value.text}
            />
          </GlobalSelectionFieldContainer>

          <FormActions justify="flex-end" margin="0 40px 0 0">
            <Button variant="contained" color="white" onClick={reset}>
              {t('button.reset', {})}
            </Button>
            <Button variant="contained" onClick={applyGlobalSelectionCriteria}>
              {t('button.load', {})}
            </Button>
          </FormActions>
        </Container>
      )}
    </StyledSection>
  );
}
