import * as React from 'react';
import { Link } from 'react-router-dom';

export const PrivacyPolicyEN = () => {
  return (
    <>
      <h2>Privacy Policy</h2>
      <p>
        We are pleased about your interest in the Schaeffler Group (Schaeffler AG and affiliated
        companies) and our products. The protection of your privacy when using our online offer is
        very important for us. If personal data is processed, we observe the applicable data
        protection laws.
      </p>

      <h3>I. General information about data processing</h3>

      <h4>1. Scope and purpose of processing of personal data</h4>
      <p>
        In principle, we collect and use your personal data only insofar as it is necessary to
        provide a functional website as well as our content and services offered on the website. The
        processing of your personal data takes place either on the basis of your consent or insofar
        as the legal regulations allow this data processing even without consent.
      </p>

      <h4>2. Legal basis for data processing</h4>
      <p>
        Your personal data is processed on the basis of the EU General Data Protection Regulation
        (GDPR) and the applicable local data protection law, f.e. the German Federal Data Protection
        Act (BDSG).
      </p>
      <p>
        Insofar as you have given us consent to process personal data for specific purposes, Art. 6
        (1) lit. a GDPR serves as the legal basis for the processing of personal data. Consent given
        can be revoked by you at any time. Please note that the revocation is only effective for the
        future. Processing that took place before the revocation is not affected.
      </p>
      <p>
        Processing of personal data in the context of the performance of a contract to which you are
        a party or in order to take steps prior to entering a contract at your request is based on
        Art. 6 (1) lit. b GDPR. The purposes of data processing are governed by the respective
        contract documents and the subject matter of the contract.
      </p>
      <p>
        If processing of personal data is necessary to comply with a legal obligation to which we
        are subject, Art. 6 (1) lit. c GDPR serves as a legal basis.
      </p>
      <p>
        If processing is necessary for the purposes of the legitimate interests pursued by
        Schaeffler or by a third party (e.g., to assert legal claims and defend against legal
        disputes; to ensure IT security; to prevent criminal acts; for business management measures
        and for the further development of services and products) and if your interests, fundamental
        rights and freedoms as data subject do not override the aforementioned interest, Art. 6 (1)
        lit. f GDPR serves as the legal basis for processing.
      </p>

      <h4>3. Data erasure and retention period</h4>
      <p>
        We process and store your personal data for as long as this is necessary to satisfy the
        respective purpose. In addition, such storage may take place in order to comply with a legal
        obligation by Union or Member State law, regulation or other provision to which we as
        controller are subject. If the data is no longer necessary or if a retention period
        prescribed by the aforementioned laws has expired, your tracking-data will be erased
        regularly after 90 days. Furthermore, customer forecasts are maintained in Demand360. In the
        case of newly created or changed customer forecasts, these are written away together with
        the name abbreviation to SAP and deleted after 36 months. The user settings data can be
        deleted on request.
      </p>

      <h4>4. Access to personal data within the Schaeffler Group and by third parties</h4>
      <p>
        Within the Schaeffler Group, those entities gain access to your data who require it as a
        part of &quot;least privilege&quot; (assignment of user rights to the lowest possible
        extent) and the &quot;need-to-know&quot; principle (knowledge of data only if necessary). We
        may only transfer data to third parties outside the Schaeffler Group if this is necessary,
        if statutory provision so requires, you have consented or processors engaged by us have
        contractually agreed to comply with the requirements of the GDPR and applicable local data
        protection law.
      </p>
      <p>
        Under these circumstances, recipients of personal data may include: Microsoft Azure, SAP API
        Management and SAP
      </p>

      <h4>5. Transfer of personal data to a third country or to an international organisation</h4>
      <p>
        A transfer of data to countries outside the EU/EA (so-called third countries) will only take
        place as it is necessary or required by law, you have given your consent or as part of data
        processing by a processor. If service providers in third countries are deployed, in addition
        to written instructions, they are required to comply with data protection standards in
        Europe by agreeing on the EU standard contractual clauses.
      </p>

      <h4>6. IT security and links to third-party websites</h4>
      <p>
        The Schaeffler Group uses technical and organizational security measures to protect your
        data that we manage against accidental or intentional destruction, manipulation, loss or
        access by unauthorized persons. These safeguards are constantly being developed in
        accordance with the respective new technical possibilities. Our websites may contain links
        to websites of other providers. Our information on data protection does not apply to these
        websites.
      </p>

      <h4> 7. Obligation to provide personal data</h4>
      <p>
        If we provide you with offers and services on this website that you can voluntarily use,
        there is no duty to provide your data to us, but without your personal data, you may not be
        able to use our offers and services.
      </p>

      <h4>8. &quot;Profiling&quot; and automated decision-making</h4>
      <p>
        We do not use fully automated decision-making pursuant to Art. 22 GDP. Schaeffler basically
        does not use &quot;profiling&quot;. If we use it in individual cases, we will inform you
        about this separately, if it is required by law and - if necessary - obtain your prior
        consent.
      </p>

      <h4>9. Sources of your personal data </h4>
      <p>We use data that we receive from you.</p>

      <h3>II. Data processing for the provision of the website and the creation of log files</h3>
      <p>
        By default, when you visit our website, our web servers obtain and collect the name of your
        Internet service provider, your IP address, the website from which you are visiting us, the
        websites you visit on our website, and the date and duration of the visit. This data is
        stored in the log files of our systems and is used for problem or error analysis. However,
        the use of the IP address is limited to the technically necessary extent and is abbreviated
        and therefore used only anonymously, so that it is not possible to assign the IP address to
        a user. The data is not merged with personal data.
      </p>
      <p>The legal basis for the temporary storage of the data is Art. 6 para. 1 lit. f GDPR.</p>
      <p>
        The temporary storage of the abbreviated IP address by our systems is technically necessary
        to display the website to your terminal device. Storage in log files is done to ensure the
        functionality of the website. Data is not being analyzed for marketing purposes in this
        context.
      </p>
      <p>
        For these purposes, we have legitimate interest in processing of data according to Art. 6
        (1) (f) GDPR.
      </p>
      <p>
        The collection of data for the provision of the website and the storage of data in log files
        is essential for the operation of the website. Therefore, there is no possibility for you as
        a user to object to such processing.
      </p>

      <h3>III. Data processing in respect of services offered on the website</h3>
      <p>
        On our website various services are offered, for the use of which we request personal data
        from you In this context, it is always optional for you to provide us with personal data. In
        this context, we process your personal data exclusively on an anonymous basis in order to
        enable the following evaluations:
      </p>
      <ul>
        <li>
          Evaluations of request rates, response times and error rates of the website to find out
          which pages are most frequently used at what times of the day and where the users are
          located.
        </li>
        <li>Evaluations of page views and loading performance of the website</li>
        <li>Evaluations of the number of users and sessions</li>
        <li>Evaluation of user-defined events and metrics</li>
      </ul>
      <p>
        In addition, we process your personal data in connection with the user setting. To improve
        the user experience, application-specific settings such as the favorite table setting for
        displaying data are saved.
      </p>
      <p>
        Furthermore, to support the S&OP process, data records that you have modified or created are
        stored in SAP.
      </p>

      <h3>IV. Use of cookies</h3>
      <p>
        We use cookies to enable you to make the best possible use of the website. Cookies are text
        files that are stored in the Internet browser or saved by the Internet browser on the
        terminal device of the user. If a user visits our website, a cookie can be stored on the
        users operating system. This cookie contains a characteristic string that allows clear
        identification of the browser when the website is accessed again.
      </p>
      <p>
        In the following, we provide you with general information about the use of cookies on our
        website and the associated processing of personal data. For further information on
        specifically used cookies, please refer to the Schaeffler Cookie Policy published on this
        website. We use cookies to make our website more user-friendly. Some elements of our website
        require the requesting browser to be identified even after a page break.
      </p>
      <p>
        In addition, we use cookies on our website to allow the analysis of the users surfing
        behavior.
      </p>
      <p>
        When visiting our website the user is informed about the use of cookies and referred to this
        privacy policy.
      </p>
      <p>
        The purpose of using technically necessary cookies is to simplify the use of our website for
        the user. Some features of our website cannot be offered without the use of cookies. It is
        necessary that the browser is recognized even after a page break. The user data collected
        through the technically necessary cookies will not be used to create user profiles. Further
        information can be found in the Schaeffler Cookie Policy. Analysis cookies are used to
        improve the quality of our website and its content. Analysis cookies show us how the website
        is used, so that we can constantly optimize our offer. Further information can be found in
        the <Link to={'/cookiePolicy'}>Schaeffler Cookie Policy</Link>.
      </p>
      <p>
        The legal basis for the processing of personal data using cookies is Art. 6 (1) (f) GDPR.
      </p>
      <p>
        Cookies are stored on the users terminal device and transmitted from it to our website.
        Therefore, you as a user also have full control over the use of cookies. By changing the
        settings in your internet browser, you can disable or restrict the transmission of cookies.
        Cookies that have already been stored can be deleted at any time. This can also be done
        automatically. If cookies are deactivated for our website, it may no longer be possible to
        use all functions of the website in full.
      </p>

      <h3>V. Your rights as data subject</h3>
      <p>
        If your personal data is being processed, you are the data subject pursuant to the GDPR and
        you have the following rights:
      </p>

      <h4>1. Right of access (Art. 15 GDPR)</h4>
      <p>
        Upon request you can obtain confirmation from us as to whether or not your personal data is
        being processed by us. If this is the case, you can request us to give you access to the
        information provided for by law (see Art. 15 (1) GDPR). We will also notify you of
        appropriate safeguards pursuant to Art. 46 GDP in the context of data transfer, in case your
        personal data is being transferred to a third country or to an international organization.
        There are the restrictions according to §§ 34 and 35 German Federal Data Protection Act
        (BDSG)
      </p>

      <h4>2. Right to rectification (Art. 16 GDPR)</h4>
      <p>
        You have a right to rectification and/or completion if the processed personal data is
        inaccurate or incomplete. We have to rectify the data without due delay.
      </p>

      <h4>3. Right to restriction of processing (Art. 18 GDPR)</h4>
      <p>
        Provided that the legal requirements are met (see Art. 18 (1) GDPR), you have the right to
        restrict processing of your personal data. For consequences of the restrictions please refer
        to Art. 18 (2) and (3) GDPR.
      </p>

      <h4>4. Right to erasure (Art. 17 GDPR)</h4>
      <p>
        You have the right to demand from us erasure of your personal data without undue delay, and
        we are obliged to immediately erase this data if any of the reasons pursuant to Art. 17 (1)
        GDPR applies. The right to erasure does not apply in cases of Art. 17 (3) GDPR. Furthermore,
        there are restrictions accord ing to 8$ 34 and 35 BDSG.
      </p>

      <h4>5. Right to notification</h4>
      <p>
        If you have exercised your right to rectification, erasure, or restriction of processing, we
        are obliged to notify each recipient to whom the personal data have been disclosed of this
        rectification, erasure, or restriction of processing, unless this proves impossible or
        involves disproportionate effort. We have to inform you about those recipients upon your
        request.
      </p>

      <h4>6. Right to data portability (Art. 20 GDPR)</h4>
      <p>
        You have the right to receive your personal data that you have provided to us in a
        structured, commonly used and machine-readable format. For details please refer to Art. 20
        GDPR.
      </p>

      <h4>7. Right to object (Art. 21 GDPR)</h4>
      <p>
        You have the right to object at any time to the processing of your personal data that is
        based on Art. 6 (1) (e) or (f) GDPR on grounds relating to your particular situation.
        Further details can be found in Art. 21 GDPR.
      </p>
      <p>
        In addition, you have a right to lodge a complaint with a supervisory authority pursuant to
        Art. 77 GDPR and § 19 BDSG.
      </p>

      <h3>VI. Name and contact details of the controller</h3>
      <p>
        Schaeffler Technologies AG & Co. KG <br />
        Industriestraße1-3 <br />
        91074 Herzogenaurach <br />
        Germany <br />
        Telephone number: + 49 9132 82-0 <br />
        Fax number: + 49 9132 82 - 49 50 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>

      <h3>VII. Contact details of the data protection officer</h3>
      <p>
        Schaeffler AG <br />
        Datenschutzbeauftragter <br />
        Industriestraße1-3 <br />
        91074 Herzogenaurach <br />
        Deutschland <br />
        Telephone number: +49 9132 82-1476 <br />
        Fax number: +49 9132 82-5901 <br />
        E-Mail: Datenschutz@schaeffler.com <br />
      </p>
      <small>Status: Version 1.0, 16.03.2022</small>
    </>
  );
};
