import streamSaver from 'streamsaver';

import { HttpError } from './httpClient';

export async function streamResponseToFile(filename: string, res: Response) {
  // we host the streamsaver's 'mitm' ourselves
  // this is the place we copy this static asset to
  streamSaver.mitm = '/mitm.html';

  // ponyfill WritableStream on some browsers (mainly FF)
  if (typeof window.WritableStream === 'undefined') {
    const streamsPonyfill = await import('web-streams-polyfill/ponyfill');
    streamSaver.WritableStream = streamsPonyfill.WritableStream as typeof WritableStream;
  }

  if (!res.ok) {
    const errorDetails = await res.json();
    throw new HttpError(res.status, errorDetails);
  }

  const fileStream = streamSaver.createWriteStream(filename);

  if (res.body) {
    const reader = res.body.getReader();
    const writer = fileStream.getWriter();

    // pump body stream to file stream
    // note: pipeTo(…) is not supported by FF at the moment
    const pump = () =>
      reader.read().then((res) => {
        if (res.done) {
          writer.close();
        } else {
          writer.write(res.value).then(pump);
        }
      });

    pump();
  }
}
