import { t } from '../../../core/i18n/i18n';
import { preferredDateFormat, preferredDateFormatWithoutDay } from '../../../core/i18n/l10n';
import { SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES } from '../forecastTable/MoreInformation';

export function getTranslationsForExport(activeAndPredecessor: boolean) {
  const translations = new Map<string, string>();

  SUPPLY_CONCEPT_SUPPORTED_STOCHASTIC_TYPES.forEach((stochasticType) => {
    translations.set(
      'supplyConcept.' + stochasticType + '.csss',
      t(`validation_of_demand.supply_concept.${stochasticType}.csss`, {
        fixHor: '{fixHor}',
        safetyStock: '{safetyStock}',
      }),
    );
    translations.set(
      'supplyConcept.' + stochasticType + '.ss',
      t(`validation_of_demand.supply_concept.${stochasticType}.ss`, {
        fixHor: '{fixHor}',
        safetyStock: '{safetyStock}',
      }),
    );
    translations.set(
      'supplyConcept.' + stochasticType,
      t(`validation_of_demand.supply_concept.${stochasticType}`, {
        fixHor: '{fixHor}',
      }),
    );
  });
  translations.set('supplyConcept.ELSE', t('validation_of_demand.supply_concept.ELSE', {}));

  translations.set('salesOrg', t('material_customer.column.salesOrg', {}));
  translations.set('customerNumber', t('material_customer.column.customerNumber', {}));
  translations.set('customerName', t('material_customer.column.customerName', {}));
  translations.set('materialNumber', t('material_customer.column.materialNumber', {}));
  translations.set('materialDescription', t('material_customer.column.materialDescription', {}));
  translations.set(
    'customerMaterialNumber',
    t('material_customer.column.customerMaterialNumber', {}),
  );
  translations.set('packagingSize', t('material_customer.column.packagingSize', {}));
  translations.set(
    'materialClassification',
    t('material_customer.column.materialClassification', {}),
  );
  translations.set('currentRltSchaeffler', t('material_customer.column.currentRLTSchaeffler', {}));
  translations.set('supplyConcept', t('validation_of_demand.supply_concept.title', {}));
  translations.set(
    'productLineAndText',
    t('validation_of_demand.more_information.product_line_and_text', {}),
  );
  translations.set('productionSegment', t('material_customer.column.productionSegment', {}));
  translations.set('productionLine', t('material_customer.column.productionLine', {}));
  translations.set('kpi', t('validation_of_demand.planning_table.kpi', {}));
  translations.set(
    'validatedForecast',
    t('validation_of_demand.planning_table.validated_forecast', {}),
  );
  translations.set(
    'deliveries',
    activeAndPredecessor
      ? t('validation_of_demand.planning_table.deliveries_combined', {})
      : t('validation_of_demand.planning_table.deliveries', {}),
  );
  translations.set(
    'firmBusiness',
    activeAndPredecessor
      ? t('validation_of_demand.planning_table.firm_business_combined', {})
      : t('validation_of_demand.planning_table.firm_business', {}),
  );
  translations.set('opportunities', t('validation_of_demand.planning_table.opportunities', {}));
  translations.set(
    'forecastProposal',
    t('validation_of_demand.planning_table.forecast_proposal', {}),
  );
  translations.set(
    'forecastProposalDemandPlanner',
    t('validation_of_demand.planning_table.forecast_proposal_demand_planner', {}),
  );
  translations.set('currentDemandPlan', t('validation_of_demand.planning_table.demand_plan', {}));
  translations.set('opAdjustment', t('validation_of_demand.menu_item.opAdjustment', {}));
  translations.set('requested', t('planing_type.title.REQUESTED', {}));
  translations.set('confirmed', t('planing_type.title.CONFIRMED', {}));
  translations.set('viewType', t('validation_of_demand.export_modal.excel_header_view', {}));
  translations.set('dateFormatWeek', preferredDateFormat);
  translations.set('dateFormatMonth', preferredDateFormatWithoutDay);
  translations.set(
    'headerCalenderWeek',
    t('validation_of_demand.planning_table.calendar_week_table_header_kw', {
      calendar_week: '{}',
    }),
  );
  translations.set(
    'headerPartialWeek',
    t('validation_of_demand.planning_table.calendar_week_table_header_part_week', { days: '{}' }),
  );

  return Object.fromEntries(translations.entries());
}
