import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';

import { CustomerMaterialNumberCellRenderer } from '../components/agGrid/cellRenderers/CustomerMaterialNumberCellRenderer';
import { IntlDateCellRenderer } from '../components/agGrid/cellRenderers/IntlDateCellRenderer';
import { IntlNumberCellRenderer } from '../components/agGrid/cellRenderers/IntlNumberCellRenderer';
import { PortfolioStatusCellRenderer } from '../components/agGrid/cellRenderers/PortfolioStatusCellRenderer';
import { getLocaleText } from '../core/i18n/i18n';
import { isGlobalSelectionCriteria } from '../domain/globalSelection/model';

export const defaultRowHeight = 42;

const tableDefaultProps: AgGridReactProps = {
  stopEditingWhenCellsLoseFocus: true, // no need for manally deselecting the cell before clicking 'save'
  suppressColumnVirtualisation: true, // column virtualisation prevents hidden columns to be autosized
  getLocaleText: getLocaleText,

  suppressRowClickSelection: true, // do not select a row when selecting a cell
  suppressCopyRowsToClipboard: true, // so one can copy values from single cells while the row is selected
  rowSelection: 'multiple',
  enableRangeHandle: true,
  enableRangeSelection: true,

  rowHeight: defaultRowHeight,
  headerHeight: 54,

  components: {
    numberRenderer: IntlNumberCellRenderer,
    dateRenderer: IntlDateCellRenderer,
    portfolioStatusCellRenderer: PortfolioStatusCellRenderer,
    customerMaterialNumberCellRenderer: CustomerMaterialNumberCellRenderer,
  },

  defaultColDef: {
    menuTabs: ['filterMenuTab', 'generalMenuTab'],
  },

  modules: [
    SideBarModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    RichSelectModule,
    RangeSelectionModule,
    ClipboardModule,
    SetFilterModule,
  ],
};

export const sideBar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivotMode: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
};

export const serverSideTableDefaultProps: AgGridReactProps = {
  ...tableDefaultProps,

  maxBlocksInCache: 200,
  blockLoadDebounceMillis: 50,
  rowModelType: 'serverSide',
  suppressServerSideInfiniteScroll: false,

  // render non-visible rows for tests
  suppressRowVirtualisation: process.env.NODE_ENV === 'test',

  modules: [...(tableDefaultProps.modules || []), ServerSideRowModelModule],
};

export const clientSideTableDefaultProps: AgGridReactProps = {
  ...tableDefaultProps,

  modules: [...(tableDefaultProps.modules || []), ClientSideRowModelModule],
};

export const getDefaultColDef = (columnFilterType?: string, columnFilterParams?: any): ColDef => {
  const additionalFilterOptions: Record<string, string[]> = {
    agNumberColumnFilter: [
      'equals',
      'greaterThan',
      'greaterThanOrEqual',
      'lessThan',
      'lessThanOrEqual',
    ],
    agTextColumnFilter: ['equals', 'contains', 'startsWith', 'endsWith'],
    agDateColumnFilter: [
      'equals',
      'greaterThan',
      'greaterThanOrEqual',
      'lessThan',
      'lessThanOrEqual',
      'blank',
    ],
  };

  // The column filter type agTextColumnFilter is the default in our application.
  // If a text filter is used, no filter type is defined.
  // Therefore, we use the agTextColumnFilter as default here.
  const filterOptions =
    additionalFilterOptions[columnFilterType ? columnFilterType : 'agTextColumnFilter'];

  return {
    filterParams: {
      maxNumConditions: 1,
      closeOnApply: true,
      filterOptions,
      buttons: ['reset', 'apply'],
      ...columnFilterParams,
    },
    resizable: true,
  };
};

export function getColFilter(
  colId: string,
  filter: string | undefined,
  criteriaData: any | undefined,
) {
  if (isGlobalSelectionCriteria(colId)) {
    return undefined;
  }

  return criteriaData?.filterableFields?.includes(colId)
    ? filter || 'agTextColumnFilter'
    : undefined;
}
