import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ProviderContext } from 'notistack';

import { formatFilterModelForBackend } from '../../../agGrid/FilterModel';
import { GridApis } from '../../../agGrid/gridTypes';
import { CustomErrorMessages, getErrorMessage } from '../../../core/errors';
import { t } from '../../../core/i18n/i18n';
import { preferredDateFormat } from '../../../core/i18n/l10n';
import { authenticatedFetch } from '../../../core/requests/httpClient';
import { streamResponseToFile } from '../../../core/requests/streamResponseToFile';
import { GlobalSelectionCriteriaFilters } from '../../../domain/globalSelection/model';

import { ColId } from './columnDefinitions';

export async function exportMaterialCustomer(
  grid: GridApis | undefined,
  globalSelectionFilters: GlobalSelectionCriteriaFilters | undefined,
  setExportModalOpen: (open: boolean) => void,
  appInsights: ReactPlugin,
  snackbar: ProviderContext,
) {
  if (!grid) return;
  if (!globalSelectionFilters) return; // should not happen since the button is disabled

  appInsights.trackEvent({ name: '[Home] Export Field List Data' });
  setExportModalOpen(true);

  const columnFilters = [formatFilterModelForBackend(grid.api.getFilterModel())];
  const columnState = grid.columnApi.getColumnState();

  const sortModel = columnState
    .map((c) => ({ colId: c.colId, sort: c.sort }))
    .filter((f) => f.sort != null);

  const filteredRequest = {
    startRow: 0,
    endRow: 1,
    sortModel: sortModel,
    selectionFilters: globalSelectionFilters,
    columnFilters: columnFilters,
  };

  try {
    // we will just export the visible columns
    // technical columns (starting with an _) will also be excluded
    const exportColIds: ColId[] = columnState
      .filter((state) => !state.hide && !state.colId.startsWith('_'))
      .map((state) => state.colId as ColId);

    await streamResponseToFile(
      'export.xlsx',
      await authenticatedFetch('material-customer/export', {
        method: 'POST',
        body: JSON.stringify({
          filteredRequest: filteredRequest,
          columns: exportColIds,
          translations: {
            ...Object.fromEntries(
              exportColIds.map((colId) => [colId, t(`material_customer.column.${colId}`, {})]),
            ),
            forecastMaintained_true: t('field.forecastMaintained.value.true', {}),
            forecastMaintained_false: t('field.forecastMaintained.value.false', {}),
            dateFormat: preferredDateFormat,
          },
        }),
      }),
    );

    snackbar.enqueueSnackbar(t('material_customer.export.download_started', {}), {
      variant: 'success',
    });
    appInsights.trackEvent({ name: '[Home] Export Field List Data Success' });
  } catch (e) {
    const errorMessage = t('material_customer.export.failed', {
      reason: getErrorMessage(e, customErrorMessages),
    });

    snackbar.enqueueSnackbar(errorMessage, {
      variant: 'error',
    });
    appInsights.trackEvent({ name: '[Home] Export Field List Data Failure' });
  }

  setExportModalOpen(false);
}

const customErrorMessages: CustomErrorMessages = {
  'material_customer.export.max_count_exceeded': (detail) => {
    return t('material_customer.export.max_count_exceeded', {
      max_count: detail.values?.max_export_count,
    });
  },
};
