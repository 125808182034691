import React from 'react';

import { Page } from '../components/page/Page';
import { t } from '../core/i18n/i18n';

const CustomerSpecificRequirements = () => {
  const title = `${t('tabbar.functions.label', {})} | ${t(
    'tabbarMenu.customer-specific-requirements.label',
    {},
  )}`;

  return <Page title={title}></Page>;
};

export default CustomerSpecificRequirements;
