import { Paper, Popover, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../../core/i18n/i18n';
import { ChartValues, chartSeriesConfig } from '../../../domain/forecastChart/model';

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
};

export function ForecastChartLegendPopover(props: Props) {
  const legendItems = Object.entries(chartSeriesConfig);

  const nameFormatter = (name: string) => {
    try {
      const labelProp = name as ChartValues;
      return t(`home.chart.legend.${labelProp}`, {});
    } catch (e) {
      return name;
    }
  };

  return (
    <>
      <Popover
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledPaper>
          {legendItems.map(([name, { color }]: [string, { color: string }], index: number) => {
            return (
              <Row key={index}>
                <div
                  style={{ background: color, width: '20px', height: '20px', marginRight: '10px' }}
                />
                <Typography>{nameFormatter(name)}</Typography>
              </Row>
            );
          })}
        </StyledPaper>
      </Popover>
    </>
  );
}
const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  padding: 5px 0;
`;
