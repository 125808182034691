import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { addMonths, formatISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { DateRange } from '../../../core/dateRange';
import { getErrorMessage } from '../../../core/errors';
import useGlobalSelectionCriteria from '../../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { t } from '../../../core/i18n/i18n';
import { authenticatedFetch } from '../../../core/requests/httpClient';
import { streamResponseToFile } from '../../../core/requests/streamResponseToFile';
import {
  DemandValidationFilter,
  demandValidationFilterToStringFilter,
} from '../../../domain/demandValidation/demandValidationFilters';
import { firstViewableDate, lastViewableDate } from '../../../domain/demandValidation/limits';
import { KpiDateRanges, SelectedKpis } from '../../../domain/demandValidation/model';
import {
  globalSelectionCriteriaToFilter,
  CustomerEntry,
} from '../../../domain/globalSelection/model';
import { secondaryColor } from '../../../styles/colors';
import { DemandValidationDatePicker, fillGapBetweenRanges } from '../DemandValidationDatePicker';
import { FormActions, FormLayout } from '../gridUploadModal/ModalFormLayouts';

import { getTranslationsForExport } from './getTranslationsForExport';

type ExportModalParams = {
  open: boolean;
  onClose: () => void;
  customers: CustomerEntry[];
  dateRange: KpiDateRanges;
  demandValidationFilters: DemandValidationFilter | undefined;
};

type KpiText =
  | 'deliveries'
  | 'firmBusiness'
  | 'opportunities'
  | 'forecastProposal'
  | 'forecastProposalDemandPlanner'
  | 'validatedForecast'
  | 'demandPlan'
  | 'demandPlanAndOPAjustment'
  | 'activeAndPredecessor';

export const ExportModal = ({
  open,
  onClose,
  customers,
  demandValidationFilters,
  dateRange,
}: ExportModalParams) => {
  const snackbar = useSnackbar();
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [dateRange1, setDateRange1] = useState<Partial<DateRange>>(dateRange.range1);
  const [dateRange2, setDateRange2] = useState<Partial<DateRange> | undefined>(dateRange.range2);
  const [dateRangeError, setDateRangeError] = React.useState(false);
  const [selectedKpis, setSelectedKpis] = useState<SelectedKpis>({
    activeAndPredecessor: false,
    deliveries: true,
    firmBusiness: true,
    opportunities: true,
    forecastProposal: true,
    forecastProposalDemandPlanner: true,
    validatedForecast: true,
    indicativeDemandPlan: false,
    currentDemandPlan: true,
    confirmedDeliveries: false,
    confirmedFirmBusiness: false,
    confirmedDemandPlan: false,
  });
  const appInsights = useAppInsightsContext();
  const { globalSelection } = useGlobalSelectionCriteria();

  useEffect(() => {
    setDateRange1(dateRange.range1);
    setDateRange2(
      dateRange.range2 || {
        from: addMonths(dateRange.range1.to, 1),
        period: 'MONTHLY',
      },
    );
  }, [dateRange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedKpis({
      ...selectedKpis,
      [event.target.name]: event.target.checked,
    });
  };

  const handleExcelExport = async () => {
    if (dateRangeError) return;

    const filters = {
      columnFilters: [],
      selectionFilters: {
        ...globalSelectionCriteriaToFilter(globalSelection),
        ...demandValidationFilterToStringFilter(demandValidationFilters),
      },
    };

    try {
      setExportModalOpen(true);
      appInsights.trackEvent({ name: '[Validated Sales Planning] Export Data' });
      const filledRange = fillGapBetweenRanges(dateRange1, dateRange2);
      if (filledRange) {
        await streamResponseToFile(
          'demandValidationExport.xlsx',
          await authenticatedFetch('demand-validation/export', {
            method: 'POST',
            body: JSON.stringify({
              dataFilters: filters,
              selectedKpis: selectedKpis,
              range1: {
                from: formatISO(filledRange.range1.from, { representation: 'date' }),
                to: formatISO(filledRange.range1.to, { representation: 'date' }),
                period: dateRange1.period,
              },
              range2: filledRange.range2
                ? {
                    from: formatISO(filledRange.range2.from, { representation: 'date' }),
                    to: formatISO(filledRange.range2.to, { representation: 'date' }),
                    period: filledRange.range2.period,
                  }
                : undefined,
              translations: getTranslationsForExport(selectedKpis.activeAndPredecessor),
            }),
          }),
        );
      }
      snackbar.enqueueSnackbar(t('validation_of_demand.export_modal.download_started', {}), {
        variant: 'success',
      });
      appInsights.trackEvent({ name: '[Validated Sales Planning] Export Data Success' });
    } catch (e: any) {
      snackbar.enqueueSnackbar(getErrorMessage(e), {
        variant: 'error',
      });
      appInsights.trackEvent({ name: '[Validated Sales Planning] Export Data Failure' });
    }

    setExportModalOpen(false);
    onClose();
  };

  function KpiSwitch({ kpi, kpiText }: { kpi: keyof SelectedKpis; kpiText: KpiText }) {
    return (
      <FormControlLabel
        control={
          <Switch checked={selectedKpis[kpi]} onChange={handleChange} name={kpi} size={'small'} />
        }
        label={<KPIspan>{getKPINameLabel(kpiText)}</KPIspan>}
      />
    );
  }

  function getKPINameLabel(name: KpiText) {
    if (name === 'demandPlanAndOPAjustment') {
      return (
        t(`validation_of_demand.menu_item.demandPlan`, {}) +
        ' / ' +
        t(`validation_of_demand.menu_item.opAdjustment`, {})
      );
    } else {
      return t(`validation_of_demand.menu_item.${name}`, {});
    }
  }

  return (
    <Modal open={open} onClose={onClose} maxWidth={'lg'} fullWidth={true}>
      <Modal.Headline onClose={onClose} text={t('validation_of_demand.export_modal.title', {})} />

      <Modal.SubHeadline />

      <Modal.Body>
        <FormLayout direction={'column'}>
          <Stack direction="row" gap={2}>
            <CustomerSelection>
              <ExportHeadline>
                {t('validation_of_demand.export_modal.selected_customer_headline', {})}
              </ExportHeadline>
              <Typography>
                {t('validation_of_demand.export_modal.selected_customer', {
                  number: customers.length,
                })}
              </Typography>
            </CustomerSelection>
            <KpiSelection>
              <ExportHeadline>
                {t('validation_of_demand.export_modal.selected_kpis', {})}
              </ExportHeadline>
              <Paper elevation={3}>
                <FormControl component="fieldset" variant="standard" fullWidth>
                  <KpiGroupHeadline>{t('planing_type.title.REQUESTED', {})}</KpiGroupHeadline>
                  <FlexFormGroup>
                    <KpiSwitch kpi="deliveries" kpiText="deliveries" />
                    <KpiSwitch kpi="firmBusiness" kpiText="firmBusiness" />
                    <KpiSwitch kpi="opportunities" kpiText="opportunities" />
                    <KpiSwitch kpi="forecastProposal" kpiText="forecastProposal" />
                    <KpiSwitch
                      kpi="forecastProposalDemandPlanner"
                      kpiText="forecastProposalDemandPlanner"
                    />
                    <KpiSwitch kpi="validatedForecast" kpiText="validatedForecast" />
                    <KpiSwitch kpi="currentDemandPlan" kpiText="demandPlanAndOPAjustment" />
                  </FlexFormGroup>
                </FormControl>
                <Divider />
                <KpiGroupHeadline>{t('planing_type.title.CONFIRMED', {})}</KpiGroupHeadline>
                <FormControl component="fieldset" variant="standard" fullWidth>
                  <FlexFormGroup>
                    <KpiSwitch kpi="confirmedDeliveries" kpiText="deliveries" />
                    <KpiSwitch kpi="confirmedFirmBusiness" kpiText="firmBusiness" />
                    <KpiSwitch kpi="confirmedDemandPlan" kpiText="demandPlan" />
                  </FlexFormGroup>
                </FormControl>
                <Divider />
                <FormControl component="fieldset" variant="standard" fullWidth>
                  <FlexFormGroup>
                    <KpiSwitch kpi="activeAndPredecessor" kpiText="activeAndPredecessor" />
                  </FlexFormGroup>
                </FormControl>
              </Paper>
            </KpiSelection>
          </Stack>
          <DateRangeSelection>
            <ExportHeadline>
              {t('validation_of_demand.export_modal.date_headline', {})}
            </ExportHeadline>
            <DemandValidationDatePicker
              dateRange1={dateRange1}
              onDateRange1Change={(value) => setDateRange1(value)}
              dateRange2={dateRange2}
              onDateRange2Change={(value) => setDateRange2(value)}
              onDateRangeErrorChange={setDateRangeError}
              minDate={firstViewableDate()}
              maxDate={lastViewableDate()}
            />
          </DateRangeSelection>
          <FormActions justify={'flex-end'}>
            <ExportButton
              variant={'contained'}
              color={'primary'}
              onClick={handleExcelExport}
              disabled={dateRangeError}
            >
              {t('validation_of_demand.export_modal.button_export', {})}
            </ExportButton>
          </FormActions>
        </FormLayout>
      </Modal.Body>

      <LoadingSpinnerModal
        open={exportModalOpen}
        text={t('validation_of_demand.export_modal.in_progress', {})}
      />
    </Modal>
  );
};

const CustomerSelection = styled.div`
  width: 400px;
  padding-right: 50px;
`;

const KpiSelection = styled.div`
  width: 100%;
`;

const DateRangeSelection = styled.div`
  padding-top: 20px;
`;

const ExportHeadline = styled(Typography).attrs({ variant: 'body1' })`
  font-weight: bold;
  padding-bottom: 5px;
  color: ${secondaryColor};
`;

const KpiGroupHeadline = styled(Typography).attrs({ variant: 'body2' })`
  font-weight: bold;
  padding-left: 18px;
  padding-top: 15px;
  color: ${secondaryColor};
`;

const FlexFormGroup = styled(FormGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 38px 50px;
  padding: 20px;
`;

const KPIspan = styled.span`
  font-size: small;
`;

const ExportButton = styled(Button)`
  text-transform: uppercase;
`;
