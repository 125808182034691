import {
  dimmedBlue,
  dimmedGreen,
  dimmedGrey,
  dimmedRed,
  dimmedYellow,
  textDarkGrey,
} from '../../styles/colors';
import { PlanningView } from '../demandValidation/planningView';

export type ForecastChartData = {
  chartUnitMode: ChartUnitMode;
  planningView: PlanningView;
  currency: string;
  chartEntries: ChartEntry[];
};

export const chartSeriesConfig = {
  orders: { color: dimmedYellow },
  deliveries: { color: dimmedGrey },
  demandPlan: { color: dimmedGreen },
  opportunities: { color: dimmedRed },
  opAdjustment: { color: dimmedBlue },
  rollingSalesForecast: { color: textDarkGrey },
} as const;

export type ChartValues = keyof typeof chartSeriesConfig;

export type ChartEntry = {
  yearMonth: string;
  orders: number;
  deliveries: number;
  demandPlan: number;
  opportunities: number;
  opAdjustment: number;
  rollingSalesForecast: number | null;
};

export enum ChartUnitMode {
  CURRENCY = 'CURRENCY',
  QUANTITY = 'QUANTITY',
}
