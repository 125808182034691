/**
 * This is a generic typesafe approach to i18n with typescript.
 * It might be moved to a separate library if needed by other projects.
 */

// prettier-ignore
type TemplateParameterName<TEMPLATE extends string> =
  TEMPLATE extends `${string}{{${infer Param}}}${infer Rest}`
    ? (Param | TemplateParameterName<Rest>)
    : (TEMPLATE extends `${string}{{${infer LastParam}}}` ? LastParam : never);

type TemplateParameters<TEMPLATE extends string> = {
  [key in TemplateParameterName<TEMPLATE>]: string | number;
};

type SimpleEntry = string;
type PluralRule = readonly [number | null, number | null, string];

// prettier-ignore
type PluralsEntry =
  | readonly [ string ]
  | readonly [ string, PluralRule ]
  | readonly [ string, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule ]
  | readonly [ string, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule, PluralRule ];

export type Language<I18N_KEY extends string> = {
  readonly [key in I18N_KEY]: SimpleEntry | PluralsEntry;
};

// prettier-ignore
type TemplateFromEntry<ENTRY> = ENTRY extends SimpleEntry
  ? ENTRY
  : ENTRY extends PluralsEntry
    ? ENTRY[0]
    : never;

type AddCountWhenPlurals<ENTRY, PARAMS> = ENTRY extends PluralsEntry
  ? PARAMS & { count: number }
  : PARAMS;

type OmitEmpty<T> = T extends Record<string, never> ? Record<string, never> : T;

export type TranslationFunction<I18N_KEY extends string, LANGUAGE extends Language<I18N_KEY>> = <
  K extends I18N_KEY,
>(
  key: K,
  params: OmitEmpty<
    AddCountWhenPlurals<LANGUAGE[K], TemplateParameters<TemplateFromEntry<LANGUAGE[K]>>>
  >,
  fallback?: string,
) => string;

export function makeTranslate<I18N_KEY extends string, LANGUAGE extends Language<I18N_KEY>>(
  language: LANGUAGE,
): TranslationFunction<I18N_KEY, LANGUAGE> {
  return function t<K extends I18N_KEY>(
    key: K,
    params: OmitEmpty<
      AddCountWhenPlurals<LANGUAGE[K], TemplateParameters<TemplateFromEntry<LANGUAGE[K]>>>
    >,
    fallback?: string,
  ) {
    const entry = language[key];

    if (entry == undefined) {
      if (fallback != undefined) {
        return fallback;
      } else {
        console.warn('[i18n] Missing translation:', key);
        return key;
      }
    }

    let template: string;
    if (typeof entry === 'string') {
      // this is simple entry -> use it directly
      template = entry;
    } else {
      // this is pluralized entry -> the default is stored in the first field
      template = entry[0];
    }

    const count = (params as any)?.count as number | undefined;

    // handle pluralization
    if (count != undefined) {
      if (entry instanceof Array) {
        // split the default value from pluralization rules
        const [_, ...pluralRules] = entry as PluralsEntry;

        // find the first matching rule
        const matchingPlural = pluralRules.find(
          (pluralRule) =>
            (pluralRule[0] === null || pluralRule[0] <= count) &&
            (pluralRule[1] === null || pluralRule[1] >= count),
        );

        // use the template from the matching rule
        if (matchingPlural) {
          template = matchingPlural[2];
        }
      }
    }

    // process template parts independently, so we never process any user input

    // split template at params
    const templateParts = template.split(/({{.*?}})/g);

    const messageParts = templateParts.map((part: string) => {
      // replace part with value if this is a param
      if (part.startsWith('{{') && part.endsWith('}}')) {
        const placeholder = part.substring(2, part.length - 2);
        const valueForPlaceholder = (params as any)[placeholder];

        if (valueForPlaceholder != undefined) {
          return valueForPlaceholder;
        }
      }

      return part;
    });

    return messageParts.join('');
  };
}
