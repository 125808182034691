import React from 'react';

import { Page } from '../components/page/Page';
import { t } from '../core/i18n/i18n';

const ApPortfolioOptimization = () => {
  const title = `${t('tabbar.functions.label', {})} | ${t(
    'tabbarMenu.ap-portfolio-optimization.label',
    {},
  )}`;
  return <Page title={title}></Page>;
};

export default ApPortfolioOptimization;
