import { ICellRendererParams } from '@ag-grid-community/core';
import { MenuItem } from '@mui/material';
import { parseISO } from 'date-fns';
import React, { useState } from 'react';

import RowMenu from '../../../components/agGrid/RowMenu';
import { t } from '../../../core/i18n/i18n';
import {
  CMPData,
  PortfolioStatus,
  parsePortfolioStatusOrNull,
} from '../modal/cmpModalStatusSpecificElements/cmpModalTypes';

import { CMPAction, CMPModal, statusActions } from './statusActions';

type AdditionalProps = {
  handleModalChange: (
    newModalOpen: CMPModal,
    selectedCmpData: CMPData,
    additionalModalInfo: AdditionalCMPModalInfo,
  ) => void;
};

export type AdditionalCMPModalInfo = {
  divergentSchaefflerSuccessor: string | undefined;
};

export default function CmpRowMenuButton(props: ICellRendererParams & AdditionalProps) {
  const [open, setOpen] = useState<boolean>(false);

  const currentStatus = parsePortfolioStatusOrNull(props.node.data.portfolioStatus);
  const hasSchaefflerSuccessor =
    !!props.node.data.successorSchaefflerMaterial &&
    props.node.data.successorSchaefflerMaterial !== '';

  const handleActionClick =
    (modal: CMPModal, changePortfolioStatusTo: PortfolioStatus | undefined) => () => {
      // datepicker uses today as a date for undefined values so use null if nothing is there
      const autoSwitchDate = props.node.data.pfStatusAutoSwitch
        ? parseISO(props.node.data.pfStatusAutoSwitch)
        : null;
      const repDate = props.node.data.repDate ? parseISO(props.node.data.repDate) : null;

      const newPortfolioStatus = changePortfolioStatusTo
        ? changePortfolioStatusTo
        : parsePortfolioStatusOrNull(props.node.data.portfolioStatus);

      // It is very important to use props.node.data not props.data
      // because the data in the latter is not up to date and may return values from old renderings
      const data: CMPData = {
        customerNumber: props.node.data.customerNumber,
        materialNumber: props.node.data.materialNumber,
        materialDescription: props.node.data.materialDescription,
        demandCharacteristic: props.node.data.demandCharacteristic,
        portfolioStatus: newPortfolioStatus,
        autoSwitchDate: autoSwitchDate,
        repDate: repDate,
        successorMaterial: props.node.data.successorMaterial,
        demandPlanAdoption: null,
      };

      const additionalInfo: AdditionalCMPModalInfo = {
        divergentSchaefflerSuccessor: props.node.data.successorSchaefflerMaterial,
      };

      props.handleModalChange(modal, data, additionalInfo);
      setOpen(false);
    };

  return (
    <>
      <RowMenu open={open} setOpen={setOpen}>
        {statusActions
          .filter((cmpAction: CMPAction) =>
            cmpAction.isAllowed(currentStatus, hasSchaefflerSuccessor),
          )
          .map((cmpAction: CMPAction) => {
            return (
              <MenuItem
                key={cmpAction.name}
                onClick={handleActionClick(cmpAction.modal, cmpAction.changeToStatus)}
              >
                {t(`customer.material_portfolio.modal.action.${cmpAction.name}`, {})}
              </MenuItem>
            );
          })}
      </RowMenu>
    </>
  );
}
