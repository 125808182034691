import { IHeaderParams } from '@ag-grid-community/core';
import {
  addDays,
  differenceInBusinessDays,
  endOfMonth,
  format,
  isFirstDayOfMonth,
  nextMonday,
  parseISO,
} from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../core/i18n/i18n';
import { formatDate, getCalendarWeek, preferredDateFormatWithoutDay } from '../../core/i18n/l10n';
import { KpiBucketType, KpiEntry } from '../../domain/demandValidation/model';

export interface ICustomHeaderParams extends IHeaderParams {
  kpiEntry: KpiEntry;
  onClickHeader(entry: KpiEntry): void;
}

export default function DemandValidationKpiHeader(props: ICustomHeaderParams) {
  return (
    <>
      {props.kpiEntry && (
        <HeaderDiv onClick={() => props.onClickHeader(props.kpiEntry)}>
          {props.kpiEntry.bucketType == 'WEEK' || props.kpiEntry.bucketType == 'PARTIAL_WEEK' ? (
            <HeaderWrapper>
              <span>{getWeekHeader(props.kpiEntry.fromDate, props.kpiEntry.bucketType)}</span>
              <span>{formatDate(parseISO(props.kpiEntry.fromDate))}</span>
            </HeaderWrapper>
          ) : (
            format(parseISO(props.kpiEntry.fromDate), preferredDateFormatWithoutDay)
          )}
        </HeaderDiv>
      )}
    </>
  );
}

export function getWeekHeader(date: string, bucketType: KpiBucketType): React.ReactNode {
  const fromDate = parseISO(date);
  const kw = t('validation_of_demand.planning_table.calendar_week_table_header_kw', {
    calendar_week: getCalendarWeek(fromDate),
  });

  const partWeek =
    bucketType == 'PARTIAL_WEEK'
      ? ' ' +
        t('validation_of_demand.planning_table.calendar_week_table_header_part_week', {
          days: isFirstDayOfMonth(fromDate)
            ? differenceInBusinessDays(nextMonday(fromDate), fromDate)
            : differenceInBusinessDays(addDays(endOfMonth(fromDate), 1), fromDate),
        })
      : '';

  return kw + partWeek;
}

const HeaderDiv = styled.div`
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
