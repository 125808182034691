import React from 'react';

import {
  ErrorMessage,
  ModalMode,
  TableUploadModal,
} from '../../../components/tableUploadModal/TableUploadModal';
import { useSelectableOptions } from '../../../core/hooks/useSelectableOptions';
import { getPreferredLanguage, t } from '../../../core/i18n/i18n';
import { deleteMultiAlterRules } from '../../../domain/alertrules/alertRuleActions';
import { AlertRule, ExecDay, ExecInterval } from '../../../domain/alertrules/model';
import useRuleTypeData from '../../../domain/alertrules/useRuleTypeData';
import {
  execIntervalOptions,
  getThresholdRequirements,
  ThresholdsRequiredForAlertType,
  whenOptions,
} from '../AlertRuleOptionsConfig';

import { MultiModalProps, parseErrors } from './AlertRuleMultiModal';
import { checkAlertRuleData, getSpecialParseFunctions } from './alertRuleLogicHelper';
import { getMultiAlertRuleModalColumns } from './multiAlertRuleModalColumns';

export default function AlertMultiDeleteModal(props: MultiModalProps) {
  const alertTypes = useSelectableOptions(
    `global-selection/alert-types?language=${getPreferredLanguage()}&isRuleEditor=true`,
  );
  const demandPlanners = useSelectableOptions(`global-selection/demand-planners`);
  const regionOptions = useSelectableOptions(`global-selection/regions`);
  const sectorMgmtOptions = useSelectableOptions(`global-selection/sector-mgmt`);
  const salesAreaOptions = useSelectableOptions(`global-selection/sales-areas`);
  const salesOrgOptions = useSelectableOptions(
    `global-selection/sales-organisations?language=${getPreferredLanguage()}`,
  );
  const gkamOptions = useSelectableOptions(`global-selection/key-accounts`);
  const productLineOptions = useSelectableOptions(`global-selection/product-line`);

  const intervalOpts = execIntervalOptions.map((o) => {
    return { id: o, text: t(`alert_rules.edit_modal.label.interval.${o as ExecInterval}`, {}) };
  });
  const whenOpts = whenOptions.map((o) => {
    return { id: o, text: t(`alert_rules.edit_modal.label.when.${o as ExecDay}`, {}) };
  });
  const ruleTypeDescriptionSet = useRuleTypeData();

  const thresholdRequirements: ThresholdsRequiredForAlertType[] =
    getThresholdRequirements(ruleTypeDescriptionSet);

  function checkData(data: AlertRule[]): ErrorMessage<AlertRule>[] {
    const errors: ErrorMessage<AlertRule>[] = [];
    data.forEach((alertRule) => {
      errors.push(...checkAlertRuleData(alertRule, thresholdRequirements));
    });
    return errors;
  }

  const specialParseFunctionsForFields: Map<keyof AlertRule, (value: string) => string> =
    getSpecialParseFunctions(
      alertTypes,
      regionOptions,
      salesAreaOptions,
      salesOrgOptions,
      sectorMgmtOptions,
      demandPlanners,
      gkamOptions,
      productLineOptions,
      intervalOpts,
      whenOpts,
    );

  return (
    <TableUploadModal
      title={t('alert_rules.multi_modal.delete_rules', {})}
      open={props.open}
      onClose={props.onClose}
      onAdded={props.onAdded}
      applyFunction={deleteMultiAlterRules}
      parseErrorsFromResult={parseErrors}
      columnDefinitions={getMultiAlertRuleModalColumns(
        alertTypes.options,
        intervalOpts,
        whenOpts,
        regionOptions.options,
        demandPlanners.options,
        sectorMgmtOptions.options,
        salesOrgOptions.options,
        salesAreaOptions.options,
        productLineOptions.options,
        gkamOptions.options,
      )}
      checkDataForErrors={checkData}
      specialParseFunctionsForFields={specialParseFunctionsForFields}
      wideModal={true}
      maxRows={500}
      modalMode={ModalMode.DElETE}
    />
  );
}
