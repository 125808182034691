import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

import Modal from '../../../components/modal/Modal';
import useDomId from '../../../core/hooks/useDomId';
import { t } from '../../../core/i18n/i18n';
import { MaterialType } from '../../../domain/demandValidation/saveValidatedDemand';
import { CustomerEntry } from '../../../domain/globalSelection/model';
import { FormActions, FormLayout } from '../gridUploadModal/ModalFormLayouts';

type Props = {
  selectedMaterialType: MaterialType;
  onSelectedMaterialTypeChanged: (materialType: MaterialType) => void;
  onCreate: () => void;
  customer: CustomerEntry;
  onClose: () => void;
  open: boolean;
};

export function ListUploadConfigurationModal(props: Props) {
  const materialTypeInputId = useDomId();

  return (
    <>
      <Modal open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
        <Modal.Headline
          onClose={props.onClose}
          text={`${t('validation_of_demand.upload_modal.list.title', {})} - ${
            props.customer.customerName
          }`}
        />

        <Modal.SubHeadline />

        <Modal.Body>
          <FormLayout direction={'row'}>
            <FormControl fullWidth size={'small'}>
              <FormLabel id={materialTypeInputId}>
                {t('validation_of_demand.upload_modal.material_type.title', {})}
              </FormLabel>
              <RadioGroup
                value={props.selectedMaterialType}
                onChange={(e) => {
                  props.onSelectedMaterialTypeChanged(e.target.value as MaterialType);
                }}
                aria-labelledby={materialTypeInputId}
                row
              >
                <FormControlLabel
                  value="schaeffler"
                  control={<Radio />}
                  label={t('validation_of_demand.upload_modal.material', {})}
                />
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label={t('validation_of_demand.upload_modal.customer_material', {})}
                />
              </RadioGroup>
            </FormControl>
          </FormLayout>

          <FormActions justify={'flex-end'}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                props.onCreate();
              }}
            >
              {t('button.create', {})}
            </Button>
          </FormActions>
        </Modal.Body>
      </Modal>
    </>
  );
}
