import React, { useState } from 'react';

import { MaterialType } from '../../../domain/demandValidation/saveValidatedDemand';
import { CustomerEntry } from '../../../domain/globalSelection/model';

import { ListUploadConfigurationModal } from './ListUploadConfigurationModal';
import { ListUploadTableModal } from './ListUploadTableModal';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  customer: CustomerEntry;
};

export function ListUploadModal(props: Props) {
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialType>('schaeffler');
  const [configured, setConfigured] = useState(false);

  return (
    <>
      <ListUploadConfigurationModal
        selectedMaterialType={selectedMaterialType}
        onSelectedMaterialTypeChanged={setSelectedMaterialType}
        customer={props.customer}
        onCreate={() => {
          setConfigured(true);
        }}
        open={props.open && !configured}
        onClose={props.onClose}
      />
      <ListUploadTableModal
        materialType={selectedMaterialType}
        customer={props.customer}
        open={props.open && configured}
        onClose={() => {
          setConfigured(false);
          props.onClose();
        }}
        onSave={props.onSave}
      />
    </>
  );
}
