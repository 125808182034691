import {
  IMRSubstitution,
  ReplacementType,
} from '../../../domain/internalMaterialReplacement/model';

type ReplacementTypeLogic = {
  replacementType: ReplacementType;
  mandatoryFields: Array<keyof IMRSubstitution>;
  deactivatedFields: Array<keyof IMRSubstitution>;
};

export function getReplacementTypeLogic(
  isNewSubstitution: boolean,
  replacementType: ReplacementType,
): ReplacementTypeLogic {
  const replacementLogicForNew = getReplacementTypeLogicForNewSubstitution(replacementType);
  return isNewSubstitution
    ? replacementLogicForNew
    : getReplacementTypeLogicForEdit(replacementLogicForNew);
}

function getReplacementTypeLogicForNewSubstitution(
  replacementType: ReplacementType,
): ReplacementTypeLogic {
  switch (replacementType) {
    case 'RELOCATION':
      return {
        replacementType: 'RELOCATION',
        mandatoryFields: [
          'replacementType',
          'region',
          'predecessorMaterial',
          'successorMaterial',
          'startOfProduction',
          'cutoverDate',
        ],
        deactivatedFields: ['salesArea', 'salesOrg', 'customerNumber', 'replacementDate'],
      };
    case 'PARTIAL_RELOCATION':
      return {
        replacementType: 'PARTIAL_RELOCATION',
        mandatoryFields: [
          'replacementType',
          'region',
          'predecessorMaterial',
          'successorMaterial',
          'customerNumber',
          'startOfProduction',
          'cutoverDate',
        ],
        deactivatedFields: ['salesArea', 'salesOrg', 'replacementDate'],
      };
    case 'PACKAGING_CHANGE':
      return {
        replacementType: 'PACKAGING_CHANGE',
        mandatoryFields: [
          'replacementType',
          'region',
          'predecessorMaterial',
          'successorMaterial',
          'replacementDate',
          'cutoverDate',
        ],
        deactivatedFields: ['customerNumber', 'startOfProduction'],
      };
    case 'PRODUCT_DEVELOPMENT':
      return {
        replacementType: 'PRODUCT_DEVELOPMENT',
        mandatoryFields: [
          'replacementType',
          'region',
          'predecessorMaterial',
          'successorMaterial',
          'replacementDate',
          'cutoverDate',
        ],
        deactivatedFields: ['customerNumber', 'startOfProduction'],
      };
    case 'DISCONTINUED':
      return {
        replacementType: 'DISCONTINUED',
        mandatoryFields: ['replacementType', 'region', 'predecessorMaterial', 'replacementDate'],
        deactivatedFields: [
          'salesArea',
          'salesOrg',
          'customerNumber',
          'successorMaterial',
          'cutoverDate',
          'startOfProduction',
        ],
      };
    case 'CUSTOMER_DROPOUT':
      return {
        replacementType: 'CUSTOMER_DROPOUT',
        mandatoryFields: ['replacementType', 'region', 'customerNumber', 'replacementDate'],
        deactivatedFields: [
          'salesArea',
          'salesOrg',
          'predecessorMaterial',
          'successorMaterial',
          'cutoverDate',
          'startOfProduction',
        ],
      };
  }
}

export function checkForbiddenFieldsForNewSubstitution(
  substitution: IMRSubstitution,
): Array<keyof IMRSubstitution> | undefined {
  if (!substitution.replacementType) return undefined;

  const forbiddenFields = getReplacementTypeLogicForNewSubstitution(
    substitution.replacementType,
  ).deactivatedFields;

  const wronglyFilledFields: Array<keyof IMRSubstitution> = [];
  forbiddenFields.forEach((field: keyof IMRSubstitution) => {
    if (Object.hasOwn(substitution, field) && substitution[field] && substitution[field] !== '') {
      wronglyFilledFields.push(field);
    }
  });

  return wronglyFilledFields;
}

export function checkMissingFields(
  substitution: IMRSubstitution,
): Array<keyof IMRSubstitution> | undefined {
  if (!substitution.replacementType) return undefined;

  // For mandatory fields it is not important if we editing or creating a new substitution, mandatory fields are still mandatory
  const mandatoryFields = getReplacementTypeLogicForNewSubstitution(
    substitution.replacementType,
  ).mandatoryFields;

  const missingFields: Array<keyof IMRSubstitution> = [];
  mandatoryFields.forEach((field: keyof IMRSubstitution) => {
    try {
      const value = substitution[field];
      if (value == null || value == '' || value == undefined) {
        missingFields.push(field);
      }
    } catch {
      missingFields.push(field);
    }
  });

  return missingFields;
}

function getReplacementTypeLogicForEdit(defaultLogic: ReplacementTypeLogic): ReplacementTypeLogic {
  // For editing, the key fields are not editable, but they are still mandatory
  const keyFields: Array<keyof IMRSubstitution> = [
    'region',
    'replacementType',
    'salesArea',
    'salesOrg',
    'customerNumber',
    'predecessorMaterial',
  ];
  // Combine old deactivated and key fields and dedupe array using set
  const newDeactivated = Array.from(new Set([...defaultLogic.deactivatedFields, ...keyFields]));
  return {
    replacementType: defaultLogic.replacementType,
    mandatoryFields: defaultLogic.mandatoryFields,
    deactivatedFields: newDeactivated,
  };
}
