import { useEffect } from 'react';

import { FETCH_ERROR_EVENT } from '../core/datasources';
import { getErrorMessage, useRateLimitedErrorNotification } from '../core/errors';

import { GridApis } from './gridTypes';

function useGridErrorNotification(grid: GridApis | undefined) {
  const showErrorMessage = useRateLimitedErrorNotification();

  useEffect(() => {
    if (!grid) return;

    const listener = ({ error }: any) => {
      showErrorMessage(getErrorMessage(error));
    };

    grid.api?.addEventListener(FETCH_ERROR_EVENT, listener);

    return () => {
      grid.api?.removeEventListener(FETCH_ERROR_EVENT, listener);
    };
  }, [grid, showErrorMessage]);
}

export default useGridErrorNotification;
