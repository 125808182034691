import useSWR from 'swr';

import { useSwrErrorNotification } from '../../core/errors';
import { requestFromApi } from '../../core/requests/httpClient';

import { VersionData } from './model';

function useVersion(): VersionData | undefined {
  const versionData = useSWR<VersionData | undefined>('info/version', requestFromApi);
  useSwrErrorNotification(versionData.error);

  return versionData?.data;
}

export default useVersion;
