import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DataHint from '../../components/DataHint';
import { StyledSection } from '../../components/StyledSection';
import GlobalSelectionCriteria from '../../components/globalSelectionCriteria/GlobalSelectionCriteria';
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner';
import { Page } from '../../components/page/Page';
import useGlobalSelectionCriteria from '../../core/globalSelectionCriteria/useGlobalSelectionCriteria';
import { t } from '../../core/i18n/i18n';
import { PlanningView } from '../../domain/demandValidation/planningView';
import { CustomerEntry } from '../../domain/globalSelection/model';
import { useCustomersData } from '../../domain/globalSelection/useCustomersData';
import useGlobalSelectionStatus, {
  GlobalSelectionStatus,
} from '../../domain/globalSelection/useGlobalSelectionStatus';

import DemandValidationContent from './DemandValidationContent';

const PageTitle = styled(Typography)`
  margin-bottom: 10px;
`;

export default function DemandValidationPage() {
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntry | undefined>(undefined);
  const [planningView, setPlanningView] = useState<PlanningView>(PlanningView.REQUESTED);

  const title = `${t('tabbar.functions.label', {})} | ${t(
    'tabbarMenu.validation-of-demand.label',
    {},
  )}`;

  const { globalSelection } = useGlobalSelectionCriteria();
  const { data: customerData, isLoading } = useCustomersData(globalSelection);

  useEffect(() => {
    setSelectedCustomer(customerData ? customerData[0] : undefined);
  }, [customerData]);

  const globalSelectionStatus = useGlobalSelectionStatus(
    { data: customerData, isLoading },
    selectedCustomer,
  );

  return (
    <Page title={title}>
      <GlobalSelectionCriteria />
      <StyledSection last={true} grow={true} style={{ position: 'relative' }}>
        <PageTitle variant={'h6'}>
          {t('validation_of_demand.title', {})} |{' '}
          {t(`planing_type.title.${planningView as PlanningView}`, {})}
        </PageTitle>
        {globalSelectionStatus === GlobalSelectionStatus.DATA_AVAILABLE &&
          // this is for the compiler, because it does not understand that the field are check in useGlobalSelectionStatus
          customerData &&
          selectedCustomer &&
          globalSelection && (
            <DemandValidationContent
              customers={customerData}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              globalSelection={globalSelection}
              planningView={planningView}
              onPlanningViewChange={setPlanningView}
            />
          )}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_LOADING && <LoadingSpinner />}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_NO_RESULTS && (
          <DataHint text={t('hint.noData', {})} />
        )}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_ERROR && (
          <DataHint text={t('hint.errorLoadingData', {})} />
        )}
        {globalSelectionStatus === GlobalSelectionStatus.DATA_NOTHING_SELECTED && (
          <DataHint text={t('hint.selectData', {})} />
        )}
      </StyledSection>
    </Page>
  );
}
