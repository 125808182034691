import { createGlobalStyle } from 'styled-components';

import { headerHeight } from '../components/page/Header';
import { tabMenuHeight } from '../components/page/TabBarNavigation';

const GlobalStyles = createGlobalStyle`
  html,body,#app {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    font-family: "Roboto", sans-serif;
  }

  body {
    background: #fff;
  }

  #app {
    padding-top: calc(${headerHeight} + ${tabMenuHeight});
    display: flex;
    flex-direction: column;
  }

  *,*:before,*:after {
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
