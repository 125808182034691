import React from 'react';

export function SpinnerSvg({ className }: { className?: string }) {
  return (
    <svg
      width="111px"
      height="111px"
      viewBox="0 0 111 111"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(2.000000, 2.000000)">
          <circle stroke="currentColor" strokeWidth="4" cx="53.5" cy="53.5" r="53.5"></circle>
          <circle stroke="currentColor" strokeWidth="4" cx="53.5" cy="53.5" r="45.5"></circle>
          <circle stroke="currentColor" strokeWidth="4" cx="54" cy="54" r="26"></circle>
          <circle stroke="currentColor" strokeWidth="4" cx="54" cy="54" r="17"></circle>
          <g transform="translate(8.000000, 9.000000)" fill="currentColor" fillRule="nonzero">
            <circle cx="45.403831" cy="9" r="9"></circle>
            <circle
              transform="translate(73.158849, 22.366112) rotate(51.000000) translate(-73.158849, -22.366112) "
              cx="73.1588486"
              cy="22.3661117"
              r="9"
            ></circle>
            <circle
              transform="translate(80.013772, 52.399493) rotate(103.000000) translate(-80.013772, -52.399493) "
              cx="80.0137717"
              cy="52.3994928"
              r="9"
            ></circle>
            <circle
              transform="translate(60.806704, 76.484395) rotate(154.000000) translate(-60.806704, -76.484395) "
              cx="60.8067043"
              cy="76.484395"
              r="9"
            ></circle>
            <circle
              transform="translate(30.000958, 76.484395) rotate(-154.000000) translate(-30.000958, -76.484395) "
              cx="30.0009576"
              cy="76.4843951"
              r="9"
            ></circle>
            <circle
              transform="translate(10.793890, 52.399493) rotate(-103.000000) translate(-10.793890, -52.399493) "
              cx="10.7938901"
              cy="52.3994929"
              r="9"
            ></circle>
            <circle
              transform="translate(17.648813, 22.366112) rotate(-51.000000) translate(-17.648813, -22.366112) "
              cx="17.6488131"
              cy="22.3661118"
              r="9"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}
